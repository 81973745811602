import React, { useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import VersionText from './VersionText'
import classNames from 'classnames'
import './DataGridWrapper.css'

type Props = {
  header?: string
  leftHeader?: JSX.Element
  rightHeader?: JSX.Element
  children: any
  scrollable?: boolean
}

const DataGridWrapper = (props: Props) => {
  const headers = !!props.leftHeader || !!props.rightHeader
  const childClass = classNames({ 'whitebox-child-wrapper': true, scrollable: props.scrollable })
  return (
    <div className="container text-center">
      <div className="dataGridContainer">
        <div className="whiteBox">
          <h1>{props.header}</h1>
          {headers ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'space-between',
                flexWrap: 'wrap',
                marginBottom: 16,
              }}
            >
              <div>{props.leftHeader}</div>
              <div style={{ marginLeft: 'auto' }}>{props.rightHeader}</div>
            </div>
          ) : null}
          <div className={childClass}>{props.children}</div>
        </div>
      </div>
      <VersionText />
    </div>
  )
}

export default DataGridWrapper
