import { Alert, Row } from 'react-bootstrap'
import { WrappedFieldProps } from 'redux-form'
import classNames from 'classnames'
import { Col } from 'react-bootstrap'
import React from 'react'
import Help from './Help'
import Loading from './Loading'

const FIELD_EVENT_HANDLER = /^(?:on|handle)[A-Z]/

/**
 * Perform shallow equals comparison of two redux-form field objects to
 * determine if the field has changed.
 */
// eslint-disable-next-line no-unused-vars
function fieldShallowEquals(field, nextField) {
  for (const prop in field) {
    // Ignore event handlers, as they continually get recreated by redux-form
    if (!FIELD_EVENT_HANDLER.test(prop) && field[prop] !== nextField[prop]) {
      return false
    }
  }
  return true
}

export type FormFieldProps = {
  id?: string
  children: React.ReactNode
  help?: string
  inputProps?: any
  inputClass?: string
  readOnly?: boolean
  label: string
  labelColSize?: number
  fieldColSize?: number
  thirdColumn?: React.ReactNode
  thirdColSize?: number
  loading?: boolean
  name?: string
} & WrappedFieldProps &
  any

/**
 * A form field in a Bootstrap 3 two column layout.
 *
 * This component manages:
 * - Bootstrap structure and classes
 * - A loading indicator
 * - A <Label> for the field
 * - Label help text
 * - Validation error style and display
 *
 * The form input itself should be passed as content.
 */
const FormField = (props: FormFieldProps) => {
  const {
    meta,
    help,
    inputClass,
    inputProps,
    label,
    loading,
    thirdColumn,
    labelColSize,
    fieldColSize,
    thirdColSize,
  } = props
  if (thirdColumn && !thirdColSize) {
    throw new Error('thirdColSize is required since a thirdColumn was provided')
  }
  const error = meta && meta.touched && meta.error
  const warning = meta && meta.touched && meta.warning
  return (
    <Col sm={12}>
      <Row className={classNames({ 'has-error': error })} style={{ paddingTop: '0em' }}>
        <Col sm={labelColSize} className="control-label">
          {loading && <Loading inline />} <label htmlFor={inputProps.id}>{label}</label>
        </Col>
        <Col sm={fieldColSize || 11 - labelColSize} className={inputClass}>
          {props.children}
        </Col>
        {thirdColumn ? <Col md={thirdColSize}>{thirdColumn}</Col> : null}
        {help && (
          <Col sm={1}>
            <Help text={help} />
          </Col>
        )}
      </Row>
      {warning && <Alert variant="warning">{warning}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
    </Col>
  )
}

FormField.defaultProps = {
  labelColSize: 4,
  inputProps: {},
}

export default FormField
