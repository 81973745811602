import React from 'react'
import queryString from 'query-string'
import { Alert } from 'react-bootstrap'
import DataGridWrapper from '../components/DataGridWrapper'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import InspectionConfigform from '../containers/InspectionConfigForm'

const InspectAdminView = (props: any) => {
  const urlQuery = queryString.parse(props.location.search)
  const agencyNameFilter = urlQuery.agencyName
  const leftHeader = <AgencyFilterSelect agencyNameFilter={agencyNameFilter} location={props.location} />
  return (
    <DataGridWrapper header="Inspect Admin" leftHeader={leftHeader} scrollable>
      <Alert variant="warning">This feature is still in beta</Alert>
      {agencyNameFilter ? <InspectionConfigform agencyName={agencyNameFilter} /> : <div>Select an agency</div>}
    </DataGridWrapper>
  )
}
export default InspectAdminView
