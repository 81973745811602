import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Row, Col, Button, Badge, Table } from 'react-bootstrap'
import { FaPlus, FaExclamation } from 'react-icons/fa'
import { LinkContainer } from 'react-router-bootstrap'
import { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import { getAgenciesList } from '../selectors'
import AddAgencyForm from '../containers/AddAgencyForm'
import FirebaseConnector from '../components/FirebaseConnector'
import ViewWrapper from '../components/ViewWrapper'
import * as agenciesActions from '../actions/agencies'
import { loadAllAgencyDevices } from '../actions/devices'
import { devicesRef } from '../firebase'
import {
  getNumDevicesByAgency,
  getNumAndroidDevicesByAgency,
  getNumIosDevicesByAgency,
  getNumPcDevicesByAgency,
} from '../selectors/devicesSelectors'

function mapStateToProps(state) {
  return {
    agencies: getAgenciesList(state),
    numDevicesByAgency: getNumDevicesByAgency(state),
    numAndroidDevicesByAgency: getNumAndroidDevicesByAgency(state),
    numIosDevicesByAgency: getNumIosDevicesByAgency(state),
    numPcDevicesByAgency: getNumPcDevicesByAgency(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...agenciesActions, loadAllAgencyDevices }, dispatch)
}

type Props = {
  agencies: Record<string, any>
  numDevicesByAgency: Record<string, any>
  numAndroidDevicesByAgency: Record<string, any>
  numIosDevicesByAgency: Record<string, any>
  numPcDevicesByAgency: Record<string, any>
  loadAllAgencyDevices: Function
} & RouteComponentProps

const AgenciesView = (props: Props) => {
  const { agencies, numDevicesByAgency, numAndroidDevicesByAgency, numIosDevicesByAgency, numPcDevicesByAgency } = props
  const urlQuery = queryString.parse(props.location.search)
  const addAgency = urlQuery && urlQuery.addAgency

  const totalLicenses = agencies.reduce((prev, agency) => prev + (agency.licenses || 0), 0)
  const totalDevices = agencies
    .map(a => numDevicesByAgency[a.agencyName])
    .reduce((prev, numDevices) => prev + (numDevices || 0), 0)
  const totalAndroidDevices = Object.values(numAndroidDevicesByAgency).reduce((a: number, b: number) => a + b, 0) || 0
  const totalIosDevices = Object.values(numIosDevicesByAgency).reduce((a: number, b: number) => a + b, 0) || 0
  const totalPcDevices = Object.values(numPcDevicesByAgency).reduce((a: number, b: number) => a + b, 0) || 0

  return (
    <ViewWrapper header="Agencies" sizable overrideStyle={{ minWidth: 670 }}>
      <FirebaseConnector key="devices" firebaseRef={devicesRef} onNodeChange={props.loadAllAgencyDevices} />

      {addAgency && (
        <div>
          <h2>Add Agency</h2>
          <AddAgencyForm location={props.location} />
        </div>
      )}
      <Col>
        <Row>
          <Col md={{ span: 9, offset: 3 }} className="text-right mb-2">
            {!addAgency && (
              <LinkContainer
                to={{ pathName: '/agencies', search: queryString.stringify({ ...urlQuery, addAgency: true }) }}
                active={false}
              >
                <Button variant="primary">
                  Add <FaPlus />
                </Button>
              </LinkContainer>
            )}
          </Col>
        </Row>
      </Col>
      <Row />
      {agencies && agencies.size && (
        <Table striped size="sm" responsive>
          <thead>
            <tr>
              <th className="text-center">agencyName</th>
              <th className="text-center">displayName</th>
              <th className="text-center">isActive</th>
              <th className="text-center">licenses</th>
              <th className="text-center">Android</th>
              <th className="text-center">iOS</th>
              <th className="text-center">PC</th>
              <th className="text-center">edit</th>
              <th className="text-center">config</th>
            </tr>
          </thead>
          <tbody>
            {agencies.map(agency => (
              <tr key={agency.agencyName}>
                <td>{agency.agencyName}</td>
                <td>{agency.displayName}</td>
                <td>{String(agency.isActive)}</td>
                <td>
                  {`${numDevicesByAgency[agency.agencyName] || 0} of ${agency.licenses || 0} `}
                  {numDevicesByAgency[agency.agencyName] > agency.licenses && (
                    <Badge variant="danger">
                      <FaExclamation />
                    </Badge>
                  )}
                </td>
                <td>{numAndroidDevicesByAgency[agency.agencyName] || 0}</td>
                <td>{numIosDevicesByAgency[agency.agencyName] || 0}</td>
                <td>{numPcDevicesByAgency[agency.agencyName] || 0}</td>
                <td>
                  <LinkContainer to={`/agencies/${agency.agencyName}`}>
                    <Button variant="primary" size="sm">
                      Edit
                    </Button>
                  </LinkContainer>
                </td>
                <td>
                  <LinkContainer to={`/agencies/${agency.agencyName}/config`}>
                    <Button variant="primary" size="sm">
                      Config
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
            <tr>
              <td />
              <td />
              <td />
              <td>
                {`${totalDevices} of ${totalLicenses} `}
                {totalDevices > totalLicenses && (
                  <Badge variant="danger">
                    <FaExclamation />
                  </Badge>
                )}
              </td>
              <td>{totalAndroidDevices}</td>
              <td>{totalIosDevices}</td>
              <td>{totalPcDevices}</td>
              <td />
              <td />
            </tr>
          </tbody>
        </Table>
      )}
    </ViewWrapper>
  )
}

export default compose(
  firebaseConnect((props, firebase) => ['/agencies/']),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AgenciesView)
