import React from 'react'
import Immutable from 'immutable'
import { Badge } from 'react-bootstrap'
import { FaExclamationCircle } from 'react-icons/fa'
import { Cell } from 'fixed-data-table-2'
import dateFormat from 'dateformat'

type Props = {
  rowIndex: number
  data: Immutable.List<any>
  columnKey: string
  isDate: boolean
}

const TextDataCell = ({ rowIndex, data, columnKey, isDate, ...props }) => {
  const val = data.get(rowIndex)[columnKey]
  let valToDisplay
  if (isDate) {
    valToDisplay = val && dateFormat(val, 'mm/dd/yyyy h:MM:ss TT')
  } else {
    valToDisplay = String(val)
  }
  return (
    <Cell {...props}>
      {val === false && (
        <Badge variant="danger">
          <FaExclamationCircle />
        </Badge>
      )}
      {valToDisplay}
    </Cell>
  )
}

export default TextDataCell
