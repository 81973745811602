/// import { firestore } from '../firebase'
import { client, GET_POST_GIS_FEATURE_SET_ID } from '../apollo'
//TODO: rename this file?? update entire admin site to no longer use firestore/featureSets
export const getFeatureSetGuidForDataSource = async (agencyName: string, dataSourceName: string) => {
  if (!agencyName || !dataSourceName) throw new Error('agencyName or dataSourceName missing')
  // const featureSets = await firestore
  //   .collection('featureSets')
  //   .where('agencyName', '==', agencyName)
  //   .where('dataSourceName', '==', dataSourceName)
  //   .get()
  // if (featureSets.empty)
  // if (featureSets.size > 1)
  // let featureSetGuid
  // featureSets.forEach(doc => {
  //   featureSetGuid = doc.id
  // })
  // return featureSetGuid

  const res = await client.query({
    query: GET_POST_GIS_FEATURE_SET_ID,
    variables: {
      agencyName,
      dataSourceName,
    },
  })

  if (res && res.data && res.data.feature_set && res.data.feature_set[0] && res.data.feature_set[0].guid) {
    if (res.data.feature_set.length > 1) {
      throw new Error('This data source corresponds to more than one feature set')
    }
    return res.data.feature_set[0].guid
  } else {
    alert('This data source is using the old editor ')
  }
  return null
}
