import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ViewWrapper from '../components/ViewWrapper'
import ExportDispatchesForm from '../containers/ExportDispatchesForm'
import { getAgencyNameUserOrUrl } from '../selectors'
import { withRouter, RouteComponentProps } from 'react-router-dom'

function mapStateToProps(state, ownProps) {
  return {
    agencyName: getAgencyNameUserOrUrl(state, ownProps),
  }
}

type Props = {
  agencyName: string
  user: Record<string, any>
} & RouteComponentProps

const ExportDispatchesView = ({ agencyName, location, history }: Props) => {
  return (
    <ViewWrapper header="Export Dispatches">
      {agencyName ? <ExportDispatchesForm onCancel={history.goBack} location={location} /> : 'No agency selected'}
    </ViewWrapper>
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(ExportDispatchesView)
