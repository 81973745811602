import React from 'react'
import { Row, Col, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import { FormFieldReactSelect, SimpleBootstrapInput } from '../components/BootstrapReduxForm'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { formValues, reduxForm, Field } from 'redux-form'
import { saveAvlDisplayGroups, updateDispatchGroups } from '../actions/agencyConfigs'
import { saveDeviceGroup, deleteDeviceGroup, setIsDispatchGroup } from '../actions/deviceGroups'
import {
  getAgencyNameUserOrUrl,
  getAgencyConfigJs,
  getUniqueDevicesArray,
  getDeviceGroupsDispatchOnlyNameArray,
  getDeviceGroupsNotDispatchNameArray,
  getDeviceGroupsIdsObjectJs,
  getAgencies,
} from '../selectors'
import { withRouter } from 'react-router-dom'

const DEVICE_IDS = 'deviceIds'
const DEVICE_GROUP = 'deviceGroup'
const IS_DISPATCH_GROUP = 'isDispatchGroup'

const validateForm = (data, props) => {
  const errors = {}

  const regexInvalidFirebaseKeyNames = /[.#$/[\]]/
  if (data[DEVICE_GROUP]) {
    const regexpMatch = data[DEVICE_GROUP].match(regexInvalidFirebaseKeyNames)
    if (regexpMatch) {
      errors[DEVICE_GROUP] = `Group name contains an invalid character: ${regexpMatch[0]}`
    }
  }

  if (data[DEVICE_IDS] === '') {
    errors[DEVICE_IDS] = 'Groups must include at least 1 device'
  }

  if (props.nonDispatchDeviceGroupNames && props.nonDispatchDeviceGroupNames.includes(data[DEVICE_GROUP])) {
    errors[DEVICE_GROUP] =
      `The group you're trying to edit, "${data[DEVICE_GROUP]}", ` +
      `already exists as a Display group. Please edit it in the Display Groups tab.`
  }

  return errors
}

function mapStateToProps(state, ownProps) {
  return {
    agencies: getAgencies(state),
    agencyName: getAgencyNameUserOrUrl(state, ownProps),
    agencyConfig: getAgencyConfigJs(state, ownProps),
    devices: getUniqueDevicesArray(state, ownProps),
    deviceGroupNames: getDeviceGroupsDispatchOnlyNameArray(state, ownProps),
    nonDispatchDeviceGroupNames: getDeviceGroupsNotDispatchNameArray(state, ownProps),
    deviceGroupIds: getDeviceGroupsIdsObjectJs(state, ownProps),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { saveDeviceGroup, deleteDeviceGroup, setIsDispatchGroup, saveAvlDisplayGroups, updateDispatchGroups },
    dispatch
  )
}

const DELIMITER = ','

type Props = {
  change: Function
  handleSubmit: Function
  initialize: Function
  reset: any
  saveDeviceGroup: Function
  deleteDeviceGroup: Function
  setIsDispatchGroup: Function
  saveAvlDisplayGroups: Function
  updateDispatchGroups: Function
  agencies: Record<string, any>
  devices: any[]
  deviceGroupNames: any[]
  deviceGroupIds: Record<string, any>
  agencyName: string
  agencyConfig: Record<string, any>
  selectedDeviceGroup: Record<string, any>
}

class EditResourceGroupForm extends React.Component<Props> {
  componentWillMount() {
    this.props.initialize({
      [DEVICE_GROUP]: '',
      [DEVICE_IDS]: '',
      [IS_DISPATCH_GROUP]: true,
    })
  }

  onSelectGroup = (weirdObject, group) => {
    // call the redux-form change action, since we are overriding it here
    if (!group) {
      return this.props.reset()
    }

    this.props.change(DEVICE_GROUP, group)

    // load the selected group's devices into the DEVICE_IDS select component
    const deviceIds = this.props.deviceGroupIds && this.props.deviceGroupIds[group]
    if (deviceIds) {
      return this.props.change(DEVICE_IDS, deviceIds.join(DELIMITER))
    }
  }

  submitForm = data => {
    if (data[DEVICE_GROUP]) {
      if (!data[DEVICE_IDS]) {
        // user has pressed "Save" with no devices in the group.
        // Rather than calling saveDeviceGroup, which will delete the group
        // quietly, call deleteGroup so they get a confirmation alert
        //
        // This should now be caught in the validation process, so we shouldn't
        // ever reach this code
        return this.deleteGroup()
      } else {
        const saveDeviceGroupPromise = this.props.saveDeviceGroup(
          this.props.agencyName,
          data[DEVICE_GROUP],
          data[DEVICE_IDS].split(DELIMITER)
        )
        const setIsDispatchGroupPromise = this.props.setIsDispatchGroup(
          this.props.agencyName,
          data[DEVICE_GROUP],
          data[IS_DISPATCH_GROUP]
        )
        return Promise.all([saveDeviceGroupPromise, setIsDispatchGroupPromise]).then(this.props.reset)
      }
    }
  }

  deleteGroup = () => {
    const groupName = this.props.selectedDeviceGroup
    const sureYouWantToDelete = confirm(`Are you sure you want to delete the "${groupName}" group?`) // eslint-disable-line no-restricted-globals
    if (sureYouWantToDelete && groupName) {
      return this.props.deleteDeviceGroup(this.props.agencyName, groupName).then(this.props.reset)
    }
  }

  selectAll = () => {
    const { devices } = this.props
    const devicesString = devices && devices.map(dev => dev.key).join(DELIMITER)
    this.props.change(DEVICE_IDS, devicesString)

    // Add a new node to the page to force it to re-render, otherwise the select
    // extends down past the bottom of the page sometimes.
    // TODO: find a less hacky way to fix this.
    const emptyDiv = document.createElement('div')
    document.body.insertBefore(emptyDiv, document.getElementById('root'))
    setTimeout(() => document.body.removeChild(emptyDiv), 10)
  }

  render() {
    const {
      agencies,
      agencyName,
      devices,
      deviceGroupNames,
      handleSubmit,
      updateDispatchGroups,
      agencyConfig,
      selectedDeviceGroup,
    } = this.props

    // const agencyDisplayNameObj = agencies
    //   ? Object.entries(agencies).reduce((acc, [agencyName, { displayName }]) => {
    //       acc[agencyName] = displayName || agencyName
    //       return acc
    //     }, {})
    //   : {}
    const deviceOptions = devices
      .filter(device => device.agencyName === this.props.agencyName)
      .map(device => ({
        value: device.key,
        // label: `${agencyDisplayNameObj[device.agencyName] || device.agencyName} - ${device.displayName}`,
        label: device.displayName,
      }))
    deviceOptions.sort((a, b) => a.label && a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }))

    const deviceGroupOptions = deviceGroupNames.map(group => ({
      value: group,
      label: group,
    }))
    deviceGroupOptions.sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }))

    const updateDispatchUseFunction = () => {
      const inUse = agencyConfig && agencyConfig.dispatchGroups ? agencyConfig.dispatchGroups : false
      updateDispatchGroups(agencyName, inUse).then(() => {})
    }

    let dispatchButton
    if (agencyConfig && agencyConfig.dispatchGroups) {
      dispatchButton = (
        <Button variant="primary" onClick={updateDispatchUseFunction}>
          Dispatch Resource Enabled
        </Button>
      )
    } else {
      dispatchButton = (
        <Button variant="outline-dark" onClick={updateDispatchUseFunction}>
          Dispatch Resource Disabled
        </Button>
      )
    }
    return (
      <form>
        <Col>{deviceGroupOptions && dispatchButton}</Col>
        {deviceGroupNames.length === 0 ? (
          <Row style={{ width: 250 }}>
            {' '}
            <Field
              name={DEVICE_GROUP}
              component={SimpleBootstrapInput}
              label="Resource Group"
              type="text"
              placeholder="Add a Resource Group"
              delimiter={DELIMITER}
            />
            {selectedDeviceGroup && (
              <ButtonToolbar>
                <Button variant="primary" className="col-sm" onClick={handleSubmit(this.submitForm)}>
                  Save
                </Button>
                <Button className="col-sm" onClick={this.props.reset}>
                  Cancel
                </Button>
              </ButtonToolbar>
            )}
          </Row>
        ) : (
          <Row style={{ textAlign: 'left' }}>
            <Field
              name={DEVICE_GROUP}
              component={FormFieldReactSelect}
              label="Resource Group"
              allowCreate
              placeholder="Select a resource, or type to add a new resource..."
              help="This must be re-configured if a device with dispatch filtering is transferred to a different resource/apparatus."
              options={deviceGroupOptions}
              delimiter={DELIMITER}
              onChange={this.onSelectGroup}
              labelColSize={2}
              fieldColSize={6}
              thirdColSize={4}
              thirdColumn={
                selectedDeviceGroup && (
                  <ButtonToolbar>
                    <ButtonGroup>
                      <Button onClick={handleSubmit(this.submitForm)}>Save</Button>
                      {deviceGroupNames.includes(selectedDeviceGroup) && (
                        // only show if this is not a newly created group
                        <Button className="ml-1" variant="danger" onClick={this.deleteGroup}>
                          Delete Group
                        </Button>
                      )}
                      <Button variant="outline-dark" className="ml-1" onClick={this.props.reset}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                )
              }
            />
          </Row>
        )}
        {selectedDeviceGroup ? (
          <Row style={{ textAlign: 'left' }}>
            <Field
              name={DEVICE_IDS}
              component={FormFieldReactSelect}
              label="Devices in Group"
              placeholder="Associate a device with this resource..."
              options={deviceOptions}
              multi
              delimiter={DELIMITER}
              labelColSize={2}
              fieldColSize={8}
              thirdColSize={2}
              thirdColumn={
                <Button variant="outline-dark" onClick={this.selectAll}>
                  Add All Devices
                </Button>
              }
            />
          </Row>
        ) : null}
      </form>
    )
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'EditResourceGroup',
    validate: validateForm,
  }),
  formValues({ selectedDeviceGroup: DEVICE_GROUP }),
  firebaseConnect((props, firebase) => {
    const { agencyName, agencyAllowedSharing } = props
    let paths = ['/agencies/']
    if (agencyName) {
      paths.push(`/agencyConfigs/${agencyName}`)
      paths.push(`/deviceGroups/${agencyName}`)
    }
    paths.push(`/devices/${agencyName}`)
    if (agencyAllowedSharing) {
      for (const ag in agencyAllowedSharing) {
        paths.push(`/devices/${ag}`)
      }
    }
    return paths
  })
)(EditResourceGroupForm)
