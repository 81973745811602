import { SAVE_DEVICE_GROUP, DELETE_DEVICE_GROUP, SET_IS_DISPATCH_GROUP } from '../constants/ActionTypes'

import { deviceGroupsRef } from '../firebase'

export function saveDeviceGroup(agencyName, groupName, idsArray) {
  const idsObj = idsArray
    ? idsArray.reduce((acc, id) => {
        acc[id] = true
        return acc
      }, {})
    : null

  return {
    type: SAVE_DEVICE_GROUP,
    payload: deviceGroupsRef
      .child(agencyName)
      .child(groupName)
      .child('deviceIds')
      .set(idsObj),
  }
}

export function setIsDispatchGroup(agencyName, groupName, isDispatchGroup) {
  return {
    type: SET_IS_DISPATCH_GROUP,
    payload: deviceGroupsRef
      .child(agencyName)
      .child(groupName)
      .child('dispatchGroup')
      .set(isDispatchGroup || null),
  }
}

export function deleteDeviceGroup(agencyName, groupName) {
  return {
    type: DELETE_DEVICE_GROUP,
    payload: deviceGroupsRef
      .child(agencyName)
      .child(groupName)
      .set(null),
  }
}
