import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as cadCentersActions from '../actions/cadCenters'
import { cadCentersRef } from '../firebase'
import DataGridView from '../components/DataGridView'
import Immutable from 'immutable'

const columns = [
  {
    key: 'displayName',
    name: 'Display Name',
    sortable: true,
    width: 240,
  },
  {
    key: 'avlFormat',
    name: 'AVL Format',
    sortable: true,
    width: 200,
  },
  {
    key: 'avlHost',
    name: 'AVL Host',
    sortable: true,
    width: 200,
  },
  {
    key: 'avlPort',
    name: 'AVL Port',
    sortable: true,
    width: 200,
  },
]

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    cadCenters: state.cadCenters.data.valueSeq().toList(),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadAllCadCenters: cadCentersActions.loadAllCadCenters,
    },
    dispatch
  )
}

type Props = {
  user: Record<string, any>
  cadCenters: Immutable.List<any>
  loadAllCadCenters: Function
  location: Record<string, any> // react-router location object
}

const CadCentersView = ({ user, cadCenters, loadAllCadCenters }: Props) => (
  <DataGridView
    dataList={cadCenters}
    firebaseRef={cadCentersRef}
    onFirebaseChange={loadAllCadCenters}
    columns={columns}
    user={user}
    requiredPermission="superAdmin" // TODO: use constant
  />
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadCentersView)
