import { ADD_USER, LOAD_ALL_USERS, LOAD_USER, CLEAR_ADD_USER_MESSAGE } from '../constants/ActionTypes'
import Immutable from 'immutable'
import User from '../records/user'

export const UsersState = Immutable.Record({
  // eslint-disable-line new-cap
  data: Immutable.Map(),
  editing: Immutable.Map(),
  addUserErrorMessage: null,
  addUserSuccessMessage: null,
}) as any

const initialState = new UsersState()

export default function reducer(state = initialState, action: { type?: string; payload?: any; error?: any } = {}) {
  switch (action.type) {
    case LOAD_ALL_USERS:
      const newUsers = Immutable.fromJS(action.payload.users, (key, val) => {
        if (val && val.get('agencyName')) {
          // users must have an agencyName
          return new User({ uid: key, ...val.toJS() })
        }
        const isIndexed = Immutable.Iterable.isIndexed(val)
        return isIndexed ? val.toList() : val.toMap()
      })
      return state.set('data', newUsers)
    case LOAD_USER:
      const { user, userId } = action.payload
      const userRecord = new User({ uid: userId, ...user })
      // return state.setIn(['data', userId], userRecord)
      return state.set('data', state.data.set(userId, userRecord))
    // return state.updateIn(['data', userId], () => userRecord)
    case ADD_USER:
      if (action.error) {
        // if error is true, payload should be an Error object
        const errorMessage = action.payload.message
        return state.withMutations(obj => {
          obj
            .set('addUserSuccessMessage', null) // remove any previous message
            .set('addUserErrorMessage', errorMessage)
        })
      } else {
        const message = action.payload
        // if we successfully added a user, remove any set addUserErrorMessage message
        return state.set('addUserSuccessMessage', message).set('addUserErrorMessage', null)
      }
    case CLEAR_ADD_USER_MESSAGE:
      return state.withMutations(obj => {
        obj.set('addUserSuccessMessage', null).set('addUserErrorMessage', null)
      })
    // return state.set('addUserErrorMessage', null)
    // return {
    // ...state,
    // data: action.payload
    // }
    default:
      return state
  }
}
