import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase'
import { getInspectionConfigsArray, getGisDataSourcesOrdered } from '../selectors'
import { InspectionConfig } from '../types/InspectionTypes'
import { FormControl, Button, Row, Col, Tabs, Tab, Alert, Card, Collapse } from 'react-bootstrap'
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'
import { Formik, Field, FieldArray } from 'formik'
import { firestore } from '../firebase'
import {
  FormikInput,
  FormikReactSelect,
  FormikSortableSelect,
  FormikCheckbox,
  FormikMultiTextInput,
  FormikTextArea,
} from '../components/FormikFields'
import SortableSelect from '../components/SortableSelect'
import './InspectionConfigForm.css'
import { useQuery, useMutation } from 'react-apollo'
import {
  INSPECTION_CONFIGS_BY_AGENCY_NAME,
  FEATURE_SETS_BY_AGENCY_QUERY,
  AGENCIES_WITH_INSPECTION_CONFIGS,
  UPDATE_INSPECTION_CONFIG,
  INSERT_INSPECTION_CONFIG,
} from '../apollo'
import { newRand } from '../utils'
import _ from 'lodash'

const initialConfigId: string = undefined

enum FormType {
  edit = 'edit',
  add = 'add',
  copy = 'copy',
}
const initialOpenKey: string = undefined
const initialErrorString: string = undefined

type InspectionConfigFormValues = {
  guid: string
  agency_name: string
  display_name: string
  feature_set_guid: string
  inspection_type: string
  schemaProperties: any[]
  visible_data_sources: string[]
  pass_fail_type: string
  customConfig: string
  automated_email_display_name: string
  automated_email_user_name: string
  agency_contact_emails: string[]
  signature_required?: boolean
}

enum ComponentType {
  email = 'email',
  hidden = 'hidden',
  readOnly = 'readOnly',
  numberInput = 'numberInput',
  select = 'select',
  checkbox = 'checkbox',
  date = 'date',
  textInput = 'textInput',
  passFailNa = 'passFailNa',
}

const componentTypeOptions: { value: ComponentType; label: string }[] = [
  { value: ComponentType.email, label: 'Email' },
  { value: ComponentType.hidden, label: 'Hidden' },
  { value: ComponentType.readOnly, label: 'Read Only' },
  { value: ComponentType.numberInput, label: 'Number' },
  { value: ComponentType.select, label: 'Select' },
  { value: ComponentType.checkbox, label: 'Check Box' },
  { value: ComponentType.date, label: 'Date Picker' },
  { value: ComponentType.textInput, label: 'Text Input' },
  { value: ComponentType.passFailNa, label: 'Pass - Fail - N/A' },
]

const newConfig: InspectionConfigFormValues = {
  guid: '',
  agency_name: '',
  display_name: '',
  feature_set_guid: '',
  inspection_type: '',
  schemaProperties: [],
  visible_data_sources: [],
  pass_fail_type: '',
  customConfig: '{}',
  automated_email_display_name: '',
  automated_email_user_name: '',
  agency_contact_emails: [],
}

const getComponentType = (property: string, schema: any): string => {
  const widget = _.get(schema, ['uiSchema', property, 'ui:widget'], 'textInput')
  const fieldProperties = schema.properties[property]
  if (!fieldProperties) {
    // TODO: do we want some error tracking here?
    return 'textInput'
  }
  const { format, type } = fieldProperties
  if (format) return format
  if (type === 'boolean') return 'checkbox'
  return widget
}

const getInitialValues = (config: InspectionConfig): InspectionConfigFormValues => {
  const {
    schema,
    feature_set,
    pass_fail_type,
    automated_email_display_name,
    automated_email_user_name,
    __typename,
    ...configSettings
  } = config
  const { required, uiSchema, properties, errorMessage, passFailProperties, ...customConfig } = schema
  const customErrorMessages = _.get(schema, ['errorMessage', 'properties'], {})
  const uiOrder = _.get(schema, ['uiSchema', 'ui:order']) || Object.keys(schema.properties)
  const order = uiOrder.filter(p => Object.keys(schema.properties).includes(p))
  const schemaProperties = order.map(p => {
    const componentType = getComponentType(p, schema)
    const integer = schema.properties[p].type === 'integer'
    const errorMessage = _.get(schema, ['errorMessage', 'properties', p], '')
    return {
      key: p,
      componentType,
      required: schema.required && schema.required.includes(p),
      minimum: '',
      maximum: '',
      integer,
      enum: [],
      errorMessage,
      tempId: newRand(),
      default: '',
      ...schema.properties[p],
    }
  })
  return {
    schemaProperties,
    pass_fail_type: pass_fail_type || '',
    automated_email_display_name: automated_email_display_name || '',
    automated_email_user_name: automated_email_user_name || '',
    customConfig: JSON.stringify(customConfig, null, 2),
    ...configSettings,
  }
}

type Props = {
  agencyName: string
  gisDataSources: any
}

const mapStateToProps = (state, ownProps) => {
  return {
    inspectionConfigs: getInspectionConfigsArray(state),
    gisDataSources: getGisDataSourcesOrdered(state),
  }
}

const getActiveConfig = (agencyInspectionConfigs: InspectionConfig[], activeConfigId?: string) => {
  if (!activeConfigId) return
  return agencyInspectionConfigs.find(config => config.guid === activeConfigId)
}

const getAgencyDataSourceOptions = (gisDataSources: Record<string, any>, agencyName: string) => {
  if (!agencyName || !gisDataSources) return []
  if (gisDataSources[agencyName]) {
    return gisDataSources[agencyName].map(datasource => {
      return { value: datasource.key, label: datasource.value.displayName || datasource.key }
    })
  }
  return []
}

const getFeatureSetOptions = (data: {
  feature_set?: { agency_name: string; data_source_name: string; guid: string }[]
}) => {
  if (!data || !data.feature_set) return []
  return data.feature_set.map(featureSet => {
    return { value: featureSet.guid, label: featureSet.data_source_name }
  })
}

const validate = (values: InspectionConfigFormValues) => {
  const requiredField = ['agency_name', 'display_name', 'feature_set_guid', 'inspection_type']
  const errors: { [key: string]: string | any[] } = {}
  for (const field of requiredField) {
    if (!values[field]) {
      errors[field] = 'Required'
    }
  }
  if (!values.visible_data_sources.length) {
    errors.visible_data_sources = 'At least one data source must be visible'
  }
  if (values.signature_required) {
    if (!values.automated_email_display_name) {
      errors.automated_email_display_name = 'This field is required on signed inspections'
    }
    if (!values.automated_email_user_name) {
      errors.automated_email_user_name = 'This field is required on signed inspections'
    }
    if (!values.agency_contact_emails.length) {
      errors.agency_contact_emails = 'At least one contact email is required on signed inspections'
    }
  }
  const schemaPropertyErrors = values.schemaProperties.map(prop => {
    const propertyError: { [key: string]: string } = {}
    if (prop.componentType === ComponentType.numberInput) {
      if (prop.minimum && isNaN(Number(prop.minimum))) {
        propertyError.minimum = 'Minimum must be a number'
      }
      if (prop.maximum && isNaN(Number(prop.maximum))) {
        propertyError.maximum = 'Maximum must be a number'
      }
      if (prop.minimum && prop.maximum && prop.minimum >= prop.maximum) {
        propertyError.maximum = 'Maximum must be larger than minimum'
      }
    }
    return propertyError
  })
  values.schemaProperties.forEach((prop, index) => {
    const propertyError: { [key: string]: string } = {}
    if (prop.componentType === ComponentType.numberInput) {
      if (prop.minimum && isNaN(Number(prop.minimum))) {
        console.log(prop, index)
        errors[`schemaProperties[${index}].minimum`] = 'Minimum must be a number'
      }
      if (prop.maximum && isNaN(Number(prop.maximum))) {
        propertyError.maximum = 'Maximum must be a number'
      }
      if (prop.minimum && prop.maximum && prop.minimum >= prop.maximum) {
        propertyError.maximum = 'Maximum must be larger than minimum'
      }
    }
  })
  //errors.schemaProperties = schemaPropertyErrors
  return errors
}

export const formValsToConfig = (values: InspectionConfigFormValues): any => {
  const { schemaProperties, customConfig, visible_data_sources, agency_contact_emails, ...standardConfig } = values
  const order = schemaProperties.map(p => p.key)
  if (!schemaProperties.length) {
    throw new Error('At least one schema property is required')
  }
  if (!visible_data_sources.length) {
    throw new Error('At least one data source must be visible')
  }
  const configValues = Object.entries(standardConfig).reduce((acc, [key, val]) => {
    if (val === undefined || val === null || val === '') return acc
    acc[key] = val
    return acc
  }, {})
  const formatArray = (array: string[]): string => `{ ${array.join(', ')} }`
  const formattedValues: any = {
    visible_data_sources: formatArray(visible_data_sources),
    agency_contact_emails: formatArray(agency_contact_emails),
    ...configValues,
  }

  const newSchema: Record<string, any> = {
    required: [],
    properties: {},
    uiSchema: { 'ui:order': order },
    ...JSON.parse(customConfig),
  }
  if (order.length !== schemaProperties.length) {
    throw new Error('Schema and UI order do not match, please contact AGI')
  }
  const customErrorMessages: Record<string, string> = {}
  const passFailProperties = []
  order.forEach(key => {
    const { required, errorMessage, componentType, description, title, integer, ...property } = schemaProperties.find(
      prop => prop.key === key
    )
    if (required) {
      newSchema.required.push(key)
    }
    if (errorMessage) {
      customErrorMessages[key] = errorMessage
    }
    if (componentType === ComponentType.passFailNa) {
      passFailProperties.push(key)
      newSchema.uiSchema[key] = { 'ui:widget': 'passFailNa' }
      newSchema.properties[key] = { title, type: 'string', enum: ['Pass', 'Fail', 'N/A'] }
      if (description) {
        newSchema.properties[key].description = description
      }
    }
    if (componentType === ComponentType.numberInput) {
      newSchema.uiSchema[key] = { 'ui:widget': 'numberInput' }
      const range: { minimum?: number; maximum?: number } = {}
      if (property.minimum || property.minimum === 0) {
        range.minimum = Number(property.minimum)
      }
      if (property.maximum || property.maximum === 0) {
        range.maximum = Number(property.maximum)
      }
      newSchema.properties[key] = { title, type: integer ? 'integer' : 'number', ...range }
    }
    if (componentType === ComponentType.select) {
      newSchema.uiSchema[key] = { 'ui:widget': 'select' }
      newSchema.properties[key] = { title, type: 'string', enum: property.enum }
    }
    if (componentType === ComponentType.checkbox) {
      newSchema.properties[key] = { title, type: 'boolean' }
    }
    if (componentType === ComponentType.hidden) {
      newSchema.uiSchema[key] = { 'ui:widget': 'hidden' }
      newSchema.properties[key] = { title, type: 'integer' }
    }
    if (componentType === ComponentType.textInput) {
      newSchema.properties[key] = { title, type: 'string' }
    }
    if (componentType === ComponentType.date) {
      newSchema.properties[key] = { title, label: 'Date', format: 'date', type: 'string' }
    }
    if (componentType === ComponentType.email) {
      newSchema.properties[key] = { title, format: 'email', type: 'string' }
    }
    // 0 and false should be allowed default values
    if (property.default !== undefined && property.default !== '') {
      newSchema.properties[key].default =
        componentType === ComponentType.date ? Number(property.default) : property.default
    }
    if (!Object.values(ComponentType).includes(componentType)) {
      throw new Error(`${key} has unhandled component type of ${componentType}`)
    }
  })
  if (Object.keys(customErrorMessages).length) {
    newSchema.errorMessage = { properties: customErrorMessages }
  }
  if (passFailProperties.length) {
    newSchema.passFailProperties = passFailProperties
  }
  formattedValues.schema = newSchema
  return formattedValues
}

const renderDefaultField = ({ componentType, index }: { componentType: ComponentType; index: number }) => {
  const passFailNaOptions = [
    { value: undefined, label: 'None' },
    { value: 'Pass', label: 'Pass' },
    { value: 'Fail', label: 'Fail' },
    { value: 'N/A', label: 'N/A' },
  ]
  if (componentType === ComponentType.passFailNa) {
    return (
      <Field
        name={`schemaProperties[${index}].default`}
        component={FormikReactSelect}
        options={passFailNaOptions}
        label="Default"
      />
    )
  }
  if (componentType === ComponentType.date) {
    return (
      <Field name={`schemaProperties[${index}].default`} component={FormikInput} label="Reinspection Period (days):" />
    )
  }

  return null
}

const InspectionConfigForm = (props: Props) => {
  const [selectedAgency, setSelectedAgency] = useState(props.agencyName)
  const configsQuery = useQuery(INSPECTION_CONFIGS_BY_AGENCY_NAME, { variables: { agency_name: selectedAgency } })
  const [updateInspectionConfig, returnVal] = useMutation(UPDATE_INSPECTION_CONFIG)
  const [addInspectionConfig, returnedRows] = useMutation(INSERT_INSPECTION_CONFIG)
  const agencyInspectionConfigs: InspectionConfig[] = _.get(configsQuery, ['data', 'inspection_config'], [])
  const featureSetsQuery = useQuery(FEATURE_SETS_BY_AGENCY_QUERY, { variables: { agencyName: props.agencyName } })
  const featureSetsOptions = getFeatureSetOptions(featureSetsQuery.data)
  const [activeConfigId, setActiveConfigId] = useState(initialConfigId)
  const [formOpen, setFormOpen] = useState(false)
  const [initialValues, setInitialValues] = useState(newConfig)
  const [openKey, setOpenKey] = useState(initialOpenKey)
  const [isSorting, setIsSorting] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorString, setErrorString] = useState(initialErrorString)
  const [copyConfig, setCopyConfig] = useState(false)
  const activeConfig = getActiveConfig(agencyInspectionConfigs, activeConfigId)
  const agenciesWithConfigsQuery = useQuery(AGENCIES_WITH_INSPECTION_CONFIGS)
  const agenciesWithConfigsOptions = _.get(agenciesWithConfigsQuery, ['data', 'inspection_config'], []).map(config => ({
    label: config.agency_name,
    value: config.agency_name,
  }))
  const agencyDataSourceOptions = getAgencyDataSourceOptions(props.gisDataSources, props.agencyName)

  const launchEditForm = (addEditSetting: FormType) => {
    if (addEditSetting === FormType.edit) {
      setInitialValues(getInitialValues(activeConfig))
      setFormOpen(true)
    }
    if (addEditSetting === FormType.copy) {
      const activeConfigValues = getInitialValues(activeConfig)
      setInitialValues({
        ...activeConfigValues,
        agency_name: props.agencyName,
        feature_set_guid: '',
        guid: '',
        visible_data_sources: [],
      })
      setFormOpen(true)
    }
    if (addEditSetting === FormType.add) {
      setInitialValues({ ...newConfig, agency_name: props.agencyName })
      setFormOpen(true)
    }
  }

  const cancelEditForm = () => {
    setFormOpen(false)
    setActiveConfigId(initialConfigId)
    setOpenKey(initialOpenKey)
    setSelectedAgency(props.agencyName)
    setCopyConfig(false)
  }

  const cancelCopy = () => {
    setSelectedAgency(props.agencyName)
    setCopyConfig(false)
  }

  const handleInspectionPropertySelect = (input: string | any) => {
    input !== openKey ? setOpenKey(input) : setOpenKey(undefined)
  }

  const submitInspectForm = async (values: InspectionConfigFormValues) => {
    setErrorString(undefined)
    try {
      const formattedValues = formValsToConfig(values)
      if (formattedValues.guid) {
        const { firebase_id, agency_name, feature_set_guid, ...variables } = formattedValues
        await updateInspectionConfig({ variables })
      }
      if (!formattedValues.guid) {
        await addInspectionConfig({ variables: formattedValues })
      }
      setIsSubmitting(false)
      cancelEditForm()
      configsQuery.refetch()
    } catch (e) {
      console.log(e)
      setErrorString(e.message)
      setIsSubmitting(false)
    }
  }

  // resets state when selected agency changes
  useEffect(() => {
    if (formOpen) {
      setFormOpen(false)
    }
    if (activeConfigId) {
      setActiveConfigId(initialConfigId)
    }
    setSelectedAgency(props.agencyName)
  }, [props.agencyName])

  return (
    <div className="inspection-config-form-wrapper">
      {formOpen ? (
        <Formik
          initialValues={initialValues}
          onSubmit={submitInspectForm}
          validate={validate}
          render={props => {
            const schemaProperties = _.get(props, ['values', 'schemaProperties'], [])
            const schemaPropertiesOrder = schemaProperties.map(prop => prop.key)
            const orderSchemaProperties = (newOrder: string[]) => {
              const reOrderedProperties = newOrder.map(key => {
                return schemaProperties.find(prop => prop.key === key)
              })
              props.setFieldValue('schemaProperties', reOrderedProperties)
            }
            return (
              <form onSubmit={props.handleSubmit}>
                {errorString ? <Alert variant="danger">{errorString}</Alert> : null}
                <Tabs defaultActiveKey="base-config" id="inspection-config-tabs">
                  <Tab eventKey="base-config" title="Base Config">
                    <Field name={'active'} component={FormikCheckbox} label="Active" />
                    <Field name="display_name" component={FormikInput} label="Display Name:" />
                    <Field name="inspection_type" component={FormikInput} label="Inspection Type:" />
                    <Field name="pass_fail_type" component={FormikInput} label="Pass/Fail Type:" />
                    <Field
                      name="feature_set_guid"
                      component={FormikReactSelect}
                      label="Inspection Feature Set:"
                      options={featureSetsOptions}
                      placeholder="Select a feature set"
                      disabled={!!initialValues.guid}
                    />
                    <Field name={'signature_required'} component={FormikCheckbox} label="Signature Required" />
                    <Field
                      name="automated_email_display_name"
                      component={FormikInput}
                      label="Automated Email Display Name:"
                    />
                    <Field
                      name="automated_email_user_name"
                      component={FormikInput}
                      label="Automated Email User Name:"
                    />
                    <Field
                      name="agency_contact_emails"
                      component={FormikMultiTextInput}
                      label="Agency Contact Emails:"
                    />
                    <Field
                      name="visible_data_sources"
                      component={FormikSortableSelect}
                      options={agencyDataSourceOptions}
                      label="Visible Data Sources:"
                    />
                  </Tab>
                  <Tab eventKey="inspection-schema" title="Inspection Schema">
                    <Col md={{ span: 8, offset: 2 }} style={{ paddingTop: '0.5em' }}>
                      {props.values.inspection_type === 'hydrant' ? (
                        <Alert variant="warning">Not all features of hydrant inspections can be edited</Alert>
                      ) : null}
                      <FieldArray
                        name="schemaProperties"
                        render={helpers => {
                          const { schemaProperties } = props.values
                          const removeOption = (index: number) => {
                            helpers.remove(index)
                            setOpenKey(undefined)
                          }
                          return (
                            <div>
                              <Card style={{ textAlign: 'left' }}>
                                <Card.Header>
                                  <Button
                                    variant="primary"
                                    disabled={isSorting}
                                    onClick={() =>
                                      helpers.push({
                                        key: '',
                                        title: '',
                                        componentType: '',
                                        required: false,
                                        minimum: '',
                                        maximum: '',
                                        integer: false,
                                        enum: [],
                                      })
                                    }
                                  >
                                    Add Field
                                  </Button>
                                  <Button
                                    variant={isSorting ? 'info' : 'primary'}
                                    onClick={() => setIsSorting(!isSorting)}
                                    style={{ marginLeft: 8 }}
                                  >
                                    {isSorting ? 'Stop Sorting' : 'Start Sorting'}
                                  </Button>
                                </Card.Header>
                              </Card>
                              {isSorting ? (
                                <Col md={{ span: 10, offset: 1 }}>
                                  <SortableSelect
                                    label="Property Order:"
                                    options={[]}
                                    value={schemaPropertiesOrder}
                                    onChange={orderSchemaProperties}
                                    sortOnly
                                  />
                                </Col>
                              ) : (
                                <div>
                                  {schemaProperties.map((p, index) => {
                                    const isOpen = openKey === p.tempId
                                    return (
                                      <Card key={p.tempId}>
                                        <Card.Header onClick={() => handleInspectionPropertySelect(p.tempId)}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            <div>
                                              {isOpen ? (
                                                <FaChevronRight style={{ paddingRight: '4px' }} />
                                              ) : (
                                                <FaChevronDown style={{ paddingRight: '4px' }} />
                                              )}
                                              {isOpen ? '' : p.key || 'New Property'}
                                            </div>
                                            <Button variant="danger" size="sm" onClick={() => removeOption(index)}>
                                              Delete
                                            </Button>
                                          </div>
                                        </Card.Header>
                                        <Collapse in={isOpen}>
                                          <div>
                                            <Card.Body>
                                              <Field
                                                name={`schemaProperties[${index}].key`}
                                                component={FormikInput}
                                                label="Key:"
                                              />
                                              <Field
                                                name={`schemaProperties[${index}].title`}
                                                component={FormikInput}
                                                label="Title:"
                                              />
                                              <Field
                                                name={`schemaProperties[${index}].description`}
                                                component={FormikTextArea}
                                                label="Description:"
                                              />
                                              <Field
                                                name={`schemaProperties[${index}].errorMessage`}
                                                component={FormikInput}
                                                label="Custom Error Message:"
                                              />
                                              <Field
                                                name={`schemaProperties[${index}].componentType`}
                                                component={FormikReactSelect}
                                                options={componentTypeOptions}
                                                placeholder="Select Component Type"
                                                label="Component Type:"
                                              />
                                              {p.componentType === 'numberInput' ? (
                                                <>
                                                  <Field
                                                    name={`schemaProperties[${index}].minimum`}
                                                    component={FormikInput}
                                                    label="Minimum:"
                                                  />
                                                  <Field
                                                    name={`schemaProperties[${index}].maximum`}
                                                    component={FormikInput}
                                                    label="Maximum:"
                                                  />
                                                  <Field
                                                    name={`schemaProperties[${index}].integer`}
                                                    component={FormikCheckbox}
                                                    label="Integer Only"
                                                  />
                                                </>
                                              ) : null}
                                              <Field
                                                name={`schemaProperties[${index}].required`}
                                                component={FormikCheckbox}
                                                label="Required"
                                              />
                                              {renderDefaultField({ componentType: p.componentType, index })}
                                              {p.componentType === 'select' ? (
                                                <Field
                                                  name={`schemaProperties[${index}].enum`}
                                                  component={FormikMultiTextInput}
                                                  placeholder="Enter Enum Values"
                                                  label="Enum:"
                                                />
                                              ) : null}
                                            </Card.Body>
                                          </div>
                                        </Collapse>
                                      </Card>
                                    )
                                  })}
                                </div>
                              )}
                            </div>
                          )
                        }}
                      />
                    </Col>
                  </Tab>
                  <Tab eventKey="customConfig" title="Advanced Configuration">
                    <Field name="customConfig" component={FormikTextArea} label="Custom Configuration:" />
                  </Tab>
                </Tabs>
                <Col sm={{ span: 7, offset: 4 }}>
                  <div className="inspection-config-form-buttons">
                    <Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                    <Button size="lg" onClick={cancelEditForm}>
                      Cancel
                    </Button>
                  </div>
                </Col>
              </form>
            )
          }}
        />
      ) : (
        <>
          {!copyConfig ? (
            <Row>
              {agencyInspectionConfigs.length ? (
                <Col sm={6} md={3}>
                  <FormControl as="select" onChange={(e: React.ChangeEvent<any>) => setActiveConfigId(e.target.value)}>
                    <option value="">Select an inspection type</option>
                    {agencyInspectionConfigs.map((config, index) => {
                      return (
                        <option key={config.inspection_type + index} value={config.guid}>
                          {config.display_name}
                        </option>
                      )
                    })}
                  </FormControl>
                </Col>
              ) : null}
              <Col md={6} lg={5}>
                <div className="inspection-config-select-buttons">
                  {activeConfigId ? (
                    <Button
                      variant="primary"
                      style={{ marginRight: '4px' }}
                      onClick={() => launchEditForm(FormType.edit)}
                    >
                      Edit Config
                    </Button>
                  ) : null}
                  <Button variant="primary" style={{ marginRight: '4px' }} onClick={() => launchEditForm(FormType.add)}>
                    New Blank Config
                  </Button>
                  <Button variant="primary" onClick={() => setCopyConfig(true)}>
                    Copy Existing Config
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={6} md={3}>
                <FormControl
                  as="select"
                  onChange={(e: React.ChangeEvent<any>) => setSelectedAgency(e.target.value)}
                  value={selectedAgency}
                >
                  <option>Select an Agency</option>
                  {agenciesWithConfigsOptions.map((agencyOption, index) => {
                    return (
                      <option key={agencyOption.value + index} value={agencyOption.value}>
                        {agencyOption.label}
                      </option>
                    )
                  })}
                </FormControl>
              </Col>
              <Col sm={6} md={3}>
                <FormControl
                  as="select"
                  onChange={(e: React.ChangeEvent<any>) => setActiveConfigId(e.target.value)}
                  value={activeConfigId}
                >
                  <option value="">Select an inspection type</option>
                  {agencyInspectionConfigs.map((config, index) => {
                    return (
                      <option key={config.inspection_type + index} value={config.guid}>
                        {config.display_name}
                      </option>
                    )
                  })}
                </FormControl>
              </Col>
              {activeConfigId ? <Button onClick={() => launchEditForm(FormType.copy)}>Copy</Button> : null}
              <Button onClick={() => setCopyConfig(false)}>Back</Button>
            </Row>
          )}
        </>
      )}
    </div>
  )
}

export default compose(
  connect(mapStateToProps),
  firebaseConnect(props => [`/gisDataSources/${props.agencyName}/`]),
  firestoreConnect((props, firestore) => {
    const paths = [
      {
        collection: 'inspectionConfigs',
        where: [['agencyName', '==', props.agencyName]],
        type: 'once',
      },
    ]
    return paths
  })
)(InspectionConfigForm)
