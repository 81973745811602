import _ from 'lodash'
import { createSelector } from 'reselect'
export const getInspections = (state: any) => _.get(state, 'firestore.data.inspections')
export const getInspectionConfigs = (state: any) => _.get(state, 'firestore.data.inspectionConfigs')
export const getInspectionConfigsArray = (state: any) => _.get(state, 'firestore.ordered.inspectionConfigs')
export const getInspectionConfigDataSources = createSelector(
  getInspectionConfigsArray,
  inspectionConfigs => {
    if (inspectionConfigs) {
      return inspectionConfigs.map(config => config.inspectionDataSource)
    }
    return []
  }
)
