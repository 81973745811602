import React, { useState, useEffect, useRef } from 'react'
import { useLazyQuery } from 'react-apollo'
import { FaImage } from 'react-icons/fa'
import { Button, Modal, Carousel } from 'react-bootstrap'
import LoadingButton from '../components/BootstrapReduxForm/LoadingButton'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { GET_INSPECTION_ATTACHMENTS } from '../apollo/queries'
import firebase from '../firebase'

type Props = {
  inspectionGuid: string
  attachmentCount: number
}
const initialUrls: string[] = []

const PhotoAttachmentButton = (props: Props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [urls, setUrls] = useState(initialUrls)
  const [width, setWidth] = useState(0)
  const [getAttachmentGuids, { called, data, loading }] = useLazyQuery(GET_INSPECTION_ATTACHMENTS)
  const [downloading, setDownloading] = useState(false)
  const ref = useRef(null)
  if (props.attachmentCount === 0) return null
  const handleClose = () => {
    setModalOpen(false)
  }
  const launchModal = () => {
    if (!data) {
      getAttachmentGuids({ variables: { inspection_guid: props.inspectionGuid } })
    }
    setModalOpen(!modalOpen)
  }
  const getDownloadUrls = async (guids: string[]) => {
    const downloadUrls = await Promise.all(
      guids.map(guid =>
        firebase
          .storage()
          .ref(`inspectionAttachments/${guid}`)
          .getDownloadURL()
      )
    )
    setUrls(downloadUrls)
  }
  useEffect(() => {
    if (data) {
      const guids = data.inspection_attachment.map(a => a.guid)
      getDownloadUrls(guids)
    }
  }, [data])
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.clientWidth)
    }
  }, [ref.current])
  const zipBlobs = async (blobs: any[]) => {
    const zip = new JSZip()
    const folder = zip.folder('inspectionPics')
    for (const [i, blob] of blobs.entries()) {
      folder.file(`${i + 1}.jpg`, blob)
    }
    const res = await zip.generateAsync({ type: 'blob' })
    saveAs(res, 'inspectionPhotos.zip')
    setDownloading(false)
  }
  const startDownload = async () => {
    setDownloading(true)
    const blobs = []
    for (const url of urls) {
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = async event => {
        const blob = await xhr.response
        blobs.push(blob)
        if (blobs.length === urls.length) {
          zipBlobs(blobs)
        }
      }
      xhr.open('GET', url)
      xhr.send()
    }
  }

  return (
    <>
      <Button variant="outline-dark" size="sm" style={{ marginTop: 1 }} onClick={launchModal}>
        {props.attachmentCount}
        <FaImage style={{ marginLeft: 2, marginBottom: 2 }} />
      </Button>
      <Modal show={modalOpen} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Attached Photos</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={ref}>
          {urls.length ? (
            <Carousel>
              {urls.map(url => (
                <Carousel.Item key={url}>
                  <img
                    src={url}
                    style={{ maxWidth: '100%', minHeight: width, height: 'auto', border: '1px solid black' }}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <div style={{ width: '100%', paddingBottom: '100%' }} />
          )}
        </Modal.Body>
        {urls.length ? (
          <Modal.Footer>
            <LoadingButton
              onClick={startDownload}
              label="Download Images"
              loadingLabel="Downloading"
              loading={downloading}
            />
          </Modal.Footer>
        ) : null}
      </Modal>
    </>
  )
}

export default PhotoAttachmentButton
