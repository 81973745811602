import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { split, from } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import apolloLogger from 'apollo-link-logger'

export const createClient = (token?: string) => {
  let DEV
  DEV = false
  const GQLURL = DEV ? '://localhost:8080/v1/graphql' : 's://graphql.incidentview.com/v1/graphql'
  const headers = token
    ? {
        authorization: `Bearer ${token}`,
      }
    : {}
  const httpLink = createHttpLink({
    uri: `http${GQLURL}`,
    headers,
  })

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: `ws${GQLURL}`,
    options: {
      reconnect: true,
      connectionParams: {
        headers,
      },
    },
  })

  const wrappedHttpLink = process.env.REACT_APP_USE_REDUX_LOGGER ? from([apolloLogger, httpLink]) : httpLink

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    wrappedHttpLink
  )
  client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
  return client
}

export let client

export * from './queries'
export * from './mutations'
