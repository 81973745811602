import React, { useState, useEffect } from 'react'
import DataGridWrapper from '../components/DataGridWrapper'
import queryString from 'query-string'
import { firestore } from '../firebase'
import * as roles from '../constants/UserRoles'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import EditHistoryGrid from '../containers/EditHistoryGrid'
import EditorExportDateEditDateWarningDisplay from '../containers/EditorExportDateEditDateWarningDisplay'
import EditorUsersForm from '../containers/EditorUsersForm'
import AddLayerToEditorForm from '../containers/AddLayerToEditorForm'
import EditingBoundaryForm from '../containers/EditingBoundaryForm'
import ExportDataForGisDepartment from '../containers/ExportDataForGisDepartment'
import { Tabs, Tab } from 'react-bootstrap'
import 'react-virtualized/styles.css'

type Props = {
  user: Record<string, any>
  location: Record<string, any>
}
const EditorView = props => {
  const urlQuery = queryString.parse(props.location.search)

  return (
    <DataGridWrapper
      header={'Incident View Editor-beta'}
      leftHeader={
        props.user.isAgi && <AgencyFilterSelect agencyNameFilter={urlQuery.agencyName} location={props.location} />
      }
      rightHeader={
        props.user.isAgi &&
        urlQuery &&
        urlQuery.agencyName && (
          <EditorExportDateEditDateWarningDisplay agencyNameFilter={urlQuery.agencyName} location={props.location} />
        )
      }
    >
      <Tabs defaultActiveKey="editorAdminHome" id="Editor Tabs" mountOnEnter={true} unmountOnExit={true}>
        <Tab eventKey="editHistory" title="Edit History">
          {urlQuery && urlQuery.agencyName ? (
            <EditHistoryGrid
              agencyName={urlQuery.agencyName}

              // location={props.location} history={props.history}
            />
          ) : (
            <div style={{ marginTop: '20px' }}>Please select an agency</div>
          )}
        </Tab>
        <Tab eventKey="editorUsers" title="Editor Users">
          {urlQuery && urlQuery.agencyName ? (
            <EditorUsersForm />
          ) : (
            <div style={{ marginTop: '20px' }}>Please select an agency</div>
          )}
        </Tab>
        {props.user && props.user.isAgi ? (
          <Tab eventKey="addLayerToEditor" title="Add Layer to Editor">
            {urlQuery && urlQuery.agencyName ? (
              <AddLayerToEditorForm />
            ) : (
              <div style={{ marginTop: '20px' }}>Please select an agency</div>
            )}
          </Tab>
        ) : null}
        {props.user && props.user.isAgi ? (
          <Tab eventKey="agencyEditingBoundary" title="Agency Editing Boundary">
            {urlQuery && urlQuery.agencyName ? (
              <EditingBoundaryForm />
            ) : (
              <div style={{ marginTop: '20px' }}>Please select an agency</div>
            )}
          </Tab>
        ) : null}
        {props.user && props.user.isAgi ? (
          <Tab eventKey="exportGisData" title="Download GIS Data">
            {urlQuery && urlQuery.agencyName ? (
              <ExportDataForGisDepartment agencyName={urlQuery.agencyName} />
            ) : (
              <div style={{ marginTop: '20px' }}>Please select an agency</div>
            )}
          </Tab>
        ) : null}
        <Tab eventKey="editorUserGuide" title="Editor User Guide">
          <div>Coming Soon: Web-Editor User Guide</div>
        </Tab>
      </Tabs>
    </DataGridWrapper>
  )
}
export default EditorView
