import React, { useState } from 'react'
import FormikGroup from './FormikGroup'
import CreatableSelect from 'react-select/creatable'
import { FieldProps } from 'formik'
import { Option } from '../../types'

type Props = {
  label: string
  placeholder?: string
  disabled: boolean
} & FieldProps

const createOption = (label: string): Option => ({ label, value: label })

const createOptions = (values: string[]): Option[] => {
  if (!values) return []
  return values.map(v => createOption(v))
}

const createValues = (options: Option[]) => options.map(opt => opt.value)

const FormikMultiTextInput = (props: Props) => {
  const { field, placeholder, form } = props
  const handleChange = (options: Option[]) => {
    if (options) {
      form.setFieldValue(field.name, createValues(options))
    } else {
      form.setFieldValue(field.name, [])
    }
  }
  const setFieldTouched = () => {
    form.setFieldTouched(field.name, true)
  }
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      //e.preventDefault()
    }
  }

  return (
    <FormikGroup {...props}>
      <CreatableSelect
        value={createOptions(field.value)}
        isMulti
        onChange={handleChange}
        placeholder={placeholder}
        onBlur={setFieldTouched}
        onKeyDown={onKeyDown}
        //menuIsOpen={false}
      />
    </FormikGroup>
  )
}

export default FormikMultiTextInput
