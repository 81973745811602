import React, { useState, useEffect, useReducer } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useLazyQuery } from 'react-apollo'
import { GET_FILTERED_INSPECTIONS_AND_COUNT } from '../apollo'
import moment from 'moment'
import { parse } from 'json2csv'
import { AutoSizer, MultiGrid } from 'react-virtualized'
import _ from 'lodash'
import { FormControl, Button, Row, Col, Alert, Card, Collapse } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { RouteComponentProps } from 'react-router-dom'
import { getInspections, getAgencyNameUserOrUrl, getAgencies } from '../selectors'
import 'react-dates/lib/css/_datepicker.css'
import 'react-virtualized/styles.css'
import './InspectionsDataGrid.css'
import { Inspection, InspectionConfig, InspectionSchema } from '../types/InspectionTypes'
import PhotoAttachmentButton from './PhotoAttachmentButton'
import InspectionQueryFilterControl from '../components/InspectionQueryFilterControl'

type DateRange = {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
}

type InspectionsObj = { [key: string]: Inspection }

// history and location are passed in from parent route
type Props = {
  agencies: any
  agencyName: string
  dispatch: Function
  firebase: any
  firestore: any
  inspectionConfigs?: InspectionConfig[]
  inspections?: InspectionsObj
  user: any
  componentSize: { height: number; width: number }
  inspectionsRole: string
} & RouteComponentProps

const initialErrorString: string = undefined
const initialFilteredInspections: Inspection[] = []
const initialInspectionType: string | null = null
const initialRnCursor: number | null = null

const getHasInspect = (agencies: any, agencyName: string) => {
  if (!agencies || !agencyName) return false
  if (_.get(agencies, [agencyName, 'hasInspect']) === true) return true
  return false
}

const getActiveConfig = (agencyInspectionConfigs: InspectionConfig[], inspectionConfigId?: string | null) => {
  if (!agencyInspectionConfigs || !inspectionConfigId) return
  return agencyInspectionConfigs.find(config => config.guid === inspectionConfigId)
}

const getActiveSchema = (activeConfig?: Record<string, any>): InspectionSchema | typeof undefined => {
  if (!activeConfig) return undefined
  return activeConfig && activeConfig.schema
}

const getColumnTitle = (columnIndex: number, activeConfig: any, columnOrder: string[]) => {
  const propName = columnOrder[columnIndex]
  if (!propName) return
  if (propName === 'signature_name') return 'Signature Name'
  if (propName === 'signature_email') return 'Signature Email'
  if (propName.includes('feature_')) {
    const keyName = propName.replace(/^feature_/, '')
    const title = activeConfig.feature_set.feature_schema.properties[keyName].title || keyName
    return 'Feature - ' + title
  }
  return activeConfig.schema.properties[propName].title || propName
}

const getCellData = (
  rowIndex: number,
  columnIndex: number,
  filteredInspections: any[],
  activeConfig: any,
  columnOrder: string[]
) => {
  const columnKey = columnOrder[columnIndex]
  const row = filteredInspections[rowIndex]
  const cellValue = row[columnKey]
  if (columnKey === 'photos') {
    return <PhotoAttachmentButton inspectionGuid={row.guid} attachmentCount={row.attachment_count} />
  }
  if (cellValue === true) return <FaCheck />
  return cellValue
}

const getPassFailType = (activeConfig?: Record<string, any>): string | undefined => {
  return _.get(activeConfig, 'pass_fail_type')
}

const getInspectionType = (activeConfig?: Record<string, any>): string | undefined => {
  return _.get(activeConfig, 'inspection_type')
}

const getHasAttachments = (activeConfig?: Record<string, any>): boolean => {
  const photoType = _.get(activeConfig, ['schema', 'properties', 'photos', 'type'])
  return photoType === 'array'
}

const getColumnOrder = (activeConfig?: Record<string, any>): string[] => {
  if (!activeConfig) return []
  const inspectionSchemaOrder = _.get(activeConfig, ['schema', 'uiSchema', 'ui:order'], [])
  const inspectionOrder = activeConfig.signature_required
    ? ['signature_name', 'signature_email'].concat(inspectionSchemaOrder)
    : inspectionSchemaOrder
  const featureSetUiOrder = _.get(activeConfig, ['feature_set', 'feature_schema', 'uiSchema', 'ui:order'])
  const attachmentPropertyNames = _.get(activeConfig, ['feature_set', 'feature_schema', 'attachmentPropertyNames'], [])
  const featureSetOrder =
    featureSetUiOrder || Object.keys(_.get(activeConfig, ['feature_set', 'feature_schema', 'properties'], {}))
  return inspectionOrder.concat(
    featureSetOrder.filter(k => !attachmentPropertyNames.includes(k)).map(k => `feature_${k}`)
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    agencies: getAgencies(state),
    agencyName: getAgencyNameUserOrUrl(state, ownProps),
    inspections: getInspections(state),
  }
}

const limit = 50

type InspectionQueryState = {
  filteredInspections: Inspection[]
  rnCursor: number
  variables: {
    guid: string
    startDate: moment.Moment
    endDate: moment.Moment
    properties: Record<string, any>
    limit: number
    cursor: number
    gt: number
    eq: number
  }
}

const initialInspectionQueryState: InspectionQueryState = {
  filteredInspections: initialFilteredInspections,
  rnCursor: initialRnCursor,
  variables: {
    guid: '',
    startDate: null,
    endDate: null,
    properties: null,
    limit,
    cursor: null,
    gt: null,
    eq: null,
  },
}

const SET_FILTERED_INSPECTIONS = 'SET_FILTERED_INSPECTIONS'
const RESET_CURSOR = 'RESET_CURSOR'
const SET_VARIABLES = 'SET_VARIABLES'

const inspectionsQueryReducer = (state: InspectionQueryState, action: Record<string, any>) => {
  if (action.type === SET_FILTERED_INSPECTIONS) {
    const { filteredInspections } = action
    const rowNumbers = filteredInspections.map(i => i.rn)
    const rnCursor = rowNumbers.length ? Math.min(...rowNumbers) : null
    return { ...state, filteredInspections, rnCursor }
  }
  if (action.type === RESET_CURSOR) {
    return { ...state, rnCursor: null }
  }
  if (action.type === SET_VARIABLES) {
    const { variables } = action
    return { ...state, variables }
  }
  return state
}

const InspectionsDataGrid = (props: Props) => {
  const { inspectionConfigs, agencyName, componentSize } = props
  const agencyHasInspect = getHasInspect(props.agencies, agencyName)
  const agencyInspectionConfigs: InspectionConfig[] = inspectionConfigs
    ? inspectionConfigs.filter(config => config.agency_name === agencyName)
    : []

  const [activeInspectionConfigId, setActiveInspectionConfigId] = useState(initialInspectionType)
  const [pending, setPending] = useState(false)
  const [errorString, setErrorString] = useState(initialErrorString)
  const [inspectionState, dispatch] = useReducer(inspectionsQueryReducer, initialInspectionQueryState)

  const activeConfig = getActiveConfig(agencyInspectionConfigs, activeInspectionConfigId)
  const activeSchema = getActiveSchema(activeConfig)
  const passFailType = getPassFailType(activeConfig)
  const inspectionType = getInspectionType(activeConfig)
  const hasAttachments = getHasAttachments(activeConfig) // currently not in use
  const columnOrder = getColumnOrder(activeConfig)

  const onInspectionTypeChange = (inspectionConfigId: string) => {
    // setFilteredInspections(filterInspectionsByInspectionType(inspectionConfigId))
    setActiveInspectionConfigId(inspectionConfigId)
  }

  useEffect(() => {
    if (activeInspectionConfigId) {
      setActiveInspectionConfigId(initialInspectionType)
      dispatch({ type: SET_FILTERED_INSPECTIONS, filteredInspections: initialFilteredInspections })
    }
  }, [agencyName])

  const [getInspections, inspectionsQuery] = useLazyQuery(GET_FILTERED_INSPECTIONS_AND_COUNT, {
    variables: {
      guid: activeInspectionConfigId,
      startDate: null,
      endDate: null,
      properties: null,
      limit,
      cursor: null,
      gt: null,
      eq: null,
    },
    context: {
      headers: {
        'x-hasura-role': `${props.inspectionsRole}`,
      },
    },
  })
  useEffect(() => {
    dispatch({ type: RESET_CURSOR })
    if (activeInspectionConfigId) {
      getInspections()
    }
    dispatch({
      type: SET_VARIABLES,
      variables: {
        guid: activeInspectionConfigId,
        startDate: null,
        endDate: null,
        properties: null,
        limit,
        cursor: null,
        gt: null,
        eq: null,
      },
    })
  }, [activeInspectionConfigId])

  const inspectionsCount = _.get(inspectionsQuery, ['data', 'inspection_fail_count_aggregate', 'aggregate', 'count'])
  const getMore = () => {
    const shouldLoadMore = inspectionsCount > inspectionState.filteredInspections.length
    if (shouldLoadMore) {
      inspectionsQuery.fetchMore({
        variables: {
          cursor: inspectionState.rnCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newResult = {
            inspection_fail_count: [...prev.inspection_fail_count, ...fetchMoreResult.inspection_fail_count],
            inspection_fail_count_aggregate: fetchMoreResult.inspection_fail_count_aggregate,
          }
          return newResult
        },
      })
    }
  }

  const setVariables = (vars: {
    startDate: moment.Moment
    endDate: moment.Moment
    properties: any
    gt: number
    eq: number
  }) => {
    const variables = {
      ...vars,
      guid: activeInspectionConfigId,
      limit,
      cursor: null,
    }
    dispatch({ type: SET_VARIABLES, variables })
  }

  useEffect(() => {
    if (inspectionState.variables.guid) {
      inspectionsQuery.refetch(inspectionState.variables)
    }
  }, [inspectionState.variables])

  useEffect(() => {
    setPending(inspectionsQuery.loading)
  }, [inspectionsQuery.loading])

  useEffect(() => {
    if (inspectionsQuery.data && inspectionsQuery.data.inspection_fail_count && activeInspectionConfigId) {
      const flatInspections = inspectionsQuery.data.inspection_fail_count.map(i => {
        const { properties, feature_properties, ...inspection } = i
        const featureProperties = Object.entries(feature_properties).reduce((acc, [key, val]) => {
          acc[`feature_${key}`] = val
          return acc
        }, {})
        const flatInspection = { ...featureProperties, ...properties, ...inspection }
        return flatInspection
      })
      const rowNumbers = flatInspections.map(i => i.rn)
      const lastRowNumber = Math.min(...rowNumbers)
      dispatch({ type: SET_FILTERED_INSPECTIONS, filteredInspections: flatInspections })
    }
  }, [inspectionsQuery.data, activeInspectionConfigId])

  useEffect(() => {
    if (inspectionsQuery.error) {
      setErrorString(inspectionsQuery.error.message)
    }
    if (errorString && !inspectionsQuery.error) {
      setErrorString(initialErrorString)
    }
  }, [inspectionsQuery.error])

  const getInspectionCsv = (activeInspections: Inspection[]) => {
    const csvFormatInspections = activeInspections.map(inspection => {
      const csvObj: any = Object.keys(inspection).reduce((acc, key) => {
        const newKey = key.replace(/^_/, '')
        acc[newKey] = inspection[key]
        return acc
      }, {})
      if (csvObj.inspectionFailed === false) {
        csvObj.noViolations = 'Pass'
      }
      if (csvObj.inspectionFailed === true) {
        csvObj.noViolations = 'Fail'
      }
      return csvObj
    })
    const idFields = ['featureGuid', 'inspectionConfigId', 'inspectionId', 'rn', '_typename']
    const failTypeFields = ['inspection_failed', 'consecutive_fails']
    const unusedFields = passFailType ? idFields : [...idFields, ...failTypeFields]
    const uiOrder = columnOrder
    if (csvFormatInspections.some(inspection => inspection.noViolations)) {
      uiOrder.push('noViolations')
    }
    const restOfFields = Object.keys(csvFormatInspections[0])
      .filter(key => !uiOrder.includes(key) && !unusedFields.includes(key))
      .sort()
    const fields = [...uiOrder, ...restOfFields]
    return parse(csvFormatInspections, { fields })
  }

  const createDownload = async () => {
    const description = inspectionType || 'inspections'
    const getDateString = () => {
      const { startDate, endDate } = inspectionState.variables
      const endDateString = endDate ? moment(endDate).format('MMDDYY') : moment().format('MMDDYY')
      if (!startDate) return 'to' + endDateString
      if (startDate === endDate) return endDateString

      return 'from' + moment(startDate).format('MMDDYY') + 'to' + endDateString
    }
    const dateString = getDateString()

    const { data } = await inspectionsQuery.refetch({
      ...inspectionState.variables,
      guid: activeInspectionConfigId,
      limit: null,
    })
    if (data && data.inspection_fail_count && data.inspection_fail_count.length) {
      const flatInspections = data.inspection_fail_count.map(i => {
        const { properties, feature_properties, ...inspection } = i
        const featureProperties = Object.entries(feature_properties).reduce((acc, [key, val]) => {
          acc[`feature_${key}`] = val
          return acc
        }, {})
        const flatInspection = { ...featureProperties, ...properties, ...inspection }
        return flatInspection
      })
      const content = new Blob([getInspectionCsv(flatInspections)], { type: 'text/csv' })
      if (content) {
        const dummy = document.createElement('a')
        dummy.href = window.URL.createObjectURL(content)
        dummy.download = `${description}${dateString}.csv`
        // dummy.setAttribute('href', 'data:application/octet-stream,' + encodeURIComponent(content))
        // dummy.setAttribute('download', 'inspections.csv')

        if (document.createEvent) {
          const event = document.createEvent('MouseEvents')
          event.initEvent('click', true, true)
          dummy.dispatchEvent(event)
        } else {
          dummy.click()
        }
      }
    }
  }
  if (!agencyHasInspect)
    return <div>Your agency is not using IncidentView Inspect, for more information contact AGI.</div>
  return (
    <div className="idgFlexContainer">
      <Row style={{ marginBottom: 18 }}>
        <Col sm={6} md={3}>
          <FormControl
            as="select"
            onChange={(e: React.ChangeEvent<any>) => {
              onInspectionTypeChange(e.target.value)
            }}
          >
            <option value="">Select an inspection type</option>
            {agencyInspectionConfigs.map((config, index) => {
              return (
                <option key={config.inspection_type + index} value={config.guid}>
                  {config.display_name}
                </option>
              )
            })}
          </FormControl>
        </Col>
        {activeInspectionConfigId ? (
          <Button variant="primary" onClick={() => createDownload()} style={{ marginLeft: 8 }}>
            Export to CSV
          </Button>
        ) : null}
      </Row>
      <InspectionQueryFilterControl activeConfig={activeConfig} onSubmit={setVariables} />
      {pending && activeSchema ? (
        <div>
          <img src="/spinner.gif" alt="Spinner..." />{' '}
        </div>
      ) : null}
      {activeSchema && !pending ? (
        <div className="inspection-count">{inspectionsCount} Inspections in range</div>
      ) : null}
      {errorString && <Alert variant="danger">{errorString}</Alert>}

      {inspectionState.filteredInspections.length && activeSchema && !pending ? (
        <div className="idgAutoSizeWrapper">
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                rowHeight={({ index }) => (index === 0 ? 40 : 30)}
                columnWidth={({ index }) => (passFailType === 'double' && index === 0 ? 75 : 150)}
                rowCount={inspectionState.filteredInspections.length + 1}
                fixedRowCount={1}
                fixedColumnCount={passFailType === 'double' ? 1 : 0}
                columnCount={passFailType === 'double' ? columnOrder.length + 1 : columnOrder.length}
                width={width}
                height={height}
                onScroll={({ scrollTop, scrollHeight, clientHeight }) => {
                  const diff = scrollHeight - (clientHeight + scrollTop)
                  if (diff < 20) {
                    getMore()
                  }
                }}
                cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                  if (passFailType === 'double') {
                    if (rowIndex === 0) {
                      if (columnIndex === 0) {
                        return (
                          <div className="idgHeader" key={key} style={style}>
                            <div>Status</div>
                          </div>
                        )
                      }
                      return (
                        <div className="idgHeader" key={key} style={style}>
                          <div>{getColumnTitle(columnIndex - 1, activeConfig, columnOrder)}</div>
                        </div>
                      )
                    }
                    if (columnIndex === 0) {
                      const num = inspectionState.filteredInspections[rowIndex - 1].consecutive_fails
                      let bgColor = 'hsl(1, 100%, 44%)'
                      if (num === 0) {
                        bgColor = 'rgb(76, 217, 100)'
                      }
                      if (num === 1) {
                        bgColor = '#fb8c00'
                      }
                      return (
                        <div className={rowIndex % 2 === 0 ? 'idgDataRow' : 'idgDataRowDark'} key={key} style={style}>
                          <div
                            style={{
                              backgroundColor: bgColor,
                              height: 20,
                              border: '1px solid black',
                              margin: '5px',
                            }}
                          />
                        </div>
                      )
                    }
                    return (
                      <div className={rowIndex % 2 === 0 ? 'idgDataRow' : 'idgDataRowDark'} key={key} style={style}>
                        <div>
                          {getCellData(
                            rowIndex - 1,
                            columnIndex - 1,
                            inspectionState.filteredInspections,
                            activeConfig,
                            columnOrder
                          )}
                        </div>
                      </div>
                    )
                  }
                  if (rowIndex === 0) {
                    return (
                      <div className="idgHeader" key={key} style={style}>
                        <div>{getColumnTitle(columnIndex, activeConfig, columnOrder)}</div>
                      </div>
                    )
                  }
                  return (
                    <div className={rowIndex % 2 === 0 ? 'idgDataRow' : 'idgDataRowDark'} key={key} style={style}>
                      <div>
                        {getCellData(
                          rowIndex - 1,
                          columnIndex,
                          inspectionState.filteredInspections,
                          activeConfig,
                          columnOrder
                        )}
                      </div>
                    </div>
                  )
                }}
              />
            )}
          </AutoSizer>
        </div>
      ) : null}
    </div>
  )
}

export default compose(connect(mapStateToProps))(InspectionsDataGrid)
