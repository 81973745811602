import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, FormSection, formValueSelector } from 'redux-form'
import { InjectedFormProps } from 'redux-form'
import { Alert, Col, Row, ButtonToolbar } from 'react-bootstrap'
import {
  FormFieldInput,
  LoadingButton,
  FormFieldCheckbox,
  SimpleBootstrapInput,
} from '../components/BootstrapReduxForm'
import { firestore } from '../firebase'
const FORM_NAME = 'fieldMapData'
const getFieldMapValueSelector = formValueSelector(FORM_NAME)

const mapStateToProps = state => {
  const fieldMap = getFieldMapValueSelector(state, 'fieldMap')
  if (fieldMap) {
    const fieldsAlreadyMapped = Object.values(fieldMap)
    return { fieldsAlreadyMapped }
  }
  return {}
}
const POLYGON_FEATURE_COUNT_MAX = 250000
const ORIGIN_ID_FIELD = 'originId'
const reservedFieldNames = ['guid', 'featureSetGuid', 'hasAttachments', 'icon']

type Props = {
  fieldsAlreadyMapped: Array<string>
  task: {
    _status?: string
    agencyName: string
    fileName: string
    gisDataSourceName: string
    inputFieldTypes: {}
    isCustomData: boolean
    styleObject: string
    featureCount: number
    templateSchema: {
      attachmentPropertyNames: any[]
      properties: {
        [key: string]: any
      }
    }
    rawInputSchema: {
      properties: {
        [key: string]: any
      }
    }
    warningMessage?: string
  }
  taskFirebasePath: string
  warningMessage: string
} & InjectedFormProps

type DataObject = { fieldMap?: { [key: string]: string }; customFields?: { [key: string]: any } }

function validateFieldMapDataForm(data: DataObject, props) {
  let errors: any = { fieldMap: {}, customFields: {} }
  const { inputFieldTypes, templateSchema } = props.task
  if (!inputFieldTypes) {
    return errors
  }
  if (templateSchema && templateSchema.required && data.fieldMap) {
    for (const requiredFieldName of templateSchema.required) {
      if (!data.fieldMap[requiredFieldName]) {
        errors.fieldMap[requiredFieldName] = 'This is a required field'
      }
    }
  }
  if (data && data.fieldMap) {
    // Remove this validation when loadGisDataToPostgres is updated, sqlite is needed for complete type casting which is currently not supported by node-gdal
    Object.entries(data.fieldMap).forEach(([field, value]) => {
      const inputFieldType = inputFieldTypes[value]
      const outputFieldType = templateSchema.properties[field] ? templateSchema.properties[field].type : null
      if (
        value &&
        inputFieldType &&
        (inputFieldType === 'real' ||
          inputFieldType === 'integer' ||
          inputFieldType === 'date' ||
          inputFieldType === 'number' ||
          inputFieldType === 'integer64') &&
        (outputFieldType === 'string' || field === 'originId')
      ) {
        errors.fieldMap[
          field
        ] = `The data upload tools currently do not support mapping a number field type to a string field type. Please convert ${value} to be a string type before uploading`
      } else if (
        value &&
        inputFieldType &&
        (inputFieldType === 'string' &&
          (outputFieldType === 'real' ||
            outputFieldType === 'integer' ||
            outputFieldType === 'date' ||
            outputFieldType === 'number' ||
            outputFieldType === 'integer64'))
      ) {
        errors.fieldMap[
          field
        ] = `The data upload tools currently do not support mapping a sting field to a number field. Please convert ${value} to be a number before uploading`
      }
    })
  }
  if (data && data.customFields) {
    Object.entries(data.customFields).forEach(([field, value]) => {
      if (value.rename && value.rename.indexOf(' ') >= 0) {
        // Redux form currently does not allow errors for more than one formSection
        // alert is temporary until this functionality is provided: https://github.com/erikras/redux-form/issues/2150
        window.alert('Spaces are not allowed in field names')
        const withoutSpaces = data.customFields[field].rename.replace(/ /g, '_')
        data.customFields[field].rename = withoutSpaces
      }
    })
  }

  return errors
}

function determineIfDataOverLimit(featureCount, templateSchema) {
  if (
    featureCount &&
    featureCount > POLYGON_FEATURE_COUNT_MAX &&
    templateSchema &&
    templateSchema.geometryType &&
    (templateSchema.geometryType === 'MultiPolygon' || templateSchema.geometryType === 'Polygon')
  ) {
    // do not allow large polygon uploads, cloud function runs out of memory.
    return 'FEATURE LIMIT EXCEEDED: polygon data uploads are limited to 250,000 features. Please split up the data and upload using the ‘Add to existing dataset’ functionality.'
  } else {
    return false
  }
}

// will need to be updated after upgrading to new mapbox styles
function styleDisplayCheck(styleObject, templateSchema, gisDataSourceName) {
  let styleDisplayCheckArray
  const featureStyleObject = JSON.parse(styleObject)
  if (featureStyleObject && gisDataSourceName !== 'address' && gisDataSourceName !== 'preplan') {
    // the generated style warnings for address/preplan are slightly misleading and unnecessary
    styleDisplayCheckArray = Object.entries(featureStyleObject).map(styleLayer => {
      let styleCheckObject = {}
      let filterValue = []
      let styleLayerEntry
      if (styleLayer[1] && !Array.isArray(styleLayer[1])) {
        let styleArray = []
        Object.values(styleLayer[1]).forEach(basemapStyle => {
          for (const nestedStyle of basemapStyle) {
            styleArray.push(nestedStyle)
          }
          styleLayerEntry = styleArray
        })
      } else {
        styleLayerEntry = styleLayer[1]
      }
      for (const styleEntry of styleLayerEntry) {
        if (styleEntry.filter) {
          filterValue.push(styleEntry.filter)
          if (typeof styleEntry.filter[1] === 'string') {
            styleCheckObject[styleEntry.filter[1]] = {
              filter: filterValue,
              type: styleEntry.type,
              styleName: Object.keys(featureStyleObject)[0],
            }
          } else {
            for (const filterValue of styleEntry.filter) {
              if (typeof filterValue === 'object') {
                filterValue.push(filterValue[1])
                styleCheckObject[filterValue[1]] = {
                  filter: filterValue,
                  type: styleEntry.type,
                  styleName: Object.keys(featureStyleObject)[0],
                }
              }
            }
          }
        }
        if (styleEntry.layout && styleEntry.layout['icon-image']) {
          const iconImageInformation = styleEntry.layout['icon-image']
          if (typeof iconImageInformation === 'string' && iconImageInformation.includes('{icon}')) {
            styleCheckObject['type'] = {
              enum: templateSchema.properties.type.enum,
              type: styleEntry.type,
              styleName: Object.keys(featureStyleObject)[0],
            }
          } // else {// TODO: determine if need logic for icon-image entries that are objects(it appears that this format case is not of immediate interest)
          // }
        }
      }
      if (Object.entries(styleCheckObject).length) {
        for (const fieldName of Object.keys(styleCheckObject)) {
          if (
            styleCheckObject[fieldName].filter &&
            templateSchema.properties &&
            templateSchema.properties[fieldName] &&
            templateSchema.properties[fieldName].type === 'boolean'
          ) {
            styleCheckObject[fieldName].fieldMapMessage = `This field (${fieldName}) determines the ${
              styleCheckObject[fieldName].type
            } style for ${gisDataSourceName}, based on the assigned mapboxStyleLayer: ${
              styleCheckObject[fieldName].styleName
            }`
            styleCheckObject[
              fieldName
            ].valueMapMessage = `Not selecting a value to map for ${fieldName} will result in those features not being visible in the vector tiles` // we are currently allowing null to be mapped to boolean fields on upload
          } else if (styleCheckObject[fieldName].filter) {
            styleCheckObject[fieldName].fieldMapMessage = `This field (${fieldName}) determines the ${
              styleCheckObject[fieldName].type
            } style for ${gisDataSourceName}, based on the assigned mapboxStyleLayer: ${
              styleCheckObject[fieldName].styleName
            } WARNING: If there are null values in this field then those entries will not be visible in the vector tiles`
          } else if (styleCheckObject[fieldName].enum) {
            styleCheckObject[
              fieldName
            ].fieldMapMessage = `If selected the Type field will require value mapping. This is determined by the ${
              styleCheckObject[fieldName].styleName
            } style for ${gisDataSourceName} `
            styleCheckObject[fieldName].valueMapMessage =
              'If a value is not selected from the drop-down, the default symbology will be applied for entries with that value'
          }
        }
      }
      return styleCheckObject
    })
  }
  if (styleDisplayCheckArray && styleDisplayCheckArray.length) {
    return styleDisplayCheckArray[0]
  } else {
    return null
  }
}

function warningValueMapDataForm(data: DataObject, props) {
  let warnings = { fieldMap: {} }
  const { inputFieldTypes, templateSchema, rawInputSchema } = props.task
  if (!inputFieldTypes) {
    return warnings
  }
  if (data.fieldMap) {
    warnings.fieldMap = {}
    if (templateSchema && templateSchema.properties) {
      for (const [outFieldName, inFieldName] of Object.entries(data.fieldMap)) {
        // template schema does not account for origin Id. Origin Id is a string field; no need to warn when mapping
        if (outFieldName !== 'originId') {
          const outPropertyType = templateSchema.properties[outFieldName].type
          const inPropertyType = rawInputSchema.properties[inFieldName].type
          if (outPropertyType === 'number' || outPropertyType === 'integer') {
            if (inPropertyType === 'string') {
              warnings.fieldMap[outFieldName] =
                'WARNING: You are mapping a string field to a number field. Only text values of numbers will be converted'
            }
          }
          // There is no boolean data type option in arcgis atributes
          // Often time 1 or 0 is assigned for these values.
          if (outPropertyType === 'boolean') {
            warnings.fieldMap[outFieldName] = 'WARNING: Mapping to a boolean field. Fields will require value mapping'
          }
        }
      }
    }
  }
  if (data.customFields) {
    for (const [inFieldName, status] of Object.entries(data.customFields)) {
      if (status.rename && !status.keep) {
        // If a field is re-named but not clicked to 'keep', assume user wants to keep field
        status.keep = true
        // warnings.customFields[inFieldName] =  `Please click the checkbox if you would like to re-name ${inFieldName} to ${status.rename}`
      }
    }
  }
  return warnings
}

class FieldMapDataForm extends React.Component<Props> {
  submitFieldMapDataForm = async (data: DataObject) => {
    const {
      isCustomData,
      rawInputSchema,
      templateSchema,
      styleObject,
      gisDataSourceName,
      featureCount,
    } = this.props.task
    let fieldMap = data.fieldMap || {}
    const customFields = data.customFields || {}
    // deep clone schema so that mutations don't mess with the form display
    let outputSchema = JSON.parse(JSON.stringify(isCustomData ? rawInputSchema : templateSchema))
    // add origin id to schema, since all data sets can have an origin id
    outputSchema.properties.originId = {
      title: 'Origin ID',
      type: 'string',
    }
    const dataOverLimit = determineIfDataOverLimit(featureCount, templateSchema)
    try {
      if (
        (Object.keys(data).length === 0 || (data.customFields && !data.fieldMap)) &&
        outputSchema &&
        outputSchema.required
      ) {
        throw new Error(`${outputSchema.required.toString()} is a required field and must have a value mapped to it`)
      }
      if (dataOverLimit) {
        throw new Error(dataOverLimit)
      }
      if (!isCustomData) {
        for (const [inFieldName, { keep, rename }] of Object.entries(customFields)) {
          if (keep) {
            const outFieldName = rename ? rename : inFieldName
            // decided to keep field, field is added to template
            outputSchema.properties[outFieldName] = rawInputSchema.properties[inFieldName]
            if (rename) {
              outputSchema.properties[outFieldName].title = rename
            }
          }
        }
      } else {
        const possibleProperties = Object.keys(rawInputSchema.properties).concat('originId')
        for (const inFieldName of possibleProperties) {
          if (customFields[inFieldName]) {
            const { keep, rename } = customFields[inFieldName]
            if (keep) {
              if (rename) {
                outputSchema.properties[rename] = rawInputSchema.properties[inFieldName]
                outputSchema.properties[rename].title = rename
                delete outputSchema.properties[inFieldName]
              }
            }
          } else {
            // if not keeping a field from the custom data; field is dropped (still needs to include originId)
            if (inFieldName !== 'originId') {
              delete outputSchema.properties[inFieldName]
            }
          }
        }
      }
      // add to field map for custom fields that are kept
      if (customFields) {
        for (const [inFieldName, { keep, rename }] of Object.entries(customFields)) {
          if (keep) {
            if (reservedFieldNames.includes(inFieldName) && keep === true && !rename) {
              throw new Error(`${inFieldName} is a reserved field name, please rename before submitting`)
            } else if (
              rename &&
              templateSchema.properties &&
              templateSchema.properties.hasOwnProperty(rename.toLowerCase()) &&
              templateSchema.properties[rename.toLowerCase()].enum
            ) {
              throw new Error(
                `Please use the field map dropdown above to assign '${inFieldName}' to the field '${rename}'`
              )
            }

            const outFieldName = rename ? rename : inFieldName

            fieldMap[outFieldName] = inFieldName
          }
        }
      }

      return firestore.doc(this.props.taskFirebasePath).update({
        _status: 'PROCESSING_FIELD_MAP',
        fieldMap: fieldMap,
        outputSchema,
        styleDisplayWarnings: JSON.stringify(styleDisplayCheck(styleObject, templateSchema, gisDataSourceName)),
      })
    } catch (error) {
      alert(error)
    }
  }

  render() {
    const { error, handleSubmit, submitting, fieldsAlreadyMapped } = this.props
    const {
      inputFieldTypes,
      isCustomData,
      templateSchema,
      gisDataSourceName,
      warningMessage,
      styleObject,
      featureCount,
    } = this.props.task
    const inputFieldsArray = Object.keys(inputFieldTypes)
    const styleDisplayWarnings = styleDisplayCheck(styleObject, templateSchema, gisDataSourceName)
    let inputFieldsNotYetMapped = []
    if (fieldsAlreadyMapped) {
      inputFieldsNotYetMapped = inputFieldsArray.filter(field => !fieldsAlreadyMapped.includes(field))
    } else {
      inputFieldsNotYetMapped = inputFieldsArray
    }
    const inputFieldNamesToKeepOrRename = isCustomData ? inputFieldsArray : inputFieldsNotYetMapped
    let fieldsToIgnore = ['originId']
    if (templateSchema.attachmentPropertyNames) {
      fieldsToIgnore = fieldsToIgnore.concat(templateSchema.attachmentPropertyNames)
    }
    const dataOverSizeLimitMessage = determineIfDataOverLimit(featureCount, templateSchema)
    return (
      <form>
        <Row style={{ color: 'red' }}>{warningMessage}</Row>
        {dataOverSizeLimitMessage && <Row style={{ color: '#e68a00' }}> {dataOverSizeLimitMessage} </Row>}
        <Col>
          <FormSection name="fieldMap">
            <Row>
              {!isCustomData &&
                Object.entries(templateSchema.properties)
                  .filter(([fieldName]) => !fieldsToIgnore.includes(fieldName))
                  .map(([fieldName, { title, type }]) => (
                    <React.Fragment key={fieldName}>
                      <Field name={fieldName} component={FormFieldInput} type="select" label={title}>
                        <option key="default" value="">
                          Choose a field to map to
                        </option>
                        {inputFieldsArray &&
                          inputFieldsArray.map(inFieldName => (
                            <option key={inFieldName} value={inFieldName}>
                              {inFieldName}
                            </option>
                          ))}
                      </Field>
                      <div>
                        {styleDisplayWarnings &&
                          styleDisplayWarnings.hasOwnProperty(fieldName) &&
                          styleDisplayWarnings[fieldName].fieldMapMessage}
                      </div>
                    </React.Fragment>
                  ))}
            </Row>
            <Row>
              <Field
                name={ORIGIN_ID_FIELD}
                component={FormFieldInput}
                type="select"
                label="Origin Id"
                help="Select the field that contains the data’s unique identifier"
              >
                <option key="default" value="">
                  Origin Id is recommended
                </option>
                {inputFieldsArray &&
                  inputFieldsArray.map(inFieldName => (
                    <option key={inFieldName} value={inFieldName}>
                      {inFieldName}
                    </option>
                  ))}
              </Field>
            </Row>
          </FormSection>
          The values below are fields from the original dataset. Use the checkbox to select data that is to be kept but
          not field mapped to the values above. The fields can be re-named using the boxes on the right. Fields that are
          not field mapped or checked will not be included on data upload. -We recommend only keeping fields that will
          be necessary for the identify tool or display purposes-
          <FormSection name="customFields">
            {inputFieldNamesToKeepOrRename.map(fieldName => (
              <FormSection name={fieldName} key={fieldName}>
                <Row>
                  <Col sm={6}>
                    <Field name="keep" component={FormFieldCheckbox} label={fieldName} />
                  </Col>
                  <Col sm={6}>
                    <Field name="rename" component={SimpleBootstrapInput} type="text" placeholder="Rename Field Here" />
                  </Col>
                </Row>
              </FormSection>
            ))}
          </FormSection>
          {error && (
            <Row>
              <Col sm={{ span: 8, offset: 2 }}>
                <Alert variant="danger">{error}</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={{ offset: 7 }}>
              <ButtonToolbar>
                <LoadingButton
                  variant="primary"
                  onClick={handleSubmit(this.submitFieldMapDataForm)}
                  label="Submit"
                  loading={submitting}
                  loadingLabel="Submitting"
                />
              </ButtonToolbar>
            </Col>
          </Row>
        </Col>
      </form>
    )
  }
}

export default compose(
  reduxForm({
    form: FORM_NAME,
    validate: validateFieldMapDataForm,
    warn: warningValueMapDataForm,
  }),
  connect(mapStateToProps)
)(FieldMapDataForm)
