import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Row, Col, Alert, Button, Table } from 'react-bootstrap'
import { FaPlus, FaPen } from 'react-icons/fa'
import { LinkContainer } from 'react-router-bootstrap'
import queryString from 'query-string'
import FirebaseConnector from '../components/FirebaseConnector'
import ViewWrapper from '../components/ViewWrapper'
import * as usersActions from '../actions/users'
import * as agenciesActions from '../actions/agencies'
import AddUserForm from '../containers/AddUserForm'
import { usersRef } from '../firebase'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import { withRouter, RouteComponentProps } from 'react-router-dom'

function mapStateToProps(state) {
  return {
    authUser: state.auth.user,
    users: state.users.data && state.users.data.toList(),
    successMessage: state.users.addUserSuccessMessage,
    errorMessage: state.users.addUserErrorMessage,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...usersActions, ...agenciesActions }, dispatch)
}

type Props = {
  authUser: Record<string, any>
  errorMessage: string
  successMessage: string
  clearAddUserMessage: Function
  users: Record<string, any>
  editStart: Function
  loadAllUsers: Function
} & RouteComponentProps

const UsersView = props => {
  const { authUser, users, errorMessage, successMessage } = props

  const urlQuery = queryString.parse(props.location.search)
  const addUser = urlQuery.addUser
  const agencyNameFilter = urlQuery.agencyName

  let filteredUsersRef: any = usersRef
  if (agencyNameFilter) {
    filteredUsersRef = usersRef.orderByChild('agencyName').equalTo(agencyNameFilter as string)
  }

  const leftHeader = <AgencyFilterSelect agencyNameFilter={agencyNameFilter} location={props.location} />
  const rightHeader = addUser ? null : (
    <LinkContainer
      to={{ pathname: '/users', search: queryString.stringify({ ...urlQuery, addUser: true }) }}
      active={false}
    >
      <Button variant="primary">
        Add <FaPlus />
      </Button>
    </LinkContainer>
  )

  return (
    <ViewWrapper
      header="Users"
      sizable
      overrideStyle={{ minWidth: 940 }}
      leftHeader={leftHeader}
      rightHeader={rightHeader}
    >
      <FirebaseConnector
        key={`users-${agencyNameFilter}`}
        firebaseRef={
          filteredUsersRef // key lets react know to umount and mount component when changed
        }
        onNodeChange={props.loadAllUsers}
      />
      {errorMessage && (
        <Alert variant="danger" onClose={props.clearAddUserMessage}>
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert variant="success" onClose={props.clearAddUserMessage}>
          {successMessage}
        </Alert>
      )}
      {addUser && (
        <div>
          <h2>Add User</h2>
          <AddUserForm location={props.location} />
        </div>
      )}
      {users && users.size && (
        <Table striped size="sm" responsive>
          <thead>
            <tr>
              <th className="text-center">uid</th>
              <th className="text-center">email</th>
              <th className="text-center">agencyName</th>
              <th className="text-center">Roles</th>
              <th className="text-center">Edit</th>
            </tr>
          </thead>
          <tbody>
            {users.toSeq().map(user => (
              <tr key={user.uid}>
                <td>{user.uid}</td>
                <td>{user.email}</td>
                <td>{user.agencyName}</td>
                <td>
                  {user.roles.toJS().join(', ')}
                  {authUser && authUser.isSuperAdmin && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                </td>
                <td>
                  <Button variant="primary" onClick={() => props.history.push(`/users/${user.uid}`)}>
                    <FaPen />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </ViewWrapper>
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UsersView)
