import React, { Component } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import Input from '../components/Input'
import { LoadingButton, FormFieldInput, FormFieldTextArea } from '../components/BootstrapReduxForm'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError, InjectedFormProps } from 'redux-form'
import * as queueActions from '../actions/queues'

const QUEUE_NAME_FIELD = 'queueName'
const QUEUE_TASK_PAYLOAD_FIELD = 'queueTaskPayload'

const QUEUE_DEFAULTS = {
  registerDeviceForDispatch: {
    agencyName: 'or_agi_fd',
    hardwareUid: 'IV_2654E95B-8400-40A8-90CD-4C10902E03C9',
    messageType: 'APNS',
    messageUid: '00657d93754f487c343e5c55615ae408f12757bd54084ae443bb09d27e55d2cc',
  },
  dispatchToAgency: {
    agencyName: 'or_agi_fd',
    subject: 'Test Message 1',
    message: {
      sound: 'fire_pager.mp3',
      dispatchMessage: {
        id: 0,
        category: 'MEDICAL',
        fromEmail: 'aaron.gifford@alseageo.net',
        toEmail: 'auto_dispatch@alseageo.net',
        dispatchLat: 0,
        dispatchLng: 0,
        lat: 0,
        lng: 0,
        building: '',
        street: '437 NW 6TH ST',
        city: 'CORVALLIS',
        state: 'OR',
        country: 'US',
        zipCode: '',
        intersection: false,
        crossStreet: '',
        shortDesc: 'UNKNOWN MEDICAL',
        longDesc: 'UNKNOWN MEDICAL',
        raw:
          'INC:UNKNOWN MEDICAL\r\nADD:437 NW 6TH ST\r\nAPT:\r\nCITY:CORVALLIS\r\nMAP:630-240\r\nCFS:101614-50\r\nDIS:BENTON CO SO\r\nDIS:CORVALLIS RURAL 6-3-1-2-4\r\n',
        timeStamp: 1462395442,
      },
      dispatchId: '-KGxYuQui6yikyse_JVD',
    },
  },
  getAgencyAssetS3Url: {
    agencyName: 'or_agi_fd',
    fileName: 'preplans.zip',
    hardwareUid: 'MY_HARDWARE_UID',
  },
  shareAvl: {
    avlId: 'myId',
    location: { lat: 45.4545, lng: -123.123 },
    shareWithCad: ['wa_spokane_cad'],
  },
  importAgencyDataToMapbox: {
    agencyName: 'or_agi_fd',
    geojsonFileNames: {
      '0': 'access.json',
      '1': 'address.json',
      '2': 'alarm.json',
      '3': 'bluediamond.json',
      '4': 'boundary.json',
      '5': 'building.json',
      '6': 'caution.json',
      '7': 'firestation.json',
      '8': 'firesuppression.json',
      '9': 'hazmat.json',
      '10': 'hydrant.json',
      '11': 'shutoff.json',
      '12': 'vacantstructure.json',
    },
  },
  generateStyleJson: {
    agencyName: 'or_agi_fd',
  },
  processLocalSearchData: {
    agencyName: 'or_agi_fd',
  },
  postAvl: {
    location: {
      activity: {
        confidence: 100,
        type: 'unknown',
      },
      battery: {
        is_charging: false,
        level: 1,
      },
      coords: {
        accuracy: 10,
        altitude: 66.96117973327637,
        altitudeAccuracy: 3,
        heading: 27.0703125,
        latitude: 44.56397478007205,
        longitude: -123.2583841123835,
        speed: 1.470000028610229,
      },
      event: 'motionchange',
      extras: {
        _transmitting: true,
        agencyName: 'or_agi_fd',
        avlColor: 'FFFF8800',
        displayName: 'IV_B42B6331-A14A-4EC1-88EB-11C06739EA74',
        hardwareUid: 'IV_B42B6331-A14A-4EC1-88EB-11C06739EA74',
      },
      is_heartbeat: false,
      is_moving: true,
      odometer: 2114.016513170643,
      timestamp: '2016-10-01T00:19:47.148Z',
      uuid: '638CF906-66F4-4DD0-94B8-46AFCAEA0840',
    },
  },
}

const DEFAULT_TASK_PAYLOAD = {
  agencyName: 'or_agi_fd',
}

function validateQueueTaskForm(data) {
  const errors = {}
  if (data[QUEUE_TASK_PAYLOAD_FIELD]) {
    try {
      JSON.parse(data[QUEUE_TASK_PAYLOAD_FIELD])
    } catch (e) {
      errors[QUEUE_TASK_PAYLOAD_FIELD] = 'Invalid JSON: ' + e
    }
  }
  return errors
}

const mapStateToProps = state => ({
  user: state.auth.user,
  queues: state.queues.data,
})
const mapDispatchToProps = dispatch => bindActionCreators(queueActions, dispatch)

type Props = {
  user: Record<string, any>
  pushTaskToQueue: Function
  // provided by redux-form
} & InjectedFormProps

class QueueTaskForm extends Component<Props> {
  componentWillMount() {
    this.props.initialize({
      [QUEUE_NAME_FIELD]: '',
      [QUEUE_TASK_PAYLOAD_FIELD]: JSON.stringify(DEFAULT_TASK_PAYLOAD, null, 4),
    })
  }

  submitQueueTaskForm = data => {
    return this.props
      .pushTaskToQueue(data[QUEUE_NAME_FIELD], JSON.parse(data[QUEUE_TASK_PAYLOAD_FIELD]))
      .then(result => {
        if (result.error) {
          throw new SubmissionError({ _error: result.payload.message })
        }
      })
  }

  render() {
    const { error, handleSubmit, submitting } = this.props
    return (
      <div>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Input
              type="select"
              placeholder="Select defaults"
              label="Select queue"
              onChange={e => {
                const queueName = e.target.value
                this.props.initialize({
                  [QUEUE_NAME_FIELD]: queueName,
                  [QUEUE_TASK_PAYLOAD_FIELD]: JSON.stringify(QUEUE_DEFAULTS[queueName], null, 4),
                })
              }}
            >
              <option key="default" value="">
                ----------------
              </option>
              {Object.keys(QUEUE_DEFAULTS).map(queueName => (
                <option key={queueName} value={queueName}>
                  {queueName}
                </option>
              ))}
            </Input>
          </Col>
        </Row>

        <form onSubmit={handleSubmit(this.submitQueueTaskForm)}>
          <div>
            <Row>
              <Field
                name={QUEUE_NAME_FIELD}
                component={FormFieldInput}
                type="text"
                label="Queue Name"
                labelColSize={2}
                help="Name of queue in firebase (ex: 'getAgencyAssetS3Url')"
              />
            </Row>
            <Row>
              <Field
                name={QUEUE_TASK_PAYLOAD_FIELD}
                component={FormFieldTextArea}
                label="JSON Payload"
                labelColSize={2}
                help="This takes a JSON object"
                rows={7}
              />
            </Row>
            {error && (
              <Row>
                <Col sm={{ span: 6, offset: 3 }}>
                  <Alert variant="danger">{error}</Alert>
                </Col>
              </Row>
            )}
            <Row className="form-group">
              <Col sm={12} className="text-center">
                <LoadingButton
                  variant="primary"
                  size="lg"
                  label="Write task"
                  loading={submitting}
                  loadingLabel="Writing task"
                  onClick={handleSubmit(this.submitQueueTaskForm)}
                  type="submit"
                />
              </Col>
            </Row>
          </div>
        </form>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'queueTask',
    validate: validateQueueTaskForm,
  })(QueueTaskForm)
)
