import gql from 'graphql-tag'

export const UNDO_FEATURE_SET_REPLACE = gql`
  mutation undoFeatureSetReplace($featureSetGuid: String!) {
    undoFeatureSetReplace(featureSetGuid: $featureSetGuid)
  }
`

export const UPDATE_FEATURE_SET_FEATURE_SCHEMA = gql`
  mutation updateFeatureSetFeatureSchema(
    $featureSetGuid: String!
    $featureSchema: JSON!
    $userGuid: String!
    $replaceNull: JSON
  ) {
    updateFeatureSetFeatureSchema(
      featureSetGuid: $featureSetGuid
      featureSchema: $featureSchema
      userGuid: $userGuid
      replaceNull: $replaceNull
    )
  }
`

export const EDIT_FEATURE_SET_EDITABLE = gql`
  mutation editFeatureSetEditable(
    $agencyName: String!
    $dataSourceName: String!
    $editable: Boolean!
    $displayName: String!
    $displayField: String!
    $modifiedDate: timestamptz!
  ) {
    update_feature_set(
      where: { agency_name: { _eq: $agencyName }, data_source_name: { _eq: $dataSourceName } }
      _set: {
        editable: $editable
        display_name: $displayName
        display_field: $displayField
        modified_date: $modifiedDate
      }
    ) {
      affected_rows
      returning {
        editable
        display_name
        display_field
        modified_date
      }
    }
  }
`

export const INSERT_DISPATCH_PARSER = gql`
  mutation insertDispatchParser($parser_name: String!, $display_name: String!) {
    insert_dispatch_parser_one(
      object: { parser_name: $parser_name, display_name: $display_name }
      on_conflict: { constraint: dispatch_parser_parser_name_key, update_columns: created_date }
    ) {
      guid
    }
  }
`

export const INSERT_AGENCY_AND_EMAIL = gql`
  mutation insertAgencyAndDispatchEmail(
    $agency_name: String!
    $agency_display_name: String!
    $parser_guid: uuid!
    $receiver_email: String!
  ) {
    insert_agency_to_dispatch_email_address_one(
      object: {
        agency: {
          data: { agency_name: $agency_name, display_name: $agency_display_name, parser_guid: $parser_guid }
          on_conflict: { constraint: agency_agency_name_key, update_columns: agency_name }
        }
        dispatch_email_address: {
          data: { email: $receiver_email }
          on_conflict: { constraint: dispatch_email_address_email_key, update_columns: email }
        }
      }
    ) {
      agency {
        guid
      }
    }
  }
`

export const INSERT_AGENCY_AND_SMS = gql`
  mutation insertAgencyAndDispatchSms(
    $agency_name: String!
    $display_name: String!
    $parser_guid: uuid!
    $sms: String!
  ) {
    insert_agency_to_dispatch_sms_one(
      object: {
        agency: {
          data: { agency_name: $agency_name, display_name: $display_name, parser_guid: $parser_guid }
          on_conflict: { constraint: agency_agency_name_key, update_columns: agency_name }
        }
        dispatch_sms_number: {
          data: { sms: $sms }
          on_conflict: { constraint: dispatch_sms_number_sms_key, update_columns: sms }
        }
      }
    ) {
      agency {
        guid
      }
    }
  }
`

export const INSERT_DISPATCH_MESSAGE_SOURCE = gql`
  mutation insertDispatchMessageSource($agency_guid: uuid!, $notes: String, $source: String!) {
    insert_dispatch_message_source_one(
      object: { agency_guid: $agency_guid, active: true, notes: $notes, source: $source }
      on_conflict: { constraint: dispatch_message_source_source_key, update_columns: source }
    ) {
      guid
    }
  }
`

export const EDIT_FEATURE_SET = gql`
  mutation editFeatureSet(
    $agencyName: String!
    $dataSourceName: String!
    $displayName: String!
    $displayField: String!
    $modifiedDate: timestamptz!
  ) {
    update_feature_set(
      where: { agency_name: { _eq: $agencyName }, data_source_name: { _eq: $dataSourceName } }
      _set: { display_name: $displayName, display_field: $displayField, modified_date: $modifiedDate }
    ) {
      affected_rows
      returning {
        display_name
        display_field
        modified_date
      }
    }
  }
`

export const UPDATE_AGENCY_BOUNDARY = gql`
  mutation updateAgencyBoundary($agencyName: String!, $boundaryGeom: JSON!) {
    updateAgencyBoundary(agencyName: $agencyName, boundaryGeom: $boundaryGeom)
  }
`

export const ADD_SHARING = gql`
  mutation addSharing($featureSetId: uuid, $agencyName: String!, $canRead: Boolean!) {
    insert_rel_agency_feature_set(
      objects: { feature_set_guid: $featureSetId, agency_name: $agencyName, can_read_features: $canRead }
    ) {
      affected_rows
      returning {
        modified_date
      }
    }
  }
`
export const REMOVE_SHARING = gql`
  mutation removeSharing($featureSetId: uuid, $agencyName: String!) {
    delete_rel_agency_feature_set(
      where: { feature_set_guid: { _eq: $featureSetId }, agency_name: { _eq: $agencyName } }
    ) {
      affected_rows
      returning {
        modified_date
      }
    }
  }
`

export const UPDATE_INSPECTION_CONFIG = gql`
  mutation UpdateConfig(
    $guid: uuid
    $active: Boolean
    $schema: jsonb
    $display_name: String
    $inspection_type: String
    $pass_fail_type: String
    $visible_data_sources: _text
    $signature_required: Boolean
    $agency_contact_emails: _text
    $automated_email_display_name: String
    $automated_email_user_name: String
  ) {
    update_inspection_config(
      where: { guid: { _eq: $guid } }
      _set: {
        schema: $schema
        active: $active
        display_name: $display_name
        inspection_type: $inspection_type
        pass_fail_type: $pass_fail_type
        signature_required: $signature_required
        visible_data_sources: $visible_data_sources
        automated_email_display_name: $automated_email_display_name
        automated_email_user_name: $automated_email_user_name
        agency_contact_emails: $agency_contact_emails
      }
    ) {
      affected_rows
    }
  }
`

export const INSERT_INSPECTION_CONFIG = gql`
  mutation AddInspectionConfig(
    $agency_name: String
    $active: Boolean
    $feature_set_guid: uuid
    $firebase_id: String
    $schema: jsonb
    $display_name: String
    $inspection_type: String
    $pass_fail_type: String
    $signature_required: Boolean
    $visible_data_sources: _text
    $agency_contact_emails: _text
    $automated_email_display_name: String
    $automated_email_user_name: String
  ) {
    insert_inspection_config(
      objects: {
        agency_name: $agency_name
        active: $active
        display_name: $display_name
        feature_set_guid: $feature_set_guid
        firebase_id: $firebase_id
        inspection_type: $inspection_type
        pass_fail_type: $pass_fail_type
        schema: $schema
        signature_required: $signature_required
        visible_data_sources: $visible_data_sources
        automated_email_display_name: $automated_email_display_name
        automated_email_user_name: $automated_email_user_name
        agency_contact_emails: $agency_contact_emails
      }
    ) {
      affected_rows
    }
  }
`
