import React from 'react'
import Files from 'react-files'
import FormField from './FormField'
import { FormFieldProps } from './FormField'

type Props = {
  type: string
  multiple?: boolean
}

const FormFieldInput = (props: FormFieldProps & Props) => (
  <FormField {...props}>
    <Files multiple={props.multiple} {...props.input}>
      <label className="btn btn-block btn-default">
        {props.input.value && props.input.value.length
          ? props.input.value.map(file => <div key={file.name}>{file.name}</div>)
          : 'Drag and drop or click'}
      </label>
    </Files>
  </FormField>
)

export default FormFieldInput
