import gql from 'graphql-tag'

export const LIST_INSPECTIONS_BY_INSPECTION_CONFIG_ID = gql`
  query listInspectionsByInspectionConfigId($inspectionConfigId: String!, $startDate: DateTime, $endDate: DateTime) {
    listInspectionsByInspectionConfigId(
      inspectionConfigId: $inspectionConfigId
      startDate: $startDate
      endDate: $endDate
    )
  }
`

export const GET_POST_GIS_FEATURE_SET_ID = gql`
  query getPostGisFeatureSetId($agencyName: String!, $dataSourceName: String!) {
    feature_set(where: { agency_name: { _eq: $agencyName }, data_source_name: { _eq: $dataSourceName } }) {
      guid
    }
  }
`

export const GET_DISPATCH_PAYLOADS = gql`
  query getDispatchPayloads {
    dispatch_payload(order_by: { created_date: desc_nulls_last }) {
      created_date
      destination
      handled
      message_id
      parsed
      raw_message
      source
      type
    }
  }
`

export const GET_AGENCY_BOUNDARY = gql`
  query getAgencyBoundary($agencyName: String!) {
    getAgencyBoundary(agencyName: $agencyName) {
      type
      geometry {
        type
        coordinates
      }
      properties
    }
  }
`

export const GET_DATA_SHARING = gql`
  query getDataSharing($consumingAgency: String!, $owningAgencyGuids: [uuid!]) {
    rel_agency_feature_set(
      where: {
        agency_name: { _eq: $consumingAgency }
        feature_set_guid: { _in: $owningAgencyGuids }
        _and: { can_read_features: { _eq: true } }
      }
    ) {
      feature_set_guid
    }
  }
`

export const GET_DATA_SOURCE_NAMES_AND_FEATURE_SET_GUIDS = gql`
  query getDataSourceNamesAndFeatureSetGuids($agencyName: String!) {
    feature_set(where: { agency_name: { _eq: $agencyName } }) {
      data_source_name
      guid
    }
  }
`

export const GET_DISPATCH_PARSERS = gql`
  query getDispatchParsers {
    dispatch_parser(order_by: { parser_name: asc }) {
      guid
      parser_name
      display_name
    }
  }
`

export const INSPECTION_CONFIGS_QUERY = gql`
  query InspectionConfigs {
    inspection_config {
      agency_name
      display_name
      feature_set_guid
      firebase_id
      guid
      inspection_type
      pass_fail_type
      schema
      visible_data_sources
      signature_required
      feature_set {
        data_source_name
        feature_schema
      }
    }
  }
`

export const INSPECTION_CONFIGS_BY_AGENCY_NAME = gql`
  query InspectionConfigsByAgencyName($agency_name: String) {
    inspection_config(where: { agency_name: { _eq: $agency_name } }) {
      active
      agency_name
      display_name
      feature_set_guid
      firebase_id
      guid
      inspection_type
      pass_fail_type
      schema
      visible_data_sources
      signature_required
      automated_email_display_name
      automated_email_user_name
      agency_contact_emails
      feature_set {
        data_source_name
        feature_schema
      }
    }
  }
`

export const GET_FILTERED_INSPECTIONS_AND_COUNT = gql`
  query GetInspections(
    $guid: uuid
    $startDate: timestamptz
    $endDate: timestamptz
    $limit: Int
    $cursor: bigint
    $properties: jsonb
    $eq: bigint
    $gt: bigint
  ) {
    inspection_fail_count_aggregate(
      where: {
        inspection_config_guid: { _eq: $guid }
        inspection_date: { _gte: $startDate, _lte: $endDate }
        properties: { _contains: $properties }
        consecutive_fails: { _eq: $eq, _gt: $gt }
      }
    ) {
      aggregate {
        count
      }
    }
    inspection_fail_count(
      where: {
        inspection_config_guid: { _eq: $guid }
        inspection_date: { _gte: $startDate, _lte: $endDate }
        properties: { _contains: $properties }
        rn: { _lt: $cursor }
        consecutive_fails: { _eq: $eq, _gt: $gt }
      }
      limit: $limit
    ) {
      created_date
      created_user_guid
      feature_guid
      guid
      inspection_date
      inspection_failed
      modified_date
      persisted_date
      properties
      feature_properties
      consecutive_fails
      signature_name
      signature_email
      attachment_count
      rn
    }
  }
`

export const GET_INSPECTION_ATTACHMENTS = gql`
  query GetInspectionAttachments($inspection_guid: uuid) {
    inspection_attachment(where: { inspection_guid: { _eq: $inspection_guid } }) {
      file_type
      guid
    }
  }
`

export const GET_OLD_DB_MIGRATION_DATE = gql`
  query getOldDbMigrationDate($agencyName: String) {
    feature_set(
      limit: 1
      where: { agency_name: { _eq: $agencyName }, created_user_guid: { _eq: "migratedFromOldSystem2" } }
      order_by: { created_date: asc }
    ) {
      created_date
    }
  }
`

export const GET_FEATURE_EDITS = gql`
  query getFeatureEdits($agencyName: String, $migrationDate: timestamptz) {
    feature(
      where: {
        _or: [
          {
            feature_set: { agency_name: { _eq: $agencyName }, created_user_guid: { _neq: "migratedFromOldSystem2" } }
            modified_user_guid: { _is_null: false }
          }
          {
            feature_set: { agency_name: { _eq: $agencyName }, created_user_guid: { _eq: "migratedFromOldSystem2" } }
            modified_user_guid: { _is_null: false }
            modified_date: { _gt: $migrationDate }
          }
        ]
      }
      limit: 200
      order_by: { modified_date: desc }
    ) {
      geom
      guid
      modified_date
      version
      properties
      modified_user_guid
      feature_set {
        data_source_name
      }
    }
  }
`

export const GET_FEATURE_EDIT_DELETIONS = gql`
  query getFeatureEditDeletions($agencyName: String) {
    feature_history(
      where: {
        feature_set: { agency_name: { _eq: $agencyName } }
        _and: { deleted_date: { _is_null: false }, modified_user_guid: { _is_null: false } }
      }
      limit: 200
      order_by: { deleted_date: desc }
    ) {
      deleted_date
      modified_user_guid
      feature_set {
        data_source_name
      }
      geom
      guid
      properties
      version
    }
  }
`

export const GET_LAST_EDIT_OR_DELETE_DATE = agencyName => gql`
  query getLastEditDate($agencyNameFilter: String!) {
    feature(
      where: { feature_set: { agency_name: { _eq: $agencyNameFilter } } }
      limit: 1
      order_by: { modified_date: desc }
    ) {
      modified_date
    }
    feature_history(
      where: { feature_set: { agency_name: { _eq: $agencyNameFilter } }, deleted_date: { _is_null: false } }
      limit: 1
      order_by: { deleted_date: desc }
    ) {
      deleted_date
    }
  }
`

export const EXPORT_GIS_DATA = gql`
  query exportGisData($featureSetGuid: String!, $format: String!) {
    exportGisData(featureSetGuid: $featureSetGuid, format: $format) {
      url
    }
  }
`

export const FEATURE_SETS_BY_AGENCY_QUERY = gql`
  query FeatureSetsByAgency($agencyName: String) {
    feature_set(where: { agency_name: { _eq: $agencyName } }) {
      agency_name
      data_source_name
      guid
    }
  }
`

export const AGENCIES_WITH_INSPECTION_CONFIGS = gql`
  query AgenciesWithInspectionConfigs {
    inspection_config(distinct_on: agency_name) {
      agency_name
    }
  }
`
