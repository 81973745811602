import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { saveAgency } from '../actions/agencies'
import { Row, Col, Alert, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import { FormFieldInput, LoadingButton } from '../components/BootstrapReduxForm' // eslint-disable-line import/named
import Agency from '../records/agency'

const DISPLAY_NAME_FIELD = 'displayName'
const IS_ACTIVE_FIELD = 'isActive'
const LICENSES_FIELD = 'licenses'
const CONTACT_EMAIL_FIELD = 'contactEmail'
const HAS_INSPECT_FIELD = 'hasInspect'

const emailRegex = /^\S+@\S+\.[A-Z]{2,}$/i

function validateEditAgencyForm(data) {
  const errors = {}
  if (isNaN(data[LICENSES_FIELD])) {
    errors[LICENSES_FIELD] = 'Invalid number of licenses. Must be integer.'
  } else if (String(data[LICENSES_FIELD]).includes('.')) {
    errors[LICENSES_FIELD] = 'Licenses must be an integer.'
  } else if (parseInt(data[LICENSES_FIELD], 10) < 0) {
    errors[LICENSES_FIELD] = 'Licenses number cannot be negative.'
  } else if (data[CONTACT_EMAIL_FIELD] && !emailRegex.test(data[CONTACT_EMAIL_FIELD])) {
    errors[CONTACT_EMAIL_FIELD] = 'Contact email must be a valid email address.'
  }
  return errors
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveAgency,
    },
    dispatch
  )
}

type Props = {
  onSuccess: Function
  // TODO: figure out correct type
  onCancel: (event: any) => void
  // agency we're editing
  agency: any
  saveAgency: Function
} & InjectedFormProps

class EditAgencyForm extends React.Component<Props> {
  componentWillMount() {
    const agency = this.props.agency
    this.loadExistingAgencyIntoForm(agency)
  }

  /**
   * Load the input agency object as the default inputs to the form.
   */
  loadExistingAgencyIntoForm(agency) {
    const initialFormValues = {}
    initialFormValues[DISPLAY_NAME_FIELD] = agency.displayName
    initialFormValues[IS_ACTIVE_FIELD] = agency.isActive
    initialFormValues[LICENSES_FIELD] = agency.licenses
    initialFormValues[CONTACT_EMAIL_FIELD] = agency.contactEmail
    initialFormValues[HAS_INSPECT_FIELD] = agency.hasInspect
    this.props.initialize(initialFormValues)
  }

  submitEditAgencyForm = data => {
    const updatedAgency = new Agency({ key: this.props.agency.key, ...data })
    // immediately convert to plain JS since that's what Firebase expects
    return this.props.saveAgency(updatedAgency).then(result => {
      if (result.error) {
        throw new SubmissionError({ _error: result.payload.message })
      }
      this.props.onSuccess()
    })
  }

  render() {
    const { error, handleSubmit, submitting } = this.props
    return (
      <form>
        <div>
          <Row>
            <Field
              name={DISPLAY_NAME_FIELD}
              id={DISPLAY_NAME_FIELD}
              component={FormFieldInput}
              type="text"
              label="Display Name:"
            />
          </Row>
          <Row>
            <Field
              name={IS_ACTIVE_FIELD}
              id={IS_ACTIVE_FIELD}
              component={FormFieldInput}
              type="checkbox"
              label="Active:"
              labelColSize={4}
            />
          </Row>
          <Row>
            <Field
              name={HAS_INSPECT_FIELD}
              id={HAS_INSPECT_FIELD}
              component={FormFieldInput}
              type="checkbox"
              label="Has Inspect:"
              labelColSize={4}
            />
          </Row>
          <Row>
            <Field name={LICENSES_FIELD} id={LICENSES_FIELD} component={FormFieldInput} type="text" label="Licenses:" />
          </Row>
          <Row>
            <Field
              name={CONTACT_EMAIL_FIELD}
              id={CONTACT_EMAIL_FIELD}
              component={FormFieldInput}
              type="text"
              label="Contact Email:"
            />
          </Row>
        </div>
        {error && (
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row className="form-group">
          <Col sm={{ span: 6, offset: 6 }} className="text-center">
            <ButtonToolbar>
              <ButtonGroup>
                <LoadingButton
                  variant="primary"
                  size="lg"
                  label="Save"
                  loading={submitting}
                  loadingLabel="Saving"
                  onClick={handleSubmit(this.submitEditAgencyForm)}
                  type="submit"
                />
                <Button variant="outline-dark" className="ml-2" onClick={this.props.onCancel} size="lg">
                  Cancel
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'editAgency',
    validate: validateEditAgencyForm,
  })(EditAgencyForm)
)
