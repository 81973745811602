import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Button, ButtonGroup, Card, Row, Col } from 'react-bootstrap'
import Input from '../components/Input'
import { Field, reduxForm, SubmissionError, InjectedFormProps } from 'redux-form'
import { firebaseConnect } from 'react-redux-firebase'
import { getAgenciesList } from '../selectors'
import { pushTaskToQueue } from '../actions/queues'
import { copyProdDataToDev } from '../actions/prodToDevDataCopy'
import { getUserToken } from '../firebase'

const SELECTED_AGENCIES = 'selectedAgencies'

const PROCESS_SEARCH_DATA_HTTPS_URL = 'https://us-central1-incidentview.cloudfunctions.net/processSearchDataHttps'

function mapStateToProps(state) {
  return {
    agencies: getAgenciesList(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ copyProdDataToDev, pushTaskToQueue }, dispatch)
}

type Props = {
  agencies: Record<string, any>
  pushTaskToQueue: Function
} & InjectedFormProps

export class MultiAgencyQueueForm extends React.Component<Props> {
  componentWillMount() {
    this.props.initialize({ [SELECTED_AGENCIES]: [] })
  }

  _onSubmitTaskToQueue({ selectedAgencies }, queueName) {
    const handler = result => {
      if (result.error) {
        throw new SubmissionError({ _error: result.payload.message })
      }
    }

    for (const agency of selectedAgencies) {
      this.props.pushTaskToQueue(queueName, { agencyName: agency }).then(handler)
    }
  }

  _onSubmitStyle = data => {
    this._onSubmitTaskToQueue(data, 'generateStyleJson')
  }

  _onSubmitSearch = async data => {
    let userToken = await getUserToken()
    let { selectedAgencies } = data
    for (const agency of selectedAgencies) {
      fetch(PROCESS_SEARCH_DATA_HTTPS_URL, {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify({
          agency: agency,
          authorization: `Bearer ${userToken}`,
        }),
      })
        .then(function(response) {
          console.log(response)
          for (let pair of response.headers.entries()) {
            console.log(pair[0] + ': ' + pair[1])
          }
        })
        .catch(function(err) {
          console.error(err)
        })
    }
  }

  render() {
    return (
      <div>
        <form>
          <Row style={{ marginTop: '16px' }}>
            <Col>
              <Card>
                <Card.Body>
                  <Field
                    name={SELECTED_AGENCIES}
                    component={props => (
                      <Input type="select" multiple {...props.input}>
                        {this.props.agencies.map(agency => (
                          <option key={agency.agencyName} value={agency.agencyName}>
                            {agency.agencyName}
                          </option>
                        ))}
                      </Input>
                    )}
                  />
                </Card.Body>
                <Card.Footer>
                  <ButtonGroup>
                    <Button onClick={this.props.handleSubmit(this._onSubmitStyle)} variant="primary">
                      Generate StyleJSON
                    </Button>
                    <Button onClick={this.props.handleSubmit(this._onSubmitSearch)} variant="primary">
                      Generate searchDb.sqlite
                    </Button>
                  </ButtonGroup>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </form>
      </div>
    )
  }
}

export default compose(
  firebaseConnect((props, firebase) => ['/agencies/']),
  reduxForm({
    form: 'multiAgencyQueue',
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MultiAgencyQueueForm)
