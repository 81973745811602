import React, { Component } from 'react'
import { Row, Col, Button, Alert } from 'react-bootstrap'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { LinkContainer } from 'react-router-bootstrap'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { SimpleBootstrapInput } from '../components/BootstrapReduxForm'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as authActions from '../actions/auth'
import queryString from 'query-string'

const mapStateToProps = state => ({ user: state.auth.user })
const mapDispatchToProps = dispatch => bindActionCreators(authActions, dispatch)

const EMAIL_FIELD = 'email'
const PASSWORD_FIELD = 'password'

type Props = {
  user: Record<string, any>
  login: Function
} & InjectedFormProps &
  RouteComponentProps

class LoginForm extends Component<Props> {
  componentWillMount() {
    this.props.initialize({ [EMAIL_FIELD]: '', [PASSWORD_FIELD]: '' })
  }

  submitLoginForm = data => {
    const urlQuery = queryString.parse(this.props.location as any)

    return this.props.login(data[EMAIL_FIELD], data[PASSWORD_FIELD]).then(result => {
      if (result.error) {
        if (result.payload.code === 'auth/user-not-found') {
          throw new SubmissionError({ [EMAIL_FIELD]: 'The user does not exist' })
        }
        if (['auth/invalid-email', 'auth/invalid-email-verified'].includes(result.payload.code)) {
          throw new SubmissionError({ [EMAIL_FIELD]: result.payload.message })
        }
        if (['auth/invalid-password', 'auth/wrong-password'].includes(result.payload.code)) {
          throw new SubmissionError({ [PASSWORD_FIELD]: 'The password is invalid' })
        }
        // if it doesn't match a code we know, just send it as a general error
        throw new SubmissionError({ _error: result.payload.message })
      } else {
        const { nextPath } = urlQuery
        const urlPath = nextPath ? `/${nextPath}` : '/'
        this.props.history.push(urlPath)
      }
    })
  }

  render() {
    const { user, error, handleSubmit, submitting } = this.props
    return (
      <Col>
        {!user && (
          <form onSubmit={handleSubmit(this.submitLoginForm)}>
            <Row>
              <Field
                name={EMAIL_FIELD}
                component={SimpleBootstrapInput}
                type="email"
                placeholder="email"
                size="lg"
                hasFeedback
                addonBefore={<FaEnvelope />}
              />
            </Row>
            <Row>
              <Field
                name={PASSWORD_FIELD}
                component={SimpleBootstrapInput}
                type="password"
                placeholder="password"
                size="lg"
                hasFeedback
                addonBefore={<FaLock />}
              />
            </Row>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>{error && <Alert variant="danger">{error}</Alert>}</Col>
            </Row>
            <Row>
              <Col>
                <Button type="submit" className="custom-btn" size="lg" block disabled={submitting}>
                  Sign In
                </Button>
              </Col>
            </Row>
            <Row>
              <Col className="right-button">
                <LinkContainer to="/reset-password">
                  <Button variant="link" className="button-link">
                    Forgot password?
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          </form>
        )}
        {user && (
          <div>
            <p>You are currently signed in as {user.email}.</p>
            <div>
              <LinkContainer to="/change-password">
                <Button size="lg" className="btn btn-grey" block>
                  Change Password
                </Button>
              </LinkContainer>
            </div>
          </div>
        )}
      </Col>
    )
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'login',
  })
)(LoginForm)
