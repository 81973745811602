import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import React, { useState } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Alert, Col, Row, Button, ButtonToolbar, Form } from 'react-bootstrap'
import { FormFieldInput, LoadingButton } from '../components/BootstrapReduxForm'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { useQuery, useMutation } from 'react-apollo'
import * as EmailValidator from 'email-validator'
import {
  client,
  GET_DISPATCH_PARSERS,
  INSERT_AGENCY_AND_EMAIL,
  INSERT_AGENCY_AND_SMS,
  INSERT_DISPATCH_MESSAGE_SOURCE,
} from '../apollo'

export const PARSER_NAME = 'parserName'
export const PARSER_DISPLAY = 'parserDisplay'
export const AGENCY_NAME = 'agencyName'
export const AGENCY_DISPLAY = 'agencyDisplay'
export const RECEIVER_EMAIL = 'receiverEmail'
export const SOURCE = 'source'
export const SOURCE_NOTES = 'sourceNotes'

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const formParams = { form: 'addDispatchAgency' }

type Props = {
  addAgency: any
} & RouteComponentProps &
  InjectedFormProps

const AddDispatchAgency = (props: Props) => {
  const getDispatchParsers = useQuery(GET_DISPATCH_PARSERS)
  const [successAlert, setSuccessAlert] = useState(false)
  const [failureAlert, setFailureAlert] = useState(false)
  const [failureMessage, setFailureMessage] = useState('')
  const [agencyName, setAgencyName] = useState('')
  const [parserGuid, setParserGuid] = useState('')
  const [agencyDisplay, setAgencyDisplay] = useState('')
  const [receiverEmail, setReceiverEmail] = useState('')
  const [source, setSource] = useState('')
  const [sourceNotes, setSourceNotes] = useState('')
  const [insertAgencyAndEmail, returnAgencyAndEmailVal] = useMutation(INSERT_AGENCY_AND_EMAIL)
  const [insertAgencyAndSms, returnAgencyAndSmsVal] = useMutation(INSERT_AGENCY_AND_SMS)
  const [insertDispatchMessageSource, returnDispatchMessageSourceVal] = useMutation(INSERT_DISPATCH_MESSAGE_SOURCE)

  function getDispatchParsersFunction() {
    if (getDispatchParsers && getDispatchParsers.data && getDispatchParsers.data.dispatch_parser) {
      return getDispatchParsers.data.dispatch_parser
    } else {
      return []
    }
  }

  function resetForm() {
    setAgencyName('')
    setParserGuid('')
    setAgencyDisplay('')
    setReceiverEmail('')
    setSource('')
    setSourceNotes('')
    setFailureMessage('')
  }

  async function submitAddDispatchAgencyForm(event) {
    try {
      let agencyGuid
      if (EmailValidator.validate(receiverEmail)) {
        const result = await insertAgencyAndEmail({
          variables: {
            agency_name: agencyName,
            agency_display_name: agencyDisplay,
            parser_guid: parserGuid,
            receiver_email: receiverEmail,
          },
        })
        agencyGuid = result.data.insert_agency_to_dispatch_email_address_one.agency.guid
        await insertDispatchMessageSource({
          variables: {
            agency_guid: agencyGuid,
            notes: sourceNotes,
            source: source,
          },
        })
      } else {
        const result = await insertAgencyAndSms({
          variables: {
            agency_name: agencyName,
            display_name: agencyDisplay,
            parser_guid: parserGuid,
            sms: receiverEmail,
          },
        })
        agencyGuid = result.data.insert_agency_to_dispatch_sms_one.agency.guid
        await insertDispatchMessageSource({
          variables: {
            agency_guid: agencyGuid,
            notes: sourceNotes,
            source: source,
          },
        })
      }

      setFailureAlert(false)
      setSuccessAlert(true)
      resetForm()
    } catch (error) {
      setFailureMessage(error.toString())
      setFailureAlert(true)
      setSuccessAlert(false)
    }
  }

  return (
    <div>
      <Col>
        {successAlert && (
          <Row>
            <Alert variant="success" onClose={() => setSuccessAlert(false)} dismissible>
              Dispatch Agency added.
            </Alert>
          </Row>
        )}
        {failureAlert && (
          <Row>
            <Alert variant="danger" onClose={() => setFailureAlert(false)} dismissible>
              <Alert.Heading>Dispatch Agency not added.</Alert.Heading>
              <p>{failureMessage}</p>
            </Alert>
          </Row>
        )}
        <Row>
          <Form.Group controlId={PARSER_NAME}>
            <Form.Label>Parser Name</Form.Label>
            <Form.Control
              value={parserGuid}
              as="select"
              onChange={(e: React.ChangeEvent<any>) => setParserGuid(e.target.value)}
            >
              <option>Select a Parser</option>
              {getDispatchParsersFunction().map((dispatchParser, index) => {
                return (
                  <option key={dispatchParser.guid} value={dispatchParser.guid}>
                    {dispatchParser.display_name}
                  </option>
                )
              })}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId={AGENCY_NAME}>
            <Form.Label>Agency Name</Form.Label>
            <Form.Control
              value={agencyName}
              placeholder="Agency Name"
              onChange={(e: React.ChangeEvent<any>) => {
                setAgencyName(e.target.value)
              }}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId={AGENCY_DISPLAY}>
            <Form.Label>Agency Display</Form.Label>
            <Form.Control
              value={agencyDisplay}
              placeholder="Agency Display"
              onChange={(e: React.ChangeEvent<any>) => setAgencyDisplay(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId={RECEIVER_EMAIL}>
            <Form.Label>Receiver Email/SMS #</Form.Label>
            <Form.Control
              value={receiverEmail}
              placeholder="Receiver Email/SMS #"
              onChange={(e: React.ChangeEvent<any>) => setReceiverEmail(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId={SOURCE}>
            <Form.Label>Source Email/SMS #</Form.Label>
            <Form.Control
              value={source}
              placeholder="Source Email/SMS #"
              onChange={(e: React.ChangeEvent<any>) => setSource(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId={SOURCE_NOTES}>
            <Form.Label>Source Notes</Form.Label>
            <Form.Control
              value={sourceNotes}
              placeholder="Source Notes"
              onChange={(e: React.ChangeEvent<any>) => setSourceNotes(e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Col xs={15}>
            <Button type="submit" onClick={event => submitAddDispatchAgencyForm(event)}>
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default compose(withRouter)(AddDispatchAgency)
