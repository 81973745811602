import React from 'react'
import FormikGroup from './FormikGroup'
import Select from 'react-select'
import { FormControl } from 'react-bootstrap'
import { FieldProps } from 'formik'
import { Option } from '../../types'

type Props = {
  label: string
  options: Option[]
  placeholder?: string
  disabled: boolean
  multi: boolean
} & FieldProps

const FormikReactSelect = (props: Props) => {
  const { field, options, placeholder, form } = props
  const handleSingleValue = (option: Option) => {
    if (option) {
      form.setFieldValue(field.name, option.value)
    } else {
      form.setFieldValue(field.name, '')
    }
  }
  const handleValueArray = (options: Option[]) => {
    if (!options) {
      form.setFieldValue(field.name, [])
    } else {
      form.setFieldValue(field.name, options.map(opt => opt.value))
    }
  }
  const handleChange = (value: any) => {
    if (props.multi) {
      handleValueArray(value)
    } else {
      handleSingleValue(value)
    }
  }

  const setFieldTouched = () => {
    form.setFieldTouched(field.name, true)
  }

  const selectValue = props.multi
    ? options.filter(opt => field.value.includes(opt.value))
    : options.find(opt => opt.value === field.value)
  // TODO: Update the handling of disabled prop when react-select is updated
  return (
    <FormikGroup {...props}>
      <Select
        value={selectValue}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        onBlur={setFieldTouched}
        isDisabled={props.disabled}
        isMulti={props.multi}
      />
    </FormikGroup>
  )
}

export default FormikReactSelect
