import React from 'react'
import queryString from 'query-string'
import DataGridWrapper from '../components/DataGridWrapper'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import EditFeatureSetsForm from '../containers/EditFeatureSetsForm'

const FeatureSetsView = (props: any) => {
  const urlQuery = queryString.parse(props.location.search)
  const agencyNameFilter = urlQuery.agencyName
  const leftHeader = <AgencyFilterSelect agencyNameFilter={agencyNameFilter} location={props.location} />
  return (
    <DataGridWrapper header="Feature Sets" leftHeader={leftHeader} scrollable>
      {agencyNameFilter ? <EditFeatureSetsForm agencyName={agencyNameFilter} /> : null}
    </DataGridWrapper>
  )
}
export default FeatureSetsView
