import React from 'react'
import { Row, Col, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import { FormField, FormFieldCheckbox, FormFieldReactSelect } from '../components/BootstrapReduxForm'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { formValues, reduxForm, Field, Fields } from 'redux-form'
import { saveAvlDisplayGroups } from '../actions/agencyConfigs'
import { saveDeviceGroup, deleteDeviceGroup, setIsDispatchGroup } from '../actions/deviceGroups'
import {
  getAgencies,
  getAgencyNameUserOrUrl,
  getAgencyConfigJs,
  getAgencyAllowedSharingJs,
  getUniqueDevicesArray,
  getDeviceGroupsNotDispatchNameArray,
  getDeviceGroupsIdsObjectJs,
  getDispatchGroups,
} from '../selectors'
import ColorSelector from '../components/ColorSelector'
import { withRouter } from 'react-router-dom'

const DEVICE_IDS = 'deviceIds'
const DEVICE_GROUP = 'deviceGroup'
const IS_DISPATCH_GROUP = 'isDispatchGroup'
const HIDE_FROM_AVL = 'hideFromAvl'
const HIDE_FROM_RESPONSE_STATUS = 'hideFromResponseStatus'
const OVERRIDE_AVL_COLOR = 'overrideAvlColor'
const AVL_COLOR = 'avlColor'

type Props = {
  change: Function
  handleSubmit: Function
  initialize: Function
  reset: any
  saveDeviceGroup: Function
  deleteDeviceGroup: Function
  setIsDispatchGroup: Function
  saveAvlDisplayGroups: Function
  agencies: Record<string, any>
  devices: any[]
  deviceGroupNames: any[]
  deviceGroupIds: Record<string, any>
  dispatchGroups: Record<string, any>
  agencyName: string
  agencyConfig: Record<string, any>
  selectedDeviceGroup: string
}

const validateForm = (data, props: Props) => {
  const errors = {}
  const regexInvalidFirebaseKeyNames = /[.#$/[\]]/
  if (data[DEVICE_GROUP]) {
    const regexpMatch = data[DEVICE_GROUP].match(regexInvalidFirebaseKeyNames)
    if (regexpMatch) {
      errors[DEVICE_GROUP] = `Group name contains an invalid character: ${regexpMatch[0]}`
    }
  }

  if (data[DEVICE_IDS] === '') {
    errors[DEVICE_IDS] = 'Groups must include at least 1 device'
  }

  if (data[OVERRIDE_AVL_COLOR] && !data[AVL_COLOR]) {
    errors[OVERRIDE_AVL_COLOR] = 'Please select a color or un-check the override option'
  }

  if (data[DEVICE_GROUP] in props.dispatchGroups) {
    errors[DEVICE_GROUP] =
      `The group you're trying to edit, "${data[DEVICE_GROUP]}", ` +
      `already exists as a Resource Group. Please edit it in the Resource Groups tab.`
  }

  if (!data[HIDE_FROM_AVL] && !data[OVERRIDE_AVL_COLOR]) {
    errors[HIDE_FROM_AVL] = 'Please check either the hide or override option'
  }

  if (data[HIDE_FROM_AVL] && data[OVERRIDE_AVL_COLOR]) {
    errors[HIDE_FROM_AVL] = 'Please un-check either the hide or override option'
  }

  const isNewGroup = !(data[DEVICE_GROUP] in props.deviceGroupIds)
  if (isNewGroup && data[DEVICE_IDS]) {
    const idsInNewGroup = data[DEVICE_IDS].split(DELIMITER)
    const idsAlreadyInDisplayGroups = Object.entries(props.deviceGroupIds).reduce((acc, [groupName, deviceIds]) => {
      if (props.agencyConfig.avlDisplayGroups && groupName in props.agencyConfig.avlDisplayGroups) {
        return acc.concat(deviceIds)
      }
      return acc
    }, [])

    let message = ''
    for (const newId of idsInNewGroup) {
      if (idsAlreadyInDisplayGroups.includes(newId)) {
        if (!message) {
          message = 'Cannot have a device in more than one display group.'
        }
        let deviceGroupThatThisIdIsAlreadyIn
        for (const [groupName, groupsIds] of Object.entries(props.deviceGroupIds)) {
          if (groupsIds.includes(newId)) {
            deviceGroupThatThisIdIsAlreadyIn = groupName
          }
        }
        const device = props.devices.find(d => d.hardwareUid === newId)
        message +=
          ` Device with name "${device.displayName}" ` +
          `already exists in display group ${deviceGroupThatThisIdIsAlreadyIn}.`
      }
    }
    if (message) {
      errors[DEVICE_IDS] = message
    }
  }

  return errors
}

function mapStateToProps(state, ownProps) {
  return {
    agencies: getAgencies(state),
    agencyName: getAgencyNameUserOrUrl(state, ownProps),
    agencyConfig: getAgencyConfigJs(state, ownProps),
    agencyAllowedSharing: getAgencyAllowedSharingJs(state, ownProps),
    devices: getUniqueDevicesArray(state, ownProps),
    deviceGroupNames: getDeviceGroupsNotDispatchNameArray(state, ownProps),
    deviceGroupIds: getDeviceGroupsIdsObjectJs(state, ownProps),
    dispatchGroups: getDispatchGroups(state, ownProps),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ saveDeviceGroup, deleteDeviceGroup, setIsDispatchGroup, saveAvlDisplayGroups }, dispatch)
}

const DELIMITER = ','

// Render these 2 fields together since they rely on each other and share a single FormField section
const renderAVLFields = fields => (
  // @ts-ignore
  <FormField
    help="Override individual AVL colors and display all devices in this group using a single color."
    label="Override AVL color"
    labelColSize={4}
    meta={fields[OVERRIDE_AVL_COLOR].meta}
  >
    <Col md={2}>
      <input type="checkbox" checked={fields[OVERRIDE_AVL_COLOR].input.value} {...fields[OVERRIDE_AVL_COLOR].input} />
    </Col>
    <Col md={10}>
      <ColorSelector
        {...fields[AVL_COLOR].input}
        value={fields[OVERRIDE_AVL_COLOR].input.value ? fields[AVL_COLOR].input.value : ''}
        disabled={!fields[OVERRIDE_AVL_COLOR].input.value}
      />
    </Col>
  </FormField>
)

class EditDeviceGroupForm extends React.Component<Props> {
  componentWillMount() {
    this.props.initialize({
      [DEVICE_GROUP]: '',
      [DEVICE_IDS]: '',
      [IS_DISPATCH_GROUP]: false,
      [HIDE_FROM_AVL]: false,
      [HIDE_FROM_RESPONSE_STATUS]: false,
      [OVERRIDE_AVL_COLOR]: false,
      [AVL_COLOR]: '',
    })
  }

  onSelectGroup = (weirdObject, group) => {
    // call the redux-form onChange action, since we are overriding it here
    if (!group) {
      return this.props.reset()
    }

    this.props.change(DEVICE_GROUP, group)

    const inDispatchGroup = group in this.props.dispatchGroups
    this.props.change(IS_DISPATCH_GROUP, inDispatchGroup)

    if (this.props.agencyConfig && this.props.agencyConfig.avlDisplayGroups) {
      const allGroupSettings = this.props.agencyConfig.avlDisplayGroups
      const groupSettings = allGroupSettings && allGroupSettings[group]
      if (groupSettings && (groupSettings === true || groupSettings['hide'])) {
        this.props.change(HIDE_FROM_AVL, true)
      } else {
        this.props.change(HIDE_FROM_AVL, false)
      }

      if (groupSettings && groupSettings['avlColor']) {
        this.props.change(OVERRIDE_AVL_COLOR, true)
        this.props.change(AVL_COLOR, groupSettings['avlColor'])
      } else {
        this.props.change(OVERRIDE_AVL_COLOR, false)
        this.props.change(AVL_COLOR, '')
      }
    }

    // load the selected group's devices into the DEVICE_IDS select component
    const deviceIds = this.props.deviceGroupIds && this.props.deviceGroupIds[group]

    if (deviceIds) {
      return this.props.change(DEVICE_IDS, deviceIds.join(DELIMITER))
    }
  }

  submitForm = data => {
    if (data[DEVICE_GROUP]) {
      if (!data[DEVICE_IDS]) {
        // user has pressed "Save" with no devices in the group.
        // Rather than calling saveDeviceGroup, which will delete the group
        // quietly, call deleteGroup so they get a confirmation alert
        //
        // This should now be caught in the validation process, so we shouldn't
        // ever reach this code
        return this.deleteGroup()
      } else {
        if (!this.props.agencyConfig) {
          throw new Error('This agency does not have an agencyConfig')
        }
        const avlDisplayGroupsObj = this.props.agencyConfig.avlDisplayGroups
          ? this.props.agencyConfig.avlDisplayGroups
          : {}
        avlDisplayGroupsObj[data[DEVICE_GROUP]] = {
          ...avlDisplayGroupsObj[data[DEVICE_GROUP]],
          avlColor: data[OVERRIDE_AVL_COLOR] ? data[AVL_COLOR] : null,
          hide: data[HIDE_FROM_AVL] || null,
        }
        const saveAvlDisplayGroupsPromise = this.props.saveAvlDisplayGroups(this.props.agencyName, avlDisplayGroupsObj)

        const saveDeviceGroupPromise = this.props.saveDeviceGroup(
          this.props.agencyName,
          data[DEVICE_GROUP],
          data[DEVICE_IDS].split(DELIMITER)
        )
        const setIsDispatchGroupPromise = this.props.setIsDispatchGroup(
          this.props.agencyName,
          data[DEVICE_GROUP],
          data[IS_DISPATCH_GROUP]
        )
        return Promise.all([saveDeviceGroupPromise, setIsDispatchGroupPromise, saveAvlDisplayGroupsPromise]).then(
          this.props.reset
        )
      }
    }
  }

  deleteGroup = () => {
    const groupName = this.props.selectedDeviceGroup
    const sureYouWantToDelete = confirm(`Are you sure you want to delete the "${groupName}" group?`) // eslint-disable-line no-restricted-globals
    if (sureYouWantToDelete && groupName) {
      const avlDisplayGroupsObj = this.props.agencyConfig.avlDisplayGroups
        ? this.props.agencyConfig.avlDisplayGroups
        : {}
      delete avlDisplayGroupsObj[groupName]
      return this.props
        .deleteDeviceGroup(this.props.agencyName, groupName)
        .then(() => this.props.saveAvlDisplayGroups(this.props.agencyName, avlDisplayGroupsObj))
        .then(this.props.reset)
    }
  }

  selectAll = () => {
    const { devices } = this.props
    const devicesString = devices && devices.map(dev => dev.key).join(DELIMITER)
    this.props.change(DEVICE_IDS, devicesString)

    // Add a new node to the page to force it to re-render, otherwise the select
    // extends down past the bottom of the page sometimes.
    // TODO: find a less hacky way to fix this.
    const emptyDiv = document.createElement('div')
    document.body.insertBefore(emptyDiv, document.getElementById('root'))
    setTimeout(() => document.body.removeChild(emptyDiv), 10)
  }

  render() {
    const { agencies, devices, deviceGroupNames, selectedDeviceGroup, handleSubmit } = this.props
    const agencyDisplayNameObj = agencies
      ? Object.entries(agencies).reduce((acc, [agencyName, { displayName }]) => {
          acc[agencyName] = displayName || agencyName
          return acc
        }, {})
      : {}
    const deviceOptions = devices.map(device => ({
      value: device.key,
      label: `${agencyDisplayNameObj[device.agencyName] || device.agencyName} - ${device.displayName}`,
    }))
    deviceOptions.sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }))

    const deviceGroupOptions = deviceGroupNames.map(group => ({
      value: group,
      label: group,
    }))
    deviceGroupOptions.sort((a, b) => a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }))

    return (
      <form>
        {
          <Row style={{ textAlign: 'left' }}>
            <Field
              name={DEVICE_GROUP}
              component={FormFieldReactSelect}
              label="Display Group"
              allowCreate
              placeholder="Select a group, or type to add a new group..."
              help={
                'Use this configuration option to create groups of one or more devices ' +
                '(from your agency or from another agency sharing AVL with your agency). ' +
                'Then use the device groups to manage how the device AVL markers are displayed on your map. ' +
                'Options include removing devices from the map and changing the color of the AVL marker icon.'
              }
              options={deviceGroupOptions}
              delimiter={DELIMITER}
              onChange={this.onSelectGroup}
              labelColSize={2}
              fieldColSize={6}
              thirdColSize={4}
              thirdColumn={
                selectedDeviceGroup && (
                  <ButtonToolbar>
                    <ButtonGroup>
                      <Button onClick={handleSubmit(this.submitForm)}>Save</Button>
                      {deviceGroupNames.includes(selectedDeviceGroup) && (
                        // only show if this is not a newly created group
                        <Button className="ml-1" variant="danger" onClick={this.deleteGroup}>
                          Delete Group
                        </Button>
                      )}
                      <Button variant="outline-dark" className="ml-1" onClick={this.props.reset}>
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                )
              }
            />
          </Row>
        }
        {selectedDeviceGroup ? (
          <Row style={{ textAlign: 'left' }}>
            <Field
              name={DEVICE_IDS}
              component={FormFieldReactSelect}
              label="Devices in Group"
              placeholder="Add devices to this group..."
              options={deviceOptions}
              multi
              delimiter={DELIMITER}
              labelColSize={2}
              fieldColSize={8}
              thirdColSize={2}
              thirdColumn={
                <Button variant="outline-dark" onClick={this.selectAll}>
                  Add All Devices
                </Button>
              }
            />
          </Row>
        ) : null}
        <Row style={{ textAlign: 'left' }}>
          <Col sm={5}>
            {selectedDeviceGroup && (
              <Row>
                <Field
                  name={HIDE_FROM_AVL}
                  component={FormFieldCheckbox}
                  type="checkbox"
                  label="Hide Group from AVL"
                  help="Select to hide this group from the AVL Layer on all IncidentView devices."
                  labelColSize={6}
                  fieldColSize={1}
                />
              </Row>
            )}
          </Col>
          <Col sm={7}>
            {selectedDeviceGroup && (
              <Row>
                <Fields names={[OVERRIDE_AVL_COLOR, AVL_COLOR]} component={renderAVLFields} />
              </Row>
            )}
          </Col>
        </Row>
      </form>
    )
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'editDeviceGroup',

    validate: validateForm,
  }),
  formValues({ selectedDeviceGroup: DEVICE_GROUP }),
  firebaseConnect((props, firebase) => {
    const { agencyName, agencyAllowedSharing } = props
    let paths = ['/agencies/']
    if (agencyName) {
      // This gives us avlDisplayGroups
      paths.push(`/agencyConfigs/${agencyName}`)

      paths.push(`/agencyAllowedSharing/${agencyName}/`)
      paths.push(`/deviceGroups/${agencyName}`)
    }
    paths.push(`/devices/${agencyName}`)
    if (agencyAllowedSharing) {
      for (const ag in agencyAllowedSharing) {
        paths.push(`/devices/${ag}`)
      }
    }
    return paths
  })
)(EditDeviceGroupForm)
