import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Field } from 'redux-form'
import { FormFieldInput } from '../../components/BootstrapReduxForm'

function mapStateToProps(state) {
  const { firebase } = state
  return {
    agencyNames: firebase.data.agencies && Object.keys(firebase.data.agencies),
  }
}

const AgencySelectField = ({ agencyNames, name }) => (
  <Field name={name} component={FormFieldInput} type="select" label="Agency name">
    <option key="default" value="">
      ------------
    </option>
    {agencyNames &&
      agencyNames.map(agencyName => (
        <option key={agencyName} value={agencyName}>
          {agencyName}
        </option>
      ))}
  </Field>
)

export default compose(
  firebaseConnect(props => {
    return [{ path: '/agencies', type: 'once' }]
  }),
  connect(mapStateToProps)
)(AgencySelectField)
