import { createSelector } from 'reselect'
import { sortDataGridItems } from '../utils/sorting'
import { getAgencyNameUserOrUrl } from './agenciesSelectors'

const getDispatchesNode = state => state.dispatches.data
const getSorting = state => state.dispatches.sorting

export const getDispatchesForAgency = createSelector(
  getDispatchesNode,
  getAgencyNameUserOrUrl,
  (dispatchesNode, agencyName) => dispatchesNode && dispatchesNode.get(agencyName)
)

export const getDispatchListForAgency = (state, agencyName) => {
  const dispatches = getDispatchesForAgency(state, agencyName)
  return (
    dispatches &&
    dispatches.toList().sort((d1, d2) => {
      return d2.timeStamp - d1.timeStamp
    })
  )
}

export const getDispatchListForAgencySorted = createSelector(
  getDispatchesForAgency,
  getSorting,
  (dispatchesForAgency, sorting) =>
    sortDataGridItems(dispatchesForAgency.toList(), sorting.get('attributeName'), sorting.get('sortDirection'))
)
