import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, formValues, Field, InjectedFormProps } from 'redux-form'
import { firebaseConnect } from 'react-redux-firebase'
import { functions } from '../firebase'
// import firebase from 'firebase'
// import { firestore } from '../firebase'
// import Immutable from 'immutable'
import { Row, Col, Alert, Button, ButtonToolbar, Image } from 'react-bootstrap'
import Select from 'react-select'
import {
  FormFieldInput,
  // FormFieldReactSelect,
  LoadingButton,
  FormField,
} from '../components/BootstrapReduxForm'
import { getGisDataSources } from '../selectors'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import AgencyFilterSelect from './AgencyFilterSelect'

const CURRENT_LAYERS = 'currentLayers'
const POSSIBLE_LAYERS = 'layerToAdd'
const AGENCY_NAME = 'agencyName'
const CHOSEN_STYLE = 'chosenStyle'

function mapStateToProps(state, ownProps) {
  const currentDataSources = getGisDataSources(state)
  const allDataSources = state.firebase.data.mapboxStyleLayers
  const user = state.auth.user
  const userGuid = state.auth.user.uid
  if (currentDataSources && allDataSources && userGuid) {
    return { currentDataSources, allDataSources, userGuid, user }
  } else {
    return { currentDataSources: [], allDataSources: [], userGuid: 'none' } // not sure how to handle crash when these are not yet defined
  }
}

function validateAddLayerToEditorForm(data) {
  const errors = {}
  if (!data[POSSIBLE_LAYERS]) {
    errors[POSSIBLE_LAYERS] = 'You forgot to select a layer!'
  }
  return errors
}

function determinePossibleLayersToAdd(currentLayers, allPossibleLayers) {
  const possibleLayersToAdd = []
  if (currentLayers && allPossibleLayers) {
    const currentLayersArray = Object.keys(currentLayers)
    const possibleLayersArray = Object.keys(allPossibleLayers)
    for (const possibleLayer of possibleLayersArray) {
      if (currentLayersArray.includes(possibleLayer)) {
        continue
      } else {
        const possibleLayerObject = { value: possibleLayer, label: possibleLayer }
        possibleLayersToAdd.push(possibleLayerObject)
      }
    }
  }
  return possibleLayersToAdd
}

function needToSelectLayerStyle(state, allPossibleLayers) {
  if (state && state.layerToAdd && state.layerToAdd !== false) {
    const layerAdded = state.layerToAdd
    const layerStyleProperties = allPossibleLayers[layerAdded]
    if (Object.keys(layerStyleProperties).length > 1) {
      return Object.keys(layerStyleProperties)
    }
  }
  return []
}

const dropdownRenderer = option => {
  const style: React.CSSProperties = {
    textIndent: '20%',
    textAlign: 'left',
    height: '20px',
    backgroundPosition: '5% 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${
      option.value
    }.svg?alt=media')`,
  }
  return <div style={style}>{option.value}</div>
}

const selectedRenderer = selected => {
  const style: React.CSSProperties = {
    textIndent: '20%',
    textAlign: 'left',
    backgroundPosition: '5% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px, 20px',
    fontSize: 'large',
    backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${
      selected.value
    }.svg?alt=media')`,
  }
  return <div style={style}>{selected.value}</div>
}

type Props = {
  label: string
  value: Record<string, any>
  handleChange: any
  userGuid: string
  user: Record<string, any>
  allDataSources: any
  currentDataSources: Record<string, any>
} & InjectedFormProps &
  RouteComponentProps

class AddLayerToEditorForm extends React.Component<Props, { layerToAdd: any; userGuid?: string }> {
  constructor(props) {
    super(props)
    this.state = {
      layerToAdd: false,
    }
  }

  handleChange = selectedLayer => {
    this.setState({ layerToAdd: selectedLayer, userGuid: this.props.userGuid })
  }

  submitAddLayerToEditorForm = async data => {
    const dataSourceName = this.state.layerToAdd
    if (!dataSourceName) {
      window.alert("You didn't select a layer to add") // because validateAddLayerToEditorForm is not working with
      return
    }

    const createdUserGuid = this.state.userGuid
    let styleName = null
    const agencyName = queryString.parse(this.props.location.search).agencyName
    if (data && Object.entries(data).length && data.chosenStyle && data.chosenStyle !== 'default') {
      styleName = this.props.allDataSources[dataSourceName][data.chosenStyle]
    } else {
      styleName = 'default'
    }
    const sureYouWantToAdd = confirm(`Are you sure you want to add ${dataSourceName} to the editor for ${agencyName}?`) // eslint-disable-line no-restricted-globals
    if (sureYouWantToAdd) {
      await functions.addLayerToEditor({ agencyName, dataSourceName, styleName, createdUserGuid })
      this.props.history.push(`/gis?agencyName=${agencyName}`)
    }
  }

  onCancel = () => {
    this.props.history.push('/editor')
  }

  render() {
    const { error, submitting, handleSubmit, submitFailed, location, currentDataSources, allDataSources } = this.props
    const agencyNameFilter = queryString.parse(location.search).agencyName as string
    const currentDataLayers = currentDataSources[agencyNameFilter] || {}
    const formStyleOptions = needToSelectLayerStyle(this.state, allDataSources)
    const layersToAddOptions = determinePossibleLayersToAdd(currentDataLayers, allDataSources)
    const selectedOption = layersToAddOptions.find(option => option.value === this.state.layerToAdd)

    return (
      <form>
        {!agencyNameFilter || agencyNameFilter === 'undefined' ? (
          <AgencyFilterSelect
            agencyNameFilter={agencyNameFilter}
            label={'Please Select an Agency'}
            location={location}
          />
        ) : (
          <div>
            <Row>
              <Field
                name={CURRENT_LAYERS}
                component={FormField}
                label="Current Layers"
                help={`Feature layers that are already used by ${agencyNameFilter}. Red text means that layer is currently set as NOT editable`}
              >
                {currentDataLayers &&
                  Object.keys(currentDataLayers).map(dataLayer => (
                    <div
                      key={dataLayer}
                      // value={dataLayer}
                      style={
                        {
                          display: 'inlineBlock',
                          width: '100%',
                          textAlign: 'left',
                          marginLeft: '25%',
                          fontSize: 'large',
                        } as React.CSSProperties
                      }
                      className={currentDataLayers[dataLayer].editable === true ? 'text-secondary' : 'text-danger'}
                    >
                      <Image
                        src={`https://firebasestorage.googleapis.com/v0/b/incidentview.appspot.com/o/layerIcons%2F${dataLayer}.svg?alt=media`}
                        style={{ height: 20, width: 20 }}
                      />
                      {`  ${dataLayer}`}
                    </div>
                  ))}
              </Field>
            </Row>
            <Row>
              <FormField
                name={POSSIBLE_LAYERS}
                label={this.state.layerToAdd ? 'Layer To Add' : 'Possible Layers'}
                help={
                  'These are the current feature layers that are available. If the layer of interest is not listed then a style will need to be created before adding to the editor'
                }
              >
                <Select
                  options={layersToAddOptions}
                  optionRenderer={dropdownRenderer}
                  valueRenderer={selectedRenderer}
                  value={selectedOption}
                  onChange={option => option && this.handleChange(option.value)}
                />
              </FormField>
            </Row>
            {formStyleOptions && formStyleOptions.length ? (
              <Row>
                <div>
                  {' '}
                  There are multiple styles available for this layer type. Please select a style below. If a style is
                  not selected then the default will be applied.{' '}
                </div>
                <Field
                  name={CHOSEN_STYLE}
                  component={FormFieldInput}
                  type="select"
                  label="Style Options"
                  help={`These are the re-usable styles that are currently offered for this layer.`}
                >
                  <option key="" value="">
                    ----------------
                  </option>
                  {formStyleOptions.map(styleName => (
                    <option key={styleName} value={styleName}>
                      {styleName}
                    </option>
                  ))}
                </Field>
              </Row>
            ) : null}
          </div>
        )}
        {error && (
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        {submitFailed && (
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <Alert variant="danger">The form failed to submit </Alert>
            </Col>
          </Row>
        )}
        <Row className="form-group">
          <Col sm={{ offset: 3 }} className="text-center">
            <ButtonToolbar>
              <LoadingButton
                variant="primary"
                size="large"
                label="Submit"
                loadingLabel="Saving"
                loading={submitting}
                type="submit"
                onClick={handleSubmit(this.submitAddLayerToEditorForm)}
              />
              <Button onClick={this.onCancel} size="lg">
                Cancel
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    )
  }
}

export default compose(
  withRouter,
  reduxForm({
    form: 'addLayerToEditor',
    validate: validateAddLayerToEditorForm,
  }),
  formValues({
    agencyName: AGENCY_NAME,
    // currentLayers: CURRENT_LAYERS,
    chosenStyle: CHOSEN_STYLE,
    layerToAdd: POSSIBLE_LAYERS,
  }),
  firebaseConnect(props => [`/gisDataSources/`, `/mapboxStyleLayers/`]),
  connect(mapStateToProps)
)(AddLayerToEditorForm)
