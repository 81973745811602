import * as SortTypes from '../constants/SortTypes'

export const sortDataGridItems = (items, attributeName, sortDirection) => {
  const comparer = (a, b) => {
    let attrA = a[attributeName]
    if (typeof attrA === 'string' || attrA instanceof String) {
      attrA = attrA.toLowerCase()
    }
    let attrB = b[attributeName]
    if (typeof attrB === 'string' || attrB instanceof String) {
      attrB = attrB.toLowerCase()
    }
    if (sortDirection === SortTypes.ASC) {
      // put null and undefined at the beginning
      if (attrA === undefined || attrA === null) {
        return -1
      }
      if (attrB === undefined || attrB === null) {
        return 1
      }
      // both are defined, so use built-in string and/or number compare
      return attrA > attrB ? 1 : -1
    } else if (sortDirection === SortTypes.DESC) {
      // put null and undefined at the end
      if (attrA === undefined || attrA === null) {
        return 1
      }
      if (attrB === undefined || attrB === null) {
        return -1
      }
      // both are defined, so use built-in string and/or number compare
      return attrA < attrB ? 1 : -1
    }
  }
  const sortedItems = sortDirection === 'NONE' ? items.slice(0) : items.sort(comparer)
  return sortedItems
}
