import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Button, Card, Row, Col } from 'react-bootstrap'
import MultiAgencyQueueForm from '../containers/MultiAgencyQueueForm'
import ViewWrapper from '../components/ViewWrapper'
import { copyProdDataToDev } from '../actions/prodToDevDataCopy'
import { withRouter, RouteComponentProps } from 'react-router-dom'

/**
 * This view is for various tools only accessible by superadmins. It currently
 * just shows the "Copy Prod data to Dev" tool.
 */

function mapStateToProps(state) {
  return {
    // dispatches: state.dispatches.data
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ copyProdDataToDev }, dispatch)
}

type Props = {
  copyProdDataToDev: any
} & RouteComponentProps

class SuperAdminView extends React.Component<Props> {
  render() {
    return (
      <ViewWrapper header="SuperAdmin">
        <Row>
          <Col md={{ span: 4, offset: 4 }} className="text-center">
            <Button variant="primary" onClick={() => this.props.history.push('/superadmin/queues')}>
              Queues
            </Button>
          </Col>
        </Row>
        <MultiAgencyQueueForm />
        <Row style={{ marginTop: '16px' }}>
          <Col>
            <Card>
              <Card.Body>
                Click this button to copy non-user data from Prod to Dev. This currently includes Agencies and
                AgencyConfigs. This "updates" the data, meaning it won't throw out data in Dev that doesn't exist in
                Prod.
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={this.props.copyProdDataToDev}>
                  Copy Prod data to Dev
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </ViewWrapper>
    )
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SuperAdminView)
