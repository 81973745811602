import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import ViewWrapper from '../components/ViewWrapper'
import ContactEmailForm from '../containers/ContactEmailForm'
import { updateContactEmail } from '../actions/agencies'
import { getAgencyName, getAgenciesMap } from '../selectors'

function mapStateToProps(state) {
  return {
    agencyName: getAgencyName(state),
    agencies: getAgenciesMap(state) && getAgenciesMap(state).toJS(),
    user: state.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateContactEmail }, dispatch)
}

type Props = {
  agencyName: string
  agencies: Record<string, any>
  user: Record<string, any>
  updateContactEmail: Function
}

const ContactEmailView = (props: Props) => {
  const ownAgency = props.agencies && props.agencies[props.agencyName]
  return (
    <ViewWrapper header="Update Contact Email">
      {props.user.isAgencyAdmin && (
        <ContactEmailForm
          agencyName={props.agencyName}
          contactEmail={ownAgency && ownAgency.contactEmail}
          updateContactEmail={props.updateContactEmail}
        />
      )}
    </ViewWrapper>
  )
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firebaseConnect((props, firebase) => {
    return props.agencyName ? [`/agencies/${props.agencyName}`] : []
  })
)(ContactEmailView)
