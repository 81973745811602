import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { avlLocationsRef } from '../firebase'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as agenciesActions from '../actions/agencies'
import * as devicesActions from '../actions/devices'
import AdminLeafletMap from '../components/AdminLeafletMap'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import {
  getAgencyNameUserOrUrl,
  getAgencyConfigJs,
  getUser,
  getAvlLocationsArray,
  getNonAgiAvlLocationsArray,
  getAvlGroupsByDevice,
  getServerTimeOffset,
  getReceivesSharedAvlFromArray,
} from '../selectors'

function mapStateToProps(state, ownProps) {
  const agencyName = getAgencyNameUserOrUrl(state, ownProps)
  const user = getUser(state)
  const receivesSharedAvlFromArray = getReceivesSharedAvlFromArray(state, ownProps)
  const agenciesShowingAvl = receivesSharedAvlFromArray.concat(agencyName) // add our own agency
  return {
    avlLocations: user.isAgi ? getAvlLocationsArray(state, ownProps) : getNonAgiAvlLocationsArray(state, ownProps),
    agencyConfig: getAgencyConfigJs(state, ownProps),
    agencyName,
    groupsByDevice: getAvlGroupsByDevice(state, ownProps),
    agenciesShowingAvl,
    serverTimeOffset: getServerTimeOffset(state),
    user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...agenciesActions, ...devicesActions }, dispatch)
}

type Props = {
  agencyConfig: Record<string, any>
  agencyName: string
  avlLocations: any[]
  groupsByDevice: Record<string, any>
  loadAvlLocations: Function
  loadAvlForAgency: Function
  deleteAvlLocation: Function
  clearAgencyDevices: Function
  serverTimeOffset: number
  agenciesShowingAvl: any[]
  user: Record<string, any>
} & RouteComponentProps

const AvlView = (props: Props) => {
  const { avlLocations, agencyConfig, agencyName, groupsByDevice, user, serverTimeOffset, location } = props

  const agencyNameFilter = agencyName

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: 'calc(100% - 50px)',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {user.isAgi && (
        <AgencyFilterSelect
          agencyNameFilter={agencyNameFilter}
          label=""
          location={location}
          style={{ position: 'absolute', zIndex: 999, left: '12px', top: '12px' }}
        />
      )}
      {agencyNameFilter ? (
        <AdminLeafletMap
          avlLocations={avlLocations}
          deleteAvlLocation={location => {
            avlLocationsRef
              .child(agencyName)
              .child(location.key)
              .remove()
          }}
          selectedAgency={agencyNameFilter}
          groupsByDevice={groupsByDevice}
          avlDisplayGroups={agencyConfig && agencyConfig.avlDisplayGroups}
          avlRemoveFromMapAfterTime={agencyConfig && agencyConfig.avlRemoveFromMapAfterTime}
          initialLatLng={agencyConfig && agencyConfig.initialLatLng}
          serverTimeOffset={serverTimeOffset}
        />
      ) : (
        <h2>Choose an agency</h2>
      )}
    </div>
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firebaseConnect((props, firebase) => {
    const { agencyName, agenciesShowingAvl } = props
    let paths = []
    if (agencyName) {
      paths.push(`/agencyConfigs/${agencyName}`)
      paths.push(`/deviceGroups/${agencyName}`)
      paths.push(`/avlLocationsDigest/${agencyName}`)

      if (agenciesShowingAvl) {
        for (const aName of agenciesShowingAvl) {
          paths.push(`/devices/${aName}`)
        }
      }
    }
    return paths
  })
)(AvlView)
