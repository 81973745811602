import React, { Component } from 'react'
import { Row, Col, Button, Alert } from 'react-bootstrap'
import { FaLock } from 'react-icons/fa'
import { LinkContainer } from 'react-router-bootstrap'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { SimpleBootstrapInput } from '../components/BootstrapReduxForm'
import { validatePasswordField } from './validation'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as authActions from '../actions/auth'

const OLD_PASSWORD_FIELD = 'oldPassword'
const NEW_PASSWORD_FIELD = 'newPassword'
const NEW_PASSWORD_AGAIN_FIELD = 'newPasswordAgain'

function validateChangePassword(data) {
  const errors = {}
  // require all fields
  for (const fieldName of [OLD_PASSWORD_FIELD, NEW_PASSWORD_FIELD, NEW_PASSWORD_AGAIN_FIELD]) {
    if (!data[fieldName]) {
      errors[fieldName] = 'Required'
    }
  }

  errors[NEW_PASSWORD_FIELD] = validatePasswordField(NEW_PASSWORD_FIELD, data)

  if (data[NEW_PASSWORD_FIELD] !== data[NEW_PASSWORD_AGAIN_FIELD]) {
    errors[NEW_PASSWORD_AGAIN_FIELD] = 'New password must be the same in both fields'
  }
  return errors
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(authActions, dispatch)
}

type Props = {
  user: Record<string, any>
  changePassword: Function
  // provided by redux-form
  error: string
  handleSubmit: Function
  initialize: Function
  submitting: boolean
} & RouteComponentProps

class ChangePasswordForm extends Component<Props> {
  componentWillMount() {
    this.props.initialize({
      [OLD_PASSWORD_FIELD]: '',
      [NEW_PASSWORD_FIELD]: '',
      [NEW_PASSWORD_AGAIN_FIELD]: '',
    })
  }

  submitChangePasswordForm = data => {
    const email = this.props.user.email
    return this.props.changePassword(email, data[OLD_PASSWORD_FIELD], data[NEW_PASSWORD_FIELD]).then(result => {
      if (result.error) {
        if (['INVALID_EMAIL', 'INVALID_USER'].includes(result.payload.code)) {
          // pass as a general error since we're not actually entering email into form
          throw new SubmissionError({ _error: result.payload.message })
        }
        if (result.payload.code === 'INVALID_PASSWORD') {
          throw new SubmissionError({ [OLD_PASSWORD_FIELD]: result.payload.message })
        }
        // if it doesn't match a code we know, just send it as a general error
        throw new SubmissionError({ _error: result.payload.message })
      }
      this.props.history.push('/change-password/success')
    })
  }

  render() {
    const { user, error, handleSubmit, submitting } = this.props
    return (
      <Col>
        {user && user.hasTemporaryPassword && (
          <p>Please enter your temporary password here, followed by a new password.</p>
        )}
        {user && (
          <div>
            <form onSubmit={handleSubmit(this.submitChangePasswordForm)}>
              <Row>
                <Field
                  name={OLD_PASSWORD_FIELD}
                  component={SimpleBootstrapInput}
                  type="password"
                  placeholder="current password"
                  size="lg"
                  hasFeedback
                  addonBefore={<FaLock />}
                />
              </Row>
              <Row>
                <Field
                  name={NEW_PASSWORD_FIELD}
                  component={SimpleBootstrapInput}
                  type="password"
                  placeholder="new password"
                  size="lg"
                  hasFeedback
                  addonBefore={<FaLock />}
                />
              </Row>
              <Row>
                <Field
                  name={NEW_PASSWORD_AGAIN_FIELD}
                  component={SimpleBootstrapInput}
                  type="password"
                  placeholder="new password again"
                  size="lg"
                  hasFeedback
                  addonBefore={<FaLock />}
                />
              </Row>
            </form>
            <Row>
              <Col md={{ span: 6, offset: 3 }}>{error && <Alert variant="danger">{error}</Alert>}</Col>
            </Row>
            <Row>
              <Col>
                <Button
                  variant="success"
                  size="lg"
                  disabled={submitting}
                  onClick={handleSubmit(this.submitChangePasswordForm)}
                >
                  Change Password
                </Button>
              </Col>
            </Row>
          </div>
        )}
        {!user && (
          <div>
            <p>You are not logged in.</p>

            <div>
              <LinkContainer to="/login">
                <Button>Login</Button>
              </LinkContainer>
            </div>
          </div>
        )}
      </Col>
    )
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'changePassword',
    validate: validateChangePassword,
  })
)(ChangePasswordForm)
