import { ADD_AGENCY, SAVE_AGENCY, LOAD_AGENCY_DISPLAY_NAME, UPDATE_CONTACT_EMAIL } from '../constants/ActionTypes'
import { firestore, agenciesRef, addAgencyPromise } from '../firebase'

export function addAgency(agencyName, displayName) {
  return {
    type: ADD_AGENCY,
    payload: addAgencyPromise(agencyName, displayName),
  }
}

// function saveAgencyPromise(agencyName) {
//   const updateFunction = (currentData) => {
//     if (currentData === null) {
//       return {...agencyConfig, configVersion: 1}
//     } else {
//       return {...currentData,
//               ...agencyConfig,
//               configVersion: currentData.configVersion + 1}
//     }
//   }
//   return new Promise((resolve, reject) => {
//     agencyConfigsRef.child(agencyName).transaction(updateFunction, (error, committed, snapshot) => {
//       if (error) {
//         reject(error)
//       } else if (!committed) {
//         reject('Saving agency config aborted.')
//       } else {
//         resolve(snapshot.val())
//       }
//     })
//   })
// }

export function saveAgency(agency) {
  return {
    type: SAVE_AGENCY,
    payload: Promise.all([
      // Save to both realtime db and firestore, since security rules for both rely on agencies
      firestore.doc(`agencies/${agency.agencyName}`).set(agency.toFirestoreObject()),
      agenciesRef.child(agency.agencyName).set(agency.toFirebaseObject()),
    ]),
  }
}

export function loadAgencyDisplayName(agencyName, payload) {
  return {
    type: LOAD_AGENCY_DISPLAY_NAME,
    agencyName,
    payload,
  }
}

export function updateContactEmail(agencyName, contactEmail) {
  return {
    type: UPDATE_CONTACT_EMAIL,
    payload: agenciesRef.child(`${agencyName}/contactEmail`).set(contactEmail),
  }
}
