import React, { useState } from 'react'
import FormikGroup from './FormikGroup'
import { FieldProps } from 'formik'
import { DateRangePicker } from 'react-dates'

type Props = {
  label: string
} & FieldProps

const FormikDateRange = (props: Props) => {
  const { field, form } = props
  const [pickerFocus, setPickerFocus] = useState(null)
  const setDateRange = input => {
    form.setFieldValue(field.name, input)
  }
  return (
    <FormikGroup {...props}>
      <DateRangePicker
        startDate={field.value.startDate}
        startDateId="startDate"
        endDate={field.value.endDate}
        endDateId="endDate"
        onDatesChange={setDateRange}
        focusedInput={pickerFocus}
        onFocusChange={input => setPickerFocus(input)}
        minimumNights={0}
        numberOfMonths={1}
        showClearDates
        isOutsideRange={moment => moment.isAfter(new Date(), 'day')}
        displayFormat="YYYY-MM-DD"
      />
    </FormikGroup>
  )
}

export default FormikDateRange
