import React from 'react'
import Select from 'react-select'

export const AVL_COLORS = [
  // '3d5afe',
  '3f51b5',
  '009688',
  '4caf50',
  '00e676',
  '76ff03',
  '607d8b',
  '9c27b0',
  '673ab7',
  '333333',
  '795548',
  'e91e63',
  'f44336',
  // 'ff5722',
  'ff8800',
  'ffc107',
  'ffeb3b',
]

const colorOptions = AVL_COLORS.map(color => {
  return {
    color,
    // label: 'HI',
    value: 'ff' + color, // we add ff to the beginning for opacity on iOS and Android
  }
})

const colorSelectorStyles = (inputValue: string) => {
  const selectedColor = '#' + inputValue.slice(2)
  return {
    option: (styles, { data }) => {
      const optionColor = '#' + data.color
      return {
        ...styles,
        color: optionColor,
        backgroundColor: optionColor,
        height: '20px',
        margin: '7px',
      }
    },
    singleValue: styles => {
      return {
        ...styles,
        backgroundColor: selectedColor,
        height: '20px',
        width: '100%',
      }
    },
  }
}

type Props = {
  label: string
  value: string
  onChange: Function
  onBlur: Function
}

const ColorSelector = ({ label, value, onChange, onBlur, ...props }: Props) => {
  const inputValue = () => {
    if (value) {
      return colorOptions.filter(opt => opt.value === value.toLowerCase())
    }
  }
  return (
    <div className="section">
      {label && <h4 className="section-heading">{label}</h4>}
      <Select
        clearable={false}
        name="avlColor" // name of hidden <input> tag
        placeholder="Select your AVL color"
        options={colorOptions}
        styles={colorSelectorStyles(value)}
        onBlur={() => onBlur(value)} // just pass the current value (updated on change) on blur
        onChange={option => {
          onChange(option.value)
        }}
        searchable={false}
        value={inputValue()}
        {...props}
      />
    </div>
  )
}

export default ColorSelector
