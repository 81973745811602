import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, FormSection } from 'redux-form'
import { InjectedFormProps } from 'redux-form'
import { Alert, ButtonToolbar, Col, Card, Row } from 'react-bootstrap'
import { FormFieldInput, LoadingButton, FormFieldCheckbox } from '../components/BootstrapReduxForm'
import { firestore } from '../firebase'

const FORM_NAME = 'valueMapData'

type Props = {
  task: {
    _status?: string
    agencyName: string
    fileName: string
    styleObject: string
    fieldMap: {}
    fieldValuesToMap: { [key: string]: any }
    gisDataSourceName: string
    styleDisplayWarnings: string
    inputFieldTypes: {}
    outputSchema: {
      properties: {}
      required?: string[]
    }
  }
  taskFirebasePath: string
} & InjectedFormProps
// TODO: maintain our own sprite sheets, so can have ability to display icon images during value mapping.
function zeroValueConvertCheck(inputFieldTypes, fieldMap, fieldValuesToMap) {
  let fieldsToCheck: { [key: string]: any } = {}
  let willBeConvertedAnyways
  if (fieldValuesToMap) {
    willBeConvertedAnyways = Object.keys(fieldValuesToMap)
  }
  if (fieldMap) {
    Object.entries(fieldMap).forEach(([field, attribute]) => {
      Object.entries(inputFieldTypes).forEach(([key, value]) => {
        if (
          attribute === key &&
          (value !== 'string' && value !== 'date') &&
          (!willBeConvertedAnyways || !willBeConvertedAnyways.includes(field))
        ) {
          fieldsToCheck[field] = attribute
        }
      })
    })
  }
  return fieldsToCheck
}

function validateValueMapDataForm(data, props: Props) {
  const errors = {}
  const { fieldValuesToMap, outputSchema } = props.task
  if (!fieldValuesToMap) {
    return errors
  }
  for (const [outAttributeName, inFieldVals] of Object.entries(fieldValuesToMap)) {
    errors[outAttributeName] = {}
    for (const inVal of inFieldVals) {
      if (
        (!data[outAttributeName] || !data[outAttributeName][`${VALUE_PREFIX}${inVal}`]) &&
        outputSchema &&
        outputSchema.properties &&
        outputSchema.properties[outAttributeName].type !== 'boolean'
      ) {
        errors[outAttributeName][`${VALUE_PREFIX}${inVal}`] = 'Please select a value to map'
      }
    }
  }
  return errors
}

// Add prefix to form values since redux-form doesn't handle integer field names well; converts form data to an array
const VALUE_PREFIX = 'value_'
const decimalReplacement = '<<DECIMAL>>'

const encodeDecimalsInString = string => {
  return String(string).replace(/\./g, decimalReplacement)
}
const decodeDecimalsInString = string => {
  return String(string).replace(new RegExp(decimalReplacement, 'g'), '.')
}

class ValueMapDataForm extends React.Component<Props> {
  constructor(props) {
    super(props)
    let initialValues = {}
    if (props.task.fieldMap) {
      Object.keys(props.task.fieldMap).forEach(value => {
        if (props.task.outputSchema.properties[value] && props.task.outputSchema.properties[value].type === 'boolean') {
          let defaultVal
          if (props.task.outputSchema.properties[value].default) {
            defaultVal = `<<${props.task.outputSchema.properties[value].default.toString().toUpperCase()}>>`
          } else {
            defaultVal = '<<TRUE>>'
          }
          for (const entry of props.task.fieldValuesToMap[value]) {
            if (!initialValues[value]) {
              initialValues[value] = {}
            }
            initialValues[value][`${VALUE_PREFIX}${encodeDecimalsInString(entry)}`] = defaultVal
          }
        }
      })
    }
    if (Object.keys(initialValues).length) {
      props.initialize(initialValues)
    }
  }
  submitValueMapDataForm = async (data, props) => {
    let needingZeroConversion = data.changeZeroToNull || null
    let newData
    const { fieldValuesToMap, outputSchema } = this.props.task
    if (data) {
      newData = JSON.parse(JSON.stringify(data))
      Object.entries(newData).forEach(([fields, map]) => {
        if (fields !== 'changeZeroToNull') {
          Object.entries(map).forEach(([key, value]) => {
            if (decodeDecimalsInString(key) !== key) {
              newData[fields][decodeDecimalsInString(key)] = value
              delete newData[fields][key]
            }
          })
        }
      })
      // ensure that all enum fields get mapped to a value
      if (fieldValuesToMap) {
        Object.entries(fieldValuesToMap).forEach(([field, value]) => {
          value.forEach(oldValue => {
            if (newData[field] && !newData[field][`value_${oldValue}`] && outputSchema.properties[field].enum) {
              newData[field][oldValue] = '<<NULL>>'
            } else if (!newData[field] && outputSchema.properties[field].enum) {
              newData[field] = { [oldValue]: '<<NULL>>' }
            }
          })
        })
      }
    }
    const valueMap: any = Object.entries(newData).reduce((acc, [fieldName, valueMap]) => {
      acc[fieldName] = Object.entries(valueMap).reduce((acc2, [key, value]) => {
        const actualKey = key.replace(VALUE_PREFIX, '')
        if (value === '<<TRUE>>') {
          value = true
        }
        if (value === '<<FALSE>>') {
          value = false
        }
        acc2[actualKey] = value
        return acc2
      }, {})
      return acc
    }, {})
    if (needingZeroConversion) {
      for (const value of Object.keys(needingZeroConversion)) {
        if (!(value in newData)) {
          valueMap[value] = { 0: '<<NULL>>' }
        }
      }
    }
    delete valueMap.changeZeroToNull
    // Return state back to PROCESSING_FIELD_MAP, but now with a valueMap
    firestore.doc(this.props.taskFirebasePath).update({ _status: 'PROCESSING_FIELD_MAP', valueMap })
  }

  render() {
    const { error, handleSubmit, submitting } = this.props
    const { fieldValuesToMap, outputSchema, fieldMap, inputFieldTypes, styleDisplayWarnings } = this.props.task
    const requiredFields = outputSchema.required || []
    const integerCheck = zeroValueConvertCheck(inputFieldTypes, fieldMap, fieldValuesToMap)
    const styleWarnings = JSON.parse(styleDisplayWarnings)
    return (
      <Col>
        <Row>
          <FormSection name="changeZeroToNull">
            {integerCheck != null && typeof integerCheck === 'object' && Object.entries(integerCheck).length > 0 && (
              <div>
                <Row> Below are integer attribute fields. Clicking the checkbox converts all 0 values to null. </Row>
                {Object.entries(integerCheck).map(([key, value]) => (
                  <FormSection name={key} key={value}>
                    <Row>
                      <Field
                        name="zeroConvert"
                        component={FormFieldCheckbox}
                        label={`${value}  (${key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')})`}
                      />
                    </Row>
                  </FormSection>
                ))}
              </div>
            )}
          </FormSection>
          {fieldValuesToMap != null &&
            typeof fieldValuesToMap === 'object' &&
            Object.entries(fieldValuesToMap).map(([outFieldName, inFieldVals]) => (
              <Card key={outFieldName}>
                <Card.Header>
                  <Card.Title as="h3">{outFieldName}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <FormSection name={outFieldName}>
                    <div>
                      {styleWarnings &&
                        styleWarnings.hasOwnProperty(outFieldName) &&
                        styleWarnings[outFieldName].valueMapMessage}
                    </div>
                    {inFieldVals.map(val => (
                      <div
                        key={`${outFieldName}_${Math.random()
                          .toString(36)
                          .substring(5)}`}
                      >
                        <Field
                          name={`${VALUE_PREFIX}${encodeDecimalsInString(val)}`}
                          component={FormFieldInput}
                          type="select"
                          label={JSON.stringify(val)}
                          help={`Choose what "${val}" should be converted to. Leave empty for existing data to be removed during upload.`}
                        >
                          {' '}
                          {outputSchema.properties[outFieldName].type !== 'boolean' ? (
                            <option key="default" value="">
                              ----------
                            </option>
                          ) : null}
                          {!requiredFields.includes(outFieldName) ? (
                            <option key="null" value="<<NULL>>">
                              (No Value)
                            </option>
                          ) : null}
                          {outputSchema.properties[outFieldName].enum
                            ? outputSchema.properties[outFieldName].enum.map(enumVal => (
                                <option key={enumVal} value={enumVal}>
                                  {enumVal}
                                </option>
                              ))
                            : null}
                          {outputSchema.properties[outFieldName].type === 'boolean'
                            ? ['true', 'false'].map(boolValue => (
                                <option key={boolValue} value={`<<${boolValue.toUpperCase()}>>`}>
                                  {boolValue}
                                </option>
                              ))
                            : null}
                        </Field>
                      </div>
                    ))}
                  </FormSection>
                </Card.Body>
              </Card>
            ))}
        </Row>
        {error && (
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={{ offset: 7 }}>
            <ButtonToolbar>
              <LoadingButton
                variant="primary"
                onClick={handleSubmit(this.submitValueMapDataForm)}
                label="Submit"
                loading={submitting}
                loadingLabel="Submitting"
              />
            </ButtonToolbar>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default compose(
  reduxForm({
    form: FORM_NAME,
    validate: validateValueMapDataForm,
  }),
  connect()
)(ValueMapDataForm)
