import { createReducer } from '../utils'
import * as types from '../constants/ActionTypes'

const initialState = 0

const handlers = {
  [types.SET_SERVER_TIME_OFFSET]: (state, action) => (action.serverTimeOffset ? action.serverTimeOffset : 0),
}

export default createReducer(initialState, handlers)
