import React from 'react'
import { Route, Switch } from 'react-router-dom'
import requiresAuth from './requiresAuth'
import * as roles from '../constants/UserRoles'
import HomeView from '../views/HomeView'
import LoginView from '../views/LoginView'
import ChangePasswordView from '../views/ChangePasswordView'
import ChangePasswordSuccessView from '../views/ChangePasswordSuccessView'
import ResetPasswordView from '../views/ResetPasswordView'
import ResetPasswordSuccessView from '../views/ResetPasswordSuccessView'
import ContactEmailView from '../views/ContactEmailView'
import ContactEmailSuccessView from '../views/ContactEmailSuccessView'
import AvlView from '../views/AvlView'
// import CommandCenterView from '../views/CommandCenterView'
import AgenciesView from '../views/AgenciesView'
import AgencyAdminConfigView from '../views/AgencyAdminConfigView'
import AgencyView from '../views/AgencyView'
import AgencyConfigView from '../views/AgencyConfigView'
import ExportDataView from '../views/ExportDataView'
import LoadDataView from '../views/LoadDataView'
import EditorView from '../views/EditorView'
import CadCentersView from '../views/CadCentersView'
import UsersView from '../views/UsersView'
import UserView from '../views/UserView'
import DevicesView from '../views/DevicesView'
import DeviceView from '../views/DeviceView'
import DispatchesView from '../views/DispatchesView'
import DispatchView from '../views/DispatchView'
import DispatchPayloadView from '../views/DispatchPayloadView'
import ExportDispatchesView from '../views/ExportDispatchesView'
import GisLayersView from '../views/GisLayersView'
import GisLayerView from '../views/GisLayerView'
import ShareGisDataView from '../views/ShareGisDataView'
import SuperAdminView from '../views/SuperAdminView'
import DispatchAgencyView from '../views/DispatchAgencyView'
import QueuesView from '../views/QueuesView'
import InspectionsView from '../views/InspectionsView'
import InspectAdminView from '../views/InspectAdminView'
import FeatureSetsView from '../views/FeatureSetsView'

export default (
  <Switch>
    <Route path="/change-password/success" component={ChangePasswordSuccessView} />
    <Route path="/change-password" component={requiresAuth(ChangePasswordView)} />
    <Route path="/reset-password/success" component={ResetPasswordSuccessView} />
    <Route path="/reset-password" component={ResetPasswordView} />
    <Route path="/contact-email/success" component={requiresAuth(ContactEmailSuccessView)} />
    <Route path="/contact-email" component={requiresAuth(ContactEmailView)} />
    <Route path="/avl" component={requiresAuth(AvlView, { allowedRoles: [roles.ROLE_AGI] })} />
    {/* <Route path="/command-center-map" component={requiresAuth(CommandCenterView)} /> */}
    <Route
      path="/agencies/:agencyName/config"
      component={requiresAuth(AgencyConfigView, { allowedRoles: [roles.ROLE_AGI] })}
    />
    <Route path="/agencies/:agencyName" component={requiresAuth(AgencyView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/agencies" component={requiresAuth(AgenciesView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/users/:userId" component={requiresAuth(UserView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/users" component={requiresAuth(UsersView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route
      path="/devices/:agencyName/:deviceId"
      component={requiresAuth(DeviceView, { allowedRoles: [roles.ROLE_AGI, roles.ROLE_AGENCY_ADMIN] })}
    />
    <Route
      path="/devices"
      component={requiresAuth(DevicesView, { allowedRoles: [roles.ROLE_AGI, roles.ROLE_AGENCY_ADMIN] })}
    />
    <Route path="/cadcenters" component={requiresAuth(CadCentersView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route
      path="/config"
      component={requiresAuth(AgencyAdminConfigView, { allowedRoles: [roles.ROLE_AGI, roles.ROLE_AGENCY_ADMIN] })}
    />
    <Route path="/editor" component={requiresAuth(EditorView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route
      path="/dispatches/:agencyName/send"
      component={requiresAuth(DispatchView, { allowedRoles: [roles.ROLE_AGI, roles.ROLE_AGENCY_ADMIN] })}
    />
    <Route
      path="/dispatches/export"
      component={requiresAuth(ExportDispatchesView, { allowedRoles: [roles.ROLE_AGI, roles.ROLE_AGENCY_ADMIN] })}
    />
    <Route
      path="/dispatches"
      component={requiresAuth(DispatchesView, { allowedRoles: [roles.ROLE_AGI, roles.ROLE_AGENCY_ADMIN] })}
    />
    <Route path="/featuresets" component={requiresAuth(FeatureSetsView)} />
    <Route
      path="/inspections"
      component={requiresAuth(InspectionsView, {
        allowedRoles: [roles.ROLE_AGI, roles.ROLE_AGENCY_ADMIN, roles.ROLE_INSPECTOR],
      })}
    />
    <Route path="/inspectadmin" component={requiresAuth(InspectAdminView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/gis/sharing" component={requiresAuth(ShareGisDataView)} />
    <Route path="/gis/sharing/editable" component={requiresAuth(ShareGisDataView)} />
    <Route path="/gis/:agencyName/:dataSourceName" component={requiresAuth(GisLayerView)} />
    <Route
      path="/gis/export"
      component={requiresAuth(ExportDataView, {
        allowedRoles: [roles.ROLE_AGI],
      })}
    />
    <Route path="/gis/load" component={requiresAuth(LoadDataView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/gis/sharing" component={requiresAuth(ShareGisDataView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/gis" component={requiresAuth(GisLayersView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/superadmin/queues" component={requiresAuth(QueuesView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/superadmin" component={requiresAuth(SuperAdminView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/dispatchagencies" component={requiresAuth(DispatchAgencyView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/dispatchpayload" component={requiresAuth(DispatchPayloadView, { allowedRoles: [roles.ROLE_AGI] })} />
    <Route path="/login" component={LoginView} />
    <Route path="/" component={requiresAuth(HomeView)} />
  </Switch>
)
