import React from 'react'
import FormField from './FormField'
import { FormFieldProps } from './FormField'

type Props = {
  disabled: boolean
}

const FormFieldCheckbox = (props: FormFieldProps & Props) => (
  <FormField {...props} labelColSize={props.labelColSize || 7}>
    <input type="checkbox" checked={!!props.input.value} disabled={props.disabled} {...props.input} />
  </FormField>
)

export default FormFieldCheckbox
