import React from 'react'
import ColorSelector from '../../components/ColorSelector'
import FormField from './FormField'
import { FormFieldProps } from './FormField'

type Props = {}

const FormFieldColorSelector = (props: FormFieldProps & Props) => (
  <FormField label={props.label} help={props.help} {...props}>
    <ColorSelector {...props.input} label={props.label} />
  </FormField>
)

export default FormFieldColorSelector
