import { Row, Col, ListGroup, ListGroupItem, Button } from 'react-bootstrap'
import React, { useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Select from 'react-select'
import { Option } from '../types'
import './SortableSelect.css'

const SortableItem = SortableElement(({ value, index, removeValAtIndex, sortOnly }) => (
  <ListGroupItem className="d-flex justify-content-between">
    {value}
    {sortOnly ? null : (
      <Button size="sm" variant="danger" onClick={() => removeValAtIndex(index)}>
        &times;
      </Button>
    )}
  </ListGroupItem>
))

const SortableList = SortableContainer(({ items, removeValAtIndex, sortOnly }) => {
  return (
    <ListGroup className="sortable-select-list-group">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          removeValAtIndex={removeValAtIndex}
          sortOnly={sortOnly}
        />
      ))}
    </ListGroup>
  )
})

type Props = {
  label: string
  value: string[]
  onChange: Function
  options: Option[]
  sortOnly?: boolean
}

const SortableSelect = (props: Props) => {
  const [newValString, setNewValString] = useState('')
  const { onChange, value, options, sortOnly } = props
  const availableOptions = options.filter(option => !props.value.includes(option.value))
  const pushNewValString = () => {
    onChange(value.concat(newValString))
    setNewValString('')
  }

  const removeValAtIndex = (index: number) => {
    const newArray = value.concat()
    newArray.splice(index, 1)
    onChange(newArray)
  }

  const handleSelect = (selection: Option) => {
    if (selection) {
      setNewValString(selection.value)
    } else {
      setNewValString('')
    }
  }

  const onSortEnd = ({ newIndex, oldIndex }) => {
    const newArray = value.concat()
    newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0])
    onChange(newArray)
  }

  const getVal = (valString: string) => {
    if (valString === '') return null
    return availableOptions.find(opt => opt.value === valString)
  }

  return (
    <Col sm={12}>
      <Row>
        {value.length ? (
          <Col>
            <SortableList sortOnly={sortOnly} items={value} onSortEnd={onSortEnd} removeValAtIndex={removeValAtIndex} />
          </Col>
        ) : null}
      </Row>
      {availableOptions.length ? (
        <div className="sortable-select__add-option">
          <div>
            <Select options={availableOptions} onChange={handleSelect} value={getVal(newValString)} />
          </div>
          <Button variant="primary" disabled={!newValString} onClick={pushNewValString}>
            Add Selection
          </Button>
        </div>
      ) : null}
    </Col>
  )
}

SortableSelect.defaultProps = {
  value: [],
}

export default SortableSelect
