import { RouteComponentProps } from 'react-router-dom'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DispatchPayloadDataGrid from '../containers/DispatchPayloadDataGrid'
import ViewWrapper from '../components/ViewWrapper'
import DataGridWrapper from '../components/DataGridWrapper'

type Props = {
  dispatches: Record<string, any>
  user: Record<string, any>
  loadDispatchesForAgency: Function
  loadDispatches: Function
} & RouteComponentProps

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const DispatchPayloadView = (props: Props) => {
  return (
    <DataGridWrapper header="Raw Dispatches">
      <DispatchPayloadDataGrid />
    </DataGridWrapper>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DispatchPayloadView)
