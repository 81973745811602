import React, { Component } from 'react'
import Immutable from 'immutable'
import { Table, Column, Cell } from 'fixed-data-table-2'
import SortHeaderCell from './SortHeaderCell'
import ButtonCell from './ButtonCell'
import TextDataCell from './TextDataCell'
import dimensions from 'react-dimensions'
import './SortableDataGrid.css'

const getCell = (column, dataList) => {
  const { button, cellComponent, ...columnProps } = column
  // If we pass in a specific cell component, use that
  if (column.cellComponent) {
    return (
      <column.cellComponent data={dataList} rowIndex={column.rowIndex} columnKey={column.columnKey} {...columnProps} />
    )
  }
  if (button) {
    return <ButtonCell data={dataList} buttonValue={button.value} buttonOnClick={button.onClick} {...columnProps} />
  } else {
    return <TextDataCell data={dataList} {...columnProps} />
  }
}

type Props = {
  dataList: Immutable.List<any>
  columns: any[]
  sortAttribute: string
  sortDirection: string
  onSort: any
  // from dimensions HOC
  containerWidth: number
  containerHeight: number
}

class SortableDataGrid extends Component<Props> {
  render() {
    const { dataList, columns, sortAttribute, sortDirection, onSort } = this.props
    const { props } = this

    // if the combined width of our columns is less than the parent's width,
    // use that so it's centered on page without adding blank space to table
    const columnsWidth = columns.reduce((prev, current) => prev + current.width, 0)
    const width = Math.min(props.containerWidth, columnsWidth)

    return (
      <Table
        rowHeight={40}
        rowsCount={dataList.count()}
        headerHeight={50}
        width={width || 1170}
        maxHeight={props.containerHeight || 670}
      >
        {columns.map(({ fixed, sortable, ...column }) => (
          <Column
            key={column.key}
            columnKey={column.key}
            fixed={fixed}
            header={
              sortable ? (
                <SortHeaderCell
                  columnKey={column.key}
                  onSortChange={onSort}
                  sortDir={sortAttribute === column.key && sortDirection}
                >
                  {column.name || column.key}
                </SortHeaderCell>
              ) : (
                <Cell>{column.name || column.key}</Cell>
              )
            }
            cell={getCell(column, dataList)}
            width={column.width || 100}
          />
        ))}
      </Table>
    )
  }
}

export default dimensions({
  getWidth: element => element.offsetWidth,
  getHeight: element => element.offsetHeight,
})(SortableDataGrid)
