import React from 'react'
import firebase from '../firebase'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase'
import { reduxForm, formValues, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import _ from 'lodash'
import uuid from 'uuid'
import { firestore } from '../firebase'
import { Alert, Col, Row, ButtonToolbar } from 'react-bootstrap'
import { FormFieldInput, FormFieldFileInput, LoadingButton, StaticField } from '../components/BootstrapReduxForm'
import AgencySelectField from './reduxFormFields/AgencySelectField'
import { getUser, getInspectionConfigDataSources } from '../selectors'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'

const storageRef = firebase.storage().ref()

const AGENCY_NAME_FIELD = 'agencyName'
const DATA_SOURCE_NAME_FIELD = 'dataSourceName'
const REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD = 'replaceOrAddToExistingDataset'
const REPLACE_OR_KEEP_EXISTING_SCHEMA = 'replaceOrKeepExistingSchema'
const DATA_SOURCE_FILE_FIELD = 'dataSourceFile'
const CUSTOM_DATA_DISPLAY_NAME = 'customDataDisplayName'
const CUSTOM_DATA_VALUE = 'addCustomData'
const CHOOSE_DATA_STYLE = 'styleName'

function validateLoadDataForm(data, props) {
  const errors = {}
  if (!data[AGENCY_NAME_FIELD]) {
    errors[AGENCY_NAME_FIELD] = 'Please choose an agencyName'
  }
  if (!data[DATA_SOURCE_NAME_FIELD]) {
    errors[DATA_SOURCE_NAME_FIELD] = 'Please choose a data type'
  }
  if (!data[CUSTOM_DATA_DISPLAY_NAME]) {
    errors[CUSTOM_DATA_DISPLAY_NAME] = 'Please type in the name of the GIS data type'
  }
  if (
    data[REPLACE_OR_KEEP_EXISTING_SCHEMA] &&
    data[REPLACE_OR_KEEP_EXISTING_SCHEMA] === 'replaceSchema' &&
    data[REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD] &&
    data[REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD] === 'addToExistingDataset'
  ) {
    // for now: only option is to keep the exising schema (default)
    errors[REPLACE_OR_KEEP_EXISTING_SCHEMA] =
      'This functionality is not available. Please contact support if you would like to change the existing schema.'
    // TODO: once there is an admin support page for updating schema in three places/gis data,
    // Utilize that functionality to replace this error with a warning
    // "Warning:  You have selected to replace the existing schema. The new schema will be generated based off the new data being imported. Features already existing for
    // ${gisDataType} will have their attributes changed to respect the new schema (if necessary)"
  }
  if (
    props.registeredFields &&
    props.registeredFields[REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD] &&
    !data[REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD]
  ) {
    errors[REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD] = 'Please choose whether to replace or add to existing dataset'
  }
  return errors
}

function mapStateToProps(state, ownProps) {
  const { firebase } = state
  let configs
  let sourceNames
  let dataType
  let currentStyle
  let possibleStyles
  let currentStyleName
  if (ownProps.chosenAgencyName) {
    configs = _.get(firebase, `data.gisDataSourceConfigurations.${ownProps.chosenAgencyName}`)
    if (configs) {
      sourceNames = Object.keys(configs)
    }
  }
  if (ownProps.chosenDataSourceName) {
    dataType = _.get(firebase, `data.gisFeatureSchemas.${ownProps.chosenDataSourceName}.geometryType`)
    possibleStyles = _.get(firebase, `data.mapboxStyleLayers.${ownProps.chosenDataSourceName}`)
  }
  if (
    ownProps.chosenDataSourceName &&
    ownProps.chosenAgencyName &&
    sourceNames &&
    ownProps.chosenDataSourceName !== 'addCustomData' &&
    sourceNames.includes(ownProps.chosenDataSourceName)
  ) {
    currentStyle = {}
    const currentStyleObject = _.get(
      firebase,
      `data.gisDataSources.${ownProps.chosenAgencyName}.${ownProps.chosenDataSourceName}.mapboxStyleLayers`
    )
    const featureStyleCheck = _.get(
      firebase,
      `data.gisDataSourceConfigurations.${ownProps.chosenAgencyName}.${ownProps.chosenDataSourceName}.mapboxStyleLayers`
    )
    if (featureStyleCheck === undefined) {
      currentStyleName = 'default'
    } else if (typeof featureStyleCheck === 'object') {
      currentStyleName = `customStyle`
      if (possibleStyles === undefined) {
        possibleStyles = {}
      }
      possibleStyles[currentStyleName] = currentStyleObject
    } else if (typeof featureStyleCheck === 'string') {
      currentStyleName = featureStyleCheck
    }
    currentStyle[currentStyleName] = currentStyleObject
  }
  return {
    agencyNames: firebase.data.agencies && Object.keys(firebase.data.agencies),
    schemaNames: firebase.data.gisFeatureSchemas && Object.keys(firebase.data.gisFeatureSchemas),
    dataSourceNamesForChosenAgency: sourceNames,
    dataSourceType: dataType,
    schemas: firebase.data.gisFeatureSchemas,
    dataSourcesforChosenAgency: configs || {},
    user: getUser(state),
    possibleStyles,
    currentStyle,
    inspectionConfigDataSources: getInspectionConfigDataSources(state),
  }
}

function createDataSourceNameFromDisplayName(displayName) {
  if (displayName) {
    return displayName.toLowerCase().replace(/\s+/g, '')
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

type Props = {
  user: Record<string, any>
  agencyNames: any[]
  schemaNames: any[]
  chosenAgencyName: string
  chosenDataSourceName: string
  dataSourceNamesForChosenAgency: any[]
  schemas: Record<string, any>
  dataSourcesforChosenAgency: Record<string, any>
  currentStyle: Record<string, any>
  possibleStyles: Record<string, any>
  customDataSourceDisplayName: string
  dataSourceType: string
  inspectionConfigDataSources: any[]
} & InjectedFormProps &
  RouteComponentProps

class LoadDataForm extends React.Component<Props> {
  componentDidMount() {
    const agencyNameFilter = queryString.parse(this.props.location.search).agencyName
    if (agencyNameFilter) {
      this.props.change(AGENCY_NAME_FIELD, agencyNameFilter)
    }
  }

  submitLoadDataForm = async data => {
    let styleObject
    const newDataSourceName = createDataSourceNameFromDisplayName(data[CUSTOM_DATA_DISPLAY_NAME])
    if (newDataSourceName) {
      if (
        this.props.dataSourceNamesForChosenAgency &&
        this.props.dataSourceNamesForChosenAgency.includes(newDataSourceName)
      ) {
        throw new SubmissionError({
          [CUSTOM_DATA_DISPLAY_NAME]: 'This data type already exists, please select from the drop down menu',
        })
      }
    }
    const agencyName = data[AGENCY_NAME_FIELD]
    let gisDataSourceName = data[DATA_SOURCE_NAME_FIELD]
    const { schemas, dataSourcesforChosenAgency, chosenDataSourceName, currentStyle, possibleStyles } = this.props
    if (gisDataSourceName === CUSTOM_DATA_VALUE) {
      gisDataSourceName = createDataSourceNameFromDisplayName(data[CUSTOM_DATA_DISPLAY_NAME])
      styleObject = 'customData'
    }
    if (data[REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD]) {
      if (!dataSourcesforChosenAgency[chosenDataSourceName].schema && !schemas[chosenDataSourceName]) {
        throw new SubmissionError({
          [DATA_SOURCE_NAME_FIELD]:
            'This dataset was directly loaded into Mapbox and cannot be replaced using the Load Data tool',
        })
      }
    }
    if (currentStyle) {
      styleObject = currentStyle
    } else if (possibleStyles && data && data.styleName) {
      const stylePropertyName = data.styleName
      styleObject = {}
      styleObject[stylePropertyName] = possibleStyles[stylePropertyName]
    } else if (possibleStyles && gisDataSourceName !== CUSTOM_DATA_VALUE) {
      styleObject = {}
      styleObject.default = possibleStyles.default
    }

    if (!styleObject) {
      throw new SubmissionError({
        _error: `No style object found.`,
      })
    }

    const isCustomData = data[DATA_SOURCE_NAME_FIELD] === CUSTOM_DATA_VALUE
    const replaceOrAddToExistingDataset = data[REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD]
    const replaceOrKeepExistingSchema = data[REPLACE_OR_KEEP_EXISTING_SCHEMA]
    const file = data[DATA_SOURCE_FILE_FIELD] && data[DATA_SOURCE_FILE_FIELD].length && data[DATA_SOURCE_FILE_FIELD][0]
    if (file) {
      const taskId = uuid.v4()
      // Upload the file to the storage bucket first, so that when the task is
      // written to the db it can assume that the file is ready for processing
      await storageRef.child(`loadGisDataToPostgres/${taskId}/${file.name}`).put(file)
      // Write task to queue {functionName}l
      let taskObj: {
        agencyName
        gisDataSourceName
        fileName: string
        userGuid: string
        styleObject: string
        isCustomData
        _status: 'GET_FIELD_INFO'
        _startTime: Date
        displayName?: string
      } = {
        agencyName,
        gisDataSourceName,
        fileName: file.name,
        userGuid: this.props.user.uid,
        styleObject: JSON.stringify(styleObject),
        isCustomData,
        _status: 'GET_FIELD_INFO',
        _startTime: new Date(),
      }
      if (replaceOrAddToExistingDataset) {
        taskObj['replaceOrAddToExistingDataset'] = replaceOrAddToExistingDataset
        if (!replaceOrKeepExistingSchema || replaceOrKeepExistingSchema === 'keepSchema') {
          taskObj['keepOrReplaceSchema'] = 'keep'
        } else {
          taskObj['keepOrReplaceSchema'] = 'replace'
        }
      }
      if (data[CUSTOM_DATA_DISPLAY_NAME]) {
        taskObj.displayName = data[CUSTOM_DATA_DISPLAY_NAME]
      }
      await firestore.doc(`functions/loadGisDataToPostgres/tasks/${taskId}`).set(taskObj)
      this.props.history.push(`/gis/load?taskId=${taskId}`)
    }
  }

  render() {
    const {
      schemaNames,
      chosenAgencyName,
      chosenDataSourceName,
      customDataSourceDisplayName,
      dataSourceNamesForChosenAgency,
      dataSourceType,
      possibleStyles,
      currentStyle,
      error,
      handleSubmit,
      submitting,
      inspectionConfigDataSources,
    } = this.props
    const agencyNameFilter = queryString.parse(this.props.location.search).agencyName
    let allPossibleDataSourceNamesForAgency = []
    if (schemaNames) {
      allPossibleDataSourceNamesForAgency = _.uniq(schemaNames.concat(dataSourceNamesForChosenAgency))
    }

    return (
      <form>
        <Col>
          <Row>
            {agencyNameFilter ? (
              <StaticField name={AGENCY_NAME_FIELD} label="Agency Name" value={agencyNameFilter}>
                {' '}
              </StaticField>
            ) : (
              <AgencySelectField name={AGENCY_NAME_FIELD} />
            )}
            <Field
              name={DATA_SOURCE_NAME_FIELD}
              component={FormFieldInput}
              type="select"
              label="GIS data type"
              help="This determines the default schemas and styles available to this data"
            >
              <option key="default" value="">
                ------------
              </option>
              {allPossibleDataSourceNamesForAgency &&
                allPossibleDataSourceNamesForAgency.map(schemaName => (
                  <option
                    key={`${schemaName}s`}
                    value={schemaName}
                    disabled={inspectionConfigDataSources.includes(schemaName)}
                  >
                    {schemaName}
                  </option>
                ))}
              <option key="customData" value={CUSTOM_DATA_VALUE}>
                Custom data
              </option>
            </Field>
            {chosenDataSourceName === CUSTOM_DATA_VALUE && (
              <div>
                <Field
                  name={CUSTOM_DATA_DISPLAY_NAME}
                  component={FormFieldInput}
                  type="text"
                  label="Custom data display name"
                  help="This name is what will be visible in IncidentView"
                />
                {customDataSourceDisplayName && (
                  <div> Database name: {createDataSourceNameFromDisplayName(customDataSourceDisplayName)}</div>
                )}
              </div>
            )}
            {chosenDataSourceName && chosenDataSourceName !== 'addCustomData' ? (
              <div>
                {chosenDataSourceName} is a {dataSourceType} gis data type
              </div>
            ) : null}
            {dataSourceNamesForChosenAgency && dataSourceNamesForChosenAgency.includes(chosenDataSourceName) ? (
              <React.Fragment>
                <Field
                  name={REPLACE_OR_ADD_TO_EXISTING_DATASET_FIELD}
                  component={FormFieldInput}
                  type="select"
                  label="Replace or add to existing dataset"
                  help={
                    `${chosenDataSourceName} already exists in ${chosenAgencyName}. ` +
                    'Choose to replace the entire existing dataset, or to add to the existing dataset.'
                  }
                >
                  <option key="default" value="">
                    ------------
                  </option>
                  <option key="replaceDataset" value="replaceExistingDataset">
                    Replace existing {chosenDataSourceName} data
                  </option>
                  <option key="addToDataset" value="addToExistingDataset">
                    Add to existing {chosenDataSourceName} data
                  </option>
                </Field>

                <Field
                  name={REPLACE_OR_KEEP_EXISTING_SCHEMA}
                  component={FormFieldInput}
                  type="select"
                  label="Keep or replace existing schema"
                  help={`The schema determines what fields are already set up for ${chosenDataSourceName} data in the database`}
                >
                  <option key="default" value="keepSchema">
                    Keep existing schema
                  </option>
                  <option key="replace" value="replaceSchema">
                    Define new schema based off uploaded data{' '}
                  </option>
                </Field>
              </React.Fragment>
            ) : null}{' '}
            {chosenDataSourceName &&
            possibleStyles &&
            chosenDataSourceName !== 'addCustomData' &&
            dataSourceNamesForChosenAgency &&
            !dataSourceNamesForChosenAgency.includes(chosenDataSourceName) ? (
              <Field
                name={CHOOSE_DATA_STYLE}
                component={FormFieldInput}
                type="select"
                label="Style Name"
                help="These are the available styles for this dataset"
              >
                {Object.keys(possibleStyles).map(styleName => (
                  <option key={styleName} value={styleName}>
                    {styleName}
                  </option>
                ))}
              </Field>
            ) : null}
            {currentStyle ? (
              <div>
                The {chosenDataSourceName} style currently set for {chosenAgencyName} is '{Object.keys(currentStyle)[0]}
                '
              </div>
            ) : null}
            <Field
              name={DATA_SOURCE_FILE_FIELD}
              component={FormFieldFileInput}
              label="Upload a shapefile"
              multiple={false}
            />
            {error && (
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <Alert variant="danger">{error}</Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={{ offset: 7 }}>
                <ButtonToolbar>
                  <LoadingButton
                    variant="primary"
                    onClick={handleSubmit(this.submitLoadDataForm)}
                    label="Submit"
                    loading={submitting}
                    loadingLabel="Submitting"
                  />
                </ButtonToolbar>
              </Col>
            </Row>
          </Row>
        </Col>
      </form>
    )
  }
}

export default compose(
  withRouter,
  reduxForm({
    form: 'loadData',
    validate: validateLoadDataForm,
  }),
  formValues({
    chosenAgencyName: AGENCY_NAME_FIELD,
    chosenDataSourceName: DATA_SOURCE_NAME_FIELD,
    customDataSourceDisplayName: CUSTOM_DATA_DISPLAY_NAME,
    chosenStyle: CHOOSE_DATA_STYLE,
  }),
  firebaseConnect(props => {
    let paths = [
      { path: '/agencies', type: 'once' },
      { path: '/gisFeatureSchemas', type: 'once' },
      { path: '/gisDataSources', type: 'once' },
      { path: '/mapboxStyleLayers', type: 'once' },
    ]
    if (props.chosenAgencyName) {
      paths.push({ path: `/gisDataSourceConfigurations/${props.chosenAgencyName}`, type: 'once' })
    }
    if (props.chosenAgencyName && props.chosenDataSourceName && props.dataSourceNamesForChosenAgency) {
      if (props.dataSourceNamesForChosenAgency.includes(props.chosenDataSourceName)) {
        paths.push({
          path: `/gisDataSources/${props.chosenAgencyName}/${props.chosenDataSourceName}/mapboxStyleLayers`,
          type: 'once',
        })
      } else {
        paths.push({ path: `/mapboxStyleLayers/${props.chosenDataSourceName}/default`, type: 'once' })
      }
    }
    return paths
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect((props, firestore) => {
    let paths = []
    if (queryString.parse(props.location.search).agencyName) {
      paths = [
        {
          collection: 'inspectionConfigs',
          where: [['agencyName', '==', queryString.parse(props.location.search).agencyName]],
          type: 'once',
        },
      ]
    } else if (props.chosenAgencyName) {
      paths = [
        {
          collection: 'inspectionConfigs',
          where: [['agencyName', '==', props.chosenAgencyName]],
          type: 'once',
        },
      ]
    }
    return paths
  })
)(LoadDataForm)
