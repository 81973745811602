import React from 'react'
import { FormGroup, InputGroup, Row, Col, FormCheck } from 'react-bootstrap'
import { FieldProps } from 'formik'

type Props = {
  label: string
} & FieldProps

const FormikCheckbox = (props: Props) => {
  const { field, form, ...otherProps } = props
  const handleChange = () => {
    form.setFieldValue(field.name, !field.value)
  }
  return (
    <Row>
      <Col sm={12}>
        <FormCheck checked={field.value} label={otherProps.label} onChange={handleChange} />
      </Col>
    </Row>
  )
}

export default FormikCheckbox
