import React, { Component } from 'react'
import { Row, Button, ButtonToolbar } from 'react-bootstrap'
import { FaEnvelope } from 'react-icons/fa'
import { compose } from 'redux'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { SimpleBootstrapInput } from '../components/BootstrapReduxForm'
import { withRouter, RouteComponentProps } from 'react-router-dom'

const EMAIL_FIELD = 'email'

const emailRegex = /^\S+@\S+\.[A-Z]{2,}$/i
const validate = data => {
  const errors = {}
  if (!data[EMAIL_FIELD] || !emailRegex.test(data[EMAIL_FIELD])) {
    errors[EMAIL_FIELD] = 'Must enter a valid email address'
  }
  return errors
}

type Props = {
  agencyName: string
  contactEmail: string
  updateContactEmail: Function
  // provided by redux-form
} & RouteComponentProps &
  InjectedFormProps

class ContactEmailForm extends Component<Props> {
  componentWillMount() {
    this.props.initialize({ [EMAIL_FIELD]: this.props.contactEmail || '' })
  }

  componentWillReceiveProps(newProps) {
    if (newProps.contactEmail && !this.props.contactEmail) {
      this.props.initialize({ [EMAIL_FIELD]: newProps.contactEmail })
    }
  }

  submitContactEmailForm = data => {
    return this.props.updateContactEmail(this.props.agencyName, data[EMAIL_FIELD]).then(result => {
      if (result.error) {
        throw new SubmissionError({ [EMAIL_FIELD]: result.payload.message })
      }
      this.props.history.push('/contact-email/success')
    })
  }

  render() {
    const { agencyName, handleSubmit, submitting } = this.props
    return (
      <div>
        {agencyName && (
          <div>
            <Row>
              Please provide an email for an administrator who will receive important notifications about billing and
              subscriptions for IncidentView.
            </Row>
            <Row />
            <form onSubmit={handleSubmit(this.submitContactEmailForm)}>
              <Row>
                <Field
                  name={EMAIL_FIELD}
                  component={SimpleBootstrapInput}
                  hasFeedback
                  size="lg"
                  type="email"
                  placeholder="enter email"
                  addonBefore={<FaEnvelope />}
                />
              </Row>
              <Row>
                <ButtonToolbar>
                  <Button variant="success" size="lg" block disabled={submitting} type="submit">
                    Save
                  </Button>
                </ButtonToolbar>
              </Row>
            </form>
          </div>
        )}
      </div>
    )
  }
}

export default compose(
  withRouter,
  reduxForm({
    form: 'contactEmail',
    validate,
  })
)(ContactEmailForm)
