import React from 'react'
import Immutable from 'immutable'
import { Cell } from 'fixed-data-table-2'
import { Button } from 'react-bootstrap'
import './ButtonCell.css'

type Props = {
  rowIndex: number
  data: Immutable.List<any>
  columnKey: string
  buttonValue: string | Record<string, any>
  buttonOnClick: any
}

const ButtonCell = ({ rowIndex, data, buttonValue, buttonOnClick, ...props }) => {
  const dataRow = data.get(rowIndex)
  const onClick = () => buttonOnClick(dataRow)
  return (
    <Cell {...props} className="button-cell">
      <Button variant="outline-dark" onClick={onClick}>
        {buttonValue}
      </Button>
    </Cell>
  )
}

export default ButtonCell
