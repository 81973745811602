import React from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import FormField from './FormField'
import { FormFieldProps } from './FormField'

type Props = {
  allowCreate?: boolean
  options: any[]
  delimiter: string
  multi: boolean
  placeholder?: string
}

const FormFieldReactSelect = (props: FormFieldProps & Props) => {
  let chosenLabels = props.input.value
  if (Array.isArray(props.input.value)) {
    chosenLabels = props.input.value.map(op => op.label).join(', ')
  }

  // String value must be converted to option object when this is a new entry (when 'allowCreate' is true)
  let value = props.input.value
  if (value && props.allowCreate && typeof value === 'string') {
    value = { value: value, label: value }
  }

  const getSelectValue = () => {
    if (!props.input.value) return null
    if (props.multi) {
      const selectedVals = props.input.value.split(',')
      return props.options.filter(opt => selectedVals.includes(opt.value))
    }
    return props.options.find(opt => opt.value === props.input.value)
  }

  let SelectCtl = props.allowCreate ? CreatableSelect : Select
  return (
    <FormField {...props}>
      {
        <div>
          {props.readOnly ? (
            <div>{chosenLabels}</div>
          ) : (
            <SelectCtl
              id={props.id}
              options={props.options}
              isMulti={props.multi}
              delimiter={props.delimiter}
              placeholder={props.placeholder}
              {...props.input}
              onChange={(d: { value: string } | Array<{ value: string }> | null) => {
                let val: string | { value: string } | { value: string }[] = d
                if (Array.isArray(d)) {
                  // Is array when props.multi is true
                  val = d.map(opt => opt.value).join(props.delimiter)
                } else if (typeof d === 'object' && d) {
                  val = d.value
                }
                props.input.onChange(val)
              }}
              value={getSelectValue()}
              onBlur={() => props.input.onBlur(props.input.value)}
            />
          )}
        </div>
      }
    </FormField>
  )
}

export default FormFieldReactSelect
