import { RouteComponentProps } from 'react-router-dom'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AddDispatchAgencyForm from '../containers/AddDispatchAgencyForm'
import ViewWrapper from '../components/ViewWrapper'

type Props = {
  dispatches: Record<string, any>
  user: Record<string, any>
  loadDispatchesForAgency: Function
  loadDispatches: Function
} & RouteComponentProps

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const DispatchAgencyView = (props: Props) => {
  return (
    <ViewWrapper header="Add new dispatch agency">
      <AddDispatchAgencyForm />
    </ViewWrapper>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DispatchAgencyView)
