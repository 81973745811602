import {
  loginPromise,
  logout as logoutFirebase,
  changePasswordPromise,
  resetPasswordPromise,
  checkContactEmail,
} from '../firebase'
import {
  LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  RESET_PASSWORD,
  AUTHENTICATED,
  UNAUTHENTICATED,
  LOAD_AUTHORIZED_USER,
} from '../constants/ActionTypes'
import history from '../history'
import { client } from '../apollo'

/**
 * Lets the store know we're authenticated. Dispatched whenever firebase
 * detects auth change, usually when loading cached auth from a previous session
 */
export function authenticated(authData, token) {
  return {
    type: AUTHENTICATED,
    authData,
    token,
  }
}

export function unauthenticated() {
  return {
    type: UNAUTHENTICATED,
  }
}

export function login(email, password) {
  return {
    type: LOGIN,
    payload: loginPromise(email, password),
  }
}

/**
 * This is passed the currently logged-in user data from firebase db. It is hooked
 * up to CoreLayout, where it is mounted to watch any changes to the logged-in user.
 * This gets all user data except for uid, which is retrieved on login.
 */
export function loadAuthorizedUser(user) {
  checkContactEmail(user)
  if (user.hasTemporaryPassword) {
    history.push('/change-password')
  }
  return {
    type: LOAD_AUTHORIZED_USER,
    payload: {
      user,
    },
  }
}

export async function logout() {
  await logoutFirebase()
  history.push('/')
  await client.resetStore()
  return {
    type: LOGOUT,
  }
}

export function changePassword(email, oldPassword, newPassword) {
  return {
    type: CHANGE_PASSWORD,
    payload: changePasswordPromise(email, oldPassword, newPassword),
  }
}

export function resetPassword(email) {
  return {
    type: RESET_PASSWORD,
    payload: resetPasswordPromise(email),
  }
}
