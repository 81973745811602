import Immutable from 'immutable'

const AgencyRecord = Immutable.Record({
  // eslint-disable-line new-cap
  key: null,
  isActive: null,
  displayName: null,
  licenses: null,
  contactEmail: null,
  hasInspect: null,
})

export default class Agency extends AgencyRecord {
  constructor(obj) {
    if (!obj.key) {
      throw new Error('Agency records must have a key')
    }
    if (obj.licenses) {
      obj.licenses = parseInt(obj.licenses, 10)
    }
    super(obj)
  }
  get agencyName() {
    return this.key
  }
  toFirebaseObject() {
    // we store roles as a map rather than array in Firebase
    return this.set('key', null).toJS()
  }
  toFirestoreObject() {
    let obj = this.toJS()
    delete obj.key
    return obj
  }
}
