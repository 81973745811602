import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Button } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'
import Immutable from 'immutable'
import dateFormat from 'dateformat'
import queryString from 'query-string'
import { getAgenciesMap } from '../selectors'
import {
  getAgencyDevicesListSorted,
  getAllNonAgiDevicesListSorted,
  getAllDevicesList,
} from '../selectors/devicesSelectors'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import SortableDataGrid from '../components/FixedDataTable/SortableDataGrid'
import ColorCell from '../components/FixedDataTable/ColorCell'
import { sortDevices } from '../actions/devices'
import history from '../history'
import { RouteComponentProps } from 'react-router-dom'

const columns = [
  {
    key: 'editButton',
    name: 'Edit',
    fixed: true,
    button: {
      value: <FaPen />,
      onClick: dataRow => history.push(`/devices/${dataRow.agencyName}/${dataRow.key}`),
    },
    width: 45,
  },
  {
    key: 'agencyName',
    name: 'Agency Name',
    sortable: true,
    width: 140,
  },
  {
    key: 'deviceType',
    name: 'Device Type',
    sortable: true,
    width: 160,
  },
  {
    key: 'systemVersion',
    name: 'System Version',
    sortable: true,
    width: 180,
  },
  {
    key: 'appVersion',
    name: 'App Version',
    sortable: true,
    width: 80,
  },
  {
    key: 'coreVersion',
    name: 'Core Version',
    sortable: true,
    width: 80,
  },
  {
    key: 'displayName',
    name: 'Display Name',
    sortable: true,
    width: 150,
  },
  {
    key: 'timeStamp',
    name: 'Time Stamp',
    isDate: true,
    sortable: true,
    width: 180,
  },
  {
    key: 'userEmail',
    name: 'User Email',
    sortable: true,
    width: 190,
  },
  {
    key: 'avlColor',
    name: 'AVL Color',
    sortable: true,
    cellComponent: ColorCell,
    width: 100,
  },
  {
    key: 'isActive',
    name: 'Active',
    sortable: true,
    width: 70,
  },
  {
    key: 'cadAvlSharingId',
    name: 'CAD AVL ID',
    sortable: true,
    width: 100,
  },
  {
    key: 'hardwareUid',
    name: 'Hardware UID',
    sortable: true,
    width: 340,
  },
  {
    key: 'messageUid',
    name: 'Message UID',
    sortable: true,
    width: 400,
  },
  {
    key: 'parseInstallationId',
    name: 'Parse Installation ID',
    sortable: true,
    width: 300,
  },
]

const columnsForCustomers = [
  'userEmail',
  'deviceType',
  'systemVersion',
  'appVersion',
  'coreVersion',
  'timeStamp',
  'displayName',
  'avlColor',
  'cadAvlSharingId',
  'hardwareUid',
]
const columnsForAgencyAdmins = [
  'editButton',
  'userEmail',
  'deviceType',
  'systemVersion',
  'appVersion',
  'coreVersion',
  'timeStamp',
  'displayName',
  'avlColor',
  'isActive',
  'cadAvlSharingId',
  'hardwareUid',
]

const mapStateToProps = (state, ownProps) => {
  const authUser = state.auth.user
  return {
    user: authUser,
    devices: authUser.isAgi ? getAgencyDevicesListSorted(state, ownProps) : getAllNonAgiDevicesListSorted(state),
    sortAttribute: state.devices.sorting.get('attributeName'),
    sortDirection: state.devices.sorting.get('sortDirection'),
    agencies: getAgenciesMap(state),
    allDevices: getAllDevicesList(state),
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ sortDevices }, dispatch)
}

// This is not actually a Route component
// but it does have location and history passed in
type Props = {
  user: Record<string, any>
  devices: Record<string, any>
  agencies: Record<string, any>
  sortAttribute: string
  sortDirection: string
  sortDevices: Function
} & RouteComponentProps

class DevicesDataGrid extends React.Component<Props> {
  createCSVExport = (columnOrder, data) => {
    const devicesBlob = data
      .map(device => {
        return columnOrder
          .map(col => {
            const val = col.isDate ? dateFormat(device.get(col.key), 'mm/dd/yyyy h:MM:ss TT') : device.get(col.key)
            return `"${val || ''}"`
          })
          .join(',')
      })
      .join('\n')
    const csvContent = `${columnOrder.map(col => `"${col.name}"`).join(',')}\n${devicesBlob}`
    return csvContent
  }

  createDownload = (filename, columnOrder, data) => {
    const content = new Blob([this.createCSVExport(columnOrder, data)], { type: 'text/csv' })
    const dummy = document.createElement('a')
    dummy.href = window.URL.createObjectURL(content)
    dummy.download = filename

    if (document.createEvent) {
      const event = document.createEvent('MouseEvents')
      event.initEvent('click', true, true)
      dummy.dispatchEvent(event)
    } else {
      dummy.click()
    }
  }

  render() {
    const { user, sortAttribute, sortDirection } = this.props
    const urlQuery = queryString.parse(this.props.location.search)
    const devices = this.props.devices || Immutable.List([])

    let cols = columns
    if (!user.isAgi) {
      if (user.isAgencyAdmin) {
        cols = columns
          .filter(col => columnsForAgencyAdmins.includes(col.key))
          .sort((a, b) => columnsForAgencyAdmins.indexOf(a.key) - columnsForAgencyAdmins.indexOf(b.key))
      } else {
        cols = columns
          .filter(col => columnsForCustomers.includes(col.key))
          .sort((a, b) => columnsForCustomers.indexOf(a.key) - columnsForCustomers.indexOf(b.key))
      }
    } else if (this.props.location && urlQuery && urlQuery.agencyName) {
      // modify the edit button onClick to persist the agencyName in the URL
      cols[0].button.onClick = dataRow => {
        this.props.history.push(`/devices/${dataRow.agencyName}/${dataRow.key}?agencyName=${urlQuery.agencyName}`)
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          height: 'calc(100% - 3rem)',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        }}
      >
        <div style={{ marginBottom: '8px' }}>
          <Button
            variant="primary"
            onClick={() => this.createDownload('devices.csv', cols.filter(col => col.key !== 'editButton'), devices)}
          >
            Export to CSV
          </Button>
        </div>
        <SortableDataGrid
          dataList={devices}
          columns={cols}
          sortAttribute={sortAttribute}
          sortDirection={sortDirection}
          onSort={this.props.sortDevices}
        />
      </div>
    )
  }
}

export default compose(
  firebaseConnect((props, firebase) => {
    if (props.user && props.user.isAgi) {
      return [`/agencies/${props.user.agencyName}`]
    } else {
      return ['/agencies/']
    }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DevicesDataGrid)
