import Immutable from 'immutable'

const AgencyConfigRecord = Immutable.Record({
  // eslint-disable-line new-cap
  key: null,
  avlDisplayGroups: new Immutable.Map(),
  avlTransmitActivatedDefault: false,
  avlTransmitToggleEnabled: true,
  // Avl locations will be filtered from the client's map view after this many hours
  // If set to 0, they are never filtered
  avlRemoveFromMapAfterTime: null,
  configVersion: 1,
  identifiables: null, // new Immutable.Map(),
  initialLatLng: null,
  bufferSrid: null, // integer
  measureSrid: null, // integer
  dispatchGroups: false,
  receivesSharedAvlFrom: new Immutable.List(),
  shareAvlWithCad: new Immutable.List(),
})

const objKeysToList = obj => (obj ? new Immutable.List(Object.keys(obj)) : new Immutable.List())
const listToObjKeys = list =>
  list
    ? list.reduce((acc, item) => {
        acc[item] = true
        return acc
      }, {})
    : {}

export default class AgencyConfig extends AgencyConfigRecord {
  /**
   * Ensure that all input integer strings are converted to integers, and
   * float strings are converted to floats
   */
  constructor(params) {
    if (params) {
      let cadCenters = params.shareAvlWithCad
      if (cadCenters) {
        if (cadCenters.constructor === Array) {
          cadCenters = new Immutable.List(cadCenters)
        } else {
          // shareAvlWithCad comes in from firebase as an object
          cadCenters = objKeysToList(cadCenters)
        }
      }
      super({
        ...params,
        configVersion: params.configVersion && parseInt(params.configVersion, 10),
        initialLatLng: params.initialLatLng && {
          lat: parseFloat(params.initialLatLng.lat),
          lng: parseFloat(params.initialLatLng.lng),
        },
        bufferSrid: parseInt(params.bufferSrid, 10),
        measureSrid: parseInt(params.measureSrid, 10),
        dispatchGroups: params && params.dispatchGroups ? params.dispatchGroups : false,
        avlDisplayGroups: new Immutable.Map(params.avlDisplayGroups),
        avlRemoveFromMapAfterTime: params.avlRemoveFromMapAfterTime && parseFloat(params.avlRemoveFromMapAfterTime),
        receivesSharedAvlFrom:
          params && params.receivesSharedAvlFrom && params.receivesSharedAvlFrom.constructor === Array
            ? new Immutable.List(params.receivesSharedAvlFrom)
            : objKeysToList(params.receivesSharedAvlFrom),
        shareAvlWithCad: cadCenters,
      })
    } else {
      super(params)
    }
  }

  get receivesSharedAvlFromList() {
    let list = this.receivesSharedAvlFrom
    // list.set(-1, this.key)
    const outList = listToObjKeys(list.set(list.size, this.key))
    // list.add(this.key)
    return Object.keys(outList)
  }

  get avlDisplayGroupsList() {
    return objKeysToList(this.avlDisplayGroups ? this.avlDisplayGroups.toJS() : {})
  }

  get avlHiddenGroupsList() {
    return this.avlDisplayGroups
      .filter(grp => grp === true || grp.hide === true)
      .keySeq()
      .toList()
  }

  get agencyName() {
    return this.key
  }

  toFirebaseObject() {
    // we store some items as a map rather than array in Firebase
    return this.set('shareAvlWithCad', new Immutable.Map(listToObjKeys(this.shareAvlWithCad)))
      .set('receivesSharedAvlFrom', new Immutable.Map(listToObjKeys(this.receivesSharedAvlFrom)))
      .toJS()
  }
}
