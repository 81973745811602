import React from 'react'
import { connect } from 'react-redux'
import { Fields, reduxForm, SubmissionError, InjectedFormProps } from 'redux-form'
import { Alert, Button, ButtonToolbar, ButtonGroup, Col, Row } from 'react-bootstrap'
import { FaDownload } from 'react-icons/fa'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { dispatchesRef } from '../firebase'
import Dispatch from '../records/dispatch'
import { getAgencyNameUserOrUrl } from '../selectors'
import { columns, columnsForCustomers } from './DispatchesDataGrid'
import dateFormat from 'dateformat'

const START_DATE_FIELD = 'startDate'
const END_DATE_FIELD = 'endDate'
const FOCUSED_INPUT_FIELD = 'focusedInput'

const validate = data => {
  let errorString = ''
  if (!data[START_DATE_FIELD] && !data[END_DATE_FIELD]) {
    errorString = 'Start and end dates must be specified.'
  } else if (!data[START_DATE_FIELD]) {
    errorString = 'Start date must be specified.'
  } else if (!data[END_DATE_FIELD]) {
    errorString = 'End date must be specified.'
  }
  return { _error: errorString }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.auth.user,
    agencyName: getAgencyNameUserOrUrl(state, ownProps),
  }
}

const renderDatePicker = fields => (
  <div>
    <Row>
      <DateRangePicker
        startDate={fields[START_DATE_FIELD].input.value || null}
        startDateId="startDate"
        endDate={fields[END_DATE_FIELD].input.value || null}
        endDateId="endDate"
        onDatesChange={({ startDate, endDate }) => {
          fields[START_DATE_FIELD].input.onChange(startDate)
          fields[END_DATE_FIELD].input.onChange(endDate)

          // Blur the fields so they're set as 'touched'
          if (startDate && endDate) {
            fields[START_DATE_FIELD].input.onBlur()
            fields[END_DATE_FIELD].input.onBlur()
          }
        }}
        focusedInput={fields[FOCUSED_INPUT_FIELD].input.value || null}
        onFocusChange={focusedInput => fields[FOCUSED_INPUT_FIELD].input.onChange(focusedInput)}
        showClearDates
        numberOfMonths={1}
        isOutsideRange={moment => moment.isAfter(new Date(), 'day')}
        displayFormat="YYYY-MM-DD"
      />
    </Row>
  </div>
)

type Props = {
  user: Record<string, any>
  agencyName: string
  onCancel: (event: any) => void
} & InjectedFormProps

class ExportDispatchesForm extends React.Component<Props> {
  constructor(props) {
    super(props)
    props.initialize({
      startDate: null,
      endDate: null,
      focusedInput: null,
    })
  }

  createCSVExport = (columnOrder, data) => {
    const dispatches = Object.entries(data).map(([key, val]) => {
      const dispatch = new Dispatch({ key, ...val })
      let output = {}
      for (const column of columnOrder) {
        output[column.name] = dispatch[column.key]
      }
      return output
    })

    const dispatchesBlob = dispatches
      .map(obj => {
        return Object.entries(obj)
          .map(([key, value]) => {
            if (value instanceof Date) {
              return dateFormat(value, 'mm/dd/yyyy h:MM:ss TT')
            }
            return `"${value || ''}"`
          })
          .join(',')
      })
      .join('\n')

    const csvContent = `${columnOrder.map(col => `"${col.name}"`).join(',')}\n${dispatchesBlob}`
    return csvContent
  }

  createDownload = (filename, columnOrder, data) => {
    const content = new Blob([this.createCSVExport(columnOrder, data)], { type: 'text/csv' })
    const dummy = document.createElement('a')
    dummy.href = window.URL.createObjectURL(content)
    dummy.download = filename

    if (document.createEvent) {
      const event = document.createEvent('MouseEvents')
      event.initEvent('click', true, true)
      dummy.dispatchEvent(event)
    } else {
      dummy.click()
    }
  }

  submitForm = data => {
    const beginTimestamp = data[START_DATE_FIELD] && data[START_DATE_FIELD].startOf('day').unix()
    const endTimestamp = data[END_DATE_FIELD] && data[END_DATE_FIELD].endOf('day').unix()
    let columnOrder = columns
    if (!this.props.user.isAgi) {
      columnOrder = columns
        .filter(col => columnsForCustomers.includes(col.key))
        .sort((a, b) => columnsForCustomers.indexOf(a.key) - columnsForCustomers.indexOf(b.key))
    }

    return dispatchesRef
      .child(this.props.agencyName)
      .orderByChild('timeStamp')
      .startAt(beginTimestamp)
      .endAt(endTimestamp)
      .once('value')
      .then(snapshot => {
        const val = snapshot.val()
        if (!val) {
          throw new SubmissionError({ _error: 'No dispatches exist in the specified date range.' })
        }
        return this.createDownload('dispatches.csv', columnOrder.filter(col => col.key !== 'key'), val)
      })
  }

  render() {
    const { anyTouched, error, handleSubmit } = this.props
    return (
      <form>
        <div>
          <Fields names={[START_DATE_FIELD, END_DATE_FIELD, FOCUSED_INPUT_FIELD]} component={renderDatePicker} />
          {error && anyTouched && (
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <Alert variant="danger">{error}</Alert>
              </Col>
            </Row>
          )}
          <Row />
          <Row className="form-group">
            <Col className="text-center">
              <ButtonToolbar>
                <ButtonGroup>
                  <Button variant="primary" size="lg" onClick={handleSubmit(this.submitForm)} type="submit">
                    Export to CSV <FaDownload />
                  </Button>
                  <Button className="ml-2" size="lg" variant="outline-dark" onClick={this.props.onCancel}>
                    Back
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
      </form>
    )
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'exportDispatches',
    validate: validate,
  })(ExportDispatchesForm)
)
