import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { FormControl } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import { getAgencies } from '../selectors'

/**
 * React component that renders a select dropdown of agencies. When an agency is
 * selected, it adds the chosen agency as a URL query to the current page
 */

type Props = {
  agencies: Record<string, any>
  agencyNameFilter: string
  label: string
  style: Record<string, any>
} & RouteComponentProps

const AgencyFilterSelect = (props: Props) => {
  const { agencies, agencyNameFilter, label, location, style, history } = props
  const urlQuery = queryString.parse(location.search)
  return (
    <div style={style}>
      <FormControl
        as="select"
        value={agencyNameFilter}
        label={typeof label === 'string' ? label : null}
        onChange={(e: React.ChangeEvent<any>) => {
          history.push({
            pathname: location.pathname,
            search: queryString.stringify({ ...urlQuery, agencyName: e.target.value || undefined }),
          })
        }}
      >
        <option key="default" value="">
          Select an agency
        </option>
        {agencies &&
          Object.keys(agencies).map(agencyName => (
            <option key={agencyName} value={agencyName}>
              {agencyName}
            </option>
          ))}
      </FormControl>
    </div>
  )
}

export default compose(
  withRouter,
  firebaseConnect((props, firebase) => ['/agencies/']),
  connect(state => ({ agencies: getAgencies(state) }))
)(AgencyFilterSelect)
