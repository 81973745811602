import { combineReducers } from 'redux'
import agencyConfigs from './agencyConfigs'
import agencyAllowedSharing from './agencyAllowedSharing'
import auth from './auth'
import cadCenters from './cadCenters'
import devices from './devices'
import dispatches from './dispatches'
import firebaseServerTimeOffset from './firebaseServerTimeOffset'
import gisDataSources from './gisDataSources'
import queues from './queues'
import users from './users'
import { reducer as reduxFormReducer } from 'redux-form'
import { routerReducer } from 'react-router-redux'
import { firebaseStateReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { LOGOUT } from '../constants/ActionTypes'

const appReducer = combineReducers({
  agencyConfigs,
  agencyAllowedSharing,
  auth,
  cadCenters,
  devices,
  dispatches,
  firebase: firebaseStateReducer,
  firebaseServerTimeOffset,
  firestore: firestoreReducer,
  gisDataSources,
  queues,
  users,
  form: reduxFormReducer,
  routing: routerReducer,
})

const rootReducer = (state, action) => {
  // when LOGOUT is dispatched, reset the state in all reducers. Passing state
  // of undefined causes reducers to reload their initialState
  let modState = state
  if (action.type === LOGOUT) {
    modState = undefined
  }

  return appReducer(modState, action)
}

export default rootReducer
