import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import { ProgressBar } from 'react-bootstrap'
import ViewWrapper from '../components/ViewWrapper'
import queryString from 'query-string'

type Props = {
  firestoreFunctionName: string
  location: {
    query: {
      taskId?: string
    }
  }
  task: {
    _error?: string
    _status?: string
  }
  taskProgress?: {
    percent: number
    label: string
  }
  taskFirebasePath: string
  render: Function
}

// This is often used with components using FirestoreFunctionViewWrapper
export const TaskViewWrapper = (props: { header: string; percent?: number; label?: string; children: any }) => (
  <ViewWrapper header={props.header}>
    {props.percent ? (
      <div>
        {props.label ? <label>{props.label}</label> : null}
        <ProgressBar animated={props.percent !== 100} now={props.percent} />
      </div>
    ) : null}
    {props.children}
  </ViewWrapper>
)

const FirestoreFunctionViewWrapper = ({ render, ...otherProps }: Props) => {
  return <div>{render(otherProps)}</div>
}

// Converts firestore path to compatible redux-firestore listener object
const convertPathToReduxFirestoreObj = path => {
  const [collection, doc, subcollection, subDoc] = path.split('/')
  return {
    collection,
    doc,
    subcollections: [{ collection: subcollection, doc: subDoc }],
  }
}

export default compose(
  connect((state, ownProps) => {
    const urlQuery = queryString.parse(ownProps.location.search)
    const { taskId } = urlQuery
    if (!taskId) {
      return {}
    }
    return {
      task: _.get(state, `firestore.data.functions.${ownProps.firestoreFunctionName}.tasks.${taskId}`),
      taskProgress: _.get(state, `firestore.data.functions.${ownProps.firestoreFunctionName}.taskProgress.${taskId}`),
      taskFirebasePath: `functions/${ownProps.firestoreFunctionName}/tasks/${taskId}`,
    }
  }),
  firestoreConnect(props => {
    if (props.taskFirebasePath) {
      const taskProgressFirebasePath = props.taskFirebasePath.replace('tasks', 'taskProgress')
      return [
        convertPathToReduxFirestoreObj(props.taskFirebasePath),
        convertPathToReduxFirestoreObj(taskProgressFirebasePath),
      ]
    }
    return []
  })
)(FirestoreFunctionViewWrapper)
