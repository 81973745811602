import { agencyConfigsRef } from '../firebase'
import {
  LOAD_AGENCY_CONFIG,
  SAVE_AGENCY_CONFIG,
  SAVE_RECEIVES_SHARED_AVL_FROM,
  SAVE_AVL_DISPLAY_GROUPS,
  HIDE_SHARED_AVL,
  SHOW_SHARED_AVL,
  UPDATE_DISPATCH_GROUPS,
} from '../constants/ActionTypes'
// import { denyIfNull } from './utils'

// TODO: this is useful if we want this action to not dispatch when null.
//       Seemed like a good idea at first, but now handling in reducer.
// function loadAgencyConfigUnsafe(agencyConfig) {
//   return {
//     type: LOAD_AGENCY_CONFIG,
//     payload: {
//       agencyConfig
//     }
//   }
// }
//
// export const loadAgencyConfig = denyIfNull(loadAgencyConfigUnsafe)

export function loadAgencyConfig(agencyConfig) {
  return {
    type: LOAD_AGENCY_CONFIG,
    payload: {
      agencyConfig,
    },
  }
}

export function updateDispatchGroups(agencyName, currentStatus) {
  return {
    type: UPDATE_DISPATCH_GROUPS,
    payload: updateDispatchGroupsPromise(agencyName, currentStatus),
  }
}

function updateDispatchGroupsPromise(agencyName, currentStatus) {
  if (currentStatus) {
    return agencyConfigsRef
      .child(agencyName)
      .child('dispatchGroups')
      .remove()
  } else {
    return agencyConfigsRef
      .child(agencyName)
      .child('dispatchGroups')
      .set(true)
  }
}

function saveAgencyConfigPromise(agencyName, agencyConfig) {
  const updateFunction = currentData => {
    if (currentData === null) {
      return { ...agencyConfig, configVersion: 1 }
    } else {
      return {
        ...currentData,
        ...agencyConfig,
        configVersion: currentData.configVersion + 1,
      }
    }
  }
  return new Promise((resolve, reject) => {
    agencyConfigsRef.child(agencyName).transaction(updateFunction, (error, committed, snapshot) => {
      if (error) {
        reject(error)
      } else if (!committed) {
        reject('Saving agency config aborted.')
      } else {
        resolve(snapshot.val())
      }
    })
  })
}

export function saveAgencyConfig(agencyName, agencyConfig) {
  return {
    type: SAVE_AGENCY_CONFIG,
    payload: saveAgencyConfigPromise(agencyName, agencyConfig),
  }
}

export function saveReceivesSharedAvlFrom(agencyName, agencyArray) {
  const agencyObj = agencyArray
    ? agencyArray.reduce((acc, agency) => {
        acc[agency] = true
        return acc
      }, {})
    : null
  return {
    type: SAVE_RECEIVES_SHARED_AVL_FROM,
    payload: agencyConfigsRef
      .child(agencyName)
      .child('receivesSharedAvlFrom')
      .set(agencyObj),
  }
}

export function saveAvlDisplayGroups(agencyName, finalGroupsObj) {
  return {
    type: SAVE_AVL_DISPLAY_GROUPS,
    payload: agencyConfigsRef
      .child(agencyName)
      .child('avlDisplayGroups')
      .set(finalGroupsObj),
  }
}

export function hideSharedAvl(receivingAgencyName, sharingAgencyName) {
  return {
    type: HIDE_SHARED_AVL,
    payload: agencyConfigsRef
      .child(receivingAgencyName)
      .child('receivesSharedAvlFrom')
      .child(sharingAgencyName)
      .set(null),
  }
}

export function showSharedAvl(receivingAgencyName, sharingAgencyName) {
  return {
    type: SHOW_SHARED_AVL,
    payload: agencyConfigsRef
      .child(receivingAgencyName)
      .child('receivesSharedAvlFrom')
      .child(sharingAgencyName)
      .set(true),
  }
}
