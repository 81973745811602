import React from 'react'
import FirebaseConnector from './FirebaseConnector'
import SortableDataGrid from './FixedDataTable/SortableDataGrid'

const containerStyle = {
  paddingLeft: '3rem',
  paddingRight: '3rem',
}

type Props = {
  dataList: Record<string, any>
  firebaseRef: any
  onFirebaseChange: Function
  columns: any[]
  user: Record<string, any>
  requiredPermission: string
}

const DataGridView = ({ dataList, firebaseRef, onFirebaseChange, columns, user, requiredPermission }: Props) => {
  if (!user || !user.roles.includes(requiredPermission)) {
    return <div /> // return nothing until we are passed a user
  }

  const uniqueName = String(firebaseRef)

  return (
    <div style={containerStyle} className="text-center">
      <h3>{uniqueName}</h3>

      <FirebaseConnector
        key={uniqueName} // key lets react know to umount and mount component when changed
        firebaseRef={firebaseRef}
        onNodeChange={onFirebaseChange}
      />

      <div
        style={{
          position: 'absolute',
          height: 'calc(100% - 7.5rem)', // 100%, minus roughly height of "CadCenters" h3
          bottom: '1.5rem',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '2rem',
          paddingRight: '2rem',
        }}
      >
        <SortableDataGrid
          dataList={dataList}
          columns={columns}
          // sortAttribute={sortAttribute}
          // sortDirection={sortDirection}
          // onSort={props.sortDevices}
        />
      </div>
    </div>
  )
}

export default DataGridView
