import { LOAD_AGENCY_ALLOWED_SHARING, LOAD_ALL_AGENCY_ALLOWED_SHARING } from '../constants/ActionTypes'
import Immutable from 'immutable'

export const AgencyAllowedSharing = Immutable.Record({
  // eslint-disable-line new-cap
  data: Immutable.Map(),
})

const initialState = new AgencyAllowedSharing()

export default function reducer(state = initialState, action: { type?: string; payload?: any } = {}) {
  switch (action.type) {
    case LOAD_AGENCY_ALLOWED_SHARING:
      const { key, ...restOfAgencyObj } = action.payload.agencyAllowedSharing
      // if the payload only has 'key', and no other attributes, data doesn't exist in Firebase
      const dataExists = Object.keys(restOfAgencyObj).length > 0
      return state.set('data', dataExists && Immutable.fromJS({ [key]: restOfAgencyObj }))
    case LOAD_ALL_AGENCY_ALLOWED_SHARING:
      return state.set('data', Immutable.fromJS(action.payload.agencyAllowedSharing))
    default:
      return state
  }
}
