import React, { useState, useEffect, useReducer } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useQuery } from 'react-apollo'
import 'react-virtualized/styles.css'
import './EditHistoryGrid.css'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { usersRef } from '../firebase'
import queryString from 'query-string'
import { firestore } from '../firebase'
import { client, GET_FEATURE_EDITS, GET_FEATURE_EDIT_DELETIONS, GET_OLD_DB_MIGRATION_DATE } from '../apollo'
import { Table, Column, Grid, AutoSizer, MultiGrid } from 'react-virtualized'
import { DateRangePicker } from 'react-dates'
import _ from 'lodash'
import { Tooltip, OverlayTrigger, Button, Popover } from 'react-bootstrap'
import { getGisDataSources, getAgencyNameUserOrUrl, getAgencies } from '../selectors'
import { featureEdit } from '../types/EditHistoryTypes'

// Taylor will make updates to form after outstanding IV client updates
// editor button on top nav bar not visible for non-agi users

type Props = {
  agencyName: string
  // users: any
} & RouteComponentProps

// const getPreviousFeatureVersion

// const mapStateToProps = state => {
//   console.log('state: ', state)
//   return {
//     user: state.auth.user,
//   }
// }

const EditHistoryGrid = (props: Props) => {
  const { agencyName } = props
  const columnNames = [
    'Data Source Name',
    'Modified Date',
    'Modified User',
    'Edit Type',
    'Version',
    'Properties',
    'Geometry',
  ]

  const [filterBy, setFilterBy] = useState(null)
  // const [users, setUsers] = useState(null)
  const [editsInListForm, setEditsInListForm] = useState([])
  const [sortDate, setSortDate] = useState(null) // ASC // DESC

  useEffect(() => {
    // getting data for grid
    let users
    const getUsers = async () => {
      await firestore
        .collection('users')
        .where('agencyName', '==', `${agencyName}`)
        .get()
        .then(results => {
          if (results.empty) {
            users = null
          } else if (results && results.docs) {
            let newObject = {}
            for (const document of results.docs) {
              newObject[document.id] = document.data().email
            }
            users = newObject
          }
          // else {
          //   setUsers(null)
          // }
        })
    }
    getUsers()

    const getGridData = async agencyName => {
      // DETERMINE_MIGRATION_DATE is needed since hasura currenly does not support comparison operators on two fields https://github.com/hasura/graphql-engine/issues/457
      // work around?: https://hasura.io/docs/1.0/graphql/manual/schema/computed-fields.html
      let dataMigrationDate
      let processedDataArray = []
      const getDataMigrationDate = await client.query({
        query: GET_OLD_DB_MIGRATION_DATE,
        variables: { agencyName },
      })

      if (getDataMigrationDate && getDataMigrationDate.data && getDataMigrationDate.data.feature_set) {
        dataMigrationDate =
          getDataMigrationDate.data.feature_set.length === 1
            ? getDataMigrationDate.data.feature_set[0].created_date
            : '2020-05-06 22:03:42.098+00' // date the first first single agency migration was run
      }
      if (dataMigrationDate) {
        const featureEditsQuery = await client.query({
          query: GET_FEATURE_EDITS,
          variables: {
            agencyName,
            migrationDate: '2020-05-06 22:03:42.098+00',
          },
        })

        const featureEditDeleteQuery = await client.query({
          query: GET_FEATURE_EDIT_DELETIONS,
          variables: {
            agencyName,
          },
        })

        if (featureEditsQuery && featureEditsQuery.data && featureEditsQuery.data.feature) {
          const editedFeaturesArray = featureEditsQuery.data.feature
          for (const featureObject of editedFeaturesArray) {
            //  const theGeom = `${featureObject.geom.type}, ${JSON.stringify(featureObject.geom.coordiantes)}`
            const editorNameFromGuid =
              users && users[featureObject.modified_user_guid]
                ? users[featureObject.modified_user_guid]
                : featureObject.modified_user_guid
            const newFeatureObject = {
              dataSourceName: featureObject.feature_set.data_source_name,
              modifiedDate: new Date(featureObject.modified_date),
              modifiedUser: editorNameFromGuid,
              editType: featureObject.version === 1 ? 'creation' : 'update',
              version: featureObject.version,
              properties: JSON.stringify(featureObject.properties),
              geometry: 'coming soon',
            }
            processedDataArray.push(newFeatureObject)
          }
        }
        if (featureEditDeleteQuery && featureEditDeleteQuery.data && featureEditDeleteQuery.data.feature_history) {
          const deletedFeaturesArray = featureEditDeleteQuery.data.feature_history
          for (const featureObject of deletedFeaturesArray) {
            //  const theGeom = `${featureObject.geom.type}, ${JSON.stringify(featureObject.geom.coordiantes)}`
            const editorNameFromGuid =
              users && users[featureObject.modified_user_guid]
                ? users[featureObject.modified_user_guid]
                : featureObject.modified_user_guid
            const newFeatureObject = {
              dataSourceName: featureObject.feature_set.data_source_name,
              modifiedDate: new Date(featureObject.deleted_date),
              modifiedUser: editorNameFromGuid,
              editType: 'delete',
              version: featureObject.version,
              properties: JSON.stringify(featureObject.properties).substring(0, 100),
              geometry: '-----', //TODO: display in red, on click will still open mapbox modal with geom displayed, in red?
            }
            processedDataArray.push(newFeatureObject)
          }
        }
      }
      const dataForGrid = flattenProcessedQueries(processedDataArray)
      setEditsInListForm(dataForGrid)
    }
    getGridData(agencyName)
  }, [agencyName])

  const flattenProcessedQueries = (dataForGrid: Array<Object>) => {
    let editQueryArray = [[]]
    for (const featureObject of dataForGrid) {
      const featureAsArray = Object.values(featureObject)
      editQueryArray.push(featureAsArray)
    }
    if (!sortDate) {
      // on load, default is to sort by modified date
      editQueryArray.shift()
      editQueryArray.sort(function(a, b) {
        return a[1].getTime() < b[1].getTime() ? 1 : b[1].getTime() < a[1].getTime() ? -1 : 0
      })
      editQueryArray.unshift([])
    }
    return editQueryArray
  }

  const columnWidthRenderer = index => {
    if (index === 0) {
      return 145
    } else if (index === 1) {
      return 200
    } else if (index === 2) {
      return 250
    } else if (index === 3) {
      return 100
    } else if (index === 4) {
      return 75
    } else if (index === 5) {
      return 250
    } else if (index === 6) {
      return 125
    }
  }

  const tooltipProperties = propertyString => {
    return <Tooltip id="propertiesToolTip">{propertyString.replace(/,|\[|\]|'|"|{|}/g, ' ')}</Tooltip>
  }

  const createPopupFilter = column => {
    // TODO: finish filtering and sorting logic.
    const columnIndex = columnNames.indexOf(column)
    let uniqueValues = []
    // const sortOptions =
    return (
      <Popover id="filter-popover" title={`Filter ${column} by:`}>
        <Button id="foo" onClick={() => console.log('click!', column)} />
      </Popover>
    )
  }

  // TODO: sorting...
  function cellRenderer({ columnIndex, key, rowIndex, style }) {
    const darkStyle = { ...style, backgroundColor: 'rgb(220, 220, 220)', overflow: 'hidden' }
    if (rowIndex === 0) {
      // Displays column names
      return (
        <div>
          {/* {columnNames[columnIndex] &&
            columnNames[columnIndex] !== 'Properties' &&
            columnNames[columnIndex] !== 'Geometry' &&
            columnNames[columnIndex] !== 'Modified Date' && (
              <div className="editGridHeader" key={key} style={style}>
                <OverlayTrigger trigger="click" placement="top" overlay={createPopupFilter(columnNames[columnIndex])}>
                  <Button id={columnNames[columnIndex]} title={columnNames[columnIndex]} variant="primary">
                    {columnNames[columnIndex]}
                  </Button>
                </OverlayTrigger>
              </div>
            )}
          {columnNames[columnIndex] && columnNames[columnIndex] === 'Modified Date' && (
            <div className="editGridHeadertwo" key={key} style={style}>
              <OverlayTrigger overlay={<Tooltip id="toolTipDate">Sort by Date</Tooltip>} placement="top">
                <Button variant="primary" onClick={() => console.log('sort modified date')}>
                  {' '}
                  {columnNames[columnIndex]}
                </Button>
              </OverlayTrigger>
            </div>
          )}

          {columnNames[columnIndex] &&
            (columnNames[columnIndex] === 'Properties' || columnNames[columnIndex] === 'Geometry') && ( */}
          <div className="editGridHeaderthree" key={key} style={style}>
            {columnNames[columnIndex]}
          </div>
          // )}
        </div>
      )
    }
    if (columnIndex === 1) {
      // Modified Date
      return (
        <div key={key} style={rowIndex % 2 === 0 ? darkStyle : style}>
          {editsInListForm[rowIndex][columnIndex]
            .toString()
            .split(' ')
            .slice(1)
            .join(' ')}
        </div>
      )
    }
    if (columnIndex === 5) {
      // Properties
      return (
        <OverlayTrigger overlay={tooltipProperties(editsInListForm[rowIndex][columnIndex])} placement="right">
          <div key={key} style={rowIndex % 2 === 0 ? darkStyle : style}>
            {editsInListForm[rowIndex][columnIndex].substring(0, 30)}
          </div>
        </OverlayTrigger>
      )
    }
    if (columnIndex === 6) {
      // Geometry
      // TODO: geom viewer
      return (
        <div key={key} style={rowIndex % 2 === 0 ? darkStyle : style}>
          {editsInListForm[rowIndex][columnIndex]}
        </div>
      )
    }
    return (
      <div key={key} style={rowIndex % 2 === 0 ? darkStyle : style}>
        {editsInListForm[rowIndex][columnIndex]}
      </div>
    )
  }

  return (
    <div style={{ paddingTop: '25px' }}>
      <MultiGrid
        cellRenderer={cellRenderer}
        columnCount={columnNames.length}
        columnWidth={({ index }) => columnWidthRenderer(index)}
        height={400}
        rowCount={editsInListForm.length}
        rowHeight={40}
        width={1500}
        fixedRowCount={1}
      />
    </div>
  )
}
export default compose(
  connect(),
  withRouter
)(EditHistoryGrid)
// export default compose(connect(mapStateToProps))(EditHistoryGrid)

// TODO: finish filtering and sorting logic, write new hasura queries to pull in 'new' data based on filtering parameters.

// TODO: set up edit date filter similar to inspect grid

// TODO: add geometry viewer: on hover or click on geometry column, display modal with mapbox map with coordinates inserted. Would react-bootstrap modal work??

// TODO: add filter option: display only edits since last export

// TODO: for 'update' edit type display the changes in red text (for property values or geometry coordinates): query feature_history, and determine difference between entries (postgres window function?)

// TODO: add spinner during queries??
