import Immutable from 'immutable'

const CadCenterRecord = Immutable.Record({
  // eslint-disable-line new-cap
  key: null,
  displayName: null,
  avlFormat: null,
  avlHost: null,
  avlPort: null,
})

export default class CadCenter extends CadCenterRecord {
  constructor(params) {
    if (!params.key) {
      throw new Error('CadCenter records must have a key')
    }
    super(params)
  }

  toFirebaseObject() {
    // we store roles as a map rather than array in Firebase
    return this.set('key', null).toJS()
  }
}
