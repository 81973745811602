import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, formValues, Field, InjectedFormProps } from 'redux-form'
import { firebaseConnect } from 'react-redux-firebase'
import { functions } from '../firebase'
// import firebase from 'firebase'
// import { firestore } from '../firebase'
// import Immutable from 'immutable'
import { Row, Col, Alert, Button, ButtonToolbar, Image } from 'react-bootstrap'
import Select from 'react-select'
import {
  FormFieldInput,
  // FormFieldReactSelect,
  LoadingButton,
  FormField,
} from '../components/BootstrapReduxForm'
import { getGisDataSources } from '../selectors'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import AgencyFilterSelect from './AgencyFilterSelect'

const CURRENT_LAYERS = 'currentLayers'
const POSSIBLE_LAYERS = 'layerToAdd'
const AGENCY_NAME = 'agencyName'
const CHOSEN_STYLE = 'chosenStyle'

function mapStateToProps(state, ownProps) {
  return {}
}

type Props = {} & InjectedFormProps & RouteComponentProps

// let users
// const getUsers = async () => {
//   await firestore
//     .collection('users')
//     .where('agencyName', '==', `${agencyName}`)
//     .get()
//     .then(results => {
//       if (results.empty) {
//         users = null
//       } else if (results && results.docs) {
//         let newObject = {}
//         for (const document of results.docs) {
//           newObject[document.id] = document.data().email
//         }
//         users = newObject
//       }
//       // else {
//       //   setUsers(null)
//       // }
//     })
// }
// getUsers()

class EditorUsersForm extends React.Component<Props, { layerToAdd: any; userGuid?: string }> {
  render() {
    const { error, submitting, handleSubmit, submitFailed, location } = this.props
    const agencyHasEditor = true
    if (!agencyHasEditor)
      return (
        <div style={{ marginTop: '20px' }}>
          Your agency is not using IncidentView Editor, for more information contact AGI.
        </div>
      )
    return (
      <div>
        {' '}
        Coming Soon: grid displaying editors (emails) for given agency, with (total number of edits, last edited
        feature, is logged in? etc.) This form is more for agency or editor admins? (customers) to manage their editors
        themselves?
      </div>
    )
  }
}

export default compose(
  withRouter,
  // reduxForm({
  //   form: 'editorUsersForm',
  //   // validate: editorUsersFormValidate,
  // }),
  firebaseConnect(props => [`/gisDataSources/`, `/mapboxStyleLayers/`]),
  connect(mapStateToProps)
)(EditorUsersForm)
