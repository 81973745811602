import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import Immutable from 'immutable'
import { loadAllAgencyAllowedSharing, saveAgencyAllowedSharing } from '../actions/agencyAllowedSharing'
import { saveAgencyConfig } from '../actions/agencyConfigs'
import { Row, Col, Alert, Button, ButtonToolbar } from 'react-bootstrap'
import {
  FormFieldInput,
  FormFieldReactSelect,
  FormFieldTextArea,
  LoadingButton,
} from '../components/BootstrapReduxForm'
import { getAgenciesList } from '../selectors'
import AgencyConfig from '../records/agencyConfig'

const IDENTIFIABLES_FIELD = 'identifiables'
const INITIAL_LAT_FIELD = 'initialLat'
const INITIAL_LNG_FIELD = 'initialLng'
const BUFFER_SRID_FIELD = 'bufferSrid'
const MEASURE_SRID_FIELD = 'measureSrid'
const AVL_TRANSMIT_ACTIVATED_DEFAULT = 'avlTransmitActivatedDefault'
const AVL_TRANSMIT_TOGGLE_ENABLED = 'avlTransmitToggleEnabled'
const AVL_REMOVE_FROM_MAP_AFTER_TIME = 'avlRemoveFromMapAfterTime'
const ALLOWS_SHARING_AVL_WITH = 'allowsSharingAvlWith'
const RECEIVES_SHARED_AVL_FROM = 'receivesSharedAvlFrom'
const SHARE_AVL_WITH_CAD_CENTERS = 'shareAvlWithCad'
const AVL_DISPLAY_GROUPS = 'avlDisplayGroups'

const DEFAULT_IDENTIFIABLES = {
  access: ['type', 'access_code', 'floor', 'notes'],
  address: [
    'full_address',
    'house_number',
    'house_number_suffix',
    'street_predir',
    'street_name',
    'street_type',
    'street_postdir',
    'unit',
    'building_number',
    'city',
    'state',
    'zip',
    'alias',
    'access',
    'type',
    'notes',
  ],
  alarm: ['type', 'floor', 'notes'],
  bluediamond: ['construction', 'material', 'date_inspected', 'notes'],
  boatramp: ['notes'],
  bridge: ['type', 'status', 'capacity_tons', 'notes', 'name'],
  building: ['type', 'notes'],
  caution: ['type', 'notes'],
  coloredzones: ['color', 'label', 'notes'],
  draftsource: ['gallons', 'connection', 'notes'],
  driveway: ['notes'],
  electricalhazard: ['hazard_type', 'notes', 'total_voltage', 'type', 'voltage_per_panel'],
  firedistrict: ['color', 'label', 'notes'],
  firestation: ['department', 'station', 'staffing_level', 'address', 'notes'],
  firesuppression: ['type', 'floor', 'notes'],
  hazmat: ['type', 'notes'],
  hydrant: [
    'number',
    'active',
    'gpm',
    'pressure_static',
    'pressure_flow',
    'main_size',
    'outlet_size',
    'potable',
    'color',
    'system_type',
    'num_gallons',
    'owner',
    'owner_number',
    'notes',
    'address',
    'cross_street',
  ],
  landingzone: ['name', 'notes'],
  milepost: ['milepost', 'notes'],
  parcel: ['notes'],
  shutoff: ['type', 'floor', 'notes'],
  waterline: ['notes'],
  vacantstructure: ['condition', 'notes'],
}

const DELIMITER = ','

function validateEditAgencyConfigForm(data) {
  const errors = {}
  if (data[IDENTIFIABLES_FIELD]) {
    try {
      JSON.parse(data[IDENTIFIABLES_FIELD])
    } catch (e) {
      errors[IDENTIFIABLES_FIELD] = 'Invalid JSON: ' + e
    }
  }

  if (data[AVL_DISPLAY_GROUPS]) {
    try {
      JSON.parse(data[AVL_DISPLAY_GROUPS])
    } catch (e) {
      errors[AVL_DISPLAY_GROUPS] = 'Invalid JSON: ' + e
    }
  }

  const initialLat = parseFloat(data[INITIAL_LAT_FIELD])
  if (initialLat > 90 || initialLat < -90) {
    errors[INITIAL_LAT_FIELD] = 'Invalid latitude'
  }
  const initialLng = parseFloat(data[INITIAL_LNG_FIELD])
  if (initialLng > 180 || initialLng < -180) {
    errors[INITIAL_LNG_FIELD] = 'Invalid longitude'
  }

  if (data[AVL_REMOVE_FROM_MAP_AFTER_TIME]) {
    const removalTime = parseFloat(data[AVL_REMOVE_FROM_MAP_AFTER_TIME])
    if (isNaN(removalTime)) {
      errors[AVL_REMOVE_FROM_MAP_AFTER_TIME] = 'Removal time must be a number'
    } else if (removalTime < 0) {
      errors[AVL_REMOVE_FROM_MAP_AFTER_TIME] = 'Removal time cannot be negative'
    }
  }

  return errors
}

function mapStateToProps(state) {
  return {
    agencies: getAgenciesList(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveAgencyConfig,
      loadAllAgencyAllowedSharing,
      saveAgencyAllowedSharing,
    },
    dispatch
  )
}

type Props = {
  onSuccess: Function
  // TODO: figure out correct type
  onCancel: (event: any) => void
  // agencyConfig we're editing
  agencyConfig: any
  agencyName: string
  saveAgencyConfig: Function
  // cadCenters needed for dropdown
  cadCenters: Immutable.List<any>
  loadAllCadCenters: Function
  // agencies needed for dropdown
  agencies: Immutable.List<any>
  // necessary information for agencyAllowedSharing setting
  agencyAllowedSharing: any[]
  allowsSharingAvlWith: any[]
  loadAllAgencyAllowedSharing: Function
  saveAgencyAllowedSharing: Function
  createNew: boolean
  // provided by redux-form
  error: string
  fields: Record<string, any>
  handleSubmit: Function
  initialize: Function
  submitting: boolean
  // provided by react-router
  location: Record<string, any>
}

class EditAgencyConfigForm extends React.Component<Props> {
  componentWillMount() {
    if (this.props.createNew) {
      const defaultAgencyConfig = new AgencyConfig() as any
      this.props.initialize({
        [IDENTIFIABLES_FIELD]: JSON.stringify(DEFAULT_IDENTIFIABLES, null, 4),
        [INITIAL_LAT_FIELD]: '',
        [INITIAL_LNG_FIELD]: '',
        [BUFFER_SRID_FIELD]: defaultAgencyConfig.bufferSrid,
        [MEASURE_SRID_FIELD]: defaultAgencyConfig.measureSrid,
        [AVL_TRANSMIT_ACTIVATED_DEFAULT]: defaultAgencyConfig.avlTransmitActivatedDefault,
        [AVL_TRANSMIT_TOGGLE_ENABLED]: defaultAgencyConfig.avlTransmitToggleEnabled,
        [AVL_REMOVE_FROM_MAP_AFTER_TIME]: defaultAgencyConfig.avlRemoveFromMapAfterTime,
        [AVL_DISPLAY_GROUPS]: JSON.stringify({}),
      })
    } else {
      this.loadExistingAgencyConfigIntoForm()
    }
  }

  /**
   * Load the input agencyConfig object as the default inputs to the form.
   * This must happen in componentWillUpdate as well as componentWillMount
   * since the agencyConfig we're counting on isn't always available when we
   * first load the page, since it must be loaded from Firebase first.
   */
  loadExistingAgencyConfigIntoForm() {
    const { agencyConfig, agencyAllowedSharing, allowsSharingAvlWith, cadCenters, agencies } = this.props
    const identifiables = JSON.stringify(agencyConfig.identifiables || DEFAULT_IDENTIFIABLES, null, 4)
    const avlDisplayGroups = JSON.stringify(
      agencyConfig.avlDisplayGroups ? agencyConfig.avlDisplayGroups.toJS() : {},
      null,
      4
    )
    const {
      initialLatLng,
      bufferSrid,
      measureSrid,
      avlTransmitActivatedDefault,
      avlTransmitToggleEnabled,
      avlRemoveFromMapAfterTime,
      receivesSharedAvlFrom,
      shareAvlWithCad,
    } = agencyConfig
    // even though this exists in a separate firebase node, it makes the most sense to edit it as part of the
    // agency config, so we are adding it into the form

    // Turn string values into the objects expected by react-select

    const cadCenterOptions = cadCenters.toJS().map(c => {
      return { value: c.key, label: c.displayName }
    })
    const agencyOptions = agencies.toJS().map(a => {
      return { value: a.key, label: a.displayName }
    })

    const receivesAvlFromOptions = receivesSharedAvlFrom
      ? agencyOptions
          .filter(agency => receivesSharedAvlFrom.includes(agency.value))
          .map(opt => opt.value)
          .join()
      : ''
    const shareAvlWithCadOptions = shareAvlWithCad
      ? cadCenterOptions
          .filter(c => shareAvlWithCad.includes(c.value))
          .map(opt => opt.value)
          .join()
      : ''
    const selectedAllowsSharingAvlWith = allowsSharingAvlWith
      ? agencyOptions
          .filter(agency => allowsSharingAvlWith.includes(agency.value))
          .map(opt => opt.value)
          .join()
      : ''
    this.props.initialize({
      [IDENTIFIABLES_FIELD]: identifiables,
      [INITIAL_LAT_FIELD]: (initialLatLng && initialLatLng.lat) || '',
      [INITIAL_LNG_FIELD]: (initialLatLng && initialLatLng.lng) || '',
      [BUFFER_SRID_FIELD]: bufferSrid || '',
      [MEASURE_SRID_FIELD]: measureSrid || '',
      [AVL_TRANSMIT_ACTIVATED_DEFAULT]: avlTransmitActivatedDefault,
      [AVL_TRANSMIT_TOGGLE_ENABLED]: avlTransmitToggleEnabled,
      [AVL_REMOVE_FROM_MAP_AFTER_TIME]: avlRemoveFromMapAfterTime,
      [ALLOWS_SHARING_AVL_WITH]: selectedAllowsSharingAvlWith,
      [RECEIVES_SHARED_AVL_FROM]: receivesAvlFromOptions,
      [SHARE_AVL_WITH_CAD_CENTERS]: shareAvlWithCadOptions,
      [AVL_DISPLAY_GROUPS]: avlDisplayGroups,
    })
  }

  submitEditAgencyConfigForm = data => {
    // since allowsSharingAvlWith is saved in a separate firebase node, take care of this first
    // TODO: handle errors with this
    const newSharingStrings = data[ALLOWS_SHARING_AVL_WITH] ? data[ALLOWS_SHARING_AVL_WITH].split(',') : []

    newSharingStrings.forEach(agencyToShareWith =>
      this.props.saveAgencyAllowedSharing(this.props.agencyName, agencyToShareWith, true)
    )
    const oldAllowsSharingAvlWith = this.props.allowsSharingAvlWith
    if (oldAllowsSharingAvlWith) {
      oldAllowsSharingAvlWith
        .filter(agency => !newSharingStrings.includes(agency))
        .forEach(agency => this.props.saveAgencyAllowedSharing(this.props.agencyName, agency, false))
    }

    const lat = data[INITIAL_LAT_FIELD]
    const lng = data[INITIAL_LNG_FIELD]
    let initialLatLng
    if (!lat || !lng) {
      initialLatLng = null
    } else {
      initialLatLng = { lat: data[INITIAL_LAT_FIELD], lng: data[INITIAL_LNG_FIELD] }
    }

    let avlRemoveFromMapAfterTime = data[AVL_REMOVE_FROM_MAP_AFTER_TIME]
    if (['', undefined].includes(avlRemoveFromMapAfterTime)) {
      avlRemoveFromMapAfterTime = null
    }
    // create AgencyConfig object to ensure everything is cast properly,
    // particularly the srid values set to integers.
    const updatedAgencyConfig = new AgencyConfig({
      initialLatLng,
      identifiables: JSON.parse(data[IDENTIFIABLES_FIELD]),
      bufferSrid: data[BUFFER_SRID_FIELD],
      measureSrid: data[MEASURE_SRID_FIELD],
      avlTransmitActivatedDefault: data[AVL_TRANSMIT_ACTIVATED_DEFAULT],
      avlTransmitToggleEnabled: data[AVL_TRANSMIT_TOGGLE_ENABLED],
      avlRemoveFromMapAfterTime,
      receivesSharedAvlFrom: data[RECEIVES_SHARED_AVL_FROM] && data[RECEIVES_SHARED_AVL_FROM].split(','),
      shareAvlWithCad: data[SHARE_AVL_WITH_CAD_CENTERS] && data[SHARE_AVL_WITH_CAD_CENTERS].split(','),
      avlDisplayGroups: JSON.parse(data[AVL_DISPLAY_GROUPS]),
    })
      // immediately convert to plain JS since that's what Firebase expects
      .toFirebaseObject()
    return this.props.saveAgencyConfig(this.props.agencyName, updatedAgencyConfig).then(result => {
      if (result.error) {
        throw new SubmissionError({ _error: result.payload.message })
      }
      this.props.onSuccess()
    })
  }

  render() {
    const { error, handleSubmit, submitting, cadCenters, agencies, agencyAllowedSharing } = this.props
    const cadCenterOptions = cadCenters.toJS().map(c => {
      return {
        value: c.key,
        label: c.displayName,
      }
    })
    const agencyOptions = agencies.toJS().map(a => {
      return {
        value: a.key,
        label: a.displayName,
      }
    })
    const agencyAllowedSharingOptions = agencyAllowedSharing
      ? agencyOptions.filter(agency => agencyAllowedSharing.includes(agency.value))
      : []
    return (
      <form>
        <Row>
          <Field
            name={IDENTIFIABLES_FIELD}
            id={IDENTIFIABLES_FIELD}
            component={FormFieldTextArea}
            label="Identifiables:"
            help={`This takes a JSON object. Each key corresponds to a GIS
                   data table in the Spatialite DB, and each value is an array
                   of column names of that table that we want to show up in
                   the Identify tool`}
            rows={7}
          />
        </Row>
        <Row>
          <Field
            name={BUFFER_SRID_FIELD}
            id={BUFFER_SRID_FIELD}
            component={FormFieldInput}
            type="text"
            label="Buffer SRID:"
          />
        </Row>
        <Row>
          <Field
            name={MEASURE_SRID_FIELD}
            id={MEASURE_SRID_FIELD}
            component={FormFieldInput}
            type="text"
            label="Measure SRID:"
          />
        </Row>
        <Row>
          <Field
            name={INITIAL_LAT_FIELD}
            id={INITIAL_LAT_FIELD}
            component={FormFieldInput}
            type="text"
            label="Initial Latitude:"
          />
        </Row>
        <Row>
          <Field
            name={INITIAL_LNG_FIELD}
            id={INITIAL_LNG_FIELD}
            component={FormFieldInput}
            type="text"
            label="Initial Longitude:"
          />
        </Row>
        <Row>
          <Field
            name={AVL_TRANSMIT_ACTIVATED_DEFAULT}
            component={FormFieldInput}
            type="checkbox"
            label="AVL transmit activated by default:"
            labelColSize={8}
            help="Check this to have IV clients default to having AVL turned on. Default is OFF"
          />
        </Row>
        <Row>
          <Field
            name={AVL_TRANSMIT_TOGGLE_ENABLED}
            component={FormFieldInput}
            type="checkbox"
            label="Allow toggling AVL transmit:"
            labelColSize={8}
            help="Allow users to toggle whether they report their AVL location. Default is ON"
          />
        </Row>
        <Row>
          <Field
            name={AVL_REMOVE_FROM_MAP_AFTER_TIME}
            id={AVL_REMOVE_FROM_MAP_AFTER_TIME}
            component={FormFieldInput}
            type="text"
            label="AVL removal time:"
            help="Remove stale AVL locations from map after this many hours. Leaving blank will result in the default of 8 hours. Setting 0 will result in locations never being removed."
          />
        </Row>
        <Row>
          <Field
            name={ALLOWS_SHARING_AVL_WITH}
            component={FormFieldReactSelect}
            label="Allows sharing AVL with:"
            labelColSize={4}
            help="Allow other agencies to view this agency's AVL. Must be set by an AGI admin."
            options={agencyOptions}
            multi
            delimiter={DELIMITER}
          />
        </Row>
        <Row>
          <Field
            name={RECEIVES_SHARED_AVL_FROM}
            component={FormFieldReactSelect}
            type="select"
            label="Receives shared AVL from:"
            labelColSize={4}
            help="Set the visibility of AVL from other agencies who have shared their AVL with this agency. NOTE: The agencies can also set this themselves."
            options={agencyAllowedSharingOptions}
            multi
            noResultsText="No other agencies have shared their data with this agency."
            delimiter={DELIMITER}
          />
        </Row>
        <Row>
          <Field
            name={SHARE_AVL_WITH_CAD_CENTERS}
            component={FormFieldReactSelect}
            label="Share AVL with CAD centers:"
            labelColSize={4}
            help="Select which CAD centers this agency should share AVL with."
            options={cadCenterOptions}
            multi
            delimiter={DELIMITER}
          />
        </Row>
        {error && (
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row className="form-group">
          <Col sm={{ span: 6, offset: 6 }} className="text-center">
            <ButtonToolbar>
              <LoadingButton
                variant="primary"
                size="lg"
                label="Save"
                loading={submitting}
                loadingLabel="Saving"
                onClick={handleSubmit(this.submitEditAgencyConfigForm)}
                type="submit"
              />
              <Button className="ml-2" variant="outline-dark" onClick={this.props.onCancel} size="lg">
                Cancel
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    )
  }
}

export default compose(
  firebaseConnect((props, firebase) => ['/agencies/']),
  reduxForm({
    form: 'editAgencyConfig',
    validate: validateEditAgencyConfigForm,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditAgencyConfigForm)
