import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import queryString from 'query-string'
import FirebaseConnector from '../components/FirebaseConnector'
import DataGridWrapper from '../components/DataGridWrapper'
import * as devicesActions from '../actions/devices'
import { devicesRef } from '../firebase'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import DevicesDataGrid from '../containers/DevicesDataGrid'
import { getNumDevicesByAgency } from '../selectors/devicesSelectors'
import { getAgencies } from '../selectors'
import { Badge } from 'react-bootstrap'
import { FaExclamation } from 'react-icons/fa'
import { RouteComponentProps } from 'react-router-dom'

// const containerStyle = {
//   paddingLeft: '3rem',
//   paddingRight: '3rem',
// }
const licenseStyle: React.CSSProperties = {
  fontSize: 16,
  // paddingTop: '5.5rem',
  color: '#337ab7',
  fontWeight: 'bold',
  position: 'relative',
  // right: '55px',
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    numDevicesForUsersAgency: getNumDevicesByAgency(state)[state.auth.user.agencyName],
    agency: getAgencies(state) && getAgencies(state)[state.auth.user.agencyName],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadAllAgencyDevices: devicesActions.loadAllAgencyDevices,
      loadDevicesForAgency: devicesActions.loadDevicesForAgency,
    },
    dispatch
  )
}

type Props = {
  agency: Record<string, any>
  agencies: Record<string, any>
  user: Record<string, any>
  devices: Record<string, any>
  numDevicesByAgency: Record<string, any>
  numDevicesForUsersAgency: string | number // unsure what the correct type for this is
  loadAllAgencyDevices: Function
  loadDevicesForAgency: Function
} & RouteComponentProps

const DevicesView = (props: Props) => {
  const { user, agency, numDevicesForUsersAgency, loadAllAgencyDevices, loadDevicesForAgency } = props
  if (!user || !user.agencyName) {
    return <div /> // return nothing until we are passed a user with an agencyName
  }
  const urlQuery = queryString.parse(props.location.search)
  const agencyNameFilter = urlQuery.agencyName

  let refForDevices = devicesRef
  let devicesOnNodeChange = loadAllAgencyDevices
  if (user && !user.isAgi) {
    refForDevices = devicesRef.child(user.agencyName)
    devicesOnNodeChange = devices_ => loadDevicesForAgency(user.agencyName, devices_)
  }

  let leftHeader
  if (user.isAgi) {
    leftHeader = <AgencyFilterSelect agencyNameFilter={agencyNameFilter} location={props.location} />
  } else {
    if (agency && numDevicesForUsersAgency) {
      leftHeader = (
        <div style={licenseStyle}>
          License Total:
          {` ${numDevicesForUsersAgency} of ${agency.licenses} `}
          {numDevicesForUsersAgency > agency.licenses && (
            <Badge variant="danger">
              <FaExclamation />
            </Badge>
          )}
        </div>
      )
    }
  }
  return (
    <DataGridWrapper header="Devices" leftHeader={leftHeader}>
      <FirebaseConnector
        key={`devices`} // key lets react know to umount and mount component when changed
        firebaseRef={refForDevices}
        onNodeChange={devicesOnNodeChange}
      />
      <div
        style={{
          position: 'absolute',
          height: 'calc(100% - 10em)',
          bottom: '1.5rem',
          left: 0,
          right: 0,
        }}
      >
        <DevicesDataGrid location={props.location} history={props.history} />
      </div>
    </DataGridWrapper>
  )
}

export default compose(
  firebaseConnect((props, firebase) => {
    if (props.user && !props.user.isAgi) {
      return [`/agencies/${props.user.agencyName}`]
    }
    return []
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DevicesView)
