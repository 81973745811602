import * as types from '../constants/ActionTypes'

export function loadAllCadCenters(allCadCentersNode) {
  return {
    type: types.LOAD_ALL_CAD_CENTERS,
    payload: {
      allCadCentersNode,
    },
  }
}
