import { prodToDevDataCopyQueueRef } from '../firebase'

export const COPY_PROD_DATA_TO_DEV = 'COPY_PROD_DATA_TO_DEV'

function _copyProdDataToDevPromise() {
  const nodesToCopy = ['agencies', 'agencyConfigs']
  return new Promise((resolve, reject) => {
    prodToDevDataCopyQueueRef.child('tasks').push({ nodeNames: nodesToCopy }, error => {
      if (error) {
        reject(error)
      } else {
        resolve('Successfully started data copy')
      }
    })
  })
}

export function copyProdDataToDev() {
  return {
    type: COPY_PROD_DATA_TO_DEV,
    payload: _copyProdDataToDevPromise(),
  }
}
