import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { compose } from 'redux'
import { Alert, ButtonToolbar, Col, Row, Button, ButtonGroup } from 'react-bootstrap'
import { firestore, firebaseRoot } from '../firebase'
import { LoadingButton } from '../components/BootstrapReduxForm'
import AgencySelectField from './reduxFormFields/AgencySelectField'
import { withRouter, RouteComponentProps } from 'react-router-dom'

const AGENCY_NAME_FIELD = 'agencyName'

type Props = InjectedFormProps & RouteComponentProps

class ExportDataForm extends React.Component<Props, { showSpinner: boolean }> {
  constructor(props) {
    super(props)
    this.state = { showSpinner: false }
  }
  submitExportVectorTiles = async data => {
    const agencyName = data[AGENCY_NAME_FIELD]
    if (agencyName) {
      try {
        const dummyRef = await firebaseRoot.child('queues/exportVectorTiles/tasks').push()
        await firestore
          .collection('functions/exportVectorTiles/tasks')
          .doc(`${dummyRef.key}`)
          .set({
            agencyName,
            _startTime: new Date(),
          })
        this.props.history.push(`/gis/export?taskId=${dummyRef.key}`)
      } catch (error) {
        throw error
      }
    }
  }

  submitOldDbToS3 = async data => {
    const agencyName = data[AGENCY_NAME_FIELD]
    if (agencyName) {
      const removeSpinner = () => {
        this.setState({
          showSpinner: false,
        })
      }
      let updateObject = {
        [agencyName]: agencyName,
      }
      await firebaseRoot.child('queues/oldEditorToS3').update(updateObject)
      this.setState({
        showSpinner: true,
      })
      await firebaseRoot.child(`queues/oldEditorToS3/${agencyName}`).on('value', async function(snapshot) {
        if (snapshot.val() !== agencyName && snapshot.val() !== null) {
          removeSpinner()
          alert(
            `Successfully started geoJSON export from old system for ${agencyName}. You can follow the progress at ${snapshot.val()}`
          )
          await firebaseRoot.child(`queues/oldEditorToS3/${agencyName}`).remove()
        }
      })
    }
  }

  render() {
    const { error, handleSubmit, submitting } = this.props
    const { showSpinner } = this.state
    return (
      <form>
        <Col>
          <Row>
            <AgencySelectField name={AGENCY_NAME_FIELD} />
            {error && (
              <Col sm={{ span: 8, offset: 2 }}>
                <Alert variant="danger">{error}</Alert>
              </Col>
            )}
          </Row>
          <Row>
            <Col lg={{ span: 10, offset: 2 }}>
              <ButtonGroup>
                <Button variant="primary" className="mr-2" onClick={handleSubmit(this.submitOldDbToS3)}>
                  Old DB to JSON
                </Button>
                <LoadingButton
                  variant="primary"
                  onClick={handleSubmit(this.submitExportVectorTiles)}
                  label="Export Vector Tiles"
                  loading={submitting}
                  loadingLabel="Submitting"
                />
                {showSpinner && <img style={{ width: '50px' }} src="/spinner.gif" alt="Spinner..." />}
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
      </form>
    )
  }
}

export default compose(
  withRouter,
  reduxForm({
    form: 'exportData',
  })
)(ExportDataForm)
