import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Tabs, Tab, Card, Col } from 'react-bootstrap'
import { firebaseConnect } from 'react-redux-firebase'
import queryString from 'query-string'
import { loadAgencyDisplayName } from '../actions/agencies'
import { loadAgencyAllowedSharing } from '../actions/agencyAllowedSharing'
import { loadDevicesForAgency, clearAgencyDevices } from '../actions/devices'
import { loadGisDataSources } from '../actions/gisDataSources'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import FirebaseConnector from '../components/FirebaseConnector'
import ViewWrapper from '../components/ViewWrapper'
import EditDeviceGroupForm from '../containers/EditDeviceGroupForm'
import EditSpecialSearchForm from '../containers/EditSpecialSearchForm'
import EditResourceGroupForm from '../containers/EditResourceGroupForm'
import { agenciesRef, devicesRef, gisDataSourcesRef } from '../firebase'
import {
  getAgencyConfigJs,
  getAgencyNameUserOrUrl,
  getAgencyAllowedSharingAvl,
  getDispatchGroupsUsed,
  getAgenciesMap,
} from '../selectors'

import ConfigAvlForm from './ConfigAvlForm'

function mapStateToProps(state, ownProps) {
  return {
    user: state.auth.user,
    agencies: getAgenciesMap(state),
    agencyName: getAgencyNameUserOrUrl(state, ownProps),
    agencyConfig: getAgencyConfigJs(state, ownProps),
    agencyAllowedSharing: getAgencyAllowedSharingAvl(state, ownProps),
    dispatchGroupsUsed: getDispatchGroupsUsed(state, ownProps),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadAgencyAllowedSharing,
      loadAgencyDisplayName,
      loadDevicesForAgency,
      loadGisDataSources,
      clearAgencyDevices,
    },
    dispatch
  )
}

type Props = {
  user: Record<string, any>
  loadAgencyAllowedSharing: Function
  loadAgencyDisplayName: Function
  loadDevicesForAgency: Function
  dispatchGroupsUsed: boolean
  clearAgencyDevices: Function
  agencyName: string
  agencyConfig: Record<string, any>
  agencyAllowedSharing: any[]
  location: Record<string, any> // react-router location object
}

const AgencyAdminConfig = props => {
  const urlQuery = queryString.parse(props.location.search)
  return (
    <ViewWrapper
      header="Configuration"
      sizable
      leftHeader={
        props.user.isAgi && <AgencyFilterSelect agencyNameFilter={urlQuery.agencyName} location={props.location} />
      }
    >
      {props.agencyName && (
        <FirebaseConnector
          key={`gisDataSources-${props.agencyName}`}
          firebaseRef={gisDataSourcesRef.child(props.agencyName)}
          onNodeChange={props.loadGisDataSources}
          shouldReturnKey
        />
      )}
      {props.agencyName &&
        props.agencyAllowedSharing &&
        props.agencyAllowedSharing.concat([props.agencyName]).map(agency => {
          return (
            <div key={agency}>
              <FirebaseConnector
                key={`agencyDisplayName-${agency}`}
                firebaseRef={agenciesRef.child(agency).child('displayName')}
                onNodeChange={payload => props.loadAgencyDisplayName(agency, payload)}
              />
              <FirebaseConnector
                key={`devices-${agency}`}
                firebaseRef={devicesRef.child(agency)}
                onNodeChange={payload => props.loadDevicesForAgency(agency, payload)}
                onUnmount={() => props.clearAgencyDevices(agency)}
              />
            </div>
          )
        })}
      <Col>
        <Tabs defaultActiveKey={1} id={'config-tabs'}>
          <Tab eventKey={1} title="AVL">
            <div style={{ margin: '10px', textAlign: 'left' }}>
              <h3>Sharing</h3>

              <div
                style={{
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingLeft: '10px',
                  borderTop: '1px solid lightgray',
                  // borderBottom: '1px solid lightgray',
                }}
              >
                View and configure AVL Sharing settings.
              </div>
            </div>
            {props.agencyName && props.agencyAllowedSharing && props.agencyConfig ? (
              <ConfigAvlForm
                key={props.agencyName}
                agencyConfig={props.agencyConfig}
                agencyAllowedSharing={props.agencyAllowedSharing}
              />
            ) : (
              <img src="/spinner.gif" alt="Spinner..." />
            )}
            {/* avlTransmitActivatedDefault, avlTransmitToggleEnabled, and avlRemoveFromMapAfterTime */}
          </Tab>
          <Tab eventKey={2} title="Display Groups">
            <div style={{ margin: '10px', textAlign: 'left' }}>
              <h3>Display Groups</h3>
              <div style={{ marginLeft: '10px' }}>
                Organize devices into groups, and adjust AVL display settings for those devices.
              </div>
            </div>
            {props.agencyName && (
              <Card>
                <Card.Header>
                  <Card.Title>Edit Display Groups</Card.Title>
                </Card.Header>
                <Card.Body>
                  <EditDeviceGroupForm key={`editgroups-${props.agencyName}`} location={props.location} />
                </Card.Body>
              </Card>
            )}
          </Tab>
          <Tab eventKey={3} title="Resource Groups">
            <div style={{ margin: '10px', textAlign: 'left' }}>
              <h3>Resource Groups</h3>
              <div style={{ marginLeft: '10px' }}>
                {/* Organize devices into groups, and filter dispatches received by those devices. */}
                Assign one or more devices to one or more Resource Groups in order to filter dispatch messages sent to
                those devices. Devices not added to any Resource Group will receive all dispatch messages.
                <br />
                <br />
                <i style={{ color: 'gray' }}>
                  This option is only available if your Dispatch Center includes the resource/apparatus being dispatched
                  in the dispatch message sent to IncidentView.
                </i>
              </div>
            </div>

            {props.agencyName && (
              <Card>
                <Card.Header>
                  <Card.Title>Edit Dispatch Resource</Card.Title>
                </Card.Header>
                <Card.Body>
                  <EditResourceGroupForm key={`editgroups-${props.agencyName}`} location={props.location} />
                </Card.Body>
              </Card>
            )}
          </Tab>
          <Tab eventKey={4} title="Special Search">
            <div style={{ margin: '10px', textAlign: 'left' }}>
              <h3>Special Search</h3>
              <div style={{ marginLeft: '10px' }}>
                Designate special codes for the IncidentView search engine to quickly locate different features.
              </div>
            </div>

            {props.agencyName && props.user.isAgi && (
              <Card>
                <Card.Header>
                  <Card.Title>Configure Special Search Information</Card.Title>
                </Card.Header>
                <Card.Body>
                  <EditSpecialSearchForm
                    form={`EditSpecialSearchGroup_${props.agencyName}`}
                    key={`editgroups-${props.agencyName}`}
                    location={props.location}
                  />
                </Card.Body>
              </Card>
            )}
          </Tab>
        </Tabs>
      </Col>
    </ViewWrapper>
  )
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firebaseConnect((props, firebase) => {
    const { agencyName } = props
    let paths = []
    if (agencyName) {
      paths.push(`/agencyConfigs/${agencyName}`)
    }
  })
)(AgencyAdminConfig)
