import React from 'react'
import FormikGroup from './FormikGroup'
import SortableSelect from '../SortableSelect'
import { FieldProps } from 'formik'
import { Option } from '../../types'

type Props = {
  label: string
  options: Option[]
  sortOnly?: boolean
} & FieldProps

const FormikSortableSelect = (props: Props) => {
  const { field, options, form } = props
  const handleChange = (val: string[]) => {
    form.setFieldValue(field.name, val)
  }
  return (
    <FormikGroup {...props}>
      <SortableSelect
        value={field.value}
        onChange={handleChange}
        options={options}
        label={props.label}
        sortOnly={props.sortOnly}
      />
    </FormikGroup>
  )
}

export default FormikSortableSelect
