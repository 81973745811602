import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import Immutable from 'immutable'
import { saveUser } from '../actions/users'
import { Row, Col, Alert, Button, ButtonToolbar } from 'react-bootstrap'
import { FormFieldCheckbox, StaticField, LoadingButton } from '../components/BootstrapReduxForm'
import User from '../records/user'
import * as userRoles from '../constants/UserRoles'

const USER_ROLES_ARRAY = Object.values(userRoles)

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveUser,
    },
    dispatch
  )
}

type Props = {
  onSuccess: Function
  // TODO: figure out correct type
  onCancel: (event: any) => void
  onClickDelete: Function
  // user we're editing
  user: any
  saveUser: Function
} & InjectedFormProps

type State = {
  deleting: boolean
}

class EditUserForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { deleting: false }
  }

  componentWillMount() {
    const user = this.props.user
    this.loadExistingUserIntoForm(user)
  }

  /**
   * Load the input user object as the default inputs to the form.
   */
  loadExistingUserIntoForm(user) {
    const initialFormValues = {}
    for (const role of user.roles) {
      initialFormValues[role] = true
    }
    this.props.initialize(initialFormValues)
  }

  submitEditUserForm = data => {
    const rolesChild = Immutable.List(
      USER_ROLES_ARRAY.map(r => (data[r] ? r : null)).filter(v => v) // filter out null
    )
    const updatedUser = this.props.user.set('roles', rolesChild)
    // immediately convert to plain JS since that's what Firebase expects
    return this.props.saveUser(updatedUser).then(result => {
      if (result.error) {
        throw new SubmissionError({ _error: result.payload.message })
      }
      this.props.onSuccess()
    })
  }

  render() {
    const { user, error, handleSubmit, submitting } = this.props
    const { deleting } = this.state
    return (
      <form>
        <Row>
          <StaticField centered labelColSize={6} label="Email:" value={user.email} />
          <StaticField centered labelColSize={6} label="Agency Name:" value={user.agencyName} />
        </Row>
        <div>
          <h4>Roles</h4>
          {USER_ROLES_ARRAY.map(role => (
            <Row key={role}>
              <Field
                name={role}
                id={role}
                component={FormFieldCheckbox}
                label={role}
                disabled={role === userRoles.ROLE_SUPER_ADMIN}
              />
            </Row>
          ))}
        </div>
        {error && (
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row className="form-group">
          <Col sm={{ span: 6, offset: 6 }} className="text-center">
            <ButtonToolbar>
              <LoadingButton
                variant="primary"
                size="lg"
                label="Save"
                loading={submitting}
                loadingLabel="Saving"
                onClick={handleSubmit(this.submitEditUserForm)}
                type="submit"
              />
              <Button onClick={this.props.onCancel} size="lg">
                Cancel
              </Button>
            </ButtonToolbar>
          </Col>
          <Col xs={3}>
            <LoadingButton
              size="lg"
              variant="danger"
              label="Delete User"
              loading={deleting}
              loadingLabel="Deleting"
              onClick={async () => {
                this.setState({ deleting: true })
                try {
                  await this.props.onClickDelete()
                } catch (error) {
                  this.setState({ deleting: false })
                }
              }}
            />
          </Col>
        </Row>
      </form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'editUser',
  })(EditUserForm)
)
