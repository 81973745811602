import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { FaPen } from 'react-icons/fa'
import Immutable from 'immutable'
import { getGisDataSources } from '../selectors'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import SortableDataGrid from '../components/FixedDataTable/SortableDataGrid'

const columns = [
  {
    key: 'editButton',
    name: 'Edit',
    fixed: true,
    button: {
      value: <FaPen />,
    },
    width: 45,
  },
  {
    key: 'dataSourceName',
    name: 'Data Source Type',
    width: 140,
  },
  {
    key: 'displayName',
    name: 'Display Name',
    width: 140,
  },
  {
    key: 'displayField',
    name: 'Display Field',
    width: 130,
  },
  {
    key: 'editable',
    name: 'Editable',
    width: 90,
  },
  {
    key: 'mapboxTileset',
    width: 140,
  },
  {
    key: 'mapboxTilesetLayer',
    width: 200,
  },
  {
    key: 'postGisFeatureSet',
    width: 200,
  },
]

const columnsForCustomers = ['dataSourceName', 'displayName', 'editable', 'identifiable', 'searchable']
const columnsForAgencyAdmins = [
  'editButton',
  'dataSourceName',
  'displayName',
  'editable',
  'identifiable',
  'searchable',
  'mapboxTileset',
  'mapboxTilesetLayer',
  'postGisFeatureSet',
]

const mapStateToProps = (state, ownProps) => {
  const authUser = state.auth.user
  const sources = getGisDataSources(state)
  let agencyDataSources = []
  if (sources && sources[ownProps.agencyName]) {
    agencyDataSources = Object.entries(sources[ownProps.agencyName]).map(([dataSourceName, dataSource]) => {
      return { dataSourceName, ...dataSource }
    })
  }

  return {
    user: authUser,
    dataSources: Immutable.List(agencyDataSources),
    sortAttribute: state.devices.sorting.get('attributeName'),
    sortDirection: state.devices.sorting.get('sortDirection'),
  }
}

type Props = {
  agencyName: string
  user: Record<string, any>
  dataSources: Record<string, any>
} & RouteComponentProps

class GisLayersDataGrid extends React.Component<Props> {
  render() {
    const { agencyName, user } = this.props
    const dataSources = this.props.dataSources || Immutable.List([])
    if (!agencyName && user.isSuperAdmin) {
      return <h4>Please select an agency to view their GIS data sources.</h4>
    }

    if (dataSources.size === 0) {
      return <h4>This agency has no GIS data sources.</h4>
    }
    let cols: any[] = columns
    if (!user.isAgi) {
      if (user.isAgencyAdmin) {
        cols = columns
          .filter(col => columnsForAgencyAdmins.includes(col.key))
          .sort((a, b) => columnsForAgencyAdmins.indexOf(a.key) - columnsForAgencyAdmins.indexOf(b.key))
      } else {
        cols = columns
          .filter(col => columnsForCustomers.includes(col.key))
          .sort((a, b) => columnsForCustomers.indexOf(a.key) - columnsForCustomers.indexOf(b.key))
      }
    } else if (this.props.agencyName) {
      // modify the edit button onClick to persist the agencyName in the URL
      cols[0].button.onClick = dataRow =>
        this.props.history.push(`/gis/${this.props.agencyName}/${dataRow.dataSourceName}`)
    }

    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          paddingTop: '1rem',
        }}
      >
        <SortableDataGrid dataList={dataSources} columns={cols} />
      </div>
    )
  }
}

export default compose(
  firebaseConnect(props => [`/gisDataSources/${props.agencyName}/`]),
  withRouter,
  connect(
    mapStateToProps,
    null
  )
)(GisLayersDataGrid)
