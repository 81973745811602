import { LOAD_AGENCY_CONFIG } from '../constants/ActionTypes'
import Immutable from 'immutable'
import AgencyConfig from '../records/agencyConfig'

export const AgencyConfigsState = Immutable.Record({
  // eslint-disable-line new-cap
  data: Immutable.Map(),
})

const initialState = new AgencyConfigsState()

export default function reducer(state = initialState, action: { type?: string; payload?: any } = {}) {
  switch (action.type) {
    case LOAD_AGENCY_CONFIG:
      const { key, ...restOfAgencyConfigObj } = action.payload.agencyConfig
      // if the payload only has 'key', and no other attributes, data doesn't exist in Firebase
      let agencyConfig = null
      if (Object.keys(restOfAgencyConfigObj).length > 0) {
        agencyConfig = new AgencyConfig(action.payload.agencyConfig)
      }
      return state.update('data', map => map.set(key, agencyConfig))
    default:
      return state
  }
}
