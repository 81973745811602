import React from 'react'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ViewWrapper from '../components/ViewWrapper'

export default class ResetPasswordSuccessView extends React.Component {
  render() {
    return (
      <ViewWrapper header="Password successfully reset">
        <div>
          <p>Please check your email inbox for instructions to change your password.</p>
        </div>
        <LinkContainer to="/">
          <Button variant="primary" size="lg">
            Back to home
          </Button>
        </LinkContainer>
      </ViewWrapper>
    )
  }
}
