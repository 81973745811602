import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, ButtonToolbar, ButtonGroup, Col } from 'react-bootstrap'
import { FaPaperPlane } from 'react-icons/fa'
import FirebaseConnector from '../components/FirebaseConnector'
import ViewWrapper from '../components/ViewWrapper'
import DataGridWrapper from '../components/DataGridWrapper'
import * as dispatchesActions from '../actions/dispatches'
import { dispatchesRef } from '../firebase'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import DispatchesDataGrid from '../containers/DispatchesDataGrid'
import queryString from 'query-string'
import { RouteComponentProps } from 'react-router-dom'

function mapStateToProps(state) {
  return {
    dispatches: state.dispatches.data,
    user: state.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...dispatchesActions }, dispatch)
}

type Props = {
  dispatches: Record<string, any>
  user: Record<string, any>
  loadDispatchesForAgency: Function
  loadDispatches: Function
} & RouteComponentProps

const DispatchesView = (props: Props) => {
  const { dispatches, loadDispatchesForAgency } = props
  const urlQuery = queryString.parse(props.location.search)
  const agencyNameFilter = urlQuery.agencyName

  const agencyName = props.user.isAgi ? agencyNameFilter : props.user.agencyName

  const agencyDispatches = dispatches.get(agencyName)

  let filteredDispatchesRef
  if (agencyName) {
    filteredDispatchesRef = dispatchesRef
      .child(agencyName)
      .orderByChild('timeStamp')
      .limitToLast(500)
  }

  // Partially apply action with agencyName
  const loadDispatchesForSelectedAgency = _dispatches => loadDispatchesForAgency(agencyName, _dispatches)

  return (
    <DataGridWrapper
      header="Dispatches"
      leftHeader={
        props.user.isAgi && <AgencyFilterSelect agencyNameFilter={agencyNameFilter} location={props.location} />
      }
    >
      {filteredDispatchesRef && (
        <FirebaseConnector
          key={`dispatches-${agencyName}`} // key lets react know to umount and mount component when changed
          firebaseRef={filteredDispatchesRef}
          onNodeChange={loadDispatchesForSelectedAgency}
          asArray
        />
      )}
      {agencyDispatches && (
        // TODO: currently only showing a few attributes - show the rest later
        //       in a better table viewer/data grid
        <div
          style={{
            position: 'absolute',
            height: 'calc(100% - 12em)',
            // bottom: '1.5rem',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: '2rem',
            paddingRight: '2rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
              marginBottom: '5px',
              paddingRight: '2rem',
            }}
          >
            <ButtonToolbar>
              <ButtonGroup>
                {agencyName && (props.user.isSuperAdmin || props.user.isAgencyAdmin) && (
                  // TODO: Add " || props.user.isAgencyAdmin" back after it has been tested
                  <Button
                    variant="primary"
                    onClick={() => {
                      // console.log(agencyName)
                      props.history.push(`/dispatches/${agencyName}/send`)
                    }}
                  >
                    Manually send dispatch <FaPaperPlane />
                  </Button>
                )}
                {agencyName && (props.user.isSuperAdmin || props.user.isAgencyAdmin) && (
                  <Button
                    className="ml-2"
                    variant="primary"
                    onClick={() => {
                      if (props.location && urlQuery && urlQuery.agencyName !== undefined) {
                        props.history.push(`/dispatches/export?agencyName=${urlQuery.agencyName}`)
                      } else {
                        props.history.push('/dispatches/export')
                      }
                    }}
                  >
                    Export to CSV
                  </Button>
                )}
              </ButtonGroup>
            </ButtonToolbar>
          </div>
          <DispatchesDataGrid location={props.location} />
        </div>
      )}
    </DataGridWrapper>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DispatchesView)
