import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, formValues, Field, InjectedFormProps } from 'redux-form'
import { firebaseConnect } from 'react-redux-firebase'
import firebase, { firestore } from '../firebase'
import { Row, Col, Alert, Button, ButtonToolbar } from 'react-bootstrap'
import { FormFieldInput, FormFieldReactSelect, LoadingButton } from '../components/BootstrapReduxForm'
import { getAgencies } from '../selectors'
import _ from 'lodash'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  client,
  GET_DATA_SHARING,
  ADD_SHARING,
  REMOVE_SHARING,
  GET_DATA_SOURCE_NAMES_AND_FEATURE_SET_GUIDS,
} from '../apollo'

const CONSUMING_AGENCY_FIELD = 'consumingField'
const FROM_AGENCY_FIELD = 'agencySharing'
const DATA_SHARED_FIELD = 'dataShared'

function mapStateToProps(state) {
  const agencies = getAgencies(state)
  const formValuesForEditing = state.form.shareGisData.values
  return {
    agencies,
    formValuesForEditing,
  }
}

function validateShareGisDataForm(data) {
  let errors = {}
  if (!data[CONSUMING_AGENCY_FIELD]) {
    errors[CONSUMING_AGENCY_FIELD] = 'Please select an agency'
  } else if (!data[FROM_AGENCY_FIELD]) {
    errors[FROM_AGENCY_FIELD] = 'Please select an agency'
  }
  return errors
}

type Props = {
  agencies: any
  agencyReceiving: any
  agencySharing: any
  formValuesForEditing: any
  editingForm: any
} & InjectedFormProps &
  RouteComponentProps

const ShareGisDataForm = (props: Props) => {
  const { agencies, error, change, submitting, handleSubmit, submitFailed, formValuesForEditing } = props
  const [dataSources, setDataSources] = useState([])
  const [dataAlreadyShared, setDataAlreadyShared] = useState([])
  const [submittedTime, setSubmittedTime] = useState(0)

  useEffect(() => {
    const fetchDataSourceNamesAndGuids = async agencySharing => {
      const featureSetQueryResult = await client.query({
        query: GET_DATA_SOURCE_NAMES_AND_FEATURE_SET_GUIDS,
        variables: {
          agencyName: agencySharing,
        },
      })
      if (
        featureSetQueryResult &&
        featureSetQueryResult.data &&
        featureSetQueryResult.data.feature_set &&
        featureSetQueryResult.data.feature_set.length
      ) {
        const dataSources = featureSetQueryResult.data.feature_set.map(dataSource => ({
          label: dataSource.data_source_name,
          value: dataSource.guid,
        }))
        setDataSources(dataSources)
      } else {
        setDataSources([])
      }
    }
    if (props.agencySharing) {
      fetchDataSourceNamesAndGuids(props.agencySharing)
    }
  }, [props.agencySharing])

  useEffect(() => {
    if (props.agencyReceiving && dataSources && dataSources.length) {
      const owningAgencyGuids = dataSources.map(e => e.value)
      const fetchExistingSharing = async (agencyName, guids) => {
        const sharingQueryResult = await client.query({
          query: GET_DATA_SHARING,
          variables: {
            consumingAgency: agencyName,
            owningAgencyGuids: guids,
          },
          fetchPolicy: 'network-only',
        })
        if (
          sharingQueryResult &&
          sharingQueryResult.data &&
          sharingQueryResult.data.rel_agency_feature_set &&
          sharingQueryResult.data.rel_agency_feature_set.length
        ) {
          let sharedData = []
          sharingQueryResult.data.rel_agency_feature_set.forEach(entry => sharedData.push(entry.feature_set_guid))
          setDataAlreadyShared(sharedData)
          const fieldValue = sharedData.length && sharedData.join(',')
          change(DATA_SHARED_FIELD, fieldValue)
        } else {
          setDataAlreadyShared([])
          change(DATA_SHARED_FIELD, null)
        }
      }
      fetchExistingSharing(props.agencyReceiving, owningAgencyGuids)
    } else {
      setDataAlreadyShared([])
      change(DATA_SHARED_FIELD, null)
    }
  }, [props.agencyReceiving, dataSources, submittedTime])

  const submitShareGisDataForm = async data => {
    let featureSetGuids
    if (data[DATA_SHARED_FIELD]) {
      featureSetGuids = data[DATA_SHARED_FIELD].split(',')
    }
    try {
      // TODO: when migrate away from firestore featureSets, remove below logic
      const batch = firestore.batch()
      if (dataAlreadyShared && dataAlreadyShared.length) {
        for (const sharedData of dataAlreadyShared) {
          if (
            (featureSetGuids && featureSetGuids.length && !featureSetGuids.includes(sharedData)) ||
            !featureSetGuids
          ) {
            await client.mutate({
              mutation: REMOVE_SHARING,
              variables: {
                featureSetId: sharedData,
                agencyName: data[CONSUMING_AGENCY_FIELD],
              },
            })
            const featuresetRef = firestore.collection('featureSets').doc(sharedData)
            batch.update(featuresetRef, {
              agenciesThatCanRead: firebase.firestore.FieldValue.arrayRemove(data[CONSUMING_AGENCY_FIELD]),
            })
          }
        }
      }
      if (featureSetGuids && featureSetGuids.length) {
        for (const id of featureSetGuids) {
          if (!dataAlreadyShared.includes(id)) {
            await client.mutate({
              mutation: ADD_SHARING,
              variables: {
                featureSetId: id,
                agencyName: data[CONSUMING_AGENCY_FIELD],
                canRead: true,
              },
            })
            const featuresetRef = firestore.collection('featureSets').doc(id)
            batch.update(featuresetRef, {
              agenciesThatCanRead: firebase.firestore.FieldValue.arrayUnion(data[CONSUMING_AGENCY_FIELD]),
            })
          }
        }
      }
      await batch.commit()
      window.alert('Sharing update success!')
    } catch (e) {
      console.warn(e)
      alert(e.message)
    } finally {
      setSubmittedTime(Date.now())
    }
  }
  const editingCancel = () => {
    props.history.push('/gis')
  }

  return (
    <form>
      <Row>
        <Field
          name={CONSUMING_AGENCY_FIELD}
          component={FormFieldInput}
          type="select"
          label="Consuming agency"
          help="The agency that will be viewing another agency's data"
        >
          <option key="" value="">
            ----------------
          </option>
          {agencies &&
            Object.keys(agencies).map(agencyName => (
              <option key={agencyName} value={agencyName}>
                {agencyName}
              </option>
            ))}
        </Field>
      </Row>
      <Row>
        <Field
          name={FROM_AGENCY_FIELD}
          component={FormFieldInput}
          type="select"
          label="From Agency"
          help="The agency whose data is being viewed by the consuming agency"
        >
          <option key="" value="">
            ----------------
          </option>
          {agencies &&
            Object.keys(agencies).map(agencyName => (
              <option key={agencyName} value={agencyName}>
                {agencyName}
              </option>
            ))}
        </Field>
      </Row>
      <Row>
        {/* {this.state.editingForm ? (       // Uncomment below once there is a need for shared editing configuration. 
            <div>
              <h4>Feature Sets</h4>
              A checked box means that {formValuesForEditing.consumingField} can edit that feature
              {formValuesForEditing.dataShared.split(',').map(feature => (
                <Row key={feature}>
                  <Field
                    name={feature}
                    id={feature}
                    component={FormFieldCheckbox}
                    label={feature
                      .slice(0, -6)
                      .replace(formValuesForEditing.agencySharing, '')
                      .replace(/_/g, '')}
                  // checked={}
                  // disabled={role === userRoles.ROLE_SUPER_ADMIN}
                  />
                </Row>
              ))}
            </div>
          ) : ( */}
        <Field
          name={DATA_SHARED_FIELD}
          component={FormFieldReactSelect}
          type="text"
          label="Shared data"
          help="The gis layers that are being shared"
          options={dataSources}
          multi
        />
      </Row>
      <Row>This sharing form is only for data that is in the 'new system'</Row>
      <Row>
        {dataAlreadyShared && (
          <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              onClick={() => window.alert('This Functionality Is Not Yet Available')}
              size="lg"
              variant="info"
              disabled
            >
              Click Here To Set Up Shared Feature Editability
            </Button>
          </ButtonToolbar>
        )}
      </Row>
      {error && (
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      {submitFailed && (
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <Alert variant="danger">The form failed to submit </Alert>
          </Col>
        </Row>
      )}
      <Row className="form-group">
        <Col sm={{ offset: 3 }} className="text-center">
          <LoadingButton
            variant="primary"
            size="lg"
            label="Save"
            loadingLabel="Saving"
            loading={submitting}
            type="submit"
            onClick={handleSubmit(submitShareGisDataForm)}
            disabled={props.agencyReceiving === props.agencySharing ? true : false}
          />
          <Button onClick={() => editingCancel()} size="lg">
            Cancel
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export default compose(
  withRouter,
  reduxForm({
    form: 'shareGisData',
    validate: validateShareGisDataForm,
  }),
  formValues({
    agencySharing: FROM_AGENCY_FIELD,
    agencyReceiving: CONSUMING_AGENCY_FIELD,
    dataShared: DATA_SHARED_FIELD,
  }),
  firebaseConnect(firebase => ['/agencies/']),
  connect(mapStateToProps)
)(ShareGisDataForm)
