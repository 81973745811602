import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import SortableDataGrid from '../components/FixedDataTable/SortableDataGrid'
import * as dispatchesActions from '../actions/dispatches'
import { getDispatchListForAgencySorted } from '../selectors/dispatchesSelectors'

export const columns = [
  {
    key: 'formattedTime',
    name: 'Time',
    isDate: true,
    sortable: true,
    width: 180,
  },
  {
    key: 'raw',
    name: 'Raw Dispatch Text',
    sortable: true,
    width: 300,
  },
  {
    key: 'category',
    name: 'Category',
    sortable: true,
    width: 90,
  },
  {
    key: 'street',
    name: 'Street',
    sortable: true,
    width: 200,
  },
  {
    key: 'city',
    name: 'City',
    sortable: true,
    width: 120,
  },
  {
    key: 'building',
    name: 'Place',
    sortable: true,
    width: 150,
  },
  {
    key: 'crossStreet',
    name: 'Cross Street',
    sortable: true,
    width: 150,
  },
  {
    key: 'dispatchUnits',
    name: 'Resources',
    sortable: true,
    width: 300,
  },
  {
    key: 'shortDesc',
    name: 'Short Description',
    sortable: true,
    width: 200,
  },
  {
    key: 'longDesc',
    name: 'Long Description',
    sortable: true,
    width: 200,
  },
  {
    key: 'dispatchLat',
    name: 'Dispatch Latitude',
    sortable: true,
    width: 100,
  },
  {
    key: 'dispatchLng',
    name: 'Dispatch Longitude',
    sortable: true,
    width: 100,
  },
  {
    key: 'specialSearchTable',
    name: 'Special Search Table',
    sortable: true,
    width: 200,
  },
  {
    key: 'specialSearchValue',
    name: 'Special Search Value',
    sortable: true,
    width: 200,
  },
  {
    key: 'fromEmail',
    name: 'Received From Email',
    sortable: true,
    width: 200,
  },
  {
    key: 'id',
    name: 'Dispatch Id',
    width: 200,
  },
  {
    key: 'key',
    name: 'Key',
    width: 200,
  },
]

export const columnsForCustomers = [
  'formattedTime',
  'raw',
  'category',
  'street',
  'city',
  'building',
  'dispatchUnits',
  'crossStreet',
  'shortDesc',
  'longDesc',
  'dispatchLat',
  'dispatchLng',
]

const mapStateToProps = (state, ownProps) => {
  const authUser = state.auth.user
  return {
    user: authUser,
    dispatches: getDispatchListForAgencySorted(state, ownProps),
    sortAttribute: state.dispatches.sorting.get('attributeName'),
    sortDirection: state.dispatches.sorting.get('sortDirection'),
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...dispatchesActions }, dispatch)
}

type Props = {
  user: Record<string, any>
  dispatches: Record<string, any>
  sortAttribute: string
  sortDirection: string
  sortDispatches: Function
  location: Record<string, any>
}

const DispatchesDataGrid = (props: Props) => {
  let filteredColumns = columns
  if (!props.user.isAgi) {
    filteredColumns = columns
      .filter(col => columnsForCustomers.includes(col.key))
      .sort((a, b) => columnsForCustomers.indexOf(a.key) - columnsForCustomers.indexOf(b.key))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        height: 'calc(100% - 3rem)',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }}
    >
      <SortableDataGrid
        dataList={props.dispatches}
        columns={filteredColumns}
        sortAttribute={props.sortAttribute}
        sortDirection={props.sortDirection}
        onSort={props.sortDispatches}
      />
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DispatchesDataGrid)
