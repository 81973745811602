import React from 'react'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ViewWrapper from '../components/ViewWrapper'

// const wellStyle = {maxWidth: 400, margin: '0 auto 10px'}

type Props = {
  user: Record<string, any>
}

const HomeView = ({ user }: Props) => {
  return (
    <ViewWrapper header="Welcome to the IncidentView admin site">
      {(user.isAgi || user.isAgencyAdmin) && (
        <LinkContainer to="/devices">
          <Button size="lg" block>
            Devices
          </Button>
        </LinkContainer>
      )}
      <LinkContainer to="/login">
        <Button variant="outline-dark" size="lg" block>
          Account
        </Button>
      </LinkContainer>
      {user.isAgencyAdmin && (
        <LinkContainer to="/contact-email">
          <Button size="lg" variant="success" block>
            Update Agency Contact Email
          </Button>
        </LinkContainer>
      )}
    </ViewWrapper>
  )
}

export default HomeView
