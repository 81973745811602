import React, { Component } from 'react'
import { Row, Col, Button, ButtonToolbar } from 'react-bootstrap'
import { FaUser } from 'react-icons/fa'
import { LinkContainer } from 'react-router-bootstrap'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { SimpleBootstrapInput } from '../components/BootstrapReduxForm'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { resetPassword } from '../actions/auth'

const EMAIL_FIELD = 'email'

function mapStateToProps(state) {
  return { user: state.auth.user }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetPassword }, dispatch)
}

type Props = {
  user: Record<string, any>
  resetPassword: Function
} & InjectedFormProps &
  RouteComponentProps

class ResetPasswordForm extends Component<Props> {
  componentWillMount() {
    this.props.initialize({ [EMAIL_FIELD]: '' })
  }

  submitResetPasswordForm = data => {
    return this.props.resetPassword(data[EMAIL_FIELD]).then(result => {
      if (result.error) {
        throw new SubmissionError({ [EMAIL_FIELD]: result.payload.message })
      }
      this.props.history.push('/reset-password/success')
    })
  }

  render() {
    const { user, handleSubmit, submitting } = this.props
    return (
      <div>
        {!user && (
          <div>
            <Row>
              <form onSubmit={handleSubmit(this.submitResetPasswordForm)}>
                <Field
                  name={EMAIL_FIELD}
                  component={SimpleBootstrapInput}
                  hasFeedback
                  size="lg"
                  type="email"
                  placeholder="email"
                  addonBefore={<FaUser />}
                />
              </form>
            </Row>
            <Row>
              <Col sm={{ offset: 3 }} className="text-center">
                <ButtonToolbar>
                  <Button
                    variant="success"
                    size="lg"
                    disabled={submitting}
                    onClick={handleSubmit(this.submitResetPasswordForm)}
                  >
                    Reset Password
                  </Button>
                  <LinkContainer to="/login">
                    <Button size="lg">Back</Button>
                  </LinkContainer>
                </ButtonToolbar>
              </Col>
            </Row>
          </div>
        )}
        {user && (
          <div>
            <p>You are currently logged in as {user.email}.</p>
          </div>
        )}
      </div>
    )
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'resetPassword',
  })
)(ResetPasswordForm)
