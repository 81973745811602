import React, { Fragment, useState, useEffect, useRef } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Col, Row, FormControl, Button, Card, Collapse } from 'react-bootstrap'
import { FaChevronDown, FaChevronRight, FaSort } from 'react-icons/fa'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { firestoreConnect } from 'react-redux-firebase'
import { reduxForm, formValues, Field, FieldArray, SubmissionError } from 'redux-form'
import { InjectedFormProps, WrappedFieldArrayProps } from 'redux-form'
import _ from 'lodash'
import AJV from 'ajv'
import { client, UPDATE_FEATURE_SET_FEATURE_SCHEMA } from '../apollo'
import { getFeatureSets, getFeatureSetsArray, getUser } from '../selectors'
import {
  FormFieldInput,
  FormFieldReactSelect,
  EnumField,
  FormFieldCheckbox,
  LoadingButton,
} from '../components/BootstrapReduxForm'
import { newRand } from '../utils'
import './EditFeatureSetsForm.css'

const attachmentType = 'attachment'
const noEnumTypes = [attachmentType, 'boolean']

const propertyTypeOptions = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'integer', label: 'Integer' },
  { value: 'boolean', label: 'Boolean' },
  { value: attachmentType, label: 'Attachment' },
]

const booleanOptions = [{ value: 'true', label: 'True' }, { value: 'false', label: 'False' }]

const widgetOptions = [
  { value: 'standard', label: 'Standard' },
  { value: 'readonly', label: 'Read Only' },
  { value: 'hidden', label: 'Hidden' },
]

const attachmentPropertySchema = {
  properties: {
    attachmentGuid: {
      type: 'string',
    },
    fileName: {
      type: 'string',
    },
    fileType: {
      type: 'string',
    },
  },
  type: 'object',
}

const SortableItemWrapper = SortableElement(props => <>{props.children}</>)
const SortableContainerWrapper = SortableContainer(props => <div>{props.children}</div>)

const propertiesFields = ({ schemaString, fields, formVals, meta: { error, submitted } }: any) => {
  const initialOpenKeys: { [key: string]: boolean } = {}
  const [openKeys, setOpenKeys] = useState(initialOpenKeys)
  const [isSorting, setIsSorting] = useState(false)

  useEffect(() => {
    setOpenKeys(initialOpenKeys)
    setIsSorting(false)
  }, [schemaString])

  const toggleCollapseByKey = (key: string) => {
    if (openKeys[key] !== true) {
      setOpenKeys({ ...openKeys, [key]: true })
    }
    if (openKeys[key] === true) {
      setOpenKeys({ ...openKeys, [key]: false })
    }
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      fields.move(oldIndex, newIndex)
    }
  }
  const toggleSorting = () => {
    if (isSorting) {
      setIsSorting(false)
    }
    if (!isSorting) {
      setOpenKeys(initialOpenKeys)
      setIsSorting(true)
    }
  }
  const CardWrapper: any = isSorting ? SortableItemWrapper : 'div'
  return (
    <>
      <Card style={{ textAlign: 'left' }}>
        <Card.Header>
          <Button variant="primary" onClick={() => fields.push({ tempId: newRand(), isNew: true })}>
            Add Property
          </Button>
          <Button variant={isSorting ? 'info' : 'primary'} style={{ marginLeft: 8 }} onClick={toggleSorting}>
            {isSorting ? 'Stop Sorting' : 'Start Sorting'}
          </Button>
        </Card.Header>
      </Card>
      <SortableContainerWrapper onSortEnd={onSortEnd}>
        {fields.map((property, index) => {
          const { tempId, key: propertyKey, type: propertyType, required: isRequired, isNew, widget } = formVals[index]
          const shouldDisplay = openKeys[tempId] === true
          const iconStyle = { paddingRight: '4px' }
          const renderIcon = () => {
            if (shouldDisplay) {
              return <FaChevronDown style={iconStyle} />
            }
            if (isSorting) {
              return <FaSort style={iconStyle} />
            }
            return <FaChevronRight style={iconStyle} />
          }
          return (
            <CardWrapper index={index} key={tempId}>
              <Card key={tempId} className={isSorting ? 'panel--sorting' : null}>
                <Card.Header onClick={() => toggleCollapseByKey(tempId)}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div>
                      {renderIcon()}
                      {shouldDisplay ? '' : propertyKey || 'New Property'}
                    </div>
                    <Button variant="danger" size="sm" onClick={() => fields.remove(index)}>
                      Delete
                    </Button>
                  </div>
                </Card.Header>
                <Collapse in={shouldDisplay}>
                  <div>
                    <Card.Body>
                      <Field
                        name={`${property}.key`}
                        component={FormFieldInput}
                        type="text"
                        label="Key:"
                        disabled={!isNew}
                      />
                      <Field name={`${property}.title`} component={FormFieldInput} type="text" label="Title:" />
                      <Field
                        name={`${property}.type`}
                        component={FormFieldReactSelect}
                        options={propertyTypeOptions}
                        label="Type:"
                      />
                      <Field
                        name={`${property}.widget`}
                        component={FormFieldReactSelect}
                        options={widgetOptions}
                        label="Widget:"
                      />
                      {!noEnumTypes.includes(propertyType) && widget === 'standard' ? (
                        <Field name={`${property}.enum`} component={EnumField} label="Enum:" />
                      ) : null}
                      <Field name={`${property}.required`} component={FormFieldCheckbox} label="Required:" />
                      {isRequired && propertyType !== 'boolean' && (
                        <Field
                          name={`${property}.replaceNull`}
                          component={FormFieldInput}
                          type="text"
                          label="Replace null with:"
                        />
                      )}
                      {isRequired && propertyType === 'boolean' && (
                        <Field
                          name={`${property}.replaceNull`}
                          component={FormFieldReactSelect}
                          options={booleanOptions}
                          label="Replace null with:"
                        />
                      )}
                    </Card.Body>
                  </div>
                </Collapse>
              </Card>
            </CardWrapper>
          )
        })}
      </SortableContainerWrapper>
    </>
  )
}

type Props = {
  agencyName: string
  featureSets: any
  featureSetsArray: any[]
  formVals: any
  user: any
} & InjectedFormProps

const initialFeatureSetId: string = undefined

const mapStateToProps = state => {
  const formVals = _.get(state, ['form', 'editFeatureSet', 'values', 'properties'])
  return {
    featureSets: getFeatureSets(state),
    featureSetsArray: getFeatureSetsArray(state),
    user: getUser(state),
    formVals,
  }
}

const getInitialValues = (schema: string) => {
  const { properties, required, attachmentPropertyNames, uiSchema } = JSON.parse(schema)
  const uiOrder: string[] = _.get(uiSchema, 'ui:order', [])
  const otherProperties = Object.keys(properties).filter(key => !uiOrder.includes(key))
  const orderedProperties = uiOrder.concat(otherProperties)
  const requiredArray = required || []
  const attachmentPropertyNamesArray = attachmentPropertyNames || []
  return orderedProperties.map(key => {
    return {
      key,
      tempId: newRand(),
      required: requiredArray.includes(key),
      ...properties[key],
      type: attachmentPropertyNamesArray.includes(key) ? attachmentType : properties[key].type,
      widget: _.get(uiSchema, [key, 'ui:widget'], 'standard'),
    }
  })
}

const formValsToObj = (formVals: any[]) => {
  if (!formVals) return undefined
  const properties = formVals.reduce((acc, property) => {
    const { key, tempId, required, replaceNull, isNew, widget, ...otherProps } = property
    for (const property in otherProps) {
      if (otherProps[property] === null || otherProps[property] === undefined) {
        delete otherProps[property]
      }
    }
    const numberTypes = ['number', 'integer']
    if (otherProps.enum && numberTypes.includes(otherProps.type)) {
      const numberOptions = otherProps.enum.map(opt => Number(opt))
      otherProps.enum = numberOptions
    }
    acc[key] = { ...otherProps }
    return acc
  }, {})
  const schemaObj: any = { properties }

  if (formVals.some(val => val.required)) {
    const requiredVals = []
    for (const value of formVals) {
      if (value.required) {
        requiredVals.push(value.key)
      }
    }
    schemaObj.required = requiredVals
  }

  if (formVals.some(val => val.type === attachmentType)) {
    const attachmentPropertyNames = []
    for (const value of formVals) {
      if (value.type === attachmentType) {
        schemaObj.properties[value.key].type = 'array'
        schemaObj.properties[value.key].items = attachmentPropertySchema
        delete schemaObj.properties[value.key].enum
        attachmentPropertyNames.push(value.key)
      }
    }
    schemaObj.attachmentPropertyNames = attachmentPropertyNames
  }
  const uiSchema = {}
  uiSchema['ui:order'] = formVals.filter(val => val.widget !== 'hidden').map(val => val.key)

  for (const val of formVals) {
    if (val.widget !== 'standard') {
      uiSchema[val.key] = {
        'ui:widget': val.widget,
      }
    }
  }
  schemaObj.uiSchema = uiSchema

  return schemaObj
}

const getFeatureSetDisplayName = (featureSet: {
  displayName?: string
  agencyName: string
  dataSourceName: string
}): string => {
  if (featureSet.displayName) return featureSet.displayName
  return `${featureSet.agencyName} - ${featureSet.dataSourceName}`
}

const validateForm = vals => {
  const errors: any = {}
  const propertiesErrors = []
  if (vals.properties) {
    const propertyKeys = vals.properties.map(val => val.key)
    vals.properties.forEach((prop, propIndex) => {
      const propertyError: { [key: string]: string } = {}
      const matchingKeys = propertyKeys.filter(key => key === prop.key)
      if (matchingKeys.length > 1) {
        propertyError.key = 'Duplicate Key'
      }
      const numberTypes = ['number', 'integer']
      if (prop.enum && numberTypes.includes(prop.type)) {
        if (prop.enum.some(opt => isNaN(Number(opt)))) {
          propertyError.enum = 'Enum options must be numbers'
        }
        if (prop.type === 'integer') {
          if (prop.enum.some(opt => !Number.isInteger(Number(opt)))) {
            propertyError.enum = 'Enum options must be integers'
          }
        }
      }
      propertiesErrors.push(propertyError)
    })
    errors.properties = propertiesErrors
  }
  return errors
}

const EditFeatureSetsForm = (props: Props) => {
  const { featureSets, featureSetsArray, agencyName } = props
  const [activeFeatureSetId, setActiveFeatureSetId] = useState(initialFeatureSetId)
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const activeFeatureSetSchemaString: string = _.get(featureSets, [activeFeatureSetId, 'featureSchema'])
  const activeFeatureSetDisplayName = activeFeatureSetId && getFeatureSetDisplayName(featureSets[activeFeatureSetId])
  const showFeatureSetSelect = featureSetsArray && featureSetsArray.length > 0 ? true : false
  const attachmentTypePropertiesCount =
    props.formVals && props.formVals.filter(val => val.type === attachmentType).length

  const [errors, setErrors] = useState(null)
  const validateSchema = (schema: any) => {
    const ajv = new AJV()
    try {
      ajv.compile(schema)
      setErrors(null)
    } catch (e) {
      setErrors(e.message)
      console.warn(e)
    }
  }

  const submitEditFeatureSet = async () => {
    const featureSchema = {
      ...JSON.parse(activeFeatureSetSchemaString),
      ...formValsToObj(props.formVals),
    }
    validateSchema(featureSchema)
    const shouldReplaceNull = props.formVals.some(formVal => formVal.required && formVal.replaceNull)
    if (!shouldReplaceNull) {
      setUpdateInProgress(true)
      try {
        const res = await client.mutate({
          mutation: UPDATE_FEATURE_SET_FEATURE_SCHEMA,
          variables: {
            featureSetGuid: activeFeatureSetId,
            userGuid: props.user.uid,
            featureSchema,
          },
        })
        console.log(res)
        alert(res.data.updateFeatureSetFeatureSchema)
        setUpdateInProgress(false)
        setActiveFeatureSetId(initialFeatureSetId)
      } catch (e) {
        console.warn(e)
        alert(e.message)
        setUpdateInProgress(false)
      }
    }
    if (shouldReplaceNull) {
      const replaceNull = props.formVals.reduce((acc, property) => {
        if (property.required && property.replaceNull) {
          let replacementValue = property.replaceNull
          if (property.type === 'boolean') {
            if (replacementValue === 'true') {
              replacementValue = true
            }
            if (replacementValue === 'false') {
              replacementValue = false
            }
          }
          if (property.type === 'number') {
            if (isNaN(Number(replacementValue))) {
              const errorString = `Replacement value for ${property.key} is not a number`
              setErrors(errorString)
              throw new SubmissionError({ _error: errorString })
            }
            replacementValue = Number(replacementValue)
          }
          acc[property.key] = replacementValue
        }
        return acc
      }, {})
      let warningMessage = 'This will: \n'
      for (const key in replaceNull) {
        warningMessage += `assign a value of ${replaceNull[key]} for all features without ${key} \n`
      }
      const sureYouWantToUpdate = window.confirm(warningMessage)
      if (sureYouWantToUpdate) {
        setUpdateInProgress(true)
        try {
          const res = await client.mutate({
            mutation: UPDATE_FEATURE_SET_FEATURE_SCHEMA,
            variables: {
              featureSetGuid: activeFeatureSetId,
              userGuid: props.user.uid,
              featureSchema,
              replaceNull,
            },
          })
          console.log(res)
          alert(res.data.updateFeatureSetFeatureSchema)
          setUpdateInProgress(false)
          setActiveFeatureSetId(initialFeatureSetId)
        } catch (e) {
          console.warn(e)
          alert(e.message)
          setUpdateInProgress(false)
        }
      }
    }
  }

  useEffect(() => {
    setActiveFeatureSetId(undefined)
  }, [agencyName])

  const prevActiveFeatureSetIdRef: any = useRef()
  const prevFormValsRef = useRef()

  useEffect(() => {
    prevActiveFeatureSetIdRef.current = activeFeatureSetId
    prevFormValsRef.current = props.formVals
  })
  const prevActiveFeatureSetId = prevActiveFeatureSetIdRef.current
  const prevFormVals: any[] = prevFormValsRef.current

  useEffect(() => {
    if (!prevFormVals || !props.formVals) return
    if (prevFormVals.some(val => val.type === attachmentType)) {
      for (const [index, value] of prevFormVals.entries()) {
        if (value.type === attachmentType && props.formVals[index] && props.formVals[index].type !== attachmentType) {
          props.change(`properties[${index}].items`, null)
        }
      }
    }
  }, [attachmentTypePropertiesCount])

  useEffect(() => {
    if (activeFeatureSetId) {
      if (!prevActiveFeatureSetId || prevActiveFeatureSetId !== activeFeatureSetId) {
        props.initialize({ properties: getInitialValues(activeFeatureSetSchemaString) })
      }
    }

    if (!activeFeatureSetId && prevActiveFeatureSetId) {
      props.destroy()
    }
  }, [activeFeatureSetId])
  return (
    <Fragment>
      <Row>
        <Col sm={6} md={2}>
          {showFeatureSetSelect ? (
            <FormControl
              as="select"
              onChange={(e: React.ChangeEvent<any>) => setActiveFeatureSetId(e.target.value)}
              value={activeFeatureSetId}
            >
              {!activeFeatureSetId ? <option>Select a Feature Set</option> : null}
              {featureSetsArray.map(featureSet => (
                <option key={featureSet.id} value={featureSet.id}>
                  {getFeatureSetDisplayName(featureSet)}
                </option>
              ))}
            </FormControl>
          ) : (
            <p>This agency does not currently have any feature set data</p>
          )}
        </Col>
      </Row>

      {activeFeatureSetSchemaString && (
        <Row>
          <Col md={6}>
            <h3>Edit {activeFeatureSetDisplayName}</h3>
            <div>
              <form>
                <FieldArray
                  name="properties"
                  component={propertiesFields}
                  formVals={props.formVals}
                  schemaString={activeFeatureSetSchemaString}
                />
                <Card>
                  <Card.Header>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Button
                        onClick={() => props.initialize({ properties: getInitialValues(activeFeatureSetSchemaString) })}
                        disabled={updateInProgress}
                      >
                        Reset
                      </Button>
                      <LoadingButton
                        variant="primary"
                        onClick={props.handleSubmit(submitEditFeatureSet)}
                        label="Submit"
                        loading={updateInProgress}
                      />
                    </div>
                  </Card.Header>
                </Card>
              </form>
            </div>
          </Col>
          <Col lg={{ span: 4, offset: 1 }} md={4}>
            <h3>Preview</h3>
            <div>
              {errors ? (
                <p style={{ color: 'red' }}>{errors}</p>
              ) : (
                <pre style={{ textAlign: 'left' }}>{JSON.stringify(formValsToObj(props.formVals), null, 1)}</pre>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, firestore) => {
    const paths = [
      {
        collection: 'featureSets',
        where: [['agencyName', '==', props.agencyName]],
        type: 'once',
      },
    ]
    return paths
  }),
  reduxForm({ form: 'editFeatureSet', validate: validateForm })
)(EditFeatureSetsForm)
