import Immutable from 'immutable'

const DeviceRecord = Immutable.Record({
  // eslint-disable-line new-cap
  key: null,
  agencyName: null,
  appVersion: null,
  avlColor: null,
  cadAvlSharingId: null,
  coreVersion: '',
  deviceType: null,
  displayName: null,
  hardwareUid: null,
  messageUid: null,
  isActive: true,
  parseInstallationId: null,
  systemVersion: null,
  timeStamp: null,
  userEmail: null,
})

export default class Device extends DeviceRecord {
  constructor(obj) {
    if (!obj.key) {
      if (Immutable.Map.isMap(obj) && !obj.get('key')) {
        throw new Error('Device records must have a key')
      }
    }
    super(obj)
  }
}
