import React from 'react'
import { connect } from 'react-redux'
import { Router } from 'react-router-dom'
import 'react-dates/initialize'
import { ApolloProvider } from 'react-apollo'
import { createClient } from '../apollo'
import { bindActionCreators } from 'redux'
import { setServerTimeOffset } from '../actions/firebase'
import { firebaseRoot } from '../firebase'
import history from '../history'
import { getToken } from '../selectors/authSelectors'
import CoreLayout from '../layouts/CoreLayout'
// import { createDevToolsWindow } from '../utils'
// import { DevTools, LogMonitor, DebugPanel } from 'redux-devtools/lib/react'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setServerTimeOffset }, dispatch)
}

type Props = {
  token: any
  store: Record<string, any>
  history: Record<string, any>
  debug: boolean
  debugExternal: boolean
  setServerTimeOffset: Function
}
class Root extends React.Component<Props> {
  componentDidMount() {
    firebaseRoot.child('/.info/serverTimeOffset').once('value', snap => {
      this.props.setServerTimeOffset(snap.val() || 0)
    })
  }

  // // TODO: not rendering devtools for the moment, just using Redux Devtools Chrome extension for now
  // renderDevTools () {
  //   if (!this.props.debug) {
  //     return null
  //   }
  //
  //   if (this.props.debugExternal) {
  //     createDevToolsWindow(this.props.store)
  //     return null
  //   }
  //
  //   return (
  //     <DebugPanel top right bottom key='debugPanel'>
  //       <DevTools store={this.props.store} monitor={LogMonitor} />
  //     </DebugPanel>
  //   )
  // }

  render() {
    const client = createClient(this.props.token)
    return (
      <ApolloProvider client={client}>
        <div style={{ height: 'inherit' }}>
          <Router history={history}>
            <CoreLayout />
          </Router>
        </div>
      </ApolloProvider>
    )
  }
}

export default connect(
  state => ({
    token: getToken(state),
  }),
  mapDispatchToProps
)(Root)
