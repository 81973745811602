import Immutable from 'immutable'

const DispatchRecord = Immutable.Record({
  // eslint-disable-line new-cap
  key: null,
  building: null,
  category: null,
  city: null,
  country: null,
  crossStreet: null,
  dispatchLat: null,
  dispatchLng: null,
  dispatchUnits: [],
  fromEmail: null,
  id: null,
  intersection: null, // boolean
  lat: null,
  lng: null,
  longDesc: null,
  raw: null,
  shortDesc: null,
  specialSearchTable: '',
  specialSearchValue: '',
  state: null,
  street: null,
  timeStamp: null,
  toEmail: null,
  zipCode: null,
})

export default class Dispatch extends DispatchRecord {
  constructor(obj) {
    if (!obj.key) {
      throw new Error('Dispatch records must have a key')
    }
    if (obj.dispatchUnits) {
      let first = true
      let unitString = ''
      for (let unit in obj.dispatchUnits) {
        if (first) {
          first = false
        } else {
          unitString += ', '
        }
        unitString += unit
      }
      obj.dispatchUnits = unitString
    }
    super(obj)
  }

  get formattedTime() {
    // // multiplied by 1000 so that the argument is in milliseconds, not seconds
    // const date = new Date(this.TimeStamp * 1000)
    // const hours = date.getHours()
    // const minutes = `0${date.getMinutes()}`
    // return this.
    return new Date(this.timeStamp * 1000)
  }
}
