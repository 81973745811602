/* eslint-disable */
import './LoadingButton.css'

import React, { Component } from 'react'
//import Button from 'react-bootstrap/lib/Button'
import { Button } from 'react-bootstrap'
import Loading from './Loading'

type Props = {
  icon?: any
  label: string
  loadingLabel?: string
  loading: boolean
  variant?: string
  onClick: any
  type?: string
  size?: string
} & any

class LoadingButton extends Component<Props> {
  render() {
    var { icon, label, loading, loadingLabel, ...props } = this.props
    if (!loadingLabel) {
      loadingLabel = `${label}ing`
    }
    return (
      <Button disabled={loading} {...props}>
        {loading ? (
          <span>
            <Loading inline delay={false} /> {icon && <img src={icon} className="LoadingButton__icon" />} {loadingLabel}
            &hellip;
          </span>
        ) : (
          <span>
            {icon && <img src={icon} className="LoadingButton__icon" />} {label}
          </span>
        )}
      </Button>
    )
  }
}

export default LoadingButton
