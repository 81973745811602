import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Alert } from 'react-bootstrap'
import DataGridWrapper from '../components/DataGridWrapper'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import InspectionsDataGrid from '../containers/InspectionsDataGrid'
import { getAgencies, getUserRoleForInspectionsQuery } from '../selectors'
import queryString from 'query-string'
import { useQuery } from 'react-apollo'
import { INSPECTION_CONFIGS_QUERY } from '../apollo'

const mapStateToProps = state => ({
  user: state.auth.user,
  agency: getAgencies(state) && getAgencies(state)[state.auth.user.agencyName],
  inspectionsRole: getUserRoleForInspectionsQuery(state),
})

const InspectionsView = (props: any) => {
  const { user } = props
  const urlQuery = queryString.parse(props.location.search)
  const agencyNameFilter = urlQuery.agencyName
  let leftHeader: JSX.Element = null
  if (user.isAgi) {
    leftHeader = <AgencyFilterSelect agencyNameFilter={agencyNameFilter} location={props.location} />
  }
  const { loading, data, error } = useQuery(INSPECTION_CONFIGS_QUERY, {
    context: {
      headers: {
        'x-hasura-role': `${props.inspectionsRole}`,
      },
    },
  })

  if (error) {
    return (
      <DataGridWrapper>
        <Alert variant="danger">{error.message}</Alert>
      </DataGridWrapper>
    )
  }

  if (data && data.inspection_config) {
    return (
      <DataGridWrapper header="Inspections" leftHeader={leftHeader}>
        <Fragment>
          {agencyNameFilter || !user.isAgi ? (
            <InspectionsDataGrid
              location={props.location}
              history={props.history}
              inspectionConfigs={data.inspection_config}
              inspectionsRole={props.inspectionsRole}
            />
          ) : null}
        </Fragment>
      </DataGridWrapper>
    )
  }
  return null
}

export default compose(
  firebaseConnect((props, firebase) => {
    if (props.user && !props.user.isAgi) {
      return [`/agencies/${props.user.agencyName}`]
    }
    return []
  }),
  connect(mapStateToProps)
)(InspectionsView)
