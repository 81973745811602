import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import TreeView from 'react-treeview'
import 'react-treeview/react-treeview.css'
import * as queuesActions from '../actions/queues'
import QueueTaskForm from '../containers/QueueTaskForm'
// import FirebaseConnector from '../components/FirebaseConnector'
import ViewWrapper from '../components/ViewWrapper'
// import { queuesRef } from '../firebase'

const mapStateToProps = state => {
  return { queues: state.queues.data }
}

const mapDispatchToProps = dispatch => bindActionCreators(queuesActions, dispatch)

type Props = {
  queues: Record<string, any>
  loadAllQueues: Function
}

const QueuesView = () => {
  // const { queues } = props
  return (
    <ViewWrapper header="Queues" sizable>
      {/* commenting this out for now because it was causing the page to hang up
      <FirebaseConnector key="queues" // key lets react know to umount and mount component when changed
        firebaseRef={queuesRef}
        onNodeChange={props.loadAllQueues}
      />

      <TreeView nodeLabel="queues">
      {queues && queues.entrySeq().map(([queueName, queueNode]) =>
        <TreeView nodeLabel={<b>{queueName}</b>} key={queueName}>
          {queueNode.get('tasks').entrySeq().map(([taskId, taskNode]) =>
            <TreeView nodeLabel={taskId} key={taskId} defaultCollapsed>
              {taskNode.entrySeq().map(([key, val]) =>
                <TreeView nodeLabel={<b>{key}</b>} key={key}>
                  {key === '_error_details' ? val.entrySeq().map(([k, v]) =>
                    <TreeView nodeLabel={<b>{k}</b>} key={k}>
                      <div className="info">{v}</div>
                    </TreeView>
                  )
                  :
                  <div className="info">{val}</div>
                  }
                </TreeView>
              )}
            </TreeView>
          )}
        </TreeView>
      )}
      </TreeView> */}

      <h3 className="text-center">Add task to queue</h3>
      <QueueTaskForm />
    </ViewWrapper>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueuesView)
