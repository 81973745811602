import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { firebaseRoot, firestore } from '../firebase'
import { getFeatureSetGuidForDataSource } from '../utils'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { Row, Col, Alert, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import { FormFieldInput, LoadingButton, StaticField } from '../components/BootstrapReduxForm'
import { client, EDIT_FEATURE_SET, EDIT_FEATURE_SET_EDITABLE } from '../apollo'

const EDITABLE_FIELD = 'editable'
const TOGGLEABLE_FIELD = 'toggleable'
const DISPLAY_NAME_FIELD = 'displayName'
const DISPLAY_FIELD_FIELD = 'displayField'
const VISIBLE_BY_DEFAULT = 'visibleByDefault'

function mapStateToProps(state, ownProps) {
  let fieldNames
  if (ownProps.dataSourceConfig && ownProps.dataSourceConfig.schema) {
    fieldNames = Object.keys(ownProps.dataSourceConfig.schema.properties)
  } else if (state.firebase.data.gisFeatureSchemas && state.firebase.data.gisFeatureSchemas[ownProps.dataSourceName]) {
    fieldNames = Object.keys(state.firebase.data.gisFeatureSchemas[ownProps.dataSourceName].properties)
  }

  return {
    displayFieldValues: fieldNames,
  }
}

function validateEditGisDataSourceForm(data) {
  let errors = {}
  if (String(data[DISPLAY_NAME_FIELD]).length > 60) {
    errors[DISPLAY_NAME_FIELD] = 'Display name must be less than 60 characters.'
  }
  return errors
}

const boolStringToBool = str => {
  if (str === 'true') return true
  if (str === 'false') return false
}

const visibleByDefaultInitial = value => {
  if (value === undefined || value === true) return true
  if (value === false) return false
}

type Props = {
  dataSource: Record<string, any>
  agencyName: string
  dataSourceName: string
  onSuccess: Function
  displayFieldValues: string[]
  // TODO: figure out correct type
  onCancel: (event: any) => void
} & InjectedFormProps

type State = {
  featureSetGuid: any
  styleName: string
}

class EditGisDataSourceForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      featureSetGuid: undefined,
      styleName: undefined,
    }
  }

  async determineMapboxStyleName() {
    let styleName
    const gisDataSourcesMapboxStyleDef = this.props.dataSource.mapboxStyleLayers
    if (typeof gisDataSourcesMapboxStyleDef === 'object') {
      try {
        await firebaseRoot
          .child(`gisDataSourceConfigurations/${this.props.agencyName}/${this.props.dataSourceName}/mapboxStyleLayers`)
          .once('value')
          .then(function(snapshot) {
            if (snapshot.exists()) {
              const styleNameOrObject = snapshot.val()
              if (typeof styleNameOrObject === 'object') {
                styleName = 'custom'
              } else {
                styleName = styleNameOrObject
              }
            } else {
              styleName = 'default'
            }
          })
      } catch (e) {
        styleName = 'default'
      }
    } else {
      styleName = 'This layer does not have a style defined'
    }
    return styleName
  }

  //load existing data into form
  async componentWillMount() {
    const { dataSource } = this.props
    const initialFormValues = {
      [EDITABLE_FIELD]: dataSource.editable,
      [TOGGLEABLE_FIELD]: dataSource.toggleable,
      [DISPLAY_NAME_FIELD]: dataSource.displayName,
      [DISPLAY_FIELD_FIELD]: dataSource.displayField,
      [VISIBLE_BY_DEFAULT]: visibleByDefaultInitial(dataSource.visibleByDefault),
    }

    if (dataSource) this.props.initialize(initialFormValues)
    const styleName = await this.determineMapboxStyleName()
    const featureSetGuid = await getFeatureSetGuidForDataSource(this.props.agencyName, this.props.dataSourceName)
    if (featureSetGuid) {
      this.setState({ featureSetGuid, styleName })
    } else {
      this.setState({ styleName })
    }
  }

  submitEditGisDataSourceForm = async data => {
    const { agencyName, dataSourceName, dataSource } = this.props
    const { featureSetGuid } = this.state
    const editable = data.editable && boolStringToBool(data.editable)
    let updateData = {
      ...data,
      editable,
      toggleable: data.toggleable && boolStringToBool(data.toggleable),
    }

    updateData = Object.entries(updateData).reduce((acc, [key, val]) => {
      if (val !== undefined) {
        acc[key] = val
      }
      return acc
    }, {})
    try {
      // if editable is not touched in form then is 'undefined'
      if ((editable === true || editable === false) && featureSetGuid) {
        await firestore.doc(`featureSets/${featureSetGuid}`).update({ editable }) // remove when migrate away from using firestore featureSets
        await client.mutate({
          mutation: EDIT_FEATURE_SET_EDITABLE,
          variables: {
            agencyName: agencyName,
            dataSourceName: dataSourceName,
            editable: editable,
            displayName: updateData.displayName ? updateData.displayName : dataSource.displayName,
            displayField: updateData.displayField ? updateData.displayField : dataSource.displayField,
            modifiedDate: new Date(Date.now()).toISOString(),
          },
        })
      } else if (featureSetGuid) {
        await client.mutate({
          mutation: EDIT_FEATURE_SET,
          variables: {
            agencyName: agencyName,
            dataSourceName: dataSourceName,
            displayName: updateData.displayName ? updateData.displayName : dataSource.displayName,
            displayField: updateData.displayField ? updateData.displayField : dataSource.displayField,
            modifiedDate: new Date(Date.now()).toISOString(),
          },
        })
      }
      await firebaseRoot.child(`gisDataSourceConfigurations/${agencyName}/${dataSourceName}`).update(updateData)
      return this.props.onSuccess()
    } catch (error) {
      throw new SubmissionError({ _error: error.message })
    }
  }
  render() {
    const { error, handleSubmit, submitting, submitFailed, displayFieldValues } = this.props
    const { featureSetGuid, styleName } = this.state

    return (
      <form>
        <Row>
          {featureSetGuid ? (
            <Field
              name={EDITABLE_FIELD}
              component={FormFieldInput}
              type="select"
              label="Editable"
              help="This property will only be set for data in the new system."
              // help="If true, the dataset will be editable in IncidentView Editor"
            >
              <option key={'true'} value={'true'}>
                True
              </option>
              <option key={'false'} value={'false'}>
                False
              </option>
            </Field>
          ) : (
            <Field
              name={EDITABLE_FIELD}
              component={FormFieldInput}
              type="select"
              label="Editable"
              help="This property will only be set for data in the new system."
              // help="If true, the dataset will be editable in IncidentView Editor"
              input={{ disabled: true }}
            >
              <option key={'true'} value={'true'}>
                True
              </option>
              <option key={'false'} value={'false'}>
                False
              </option>
            </Field>
          )}
        </Row>
        <Row>
          <Field
            name={TOGGLEABLE_FIELD}
            component={FormFieldInput}
            type="select"
            label="Toggleable"
            help="If true, the dataset will be in the layer list in IncidentView"
          >
            <option key={'true'} value={'true'}>
              True
            </option>
            <option key={'false'} value={'false'}>
              False
            </option>
          </Field>
        </Row>
        <Row>
          <Field
            name={DISPLAY_NAME_FIELD}
            component={FormFieldInput}
            type="text"
            label="Display Name"
            help="This is the visible name for this data; displayed in IV and IV Editor"
          />
        </Row>
        {displayFieldValues &&
          displayFieldValues.length &&
          (featureSetGuid ? (
            <Row>
              <Field
                name={DISPLAY_FIELD_FIELD}
                component={FormFieldInput}
                type="select"
                label="Display Field"
                help="This field is displayed when selecting two features in IncidentView Editor"
              >
                {displayFieldValues.map(fieldName => (
                  <option key={fieldName} value={fieldName}>
                    {fieldName}
                  </option>
                ))}
              </Field>
            </Row>
          ) : (
            <Row>
              <Field
                name={DISPLAY_FIELD_FIELD}
                component={FormFieldInput}
                type="select"
                label="Display Field"
                help="This field is displayed when selecting two features in IncidentView Editor"
                input={{ disabled: true }}
              />
            </Row>
          ))}
        <Row>
          <Field
            name={VISIBLE_BY_DEFAULT}
            component={FormFieldInput}
            type="checkbox"
            label="Visible By Default"
            labelColSize={4}
            help="If checked the data will be displayed in IV by default"
          />
        </Row>
        <Row>
          <StaticField labelColSize={4} label="Style Name" value={styleName} />
        </Row>
        {error && (
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        {submitFailed && (
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <Alert variant="danger">The form failed to submit</Alert>
            </Col>
          </Row>
        )}
        <Row className="form-group">
          <Col sm={{ offset: 3 }} className="text-center">
            <ButtonToolbar>
              <ButtonGroup>
                <LoadingButton
                  variant="primary"
                  size="lg"
                  label="Save"
                  loadingLabel="Saving"
                  loading={submitting}
                  onClick={handleSubmit(this.submitEditGisDataSourceForm)}
                  type="submit"
                />
                <Button className="ml-2" variant="outline-dark" onClick={this.props.onCancel} size="lg">
                  Cancel
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    )
  }
}

export default compose(
  reduxForm({
    form: 'editGisDataSource',
    validate: validateEditGisDataSourceForm,
  }),
  firebaseConnect((props, firebase) => {
    let paths = []
    if (props.dataSourceConfig && !props.dataSourceConfig.schema) {
      paths.push(`gisFeatureSchemas/${props.dataSourceName}`)
    }
    return paths
  }),
  connect(mapStateToProps)
)(EditGisDataSourceForm)
