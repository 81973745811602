import React from 'react'
import ViewWrapper from '../components/ViewWrapper'
import ChangePasswordForm from '../containers/ChangePasswordForm'

export default class ChangePasswordView extends React.Component {
  render() {
    return (
      <ViewWrapper header="Change Password">
        <ChangePasswordForm />
      </ViewWrapper>
    )
  }
}
