import React from 'react'
import { Button, DropdownButton, Dropdown } from 'react-bootstrap'
import { FaPen, FaDownload, FaShareSquare, FaUpload } from 'react-icons/fa'
import ViewWrapper from '../components/ViewWrapper'
import DataGridWrapper from '../components/DataGridWrapper'
import GisLayersDataGrid from '../containers/GisLayersDataGrid'
import AgencyFilterSelect from '../containers/AgencyFilterSelect'
import queryString from 'query-string'
import { RouteComponentProps } from 'react-router-dom'

type Props = {
  user: Record<string, any>
} & RouteComponentProps

const GisLayersView = ({ user, ...props }: Props) => {
  const urlQuery = queryString.parse(props.location.search)
  const agencyNameFilter = urlQuery.agencyName
  const goToLoadData = () => {
    agencyNameFilter ? props.history.push(`/gis/load?agencyName=${agencyNameFilter}`) : props.history.push('/gis/load')
  }
  return (
    <DataGridWrapper
      header="GIS Data"
      leftHeader={
        user && user.isAgi && <AgencyFilterSelect agencyNameFilter={agencyNameFilter} location={props.location} />
      }
      rightHeader={
        <DropdownButton id="actions" title="Actions" variant="primary" pullRight>
          <Dropdown.Item onClick={goToLoadData}>
            Load Data <FaDownload />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => props.history.push('/gis/export')}>
            Export vector tiles <FaUpload />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => props.history.push('/gis/sharing')}>
            Data Sharing <FaShareSquare />
          </Dropdown.Item>
          <Dropdown.Item onClick={() => props.history.push(`/featuresets?agencyName=${agencyNameFilter}`)}>
            Edit Feature Sets <FaPen />
          </Dropdown.Item>
        </DropdownButton>
      }
    >
      <div
        style={{
          position: 'absolute',
          height: 'calc(100% - 10em)',
          bottom: '1.5rem',
          left: 0,
          right: 0,
        }}
      >
        <GisLayersDataGrid agencyName={urlQuery.agencyName} user={user} />
      </div>
    </DataGridWrapper>
  )
}

export default GisLayersView
