import { firestore, usersRef, functions } from '../firebase'
import { ADD_USER, LOAD_ALL_USERS, LOAD_USER, SAVE_USER, CLEAR_ADD_USER_MESSAGE } from '../constants/ActionTypes'

export function addUser(email, agencyName) {
  return {
    type: ADD_USER,
    payload: functions.addUser({ email, agencyName }).then(({ data }) => {
      return (
        `${data.email} (with password "${data.password}") successfully added to users table. ` +
        `Be sure to email this user with this password.`
      )
    }),
  }
}

// this is passed users using FirebaseConnector component
export function loadAllUsers(users) {
  return {
    type: LOAD_ALL_USERS,
    payload: {
      users,
    },
  }
}

export function loadUser(userId, user) {
  return {
    type: LOAD_USER,
    payload: {
      userId,
      user,
    },
  }
}

export function saveUser(user) {
  return {
    type: SAVE_USER,
    payload: Promise.all([
      // Save to both realtime db and firestore, since security rules for both rely on users
      usersRef.child(user.uid).set(user.toFirebaseObject()),
      firestore.doc(`users/${user.uid}`).set(user.toFirestoreObject()),
    ]),
  }
}

export function clearAddUserMessage() {
  return {
    type: CLEAR_ADD_USER_MESSAGE,
  }
}
