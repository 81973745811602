import { Alert, Row, Col, ListGroup, ListGroupItem, Button, Modal } from 'react-bootstrap'
import { WrappedFieldProps } from 'redux-form'
import classNames from 'classnames'
import React, { useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Input from '../Input'

const SortableItem = SortableElement(({ value, index, removeValAtIndex }) => (
  <ListGroupItem style={{ zIndex: 10000 }}>
    {value}
    <Button className="pull-right" size="sm" variant="danger" onClick={() => removeValAtIndex(index)}>
      &times;
    </Button>
  </ListGroupItem>
))

const SortableList = SortableContainer(({ items, removeValAtIndex }) => {
  return (
    <ListGroup>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} removeValAtIndex={removeValAtIndex} />
      ))}
    </ListGroup>
  )
})

type Props = {
  label: string
} & WrappedFieldProps

const getInitialValue = (inputValue?: string[]) => {
  if (!inputValue) return []
  return inputValue.concat()
}

const EnumField = (props: Props) => {
  const { meta, label } = props
  const error = meta && meta.touched && meta.error
  const warning = meta && meta.touched && meta.warning
  const [modalOpen, setModalOpen] = useState(false)
  const [orderedVals, setOrderedVals] = useState(getInitialValue(props.input.value))
  const [newValString, setNewValString] = useState('')
  const pushNewValString = () => {
    setOrderedVals(orderedVals.concat(newValString))
    setNewValString('')
  }
  const removeValAtIndex = (index: number) => {
    const newArray = orderedVals.concat()
    newArray.splice(index, 1)
    setOrderedVals(newArray)
  }

  const handleCancel = () => {
    setModalOpen(false)
    setOrderedVals(props.input.value || [])
  }

  const handleSubmit = () => {
    const newVal = orderedVals.length ? orderedVals : null
    props.input.onChange(orderedVals)
    props.input.onBlur(orderedVals)
    setModalOpen(false)
  }

  const onSortEnd = ({ newIndex, oldIndex }) => {
    const newArray = orderedVals.concat()
    newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0])
    setOrderedVals(newArray)
  }

  return (
    <Col sm={12}>
      <Row className={classNames({ 'has-error': error })} style={{ paddingTop: '0em' }}>
        <Col sm={4} className="control-label">
          <label htmlFor={'foo'}>{label}</label>
        </Col>
        <Col sm={7} style={{ paddingTop: '0.4em' }}>
          {props.input.value && <p>{props.input.value.join(', ')}</p>}

          <Button variant="primary" onClick={() => setModalOpen(true)}>
            {props.input.value ? 'Edit Values' : 'Set Enum Values'}
          </Button>
        </Col>
      </Row>

      {warning && <Alert variant="warning">{warning}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="static-modal">
        <Modal show={modalOpen} onHide={handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>{label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <SortableList items={orderedVals} onSortEnd={onSortEnd} removeValAtIndex={removeValAtIndex} />
                <Input
                  value={newValString}
                  onChange={e => setNewValString(e.target.value)}
                  buttonAfter={<Button onClick={pushNewValString}>Add</Button>}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Col>
  )
}

export default EnumField
