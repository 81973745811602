import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import * as selectors from '../selectors'
import ViewWrapper from '../components/ViewWrapper'
import EditAgencyForm from '../containers/EditAgencyForm'
import Agency from '../records/agency'

function mapStateToProps(state, ownProps) {
  const { agencyName } = ownProps.match.params
  let agency
  const agencies = selectors.getAgenciesMap(state)
  if (agencies.size) {
    agency = agencies.get(agencyName)
  }
  return {
    agency,
  }
}

type Props = {
  agency: any
  params: Record<string, any> // react-router params object
}

const AgencyView = props => {
  const returnToAgencies = () => props.history.push('/agencies')
  const { agencyName } = props.match.params
  const { agency } = props
  return (
    <ViewWrapper header={`Edit ${agencyName}`}>
      {agency && <EditAgencyForm agency={agency} onSuccess={returnToAgencies} onCancel={returnToAgencies} />}
    </ViewWrapper>
  )
}

export default compose(
  firebaseConnect((props, firebase) => {
    let paths = []
    if (props.match.params.agencyName) {
      paths.push({ type: 'once', path: `/agencies/${props.match.params.agencyName}/` })
    }
    return paths
  }),
  connect(mapStateToProps)
)(AgencyView)
