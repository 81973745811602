import Immutable from 'immutable'
import { createReducer } from '../utils'
import * as types from '../constants/ActionTypes'

export const GisDataSource = Immutable.Record({
  data: Immutable.Map(),
})

const initialState = new GisDataSource()

const handlers = {
  [types.LOAD_GIS_DATA_SOURCES]: (state, action) => {
    return state.set('data', Immutable.fromJS(action.gisDataSources))
  },
  [types.UPDATE_SPECIAL_SEARCH_DATA]: (state, action) => {
    let updated = action.updatedSpeicalSearchData.updated
    let newState = state
    try {
      for (const table in updated) {
        newState = newState.setIn(['data', table, 'searchableFields', 'search'], updated[table].trigger)
      }
      return newState
    } catch (error) {
      console.log(error)
      return state
    }
  },
}

export default createReducer(initialState, handlers)
