import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
// import { firebaseConnect } from 'react-redux-firebase'
// import { firebaseRoot } from '../firebase'
import ViewWrapper from '../components/ViewWrapper'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import ShareGisDataForm from '../containers/ShareGisDataForm'

function mapStateToProps(state, ownProps) {
  return {
    user: state.auth.user,
  }
}

type Props = {
  user: any
  dataSource: any
  path: any
} & RouteComponentProps

const ShareGisDataView = ({ user, dataSource, match, path }: Props) => {
  return (
    <ViewWrapper header={'GIS Data Sharing'}>
      <div>
        <ShareGisDataForm />
      </div>
    </ViewWrapper>
  )
}

export default compose(
  withRouter,
  // firebaseConnect(props => {
  //   return [`/gisDataSources/${props.match.params.agencyName}/${props.match.params.dataSourceName}`]
  // }),
  connect(mapStateToProps)
)(ShareGisDataView)
