// export const ROLE_AGI_SUPER = 'agiSuperAdmin'
export const ROLE_SUPER_ADMIN = 'superAdmin'
export const ROLE_AGI = 'agi'
// export const ROLE_AGI_EDIT = 'agiEdit'
// export const ROLE_AGI_VIEW = 'agiView'
export const ROLE_AGENCY_ADMIN = 'agencyAdmin'
export const ROLE_VIEW_AVL = 'viewAvl'
export const ROLE_EDITOR = 'editor'
export const ROLE_RESPONDER = 'responder'
export const ROLE_INSPECTOR = 'inspector'
