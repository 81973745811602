/* eslint no-unused-expressions: 0 */
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
// import { push } from 'react-router-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ViewWrapper from '../components/ViewWrapper'

type Props = {
  user: Record<string, any>
} & RouteComponentProps

type State = { warnUnauthenticated: boolean; warnWrongPermissions: boolean }

export default function requiresAuth(Component: any, options?: { allowedRoles: string[] }) {
  let allowedRoles: string[] | undefined
  if (options) {
    allowedRoles = options.allowedRoles
  }
  class AuthenticatedComponent extends React.Component<Props, State> {
    componentDidMount() {
      // We delay warning the user that they are unauthenticated since they are always
      // briefly unauthenticated when first loading the page.
      this.setState({ warnUnauthenticated: false, warnWrongPermissions: false })
      setTimeout(() => {
        this.setState({ warnUnauthenticated: true })
      }, 800)
      this._checkAndRedirect()
    }

    componentDidUpdate() {
      this._checkAndRedirect()
    }

    _redirectTimeout = null
    _warnPermissionsTimeout = null

    _checkAndRedirect() {
      if (this._redirectTimeout) clearTimeout(this._redirectTimeout)
      if (this._warnPermissionsTimeout) clearTimeout(this._warnPermissionsTimeout)

      if (!this.props.user) {
        this._redirectTimeout = setTimeout(() => {
          // remove first '/' in pathname (resulting in empty string for '/')
          const nextPath = this.props.location.pathname.slice(1)
          const nextUrlPath = nextPath ? `/login?nextPath=${nextPath}` : '/login'
          this.props.history.push(nextUrlPath)
        }, 4000)
      } else if (allowedRoles && !allowedRoles.some(role => this.props.user.roles.includes(role))) {
        // If on page for a few seconds without proper permissions, display warning explaining why it won't render.
        // We don't display the warning immediately since when you first load the site you don't have your user
        // immediately, causing legit users to see the warning temporarily.
        this._warnPermissionsTimeout = setTimeout(() => {
          this.setState({ warnWrongPermissions: true })
        }, 3000)
      }
    }

    render() {
      let content
      if (this.props.user) {
        if (allowedRoles && !allowedRoles.some(role => this.props.user.roles.includes(role))) {
          if (this.state && this.state.warnWrongPermissions) {
            content = (
              <ViewWrapper header="You do not have permission to view this page.">
                {/* <button className="btn btn-lg btn-primary btn-block">Return Home</button> */}
                <LinkContainer to="/">
                  <Button size="lg" block>
                    Return Home
                  </Button>
                </LinkContainer>
              </ViewWrapper>
            )
          } else {
            content = <div />
          }
        } else {
          content = <Component {...this.props} />
        }
      } else {
        if (this.state && this.state.warnUnauthenticated) {
          content = <ViewWrapper header="You must sign in to view this page. Redirecting..." />
        }
      }

      return <div className="authenticated">{content}</div>
    }
  }

  const mapStateToProps = state => {
    return {
      user: state.auth.user,
    }
  }

  return compose(
    withRouter,
    connect(mapStateToProps)
  )(AuthenticatedComponent)
}
