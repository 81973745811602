import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, Field, formValues, SubmissionError } from 'redux-form'
import { Row, Col, Alert, Button, ButtonToolbar, Image } from 'react-bootstrap'
import {
  FormFieldInput,
  // FormFieldReactSelect,
  LoadingButton,
  FormField,
} from '../components/BootstrapReduxForm'
import { client, GET_DATA_SOURCE_NAMES_AND_FEATURE_SET_GUIDS, EXPORT_GIS_DATA } from '../apollo'
import { withRouter, RouteComponentProps } from 'react-router-dom'

const POSSIBLE_FEATURE_SETS = 'possibleFeatureSets'
const POSSIBLE_DATA_EXPORT_TYPES = 'possibleDataExportTypes'
const ENTIRE_FEATURE_SET_OR_SELECTION = 'entireFeatureSetOrSelection'
const INCLUDE_ATTACHMENTS = 'includeAttachments'
// todo: also have option to download all featureSets as a gdb?

// function mapStateToProps(state, ownProps) {
//   return {

//   }
// }
type Props = {
  agencyName: string
  handleSubmit: Function
  // users: any
} & RouteComponentProps

function validateExportDataForGisDepartmentForm(data) {
  const errors = {}
  if (!data[POSSIBLE_FEATURE_SETS]) {
    errors[POSSIBLE_FEATURE_SETS] = 'Please select a feature set'
  }
  if (!data[POSSIBLE_DATA_EXPORT_TYPES]) {
    errors[POSSIBLE_DATA_EXPORT_TYPES] = 'Please select a data format'
  }
  if (!data[ENTIRE_FEATURE_SET_OR_SELECTION]) {
    errors[ENTIRE_FEATURE_SET_OR_SELECTION] = 'Please choose to download the entire data set or apply a filter'
  }
  return errors
}

function warnExportDataForGisDepartmentForm(data) {
  const warnings = {}
  if (data && data[POSSIBLE_FEATURE_SETS]) {
    const dataSouceNameAndGuid = data[POSSIBLE_FEATURE_SETS].split('.')
    const dataSourceName = dataSouceNameAndGuid[0]
    // TODO: remove warning once supporting downloading attachments
    if (dataSourceName === 'preplan') {
      warnings[POSSIBLE_FEATURE_SETS] =
        'Warning: only preplan gis data will be downloaded. Attachment data download is currently not supported.'
    }
  }
  return warnings
}

// Will need special logic for preplans, with option to export only gisData vs gisData and supporting zip folder containing attachments. Won't have this customer facing at first, at least not the attachments...??
// attachments associated with other data types will also need to be supported
const ExportDataForGisDepartmentForm = (props: Props) => {
  const { agencyName, handleSubmit } = props
  const [dataSources, setDataSources] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    const fetchDataSourceNamesAndGuids = async agency => {
      const featureSetQueryResult = await client.query({
        query: GET_DATA_SOURCE_NAMES_AND_FEATURE_SET_GUIDS,
        variables: {
          agencyName: agency,
        },
      })
      if (
        featureSetQueryResult &&
        featureSetQueryResult.data &&
        featureSetQueryResult.data.feature_set &&
        featureSetQueryResult.data.feature_set.length
      ) {
        const dataSources = featureSetQueryResult.data.feature_set.map(dataSource => ({
          label: dataSource.data_source_name,
          value: dataSource.guid,
        }))
        setDataSources(dataSources)
      } else {
        setDataSources([])
      }
    }
    if (agencyName) {
      fetchDataSourceNamesAndGuids(agencyName)
    }
  }, [agencyName])

  const submitDownloadDataForm = async data => {
    setShowSpinner(true)
    if (data && data.possibleFeatureSets && data.possibleDataExportTypes) {
      const resultUrl = await client.query({
        query: EXPORT_GIS_DATA,
        variables: {
          featureSetGuid: data.possibleFeatureSets,
          format: data.possibleDataExportTypes,
        },
      })

      window.open(resultUrl.data.exportGisData.url)

      setShowSpinner(false)
    } else {
      throw new SubmissionError({
        _error: 'A value from all the above fields must be selected before data can be exported',
      })
    }
  }

  return (
    <form>
      <div style={{ marginTop: '15px' }} />
      <Field name={POSSIBLE_FEATURE_SETS} component={FormFieldInput} type="select" label="GIS Feature Set">
        <option key="default" value="">
          ------------
        </option>
        {dataSources &&
          dataSources.map(dataSource => (
            <option key={dataSource.value} value={dataSource.value}>
              {dataSource.label}
            </option>
          ))}
      </Field>

      <Field
        name={ENTIRE_FEATURE_SET_OR_SELECTION}
        component={FormFieldInput}
        type="select"
        label="Data to be Included"
      >
        <option key="default" value="">
          ------------
        </option>
        <option key="all" value="all">
          Entire Feature Set
        </option>
        <option key="property" value="property" disabled={true}>
          Filter by property value
        </option>
        <option key="edit_date" value="edit_date" disabled={true}>
          Filter by edit date
        </option>
      </Field>

      <Field name={POSSIBLE_DATA_EXPORT_TYPES} component={FormFieldInput} type="select" label="Download as ">
        <option key="default" value="">
          ------------
        </option>
        <option key="ESRI Shapefile" value="ESRI Shapefile">
          Shapefile
        </option>
        <option key="csv" value="csv" disabled={true}>
          csv
        </option>
        <option key="sqlite" value="sqlite" disabled={true}>
          sqLite
        </option>
        {/* <option key="esrigdb" value="esrigdb">
                  esriGDB
                  </option> */}
        <option key="GeoJSON" value="GeoJSON">
          GeoJSON
        </option>
      </Field>
      <Row>
        <Col sm={{ offset: 7 }}>
          <ButtonToolbar>
            <LoadingButton
              bsStyle="primary"
              onClick={handleSubmit(submitDownloadDataForm)}
              label="Submit"
              loading={showSpinner}
              loadingLabel="Submitting"
            />
          </ButtonToolbar>
        </Col>
      </Row>
    </form>
  )
}

export default compose(
  reduxForm({
    form: 'exportGisData',
    validate: validateExportDataForGisDepartmentForm,
    warn: warnExportDataForGisDepartmentForm,
  }),
  formValues({
    chosenFeatureSet: POSSIBLE_FEATURE_SETS,
    chosenDataFormat: POSSIBLE_DATA_EXPORT_TYPES,
    chosenFilter: ENTIRE_FEATURE_SET_OR_SELECTION,
  }),
  connect(),
  withRouter
)(ExportDataForGisDepartmentForm)
