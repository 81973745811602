import * as types from '../constants/ActionTypes'
import Immutable from 'immutable'
import CadCenter from '../records/cadCenter'

export const CadCentersState = Immutable.Record({
  // eslint-disable-line new-cap
  data: Immutable.Map(),
  // sorting: new Immutable.Map()
})

const initialState = new CadCentersState()

const handlers = {
  [types.LOAD_ALL_CAD_CENTERS]: (state, action) => {
    let newCadCenters = Immutable.OrderedMap()
    for (const [key, val] of Object.entries(action.payload.allCadCentersNode)) {
      newCadCenters = newCadCenters.set(key, new CadCenter({ key, ...val }))
    }
    return state.set('data', newCadCenters)
  },
}

export default function reducer(state = initialState, action) {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  } else {
    return state
  }
}
