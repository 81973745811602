import { SET_SERVER_TIME_OFFSET } from '../constants/ActionTypes'

export function setServerTimeOffset(serverTimeOffset) {
  return {
    type: SET_SERVER_TIME_OFFSET,
    payload: {
      serverTimeOffset,
    },
  }
}
