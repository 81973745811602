import React, { useState, useEffect, useReducer } from 'react'
import { useLazyQuery } from 'react-apollo'
import Immutable from 'immutable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import SortableDataGrid from '../components/FixedDataTable/SortableDataGrid'
import * as dispatchesActions from '../actions/dispatches'
import { client, GET_DISPATCH_PAYLOADS } from '../apollo'
import { DispatchPayload } from '../types/DispatchTypes'
import { AutoSizer, MultiGrid } from 'react-virtualized'
import { getDispatchListForAgencySorted } from '../selectors/dispatchesSelectors'

export const columns = [
  {
    key: 'created_date',
    name: 'Created Date',
    isDate: true,
    sortable: true,
    width: 180,
  },
  {
    key: 'destination',
    name: 'Destination',
    sortable: true,
    width: 300,
  },
  {
    key: 'handled',
    name: 'Handled',
    sortable: true,
    width: 90,
  },
  {
    key: 'message_id',
    name: 'Message ID',
    sortable: true,
    width: 120,
  },
  {
    key: 'parsed',
    name: 'Parsed',
    sortable: true,
    width: 150,
  },
  {
    key: 'raw_message',
    name: 'Raw Message',
    width: 150,
  },
  {
    key: 'source',
    name: 'Source',
    sortable: true,
    width: 300,
  },
  {
    key: 'type',
    name: 'Type',
    sortable: true,
    width: 200,
  },
]

export const columnsForCustomers = [
  'formattedTime',
  'raw',
  'category',
  'street',
  'city',
  'building',
  'dispatchUnits',
  'crossStreet',
  'shortDesc',
  'longDesc',
  'dispatchLat',
  'dispatchLng',
]

const initialDispatchPayloads: DispatchPayload[] = []

type DispatchPayloadState = {
  dispatchPayloads: DispatchPayload[]
}

const initialDispatchPayloadsQueryState: DispatchPayloadState = {
  dispatchPayloads: initialDispatchPayloads,
}

const SET_DISPATCH_PAYLOADS = 'SET_DISPATCH_PAYLOADS'

const dispatchPayloadsQueryReducer = (state: DispatchPayloadState, action: Record<string, any>) => {
  /*
    This is here for handling page only state.  Currently we aren't doing anything with it, but
      if/when we add filtering, pagination, etc we would handle the state here.
      
    See InspectionDataGrid.tsx for an example
  */
  if (action.type === SET_DISPATCH_PAYLOADS) {
    return state
  }
  return state
}

const mapStateToProps = (state, ownProps) => {
  const authUser = state.auth.user
  return {
    user: authUser,
    dispatches: null,
    sortAttribute: state.dispatches.sorting.get('attributeName'),
    sortDirection: state.dispatches.sorting.get('sortDirection'),
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...dispatchesActions }, dispatch)
}

type Props = {
  user: Record<string, any>
  dispatches: Record<string, any>
  sortAttribute: string
  sortDirection: string
  sortDispatches: Function
  agencyName: string
  location: Record<string, any>
}

const DispatchPayloadDataGrid = (props: Props) => {
  const [dispatchPayloadsState, dispatch] = useReducer(dispatchPayloadsQueryReducer, initialDispatchPayloadsQueryState)
  const [dispatchPayloads, setDispatchPayloads] = useState(null)
  const [pending, setPending] = useState(false)

  const agencyName = props.agencyName ? props.agencyName : undefined

  useEffect(() => {
    const getDispatchPayloads = async agencyNameLocal => {
      // We aren't using `agencyNameLocal` currently, but like will in the future.
      const dispatchPayloadsQuery = await client.query({
        query: GET_DISPATCH_PAYLOADS,
        variables: {},
      })
      if (dispatchPayloadsQuery && dispatchPayloadsQuery.data && dispatchPayloadsQuery.data.dispatch_payload) {
        const dispatchPayloadArray = dispatchPayloadsQuery.data.dispatch_payload
        setDispatchPayloads(dispatchPayloadArray)
      }
    }
    getDispatchPayloads(agencyName)
  }, [dispatchPayloadsState])

  useEffect(() => {
    const loading = dispatchPayloads ? false : true
    setPending(loading)
  }, [dispatchPayloads])

  function cellRenderer({ columnIndex, key, rowIndex, style }) {
    const columnName = columns[columnIndex].key
    if (columnName === 'message_id') {
      style = { ...style, fontSize: 'smaller' }
    }
    rowIndex -= 1
    if (dispatchPayloads && rowIndex >= 0 && dispatchPayloads[rowIndex]) {
      style = { ...style, overflow: 'scroll', border: 'thin solid black' }
      const darkStyle = { ...style, backgroundColor: 'rgb(220, 220, 220)' }
      let value = dispatchPayloads[rowIndex][columnName]
      if (typeof value === 'boolean') {
        value = value ? 'True' : 'False'
      }
      return (
        <div key={key} style={rowIndex % 2 === 0 ? darkStyle : style}>
          {value}
        </div>
      )
    } else {
      style = { ...style, border: 'solid black' }
      style = { ...style, fontSize: 'large' }
      return (
        <div key={key} style={style}>
          {columnName}
        </div>
      )
    }
  }

  let filteredColumns = columns
  if (!props.user.isAgi) {
    filteredColumns = columns
      .filter(col => columnsForCustomers.includes(col.key))
      .sort((a, b) => columnsForCustomers.indexOf(a.key) - columnsForCustomers.indexOf(b.key))
  }

  if (dispatchPayloads && dispatchPayloads.length) {
    return (
      <div style={{ paddingTop: '25px', height: '100%', width: '100%' }}>
        <AutoSizer>
          {({ width, height }) => (
            <MultiGrid
              cellRenderer={cellRenderer}
              columnCount={8}
              columnWidth={280}
              width={width}
              height={height}
              rowCount={dispatchPayloads.length + 1}
              rowHeight={70}
              fixedRowCount={1}
            />
          )}
        </AutoSizer>
      </div>
    )
  } else {
    return (
      <div style={{ paddingTop: '25px', height: '100%', width: '100%' }}>
        {pending ? (
          <div>
            <img src="/spinner.gif" alt="Spinner..." />{' '}
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DispatchPayloadDataGrid)
