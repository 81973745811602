import { LOGIN, LOGOUT, AUTHENTICATED, UNAUTHENTICATED, LOAD_AUTHORIZED_USER } from '../constants/ActionTypes'
import Immutable from 'immutable'
import User from '../records/user'

const AuthState = Immutable.Record({
  // eslint-disable-line new-cap
  firebaseAuthData: null,
  token: null,
  user: null,
  error: null,
}) as any

const initialState = new AuthState()

export default function reducer(
  state = initialState,
  action: { type?: string; payload?: any; authData?: any; token?: any; error?: any } = {}
) {
  switch (action.type) {
    case AUTHENTICATED:
      return state
        .set('firebaseAuthData', action.authData)
        .set('token', action.token)
        .set('user', new User({ uid: action.authData.uid, email: action.authData.email }))
    case LOGIN:
      if (action.error) {
        return state.set('error', action.payload)
      }
      return state.set('firebaseAuthData', action.payload).set(
        'user',
        new User({
          uid: action.payload.uid,
          email: action.payload.email,
        })
      )
    case LOAD_AUTHORIZED_USER:
      if (action.payload.user) {
        // since firebase doesn't return the user's uid (since it's the key), keep it when loading the full user data
        return state.set('user', new User({ uid: state.user.uid, ...action.payload.user }))
      }
      // TODO: if no user, this is a problem - display notification
      return state
    case UNAUTHENTICATED:
    case LOGOUT:
      return state.clear() // resets to default Record values
    default:
      return state
  }
}
