import { LOAD_DISPATCHES_FOR_AGENCY, SORT_DISPATCHES } from '../constants/ActionTypes'
import Immutable from 'immutable'
import Dispatch from '../records/dispatch'
import { sortDataGridItems } from '../utils/sorting'
import * as SortTypes from '../constants/SortTypes'

const DispatchesState = Immutable.Record({
  // eslint-disable-line new-cap
  data: Immutable.OrderedMap(),
  // default to sorting in reverse chronological order
  sorting: Immutable.Map({ attributeName: 'formattedTime', sortDirection: SortTypes.DESC }),
}) as any

const initialState = new DispatchesState()

export default function reducer(state = initialState, action: { type?: string; payload?: any } = {}) {
  switch (action.type) {
    case LOAD_DISPATCHES_FOR_AGENCY:
      const agencyName = action.payload.agencyName
      let newDispatches = Immutable.List()
      for (const [key, val] of Object.entries(action.payload.dispatches)) {
        newDispatches = newDispatches.push(new Dispatch({ key, ...val }))
      }
      return state.updateIn(['data', agencyName], () => newDispatches)
    case SORT_DISPATCHES:
      const { attributeName, sortDirection } = action.payload
      const sortedDevices = sortDataGridItems(state.data, attributeName, sortDirection)
      return state
        .update('sorting', map => map.set('attributeName', attributeName))
        .update('sorting', map => map.set('sortDirection', sortDirection))
        .set('data', sortedDevices)
    default:
      return state
  }
}
