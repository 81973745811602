import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Row, Col, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import FirebaseConnector from '../components/FirebaseConnector'
import ViewWrapper from '../components/ViewWrapper'
import { loadAgencyConfig } from '../actions/agencyConfigs'
import { loadAllAgencyAllowedSharing } from '../actions/agencyAllowedSharing'
import { loadAllCadCenters } from '../actions/cadCenters'
import EditAgencyConfigForm from '../containers/EditAgencyConfigForm'
import { agencyConfigsRef, cadCentersRef } from '../firebase'
import { getAgenciesMap, getAgencyAllowedSharingNode, getCadCentersList } from '../selectors'
import queryString from 'query-string'
import { withRouter, RouteComponentProps } from 'react-router-dom'

function mapStateToProps(state) {
  return {
    agencies: getAgenciesMap(state),
    agencyConfigs: state.agencyConfigs.data,
    agencyAllowedSharing: getAgencyAllowedSharingNode(state),
    cadCenters: getCadCentersList(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadAgencyConfig, loadAllAgencyAllowedSharing, loadAllCadCenters }, dispatch)
}
const getAllowsSharingAvlWith = (agencyAllowedSharing, agencyName) => {
  if (agencyAllowedSharing && agencyAllowedSharing[agencyName]) {
    const keys = Object.keys(agencyAllowedSharing)
    return keys.filter(a => agencyAllowedSharing[a][agencyName] && agencyAllowedSharing[a][agencyName].avl)
  }
  return null
}

const getAgencyAllowedSharingForAgencyName = (agencyAllowedSharing, agencyName) => {
  if (agencyAllowedSharing && agencyAllowedSharing[agencyName]) {
    return Object.keys(agencyAllowedSharing[agencyName])
  }
  return []
}

const renderEditAgencyConfigForm = props => {
  const { agencyConfigs, location, cadCenters } = props
  const agencyName = props.match.params.agencyName
  const urlQuery = queryString.parse(location.search)
  const editing = urlQuery.edit
  const creating = urlQuery.create
  const agencyConfig = agencyConfigs.get(agencyName)

  const exitEditMode = () =>
    props.history.push({
      pathname: location.pathname,
      search: queryString.stringify({ edit: undefined, create: undefined }),
    })

  const allowsSharingAvlWith = getAllowsSharingAvlWith(props.agencyAllowedSharing, agencyName)
  const activeAgencyAllowedSharing = getAgencyAllowedSharingForAgencyName(props.agencyAllowedSharing, agencyName)

  if (creating) {
    return (
      <EditAgencyConfigForm
        createNew={creating}
        agencyAllowedSharing={activeAgencyAllowedSharing}
        allowsSharingAvlWith={allowsSharingAvlWith}
        agencyName={agencyName}
        cadCenters={cadCenters}
        onSuccess={exitEditMode}
        onCancel={exitEditMode}
        location={location}
      >
        <LinkContainer
          key="cancel"
          to={{
            pathName: location.pathname,
            search: queryString.stringify({
              edit: undefined,
              create: undefined,
            }),
          }}
        >
          <Button>Cancel</Button>
        </LinkContainer>
      </EditAgencyConfigForm>
    )
  }
  if (editing && agencyConfig) {
    return (
      <EditAgencyConfigForm
        agencyConfig={agencyConfig}
        agencyAllowedSharing={activeAgencyAllowedSharing}
        allowsSharingAvlWith={allowsSharingAvlWith}
        agencyName={agencyName}
        cadCenters={cadCenters}
        onSuccess={exitEditMode}
        onCancel={exitEditMode}
        location={location}
      >
        <LinkContainer
          key="cancel"
          to={{
            pathName: location.pathname,
            search: queryString.stringify({
              edit: undefined,
            }),
          }}
        >
          <Button>Cancel</Button>
        </LinkContainer>
      </EditAgencyConfigForm>
    )
  }

  return (
    (agencyConfig && agencyConfig.size && (
      <div>
        <LinkContainer
          to={{
            pathName: location.pathname,
            search: queryString.stringify({
              edit: true,
            }),
          }}
          active={false}
        >
          <Button>Edit</Button>
        </LinkContainer>
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            {Object.keys(agencyConfig.toJS()).map(key => (
              <Row key={key}>
                <Col sm={4}>{key}</Col>
                <Col sm={8}>{JSON.stringify(agencyConfig[key], null, 4)}</Col>
              </Row>
            ))}
            <Row>
              <Col sm={4}>Allows sharing AVL with agencies:</Col>
              <Col sm={8}>{JSON.stringify(allowsSharingAvlWith)}</Col>
            </Row>
          </Col>
        </Row>
      </div>
    )) || (
      <div>
        <p>This agency, {agencyName}, does not have a config.</p>
        <LinkContainer
          to={{
            pathName: location.pathname,
            search: queryString.stringify({
              create: true,
            }),
          }}
          query={{ create: true }}
        >
          <Button variant="primary">Create Config</Button>
        </LinkContainer>
      </div>
    )
  )
}

type Props = {
  agencies: any
  agencyConfigs: Record<any, string>
  agencyAllowedSharing: Record<any, string>
  loadAgencyConfig: Function
  loadAllAgencyAllowedSharing: Function
  loadAllCadCenters: any
} & RouteComponentProps<any>

const AgencyConfigView = (props: Props) => {
  const { agencies } = props
  const agencyName = props.match.params.agencyName
  const agency = agencies.get(agencyName)

  return (
    <ViewWrapper header={`${agency && agency.displayName} Agency Config`} sizable>
      <FirebaseConnector
        key={`agencyConfig-${agencyName}`}
        firebaseRef={agencyConfigsRef.child(agencyName)}
        onNodeChange={props.loadAgencyConfig}
        shouldReturnKey
      />
      <FirebaseConnector key="cadCenters" firebaseRef={cadCentersRef} once onNodeChange={props.loadAllCadCenters} />
      {renderEditAgencyConfigForm(props)}
    </ViewWrapper>
  )
}

export default compose(
  withRouter,
  firebaseConnect(['/agencies/', `/agencyAllowedSharing/`]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AgencyConfigView)
