import { LOAD_ALL_QUEUES } from '../constants/ActionTypes'
import Immutable from 'immutable'

const DispatchesState = Immutable.Record({
  // eslint-disable-line new-cap
  data: null,
})

const initialState = new DispatchesState()

export default function reducer(state = initialState, action: { type?: string; payload?: any } = {}) {
  switch (action.type) {
    case LOAD_ALL_QUEUES:
      const newQueues = Immutable.fromJS(action.payload.queuesNode)
      return state.set('data', newQueues)
    default:
      return state
  }
}
