import './Loading.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { FaSync } from 'react-icons/fa'

class Loading extends Component<any, { delaying: boolean }> {
  static defaultProps = {
    delay: 500,
    inline: false,
  }
  timeout: any

  constructor(props: any) {
    super(props)
    this.state = {
      delaying: !!props.delay,
    }
  }

  componentDidMount() {
    if (this.props.delay) {
      this.timeout = setTimeout(this.handleDisplay, this.props.delay)
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  }

  handleDisplay() {
    this.timeout = null
    this.setState({ delaying: false })
  }

  render() {
    const { delay, inline, text } = this.props
    const { delaying } = this.state
    const className = classNames('Loading', {
      'Loading--delaying': delaying,
      'Loading--displaying': delay && !delaying,
      'Loading--inline': inline,
    })
    return (
      <div className={className}>
        <FaSync />
        {text && <div className="Loading__text">{text}&hellip;</div>}
      </div>
    )
  }
}

export default Loading
