import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import { MAPBOX_ACCESS_TOKEN } from '../constants'
import dateFormat from 'dateformat'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import NC from 'nearest-color'
import { AVL_COLORS } from './ColorSelector'

// Need to pass in an object
const colorObj = AVL_COLORS.reduce((acc, item) => {
  const hashColor = `#${item}`
  acc[hashColor] = hashColor
  return acc
}, {})
const nearestColor = NC.from(colorObj)

const mapDivStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'relative',
  flexGrow: 1,
}

const mapStyle = {
  height: '100%',
}

// const startingPosition = [44.5667, -123.2833] // corvallis
const mapId = 'mapbox.streets'

const millisecondsInEightHours = 28800000
const crumbStaleTime = 30000

const getAvlIcon = (timeStamp, color, groupSettings, avlRemoveFromMapAfterTime, serverTimeOffset = 0) => {
  const currentTimeStamp = new Date().getTime() + serverTimeOffset
  const removeFromMapTimeMilliseconds = avlRemoveFromMapAfterTime
    ? avlRemoveFromMapAfterTime * 3600000
    : millisecondsInEightHours
  let iconUrl
  if (currentTimeStamp - timeStamp < crumbStaleTime) {
    // AVL is up-to-date so use a colored dot
    let avlColor
    if (groupSettings && groupSettings.avlColor) {
      avlColor = groupSettings.avlColor.toLowerCase().slice(-6)
    } else {
      avlColor = color.toLowerCase().slice(-6)
    }

    // If color doesn't exist in our predefined list of allowable colors, use the nearest color
    if (!AVL_COLORS.includes(avlColor)) {
      const nearestObj = nearestColor(`#${avlColor}`)
      const nearest = nearestObj && nearestObj.value.replace('#', '')
      if (!nearest) {
        throw new Error('No nearest color for ' + avlColor)
      }
      avlColor = nearest
    }
    iconUrl = require(`../images/avl_location_${avlColor}.png`)
  } else if (currentTimeStamp - timeStamp < removeFromMapTimeMilliseconds || avlRemoveFromMapAfterTime === 0) {
    // if avlRemoveFromMapAfterTime is explicitly set to 0, never change to red X
    iconUrl = require(`../images/avl_location_9e9e9e.png`) // grey dot
  } else {
    iconUrl = require('../images/avl_location_9e9e9e_X.png') // grey dot with X
  }

  return L.icon({
    iconUrl,
    iconSize: [42, 42], // size of the icon
    iconAnchor: [21, 21], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -12], // point from which the popup should open relative to the iconAnchor
  })
}

const maxZoom = 21

const mapProps = {
  ref: 'map',
  style: mapStyle,
  // center: startingPosition,
  maxZoom: maxZoom,
  zoom: 13,
  zoomControl: false, // we start the map without a zoomControl, then add it to the bottomright on mount
}

type Props = {
  avlLocations: any[]
  deleteAvlLocation: any
  selectedAgency: string // lets component know we switched agency
  groupsByDevice: Record<string, any>
  avlDisplayGroups: Record<string, any>
  avlRemoveFromMapAfterTime: number
  initialLatLng: Record<string, any>
  serverTimeOffset: number
} & RouteComponentProps

type State = {
  awaitingNewAvlLocations: boolean
  updateTimerFired: boolean
  initialSelect: boolean
}

class AdminLeafletMap extends React.Component<Props, State> {
  lastUpdate: number
  refs: any
  constructor(props) {
    super(props)
    this.state = {
      awaitingNewAvlLocations: true,
      updateTimerFired: false,
      initialSelect: true,
    }

    this.lastUpdate = Date.now()
  }

  shouldComponentUpdate(nextProps) {
    const currentTime = Date.now()
    if (currentTime - this.lastUpdate > 4000) {
      this.lastUpdate = currentTime
      return true
    }
    return false
  }

  componentDidMount() {
    const leafletMap = this.refs.map.leafletElement
    new L.Control.Zoom({ position: 'bottomright' }).addTo(leafletMap)
  }

  componentWillUpdate(newProps, newState) {
    this._setZoomToInitialAvlExtent(newProps)
  }

  componentWillReceiveProps(newProps) {
    this._setZoomToInitialAvlExtent(newProps)
  }

  _setZoomToInitialAvlExtent = newProps => {
    const leafletMap = this.refs.map.leafletElement
    if (newProps.selectedAgency !== this.props.selectedAgency || this.state.initialSelect) {
      this.setState({ awaitingNewAvlLocations: true })
      this.setState({ updateTimerFired: false })
      this.setState({ initialSelect: false })
      setTimeout(() => {
        this.setState({ updateTimerFired: true })
      }, 50)
    }
    if (
      newProps.avlLocations &&
      newProps.avlLocations !== this.props.avlLocations &&
      this.state.awaitingNewAvlLocations &&
      this.state.updateTimerFired
    ) {
      this.setState({ awaitingNewAvlLocations: false })
      const arrayOfLatLngs = newProps.avlLocations.map(loc => {
        return { lat: loc.lat, lng: loc.lng }
      })
      if (arrayOfLatLngs.length) {
        const bounds = L.latLngBounds(arrayOfLatLngs).pad(0.05) // add 5% padding
        leafletMap.fitBounds(bounds, { animate: true })
      }
    }
  }

  render() {
    return (
      <div style={mapDivStyle}>
        <Map
          {...mapProps}
          center={this.props.initialLatLng && [this.props.initialLatLng.lat, this.props.initialLatLng.lng]}
        >
          <TileLayer
            url={`http://api.tiles.mapbox.com/v4/${mapId}/{z}/{x}/{y}.png?access_token=${MAPBOX_ACCESS_TOKEN}`}
            attribution="&copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
            maxZoom={maxZoom}
          />
          {this.props.avlLocations &&
            this.props.avlLocations.map(location => {
              const groupSettings = this.props.groupsByDevice && this.props.groupsByDevice[location.hardwareUid]
              if (groupSettings === true || (groupSettings && groupSettings.hide === true)) {
                return null
              }
              return (
                <Marker
                  key={location.key}
                  position={[location.lat, location.lng]}
                  icon={getAvlIcon(
                    location.timeStamp,
                    location.avlColor,
                    groupSettings,
                    this.props.avlRemoveFromMapAfterTime,
                    this.props.serverTimeOffset
                  )}
                >
                  <Popup>
                    <span>
                      Display Name: {location.displayName}
                      <br />
                      Timestamp: {location.timeStamp && dateFormat(location.timeStamp, 'mm/dd/yyyy h:MM:ss TT')}
                      <br />
                      HardwareUid: {location.hardwareUid}
                      <br />
                      <button
                        onClick={() =>
                          this.props.history.push(`/devices/${this.props.selectedAgency}/${location.hardwareUid}`)
                        }
                      >
                        Edit device
                      </button>
                      <button
                        onClick={() => {
                          const reallyDelete = confirm('Are you sure you want to delete this?') // eslint-disable-line no-restricted-globals
                          if (reallyDelete) {
                            this.props.deleteAvlLocation(location)
                          }
                        }}
                      >
                        Delete avlLocation
                      </button>
                    </span>
                  </Popup>
                </Marker>
              )
            })}
        </Map>
      </div>
    )
  }
}

export default withRouter(AdminLeafletMap)
