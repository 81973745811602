import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { Card, Collapse, Button, ButtonGroup } from 'react-bootstrap'
import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import { Formik, Field, Form } from 'formik'
import { FormikReactSelect, FormikDateRange } from '../components/FormikFields'
import { InspectionConfig } from '../types/InspectionTypes'

type Props = {
  activeConfig?: InspectionConfig
  onSubmit: Function
}

const getInspectionPropertiesForQuery = (filter: string, inspectorName: string, failedProps: string[]) => {
  const booleanFilters = ['flush', 'flowTest']
  const hasBooleanFilter = filter && booleanFilters.includes(filter)
  if (!hasBooleanFilter && !inspectorName && !failedProps.length) return
  const properties: any = {}
  if (hasBooleanFilter) {
    properties[filter] = true
  }
  if (inspectorName) {
    properties.inspector = inspectorName
  }
  if (failedProps.length) {
    failedProps.forEach(p => {
      properties[p] = 'Fail'
    })
  }
  return properties
}

const formValuesToVariables = vals => {
  const { filter, inspector, failedProps } = vals
  const { startDate, endDate } = vals.dateRange
  const variables = {
    startDate: startDate ? startDate.startOf('day').toISOString() : null,
    endDate: endDate ? endDate.endOf('day').toISOString() : null,
    properties: getInspectionPropertiesForQuery(filter, inspector, failedProps),
    gt: null,
    eq: null,
  }
  if (filter === 'Passed') {
    variables.eq = 0
  }
  if (filter === 'Single') {
    variables.eq = 1
  }
  if (filter === 'Multiple') {
    variables.gt = 1
  }
  return variables
}

const INITIAL_VALUES = { filter: '', dateRange: { startDate: null, endDate: null }, inspector: '', failedProps: [] }

const getFilterOptions = (config: InspectionConfig) => {
  if (config.pass_fail_type === 'double') {
    return [
      { value: '', label: 'All Inspections' },
      { value: 'Passed', label: 'Passed Inspections' },
      { value: 'Single', label: 'First Failure' },
      { value: 'Multiple', label: 'Multiple Failure' },
    ]
  }
  if (config.inspection_type === 'hydrant') {
    return [
      { value: '', label: 'All Inspections' },
      { value: 'flush', label: 'Flush' },
      { value: 'flowTest', label: 'Flow Test' },
    ]
  }
  return []
}

const getInspectorOptions = (inspectors: string[]) => {
  return [{ value: '', label: 'Any' }].concat(inspectors.map(i => ({ label: i, value: i })))
}

const InspectionQueryFilterControl = ({ activeConfig, onSubmit }: Props) => {
  if (!activeConfig) return null
  const [showFilters, setShowFilters] = useState(false)
  const toggleShowFilters = () => {
    setShowFilters(!showFilters)
  }

  const formikRef = useRef(null)
  const reset = () => {
    if (formikRef.current && formikRef.current.setValues) {
      formikRef.current.setValues(INITIAL_VALUES)
    }
  }

  const handleSubmit = values => {
    onSubmit(formValuesToVariables(values))
  }
  const inspectorEnum = _.get(activeConfig, ['schema', 'properties', 'inspector', 'enum'], [])

  const passFailProperties = _.get(activeConfig, ['schema', 'passFailProperties'], [])
  const passFailOptions = passFailProperties.map(p => ({
    value: p,
    label: _.get(activeConfig, ['schema', 'properties', p, 'title'], p),
  }))

  useEffect(() => {
    reset()
    setShowFilters(false)
  }, [activeConfig])

  return (
    <Card bg="light" className="text-left">
      <Card.Header onClick={toggleShowFilters}>
        {showFilters ? <FaChevronDown /> : <FaChevronRight />} Filter Inspections
      </Card.Header>
      <Collapse in={showFilters}>
        <Card.Body>
          <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit} ref={formikRef}>
            <Form>
              <Field
                name="filter"
                component={FormikReactSelect}
                label="Inspection Result:"
                options={getFilterOptions(activeConfig)}
                innerRef={formikRef}
              />
              <Field name="dateRange" component={FormikDateRange} label="Date Range:" />
              {inspectorEnum.length ? (
                <Field
                  name="inspector"
                  component={FormikReactSelect}
                  label="Inspector:"
                  options={getInspectorOptions(inspectorEnum)}
                />
              ) : null}
              {passFailProperties.length ? (
                <Field
                  name="failedProps"
                  component={FormikReactSelect}
                  label="Failed Properties"
                  options={passFailOptions}
                  multi
                />
              ) : null}
              <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '1.25rem' }}>
                <ButtonGroup>
                  <Button variant="outline-dark" onClick={reset}>
                    Clear
                  </Button>
                  <Button type="submit">Apply</Button>
                </ButtonGroup>
              </div>
            </Form>
          </Formik>
        </Card.Body>
      </Collapse>
    </Card>
  )
}

export default InspectionQueryFilterControl
