import React from 'react'
import { version } from '../../package.json'

const currentYear = new Date().getFullYear()

const VersionText = () => (
  <div className={version.includes('beta') ? 'beta-version-text' : 'version-text'}>
    © {currentYear} Alsea Geospatial, Inc. v{version}
  </div>
)

export default VersionText
