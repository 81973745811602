/* @flow */
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, formValues, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { Row, Col, Alert, Tab, ButtonToolbar, Nav, NavItem, NavLink } from 'react-bootstrap'
import { LoadingButton, FormField, FormFieldFileInput } from '../components/BootstrapReduxForm'
import { client, UPDATE_AGENCY_BOUNDARY, GET_AGENCY_BOUNDARY } from '../apollo'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import ReactMapboxGl, { Popup, GeoJSONLayer, Layer, Feature, Source } from 'react-mapbox-gl'
import * as turf from '@turf/turf'
import proj4 from 'proj4'
// import { bindActionCreators } from 'redux'
// import { diff as diffStyles } from 'mapbox-gl-style-spec'
// import mapboxLogo from '../../assets/images/mapbox-logo.png'
// import DrawControl from 'react-mapbox-gl-draw'
// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { MAPBOX_ACCESS_TOKEN } from '../constants/index'
import 'mapbox-gl/dist/mapbox-gl.css'

const Map = ReactMapboxGl({
  accessToken: MAPBOX_ACCESS_TOKEN,
})
const shapefile = require('shapefile')

// TODO: set up mapbox-gl-draw: If there currently is a boundary loaded, have the option to edit existing, or draw new

const BOUNDARY_SHAPEFILE_FIELD = 'uploadedBoundaryShapefile'

const mapDivStyle = {
  // height: '600px',
  // width: '600px',
  // paddingTop: '10px',
  marginLeft: '-25px',
}
const mapContainerStyle = {
  height: '100vh',
  width: '150vh',
  border: '10px solid white',
}

const boundaryEditingInstructionsStyle = {
  marginTop: '20px',
  fontSize: '110%',
  color: '#5eb5bb',
}
const boundaryEditingInstructionsText =
  'The existing boundary is displayed in grey. The proposed boundary upload is displayed in red'

type Props = {
  existingAgencyBoundary: any
  convertedShapefile: any
  location: any
  validateEditingBoundaryForm: Function
  handleStyleLoad: Function
  getMapCenter: Function
} & InjectedFormProps &
  RouteComponentProps

type State = {
  existingAgencyBoundary: any
  convertedShapefile: any
  hasFormError: boolean
  hasBoundary: boolean
}

function validateEditingBoundaryForm(data) {
  let errors = {}
  if (!data[BOUNDARY_SHAPEFILE_FIELD]) {
    errors[BOUNDARY_SHAPEFILE_FIELD] = 'No data selected'
  } else if (data && data[BOUNDARY_SHAPEFILE_FIELD] && data[BOUNDARY_SHAPEFILE_FIELD].length > 2) {
    errors = { _error: 'Only two files are allowed for upload' }
  } else if (data && data[BOUNDARY_SHAPEFILE_FIELD] && data[BOUNDARY_SHAPEFILE_FIELD].length === 2) {
    const fileTypeArray = [data[BOUNDARY_SHAPEFILE_FIELD][0].extension, data[BOUNDARY_SHAPEFILE_FIELD][1].extension]
    if (!fileTypeArray.includes('shp')) {
      errors = { _error: 'missing .shp file from upload' }
    } else if (!fileTypeArray.includes('prj')) {
      errors = { _error: 'missing .prj file from upload' }
    }
  } else if (data && data[BOUNDARY_SHAPEFILE_FIELD] && data[BOUNDARY_SHAPEFILE_FIELD].length < 2) {
    const fileType = data[BOUNDARY_SHAPEFILE_FIELD][0].extension
    if (fileType === 'shp') {
      errors = { _error: 'missing .prj file from upload' }
    } else if (fileType === 'prj') {
      errors = { _error: 'missing .shp file from upload' }
    } else if (fileType === 'zip') {
      errors = { _error: 'files need to be un-zipped for upload' }
    } else {
      errors = { _error: 'error: file uploads are not .prj or .shp type' }
    }
  }
  return errors
}

class EditingBoundaryForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      existingAgencyBoundary: null,
      convertedShapefile: null,
      hasFormError: false,
      hasBoundary: true,
    }
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  async grabAgencyBoundary(agencyName) {
    try {
      const res = await client.query({
        query: GET_AGENCY_BOUNDARY,
        variables: {
          agencyName,
        },
        fetchPolicy: 'network-only',
      })
      const existingAgencyBoundary = res.data.getAgencyBoundary
      if (existingAgencyBoundary) {
        this.setState({
          existingAgencyBoundary,
          hasBoundary: true,
        })
      } else {
        this.setState({
          hasBoundary: false,
        })
      }
    } catch (err) {
      console.log('ERROR: grabAgencyBoundary', err)
    }
  }

  async componentDidMount() {
    const { location } = this.props
    const agencyName = queryString.parse(location.search).agencyName
    await this.grabAgencyBoundary(agencyName)
  }
  async componentDidUpdate(prevProps) {
    const oldPath = prevProps.location
    const newPath = this.props.location
    if (queryString.parse(oldPath.search).agencyName !== queryString.parse(newPath.search).agencyName) {
      await this.grabAgencyBoundary(queryString.parse(newPath.search).agencyName)
    }
  }

  async onChangeHandler(newValue) {
    // process uploaded files for map display
    if (newValue) {
      try {
        let projectionFile
        let shapefileFile
        let result
        if (newValue[0].extension === 'shp') {
          projectionFile = newValue[1]
          shapefileFile = newValue[0]
        } else if (newValue[0].extension === 'prj') {
          projectionFile = newValue[0]
          shapefileFile = newValue[1]
        }
        // use 'shapefile' to convert .shp to geoJSON
        const shapefileAsBlob = new Blob([shapefileFile], { type: 'shapefile/shp' })

        if (projectionFile && shapefileFile) {
          // @ts-ignore
          result = await shapefileAsBlob.arrayBuffer().then(function(buffer) {
            return shapefile
              .open(buffer)
              .then(source =>
                source.read().then(function log(result) {
                  if (result.value) {
                    return result.value
                  }
                })
              )
              .catch(error => alert(error.stack)) // cannot 'throw' an error here
            // SyntaxError: Support for the experimental syntax 'throwExpressions' isn't currently enabled
          })
        } else {
          this.setState({
            hasFormError: true,
          })
          return
        }
        // use FileReader to read projection definition
        const reader = new FileReader()
        const prjBlob = new Blob([projectionFile])
        await reader.readAsBinaryString(prjBlob)
        const projectionString = await new Promise((resolve, reject) => {
          reader.onload = function() {
            resolve(reader.result)
          }
          if (reader.error) {
            reject(reader.error)
          }
        })
        // use 'proj4' to re-project the geoJSON's coordinates to WGS84
        if (result && projectionString) {
          if (result && result.geometry && result.geometry.type && result.geometry.type === 'MultiPolygon') {
            const coordinatesToUpdate = JSON.parse(JSON.stringify(result.geometry.coordinates))
            coordinatesToUpdate.forEach((coordinate, index1) => {
              coordinate.forEach((polygonCoordinates, index2) => {
                if (polygonCoordinates) {
                  polygonCoordinates.forEach((coordinatePair, index3) => {
                    if (coordinatePair.length === 2) {
                      const newCoord = proj4(projectionString, 'WGS84', coordinatePair)
                      coordinatesToUpdate[index1][index2][index3] = newCoord
                    } else {
                      throw new Error('Invlaid geoJSON coordinates')
                    }
                  })
                }
              })
            })
            result.geometry.coordinates = coordinatesToUpdate
            this.setState({ convertedShapefile: result })
          } else if (result && result.geometry && result.geometry.type && result.geometry.type === 'Polygon') {
            const coordinatesToUpdate = JSON.parse(JSON.stringify(result.geometry.coordinates))
            coordinatesToUpdate.forEach((coordinate, index1) => {
              coordinate.forEach((polyCoordinates, index2) => {
                if (polyCoordinates && polyCoordinates.length === 2) {
                  const newCoord = proj4(projectionString, 'WGS84', polyCoordinates)
                  coordinatesToUpdate[index1][index2] = newCoord
                } else {
                  this.setState({
                    hasFormError: true,
                  })
                  throw new Error("There is a problem with the polygon geoJSON's coordinate array")
                }
              })
            })
            result.geometry.coordinates = coordinatesToUpdate
            this.setState({ hasFormError: false, convertedShapefile: result })
          } else {
            this.setState({
              hasFormError: true,
            })
            throw new Error('Boundary data must be either Polygon or Multi-Polygon geometry type')
          }
        } else if (!result && projectionString) {
          this.setState({
            hasFormError: true,
          })
          throw new Error('Unable to process shapefile')
        } else if (result && !projectionString) {
          this.setState({
            hasFormError: true,
          })
          throw new Error('There was a problem reading the .prj file')
        } else {
          this.setState({
            hasFormError: true,
          })
          throw new Error('There was a problem with the uploaded files')
        }
      } catch (e) {
        console.warn(e)
        alert(e.message)
      }
    }
  }

  // handleStyleLoad = map => {
  //   this._map = map
  // }

  submitEditingBoundaryForm = async () => {
    // convert geoJson into multiPolygon before submit.
    const { location } = this.props
    const agencyName = queryString.parse(location.search).agencyName
    // @ts-ignore
    const boundaryToSubmit = this.state.convertedShapefile
    if (boundaryToSubmit && agencyName) {
      if (boundaryToSubmit.geometry && boundaryToSubmit.geometry.type && boundaryToSubmit.geometry.type === 'Polygon') {
        // set as multiPolygon geometry type to satisfy postgres
        boundaryToSubmit.geometry.type = 'MultiPolygon'
        // nest polygon one more deep to satisfy multiPolygon geom type
        // @ts-ignore
        boundaryToSubmit.geometry.coordinates = [this.state.convertedShapefile.geometry.coordinates]
      }

      //  only pass in geometry property object
      try {
        const res = await client.mutate({
          mutation: UPDATE_AGENCY_BOUNDARY,
          variables: {
            agencyName,
            boundaryGeom: boundaryToSubmit.geometry,
          },
        })
        alert(res.data.updateAgencyBoundary)
      } catch (e) {
        console.warn(e)
        alert('ERROR: ' + e.message)
      }
    }
  }

  getMapCenter = existingBoundaryGeoJson => {
    if (existingBoundaryGeoJson && existingBoundaryGeoJson.geometry && existingBoundaryGeoJson.geometry.coordinates) {
      try {
        let centroid = turf.centroid(existingBoundaryGeoJson)
        let [lng, lat] = centroid.geometry.coordinates
        if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
          return [lng, lat]
        }
      } catch (error) {
        console.log('turf error: ', error)
      }
      return [-123.262, 44.5646]
    }
  }

  render() {
    const { error, submitting, handleSubmit, reset } = this.props
    const agencyName = queryString.parse(location.search).agencyName
    const { existingAgencyBoundary, convertedShapefile, hasFormError, hasBoundary } = this.state

    const navProps: { variant: 'pills' | 'tabs'; pullLeft: boolean; stacked: boolean } = {
      variant: 'pills',
      pullLeft: true,
      stacked: true,
    }

    return (
      <div>
        <div style={boundaryEditingInstructionsStyle}>{boundaryEditingInstructionsText}</div>
        {hasBoundary === false && <div> {agencyName} does not have a boundary loaded yet </div>}
        <Tab.Container id="editorBoundaryTabs" defaultActiveKey="left-tabs">
          <Row className="boundaryUpdateHasOptions">
            <Col sm={4}>
              <Nav {...navProps}>
                <NavItem>
                  <NavLink eventKey="uploadBoundary">Upload New</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="drawNewBoundary">Draw New</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="editExistingBoundary">Edit Existing</NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="uploadBoundary">
                  <form style={{ paddingTop: '10px' }}>
                    <Field
                      name={BOUNDARY_SHAPEFILE_FIELD}
                      component={FormFieldFileInput}
                      label="Upload .shp and .prj"
                      multiple={true}
                      help="Upload two single un-zipped files: one .prj and one .shp"
                      onChange={this.onChangeHandler}
                    />
                    <button
                      type="button"
                      //@ts-ignore
                      onClick={reset}
                    >
                      Remove Files
                    </button>
                    <Row>
                      <Col md={{ span: 6, offset: 3 }}>{error && <Alert variant="danger">{error}</Alert>}</Col>
                    </Row>
                    <Row>
                      <Col sm={{ offset: 6 }}>
                        <ButtonToolbar>
                          <LoadingButton
                            variant="primary"
                            onClick={handleSubmit(this.submitEditingBoundaryForm)}
                            label="Submit"
                            loading={submitting}
                            loadingLabel="Submitting"
                            disabled={hasFormError ? true : false}
                          />
                        </ButtonToolbar>
                      </Col>
                    </Row>
                  </form>
                </Tab.Pane>
                <Tab.Pane eventKey="drawNewBoundary">Functionality Coming Soon</Tab.Pane>
                <Tab.Pane eventKey="editExistingBoundary">Coming Soon</Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        {!existingAgencyBoundary && hasBoundary === true && (
          <img style={{ width: '100px' }} src="/spinner.gif" alt="Spinner..." />
        )}
        {existingAgencyBoundary && (
          <>
            <div style={mapDivStyle}>
              <Map
                style="mapbox://styles/mapbox/streets-v9"
                containerStyle={mapContainerStyle}
                center={this.getMapCenter(existingAgencyBoundary)}
                zoom={[9]}
                // @ts-ignore
                onStyleLoad={this.handleStyleLoad}
              >
                {/* <DrawControl />{' '} */}
                <GeoJSONLayer
                  data={existingAgencyBoundary}
                  linePaint={{
                    'line-color': 'hsl(360, 1%, 49%)',
                    'line-opacity': 0.85,
                    'line-width': 2,
                  }}
                />
                {convertedShapefile && (
                  <GeoJSONLayer
                    data={convertedShapefile}
                    linePaint={{
                      'line-color': 'hsl(360, 90%, 61%)',
                      'line-opacity': 0.85,
                      'line-width': 2,
                    }}
                  />
                )}
              </Map>
            </div>
          </>
        )}
      </div>
    )
  }
}
// FormField.propTypes = {
//   label: PropTypes.string,
//   value: PropTypes.object,
//   handleChange: PropTypes.func,
//   error: PropTypes.string,
// }

export default compose(
  withRouter,
  reduxForm({
    form: 'editingBoundary',
    validate: validateEditingBoundaryForm,
  }),
  connect()
)(EditingBoundaryForm)
