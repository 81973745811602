import React from 'react'
import { Row, Col } from 'react-bootstrap'
import VersionText from './VersionText'
import IVLogo from '../images/IV_logo_icon_only.svg'

type Props = {
  header?: string
  children?: any
  overrideStyle?: Record<string, any>
  sizable?: boolean
  leftHeader?: any
  rightHeader?: any
}

const ViewWrapper = (props: Props) => {
  const headers = !!props.leftHeader || !!props.rightHeader

  return (
    <div className="container text-center">
      <Col xl={props.sizable ? null : { span: 6, offset: 3 }}>
        <div className="container-div" style={props.overrideStyle || null}>
          {/* <img className="logo-border" src={IVLogo} alt="IV Logo" /> */}
          <Row>
            <Col>
              <h1>{props.header}</h1>
              {headers ? (
                <Row>
                  <Col sm={6}>{props.leftHeader}</Col>
                  <Col sm={6} style={{ marginLeft: 'auto' }}>
                    {props.rightHeader}
                  </Col>
                </Row>
              ) : null}
              {/* <Col sm={headers ? 3 : null}>{props.leftHeader}</Col>
            <Col sm={headers ? 6 : 12}>
              <h1>{props.header}</h1>
            </Col>
            <Col sm={headers ? 3 : null}>{props.rightHeader}</Col> */}
            </Col>
          </Row>
          <Row>{props.children}</Row>
        </div>
      </Col>
      <VersionText />
    </div>
  )
}

export default ViewWrapper
