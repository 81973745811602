import React from 'react'
import FormField from './FormField'
import { FormFieldProps } from './FormField'

type Props = {
  rows?: number
}

const FormFieldTextArea = (props: FormFieldProps & Props) => (
  <FormField {...props}>
    <textarea rows={props.rows} {...props.input} className="form-control" />
  </FormField>
)

export default FormFieldTextArea
