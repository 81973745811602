import React from 'react'
import { Col } from 'react-bootstrap'
import Input from '../Input'
import { WrappedFieldProps } from 'redux-form'

type SimpleBootstrapInputProps = {
  hasFeedback: boolean
  size: number
  variant: string
  addonBefore: React.ReactNode
  type: string
  placeholder?: string
} & WrappedFieldProps

const SimpleBootstrapInput = (props: SimpleBootstrapInputProps) => {
  const error = props.meta.touched && props.meta.error
  return (
    <Col>
      <Input
        variant={error ? 'error' : undefined}
        size={props.size}
        hasFeedback={props.hasFeedback}
        type={props.type}
        placeholder={props.placeholder}
        addonBefore={props.addonBefore}
        {...props.input}
      />
      {error && (
        <span id="helpBlock" className="help-block error">
          {error}
        </span>
      )}
    </Col>
  )
}

export default SimpleBootstrapInput
