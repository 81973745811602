import React from 'react'
import { Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ViewWrapper from '../components/ViewWrapper'

export default class ChangePasswordSuccessView extends React.Component {
  render() {
    return (
      <ViewWrapper header="Password successfully changed">
        <LinkContainer to="/">
          <Button variant="primary" size="lg">
            Back to home
          </Button>
        </LinkContainer>
      </ViewWrapper>
    )
  }
}
