import {
  LOAD_DISPATCHES_FOR_AGENCY,
  SORT_DISPATCHES,
  ADD_DISPATCH,
  ADD_DISPATCH_PARSER,
} from '../constants/ActionTypes'
import { dispatchToAgencyRef } from '../firebase'

export function loadDispatchesForAgency(agencyName, dispatches) {
  return {
    type: LOAD_DISPATCHES_FOR_AGENCY,
    payload: {
      agencyName,
      dispatches,
    },
  }
}

export function sortDispatches(attributeName, sortDirection) {
  return {
    type: SORT_DISPATCHES,
    payload: {
      attributeName,
      sortDirection,
    },
  }
}

export function addDispatch(dispatchObj) {
  return {
    type: ADD_DISPATCH,
    payload: dispatchToAgencyRef.push(dispatchObj),
  }
}

export function addDispatchParser(dispatchObj) {
  return {
    type: ADD_DISPATCH_PARSER,
    payload: dispatchToAgencyRef.push(dispatchObj),
  }
}
