import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { firebaseConnect } from 'react-redux-firebase'
import { addUser } from '../actions/users'
import { getAgenciesList } from '../selectors'
import { Alert, Col, Row, Button, ButtonToolbar } from 'react-bootstrap'
import { FaUser } from 'react-icons/fa'
import { FormFieldInput, LoadingButton } from '../components/BootstrapReduxForm'
import { LinkContainer } from 'react-router-bootstrap'
import queryString from 'query-string'
import { withRouter, RouteComponentProps } from 'react-router-dom'

const EMAIL_FIELD = 'email'
const AGENCY_NAME_FIELD = 'agencyName'

function validateAddUserForm(data) {
  const errors = {}
  if (!data[EMAIL_FIELD]) {
    errors[EMAIL_FIELD] = 'Please enter a valid email address'
  } else if (!data[EMAIL_FIELD].includes('@') || !data[EMAIL_FIELD].includes('.')) {
    errors[EMAIL_FIELD] = 'Email address must contain "@" and "."'
  }
  if (!data[AGENCY_NAME_FIELD]) {
    errors[AGENCY_NAME_FIELD] = 'agencyName must be chosen'
  }
  return errors
}

function mapStateToProps(state) {
  return {
    agencies: getAgenciesList(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addUser,
    },
    dispatch
  )
}

type Props = {
  agencies: Record<string, any>
  addUser: any
} & InjectedFormProps &
  RouteComponentProps

class AddUserForm extends React.Component<Props> {
  submitAddUserForm = data => {
    return this.props.addUser(data[EMAIL_FIELD], data[AGENCY_NAME_FIELD]).then(result => {
      if (result.error) {
        throw new SubmissionError({ _error: result.payload.message })
      }
      // move out of addUser mode, and show users of the agency you just added to
      this.props.history.push({
        pathname: '/users',
        search: queryString.stringify({ addUser: undefined, agencyName: data[AGENCY_NAME_FIELD] }),
      })
    })
  }

  render() {
    const { agencies, error, handleSubmit, submitting } = this.props
    const urlQuery = queryString.parse(this.props.location.search)
    return (
      <form>
        <Col>
          <Row>
            <Row>
              <Field
                name={EMAIL_FIELD}
                component={FormFieldInput}
                type="email"
                label="Email Address"
                placeholder="Enter email"
                addonBefore={<FaUser />}
              />
            </Row>
            <Row>
              <Field name={AGENCY_NAME_FIELD} component={FormFieldInput} type="select" label="agencyName">
                <option key="default" value="">
                  Choose an agency
                </option>
                {agencies.map(agency => (
                  <option key={agency.agencyName} value={agency.agencyName}>
                    {agency.agencyName}
                  </option>
                ))}
              </Field>
            </Row>
            {error && (
              <Row>
                <Col sm={{ span: 6, offset: 3 }}>
                  <Alert variant="danger">{error}</Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={{ offset: 7 }}>
                <ButtonToolbar>
                  <LoadingButton
                    variant="primary"
                    loading={submitting}
                    label="Submit"
                    loadingLabel="Adding"
                    onClick={handleSubmit(this.submitAddUserForm)}
                  />
                  <LinkContainer
                    to={{ pathname: '/users', search: queryString.stringify({ ...urlQuery, addUser: undefined }) }}
                    active={false}
                  >
                    <Button>Cancel</Button>
                  </LinkContainer>
                </ButtonToolbar>
              </Col>
            </Row>
          </Row>
        </Col>
      </form>
    )
  }
}

export default compose(
  withRouter,
  firebaseConnect((props, firebase) => ['/agencies/']),
  reduxForm({
    form: 'addUser',
    validate: validateAddUserForm,
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddUserForm)
