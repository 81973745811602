import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
// import { saveUser } from '../actions/users'
import { updateDevice } from '../actions/devices'
import { Row, Col, Alert, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import {
  LoadingButton,
  FormFieldCheckbox,
  FormFieldInput,
  FormFieldColorSelector,
} from '../components/BootstrapReduxForm'
import Device from '../records/device'
import { agencyConfigsRef, cadCentersRef, devicesRef } from '../firebase'

const DISPLAY_NAME_FIELD = 'displayName'
const CAD_AVL_SHARING_ID_FIELD = 'cadAvlSharingId'
const AVL_COLOR_FIELD = 'avlColor'
const RETIRED_FIELD = 'retired'

const validate = data => {
  const errors = {}
  if (data[DISPLAY_NAME_FIELD]) {
    if (data[DISPLAY_NAME_FIELD].length > 50) {
      errors[DISPLAY_NAME_FIELD] = 'Length must be no more than 50 characters'
    }
  }
  if (data[CAD_AVL_SHARING_ID_FIELD]) {
    if (data[CAD_AVL_SHARING_ID_FIELD].length > 100) {
      errors[CAD_AVL_SHARING_ID_FIELD] = 'Length must be no more than 100 characters'
    }
  }
  return errors
}

const asyncValidate = (values, dispatch, props) => {
  const agencyName = props.device.agencyName
  const cadAvlSharingId = values[CAD_AVL_SHARING_ID_FIELD]
  // if we're not setting the cadAvlSharingId (or setting it to null), we don't want to validate it
  if (!cadAvlSharingId) {
    return Promise.resolve()
  }
  return agencyConfigsRef
    .child(agencyName)
    .child('shareAvlWithCad')
    .once('value')
    .then(snap => {
      const cadCenterNamesObj = snap.val()
      if (!cadCenterNamesObj) {
        return Promise.reject({
          [CAD_AVL_SHARING_ID_FIELD]: "This device's agency is not set up to share with any CAD centers",
        })
      }
      const cadCenterNames = Object.keys(cadCenterNamesObj)
      return Promise.all(
        cadCenterNames.map(name =>
          cadCentersRef
            .child(name)
            .once('value')
            .then(snp => snp.val())
        )
      )
    })
    .then(cadCenters => {
      for (const cad of cadCenters) {
        if (!cad) {
          return Promise.reject({
            [CAD_AVL_SHARING_ID_FIELD]:
              "One of the CAD centers that this device shares with doesn't exist in the cadCenters node",
          })
        }
        if (cad.avlFormat === 'TAIP') {
          if (![0, 4].includes(cadAvlSharingId.length)) {
            return Promise.reject({
              [CAD_AVL_SHARING_ID_FIELD]: `Must be 4 characters long since this
                          device shares with a CAD center using the TAIP format`,
            })
          }
          if (!cadAvlSharingId.match(/^[A-Z0-9]+$/)) {
            return Promise.reject({
              [CAD_AVL_SHARING_ID_FIELD]: `Must be alphanumeric and uppercase
                                           since this device shares with a CAD
                                           center using the TAIP format`,
            })
          }
        }
      }
      // cadAvlSharingId is formatted properly - now check if it's unique
      return devicesRef
        .child(agencyName)
        .once('value')
        .then(snap => snap.val())
        .then(devices => {
          for (const [key, dev] of Object.entries(devices as Record<string, any>)) {
            // skip checking the device against itself
            if (key === props.device.key) continue
            if (dev.cadAvlSharingId === cadAvlSharingId) {
              return Promise.reject({
                [CAD_AVL_SHARING_ID_FIELD]: 'A device with this ID already exists',
              })
            }
          }
          return Promise.resolve()
        })
    })
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDevice,
    },
    dispatch
  )
}

type Props = {
  onSuccess: Function
  // TODO: figure out correct type
  onCancel: (event: any) => void
  // device we're editing
  device: any
  updateDevice: Function
  // agencyConfig of the device we're editing
  agencyConfig: Record<string, any>
  // provided by redux-form
  error: string
  handleSubmit: Function
  initialize: Function
  submitting: boolean
}

type State = {
  cadAvlSharingIdEnabled: boolean
}

class EditDeviceForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { cadAvlSharingIdEnabled: false }
    const device = props.device
    this.loadExistingDeviceIntoForm(device)
  }

  componentDidMount() {
    const device = this.props.device
    agencyConfigsRef
      .child(device.agencyName)
      .child('shareAvlWithCad')
      .once('value')
      .then(snap => snap.val())
      .then(cadCentersObj => {
        if (cadCentersObj) {
          // device's agency is sharing with at least 1 cadCenter, so display cad ID input
          this.setState({ cadAvlSharingIdEnabled: true })
        }
      })
  }

  /**
   * Load the input device object as the default inputs to the form.
   */
  loadExistingDeviceIntoForm(device) {
    const { displayName, cadAvlSharingId, avlColor, isActive } = device
    this.props.initialize({
      [DISPLAY_NAME_FIELD]: displayName,
      [CAD_AVL_SHARING_ID_FIELD]: cadAvlSharingId,
      [AVL_COLOR_FIELD]: avlColor,
      [RETIRED_FIELD]: !isActive || false,
    })
  }

  submitEditDeviceForm = data => {
    const { agencyName, key } = this.props.device
    const updateObj = {
      avlColor: data[AVL_COLOR_FIELD],
      cadAvlSharingId: data[CAD_AVL_SHARING_ID_FIELD] || null,
      displayName: data[DISPLAY_NAME_FIELD],
      isActive: !data[RETIRED_FIELD],
    }
    return this.props.updateDevice(agencyName, key, updateObj).then(result => {
      if (result.error) {
        throw new SubmissionError({ _error: result.payload.message })
      }
      this.props.onSuccess()
    })
  }

  render() {
    const { error, handleSubmit, submitting } = this.props
    return (
      <form>
        {/* <Row>
          <StaticField centered labelColSize={8} label="Email:" value={user.email} />
          <StaticField centered labelColSize={8} label="Agency Name:" value={user.agencyName} />
        </Row> */}
        <div>
          <Row>
            <Field
              name={DISPLAY_NAME_FIELD}
              component={FormFieldInput}
              type="text"
              label="Display Name"
              help="Device's name as displayed in AVL"
            />
          </Row>
          {this.state.cadAvlSharingIdEnabled && (
            <Row>
              <Field
                name={CAD_AVL_SHARING_ID_FIELD}
                component={FormFieldInput}
                type="text"
                label="CAD Center unique ID"
                help="Unique ID expected by the CAD centers this device shares with"
              />
            </Row>
          )}
          <Row>
            <Field
              name={AVL_COLOR_FIELD}
              component={FormFieldColorSelector}
              label="AVL Color"
              help="Color that this device will have in the AVL layer"
            />
          </Row>
          <Row>
            <Field
              name={RETIRED_FIELD}
              component={FormFieldCheckbox}
              label="Retired"
              help="Mark this device as permanently out of commission or no longer running IncidentView"
            />
          </Row>
        </div>
        {error && (
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row className="form-group">
          <Col sm={{ span: 6, offset: 6 }}>
            <ButtonToolbar>
              <ButtonGroup>
                <LoadingButton
                  variant="primary"
                  size="lg"
                  label="Save"
                  loading={submitting}
                  loadingLabel="Saving"
                  onClick={handleSubmit(this.submitEditDeviceForm)}
                  type="submit"
                />
                <Button className="ml-2" variant="outline-dark" onClick={this.props.onCancel} size="lg">
                  Cancel
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'editDevice',
    asyncBlurFields: [CAD_AVL_SHARING_ID_FIELD],
    asyncValidate: asyncValidate,
    validate: validate,
  })(EditDeviceForm)
)
