import {
  LOAD_AGENCY_ALLOWED_SHARING,
  LOAD_ALL_AGENCY_ALLOWED_SHARING,
  SAVE_AGENCY_ALLOWED_SHARING,
} from '../constants/ActionTypes'
import { agencyAllowedSharingRef } from '../firebase'

export function loadAgencyAllowedSharing(agencyAllowedSharing) {
  return {
    type: LOAD_AGENCY_ALLOWED_SHARING,
    payload: {
      agencyAllowedSharing,
    },
  }
}

export function loadAllAgencyAllowedSharing(agencyAllowedSharing) {
  return {
    type: LOAD_ALL_AGENCY_ALLOWED_SHARING,
    payload: {
      agencyAllowedSharing,
    },
  }
}

export function saveAgencyAllowedSharing(agencyName, agencyToShareWith, share) {
  return {
    type: SAVE_AGENCY_ALLOWED_SHARING,
    payload: share
      ? agencyAllowedSharingRef
          .child(agencyToShareWith)
          .child(agencyName)
          .child('avl')
          .set(true)
      : agencyAllowedSharingRef
          .child(agencyToShareWith)
          .child(agencyName)
          .set(null),
  }
}
