import { createSelector, Selector } from 'reselect'
import * as Immutable from 'immutable'
import { objectToArray } from '../utils'
import Agency from '../records/agency'
import queryString from 'query-string'

export const getAgencyName = state => state.auth.user && state.auth.user.agencyName
export const getAgencyNameUserOrUrl = (state: any, ownProps?: any): string => {
  const urlQuery = queryString.parse(ownProps.location.search)
  const agencyName = urlQuery.agencyName
  return state.auth.user.isAgi ? agencyName : state.auth.user.agencyName
}

export const getAgencies = (state: any): { [agencyName: string]: {} } => state.firebase.data.agencies

export const getAgenciesMap: (state: {}) => Immutable.Map<string, Agency> = createSelector(
  getAgencies,
  agencies =>
    Immutable.Map(
      agencies
        ? Object.entries(agencies).reduce((acc, [agencyName, ag]) => {
            acc[agencyName] = new Agency({ ...ag, key: agencyName })
            return acc
          }, {})
        : {}
    )
)

export const getAgenciesList: (state: {}) => Immutable.List<Agency> = createSelector(
  getAgencies,
  agencies => Immutable.List(objectToArray(agencies).map(ag => new Agency(ag)))
)

const getAgencyConfigsImmutable = state => state.agencyConfigs.data
export const getAgencyConfig = createSelector(
  getAgencyNameUserOrUrl,
  getAgencyConfigsImmutable,
  (agencyName, agencyConfigs) => agencyConfigs.get(agencyName)
)

const getAgencyConfigs = (state: any): any => state.firebase.data.agencyConfigs
export const getAgencyConfigJs = createSelector(
  getAgencyNameUserOrUrl,
  getAgencyConfigs,
  (agencyName, agencyConfigs) => {
    return agencyConfigs && agencyConfigs[agencyName]
  }
)

export const getDispatchGroupsUsed = createSelector(
  getAgencyConfigsImmutable,
  getAgencyName,
  (agencyConfigs, agencyName) => {
    let output = false
    if (agencyConfigs && agencyConfigs.get(agencyName)) {
      output = agencyConfigs.get(agencyName).get('dispatchGroups')
    }
    return output
  }
)

export const getAgencyAllowedSharingNode = (state: any): { [key: string]: { [key: string]: any } } =>
  state.firebase.data.agencyAllowedSharing
export const getAgencyAllowedSharingJs = createSelector(
  getAgencyNameUserOrUrl,
  getAgencyAllowedSharingNode,
  (agencyName, sharing) => sharing && sharing[agencyName]
)
export const getAgencyAllowedSharing = createSelector(
  getAgencyAllowedSharingJs,
  agencyAllowedSharingJs => Immutable.fromJS(agencyAllowedSharingJs)
)

export const getReceivesSharedAvlFromArray = createSelector(
  getAgencyConfigs,
  getAgencyNameUserOrUrl,
  (agencyConfigs, agencyName) => {
    if (agencyConfigs && agencyConfigs[agencyName]) {
      const { receivesSharedAvlFrom } = agencyConfigs[agencyName]
      if (receivesSharedAvlFrom) {
        return Object.entries(receivesSharedAvlFrom).reduce((acc, [aName, isSharing]) => {
          if (isSharing) {
            acc.push(aName)
          }
          return acc
        }, [])
      }
    }
    return []
  }
)
export const getReceivesSharedAvlFromList = createSelector(
  getAgencyConfigsImmutable,
  getAgencyNameUserOrUrl,
  (agencyConfigs, agencyName) => {
    let output = []
    if (agencyConfigs && agencyConfigs.get(agencyName)) {
      output = agencyConfigs.get(agencyName).receivesSharedAvlFromList
    } else if (agencyName) {
      output = [agencyName]
    }
    return output
  }
)

export const getAgencyAllowedSharingAvl = createSelector(
  getAgencyAllowedSharingJs,
  sharing => {
    let agenciesThatAllowSharingAvl = []
    if (sharing && Object.keys(sharing).length) {
      for (const [agencyName, { avl }] of Object.entries(sharing)) {
        if (avl) {
          agenciesThatAllowSharingAvl.push(agencyName)
        }
      }
    }
    return agenciesThatAllowSharingAvl
  }
)

export const getAgencyAllowsSharingAvlWithNode = state => state.firebase.data.agencyAllowsSharingAvlWith
export const getAgencyAllowsSharingAvlWith = createSelector(
  getAgencyNameUserOrUrl,
  getAgencyAllowsSharingAvlWithNode,
  (agencyName, shared) => shared && shared[agencyName]
)

export const getAgencySharesAvlWithNode = state => state.firebase.data.agencySharesAvlWith
export const getAgencySharesAvlWithJs = createSelector(
  getAgencyNameUserOrUrl,
  getAgencySharesAvlWithNode,
  (agencyName, shared) => shared && shared[agencyName]
)

export const getAgencySharesAvlWith = createSelector(
  getAgencySharesAvlWithJs,
  shared => {
    let agenciesThatHaveSharedAvl = []
    if (shared && Object.keys(shared).length) {
      for (const [agencyName] of Object.entries(shared)) {
        agenciesThatHaveSharedAvl.push(agencyName)
      }
    }
    return agenciesThatHaveSharedAvl
  }
)
