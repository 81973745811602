import { compose, createStore, applyMiddleware } from 'redux'
import promiseMiddleware from 'redux-promise'
import { reactReduxFirebase } from 'react-redux-firebase'
import { reduxFirestore } from 'redux-firestore'
import createLogger from 'redux-logger'
import { firebaseApp } from '../firebase'
import Immutable from 'immutable'
// import { devTools } from 'redux-devtools'
import rootReducer from '../reducers'

let createStoreWithMiddleware

const logger = createLogger({
  // transform logged Immutable objects into regular JS, so it is human readable
  stateTransformer: state => {
    const newState = {}
    for (const i of Object.keys(state)) {
      if (Immutable.Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS()
      } else {
        newState[i] = state[i]
      }
    }
    return newState
  },
  collapsed: true,
})

const middleware = [promiseMiddleware]

if (process.env.REACT_APP_USE_REDUX_LOGGER) {
  middleware.push(logger)
}

// Currently our Redux DevTools isn't working the greatest, so we're simply using the Redux Devtools Chrome extension
// if (process.env.REACT_APP_DEBUG) {
//   createStoreWithMiddleware = compose(applyMiddleware(...middleware), devTools())(createStore)
// } else {
createStoreWithMiddleware = compose(
  reactReduxFirebase(firebaseApp, {}),
  reduxFirestore(firebaseApp),
  applyMiddleware(...middleware),
  // @ts-ignore
  window.devToolsExtension ? window.devToolsExtension() : f => f
)(createStore)
// }

function configureStore(initialState) {
  const store = createStoreWithMiddleware(rootReducer, initialState)

  //TODO: consider removing this
  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers/index')

      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}

//@ts-ignore
const store = configureStore(window.__INITIAL_STATE__)

export default store
