import { LOAD_GIS_DATA_SOURCES, UPDATE_SPECIAL_SEARCH_DATA } from '../constants/ActionTypes'

export function loadGisDataSources(gisDataSources) {
  return {
    type: LOAD_GIS_DATA_SOURCES,
    payload: {
      gisDataSources,
    },
  }
}

export function updateSpecialSearchData(updatedSpeicalSearchData) {
  return {
    type: UPDATE_SPECIAL_SEARCH_DATA,
    payload: {
      updatedSpeicalSearchData,
    },
  }
}
