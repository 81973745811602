import Immutable from 'immutable'
import * as roles from '../constants/UserRoles'

const UserRecord = Immutable.Record({
  // eslint-disable-line new-cap
  key: null, // not in firebase data, but needed in app
  uid: null, // not in firebase data, but needed in app
  email: null,
  agencyName: null,
  hasTemporaryPassword: false,
  roles: new Immutable.List(),
})

export default class User extends UserRecord {
  // have getter and setter for key, since uid comes in from firebase as key
  constructor(params) {
    super({
      ...params,
      // uid comes in from firebase as key
      uid: params.uid || params.key,
      key: params.key || params.uid,
      roles: params.roles ? new Immutable.List(Object.keys(params.roles)) : new Immutable.List(),
    })
  }

  // returns true if user is an AGI user
  get isAgi() {
    return this.roles.includes(roles.ROLE_AGI)
  }

  get isSuperAdmin() {
    return this.roles.includes(roles.ROLE_SUPER_ADMIN)
  }

  get isAgencyAdmin() {
    return this.roles.includes(roles.ROLE_AGENCY_ADMIN)
  }

  toFirebaseObject() {
    // we store roles as a map rather than array in Firebase
    const userRolesObj = {}
    for (const role of this.roles) {
      userRolesObj[role] = true
    }
    return this.set('roles', new Immutable.Map(userRolesObj))
      .set('uid', null)
      .set('key', null)
      .toJS()
  }

  toFirestoreObject() {
    // we store roles as an array in Firestore
    let obj = this.toJS()
    delete obj.key
    delete obj.uid
    return obj
  }
}
