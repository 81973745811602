import { createSelector } from 'reselect'
import Immutable from 'immutable'
import { sortDataGridItems } from '../utils/sorting'
import { getAgencyNameUserOrUrl, getAgencyAllowedSharing, getAgencyAllowedSharingJs } from './agenciesSelectors'
import { getUser } from './authSelectors'

const regexAgiTestEmail = /iv\..*\...@gmail\.com/g
const regexAlseageoEmail = /.*@alseageo\..*/g

const getAllAgencyDevicesNodeImmutable = state => state.devices.data
const getAllAgencyDevicesNode = state => state.firebase.data.devices
const getSorting = state => state.devices.sorting

export const getAllDevicesList = createSelector(
  getAllAgencyDevicesNodeImmutable,
  allAgencyDevicesNode => {
    let newDevices: Immutable.List<any> = Immutable.List()
    for (const agencyDevicesObj of allAgencyDevicesNode.values()) {
      for (const device of agencyDevicesObj.values()) {
        newDevices = newDevices.push(device)
      }
    }
    return newDevices
  }
)

export const getAgencyDevicesList = createSelector(
  getAllDevicesList,
  getAgencyNameUserOrUrl,
  (allAgencyDevicesNode, agencyName) => {
    const filteredAgencyDevicesNode = allAgencyDevicesNode.filter(d => {
      return d.agencyName === agencyName
    })
    return filteredAgencyDevicesNode
  }
)

export const getAllNonAgiDevicesList = createSelector(
  getAllDevicesList,
  allDevicesList => {
    return allDevicesList.filter(
      dev => !(dev.userEmail && (dev.userEmail.match(regexAgiTestEmail) || dev.userEmail.match(regexAlseageoEmail)))
    )
  }
)

export const getUniqueDevicesArray = createSelector(
  getUser,
  getAllAgencyDevicesNode,
  getAgencyAllowedSharingJs,
  getAgencyNameUserOrUrl,
  (user, allAgencyDevicesNode, agencyAllowedSharing, agencyName) => {
    const agencies = agencyAllowedSharing ? [agencyName].concat(Object.keys(agencyAllowedSharing)) : [agencyName]
    let devices = []
    let uniqueDeviceIds = {}
    if (agencies) {
      for (const agency of agencies) {
        if (allAgencyDevicesNode && allAgencyDevicesNode[agency]) {
          for (const [key, device] of Object.entries(allAgencyDevicesNode[agency])) {
            if (!uniqueDeviceIds[key]) {
              devices.push({ ...device, key, agencyName: agency })
            }
            uniqueDeviceIds[key] = true
          }
        }
      }
    }

    if (user.isAgi || user.isSuperAdmin || agencyName === 'or_agi_fd') {
      return devices
    }

    // Filter out any AGI devices
    return devices.filter(
      dev => !(dev.userEmail && (dev.userEmail.match(regexAgiTestEmail) || dev.userEmail.match(regexAlseageoEmail)))
    )
  }
)

// used in EditDeviceGroupForm for the device dropdown that spans all shared agencies
export const getUniqueDevicesList = createSelector(
  getAllAgencyDevicesNodeImmutable,
  getAgencyAllowedSharing,
  getAgencyNameUserOrUrl,
  (allAgencyDeviceNode, agencyAllowedSharing, agencyName) => {
    const agencies = agencyAllowedSharing
      ? agencyAllowedSharing
          .keySeq()
          .toList()
          .unshift(agencyName)
      : Immutable.List([agencyName])
    let devices = Immutable.List()
    let uniqueDeviceIds = {}
    agencies &&
      agencies.forEach(agency => {
        if (allAgencyDeviceNode.get(agency)) {
          for (const device of allAgencyDeviceNode.get(agency).values()) {
            if (!uniqueDeviceIds[device.get('key')]) {
              devices = devices.push(device)
            }
            uniqueDeviceIds[device.get('key')] = true
          }
        }
      })
    return devices
  }
)

export const getUniqueNonAgiDevicesArray = createSelector(
  getUniqueDevicesArray,
  uniqueDevicesArray => {
    return uniqueDevicesArray.filter(
      dev => !(dev.userEmail && (dev.userEmail.match(regexAgiTestEmail) || dev.userEmail.match(regexAlseageoEmail)))
    )
  }
)

export const getAllNonAgiDeviceIds = createSelector(
  getUniqueDevicesArray,
  devices => {
    return devices
      .filter(
        dev => !(dev.userEmail && (dev.userEmail.match(regexAgiTestEmail) || dev.userEmail.match(regexAlseageoEmail)))
      )
      .map(dev => dev.hardwareUid)
  }
)

export const getAllNonAgiDeviceIdsImmutable = createSelector(
  getAllNonAgiDevicesList,
  devicesList => devicesList.map(dev => dev.hardwareUid)
)

export const getAllDevicesListSorted = createSelector(
  getAllDevicesList,
  getSorting,
  (allAgencyDevicesList, sorting) => {
    return sortDataGridItems(allAgencyDevicesList, sorting.get('attributeName'), sorting.get('sortDirection'))
  }
)

export const getAgencyDevicesListSorted = createSelector(
  getAllDevicesList,
  getSorting,
  getAgencyNameUserOrUrl,
  (allDevicesList, sorting, agencyName) => {
    if (!agencyName) {
      return sortDataGridItems(allDevicesList, sorting.get('attributeName'), sorting.get('sortDirection'))
    } else {
      return sortDataGridItems(
        allDevicesList.filter(d => d.agencyName === agencyName),
        sorting.get('attributeName'),
        sorting.get('sortDirection')
      )
    }
  }
)

export const getAllNonAgiDevicesListSorted = createSelector(
  getAllNonAgiDevicesList,
  getSorting,
  (allNonAgiDevicesList, sorting) => {
    return sortDataGridItems(allNonAgiDevicesList, sorting.get('attributeName'), sorting.get('sortDirection'))
  }
)

const deviceIsAndroid = d => d.systemVersion && d.systemVersion.includes('Android')
const deviceIsIos = d => d.systemVersion && (d.systemVersion.includes('iOS') || d.systemVersion.includes('iPhone'))
const deviceIsPc = d => d.systemVersion && (d.systemVersion.includes('browser') || d.systemVersion.includes('Windows'))
const deviceFiltersByOs = {
  android: deviceIsAndroid,
  ios: deviceIsIos,
  pc: deviceIsPc,
}

const getNumDevicesByAgencyOsFilter = (deviceArraysByAgency, os) => {
  return Object.entries(deviceArraysByAgency as Record<string, any[]>).reduce((acc, [agencyName, devices]) => {
    acc[agencyName] = os ? devices.filter(deviceFiltersByOs[os]).length : devices.length
    return acc
  }, {})
}

const getDeviceArraysByAgency = createSelector(
  getAllAgencyDevicesNodeImmutable,
  allAgencyDevicesNode => {
    const deviceMap = {}
    for (const agencyDevicesObj of allAgencyDevicesNode.values()) {
      for (const device of agencyDevicesObj.values()) {
        if (
          device.userEmail &&
          (device.userEmail.match(regexAgiTestEmail) || device.userEmail.match(regexAlseageoEmail))
        ) {
          continue
        }
        if (!deviceMap[device.agencyName]) {
          deviceMap[device.agencyName] = []
        }
        deviceMap[device.agencyName].push(device)
      }
    }
    return deviceMap
  }
)

/**
 * Get device totals for each agency (using no filter by OS)
 */
export const getNumDevicesByAgency = createSelector(
  getDeviceArraysByAgency,
  deviceArraysByAgency => getNumDevicesByAgencyOsFilter(deviceArraysByAgency, null)
)

export const getNumAndroidDevicesByAgency = createSelector(
  getDeviceArraysByAgency,
  deviceArraysByAgency => getNumDevicesByAgencyOsFilter(deviceArraysByAgency, 'android')
)

export const getNumIosDevicesByAgency = createSelector(
  getDeviceArraysByAgency,
  deviceArraysByAgency => getNumDevicesByAgencyOsFilter(deviceArraysByAgency, 'ios')
)

export const getNumPcDevicesByAgency = createSelector(
  getDeviceArraysByAgency,
  deviceArraysByAgency => getNumDevicesByAgencyOsFilter(deviceArraysByAgency, 'pc')
)
