// import 'bootstrap/dist/css/bootstrap.css'
// import 'leaflet/dist/leaflet.css'
import './app.css'
import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { Provider } from 'react-redux'
import Root from './containers/Root'
import store from './store'
import history from './history'
import firebase from './firebase'
import { authenticated, unauthenticated, loadAuthorizedUser } from './actions/auth'

const target = document.getElementById('root')

// this listens for any changes in authentication - startup, login, logout, and token refreshes.
firebase.auth().onIdTokenChanged(async user => {
  if (user) {
    let idToken = await user.getIdToken()
    const decodedIdToken = await user.getIdTokenResult()
    const claims = decodedIdToken.claims['https://hasura.io/jwt/claims']
    if (!claims) {
      try {
        await axios({
          url: 'https://us-central1-incidentview.cloudfunctions.net/setHasuraClaims',
          method: 'POST',
          data: { idToken },
        })
        // Now that Firebase Auth has added Hasura claims to our token, refetch it from the server
        await user.getIdToken(true)
        // The above getIdToken(true) causes onIdTokenChanged to be called again, so just return
        return
      } catch (error) {
        Sentry.withScope(scope => {
          scope.setExtras({ message: `Failed to setHasuraClaims for ${user.uid}` })
          Sentry.captureException(error)
        })
        alert('Failed getting claims from server. Please contact support.')
        return
      }
    }
    store.dispatch(authenticated(user, idToken))
    const fullUser = await firebase
      .database()
      .ref(`users/${user.uid}`)
      .once('value')
      .then(snap => snap.val())
    store.dispatch(loadAuthorizedUser(fullUser))
  } else {
    store.dispatch(unauthenticated())
  }
})

//Sentry for error tracking
if (process.env.REACT_APP_DEBUG !== '1') {
  Sentry.init({ dsn: 'https://e5bc2592895b4495808eb5764d981f0a@sentry.io/1499892' })
}

const node = (
  <Provider store={store}>
    <Root
      store={store}
      history={history} // maybe this uses routerHistory param instead?
      debug={process.env.REACT_APP_DEBUG === '1'}
      debugExternal={process.env.REACT_APP_DEBUG_EXTERNAL === '1'}
    />
  </Provider>
)

ReactDOM.render(node, target)
