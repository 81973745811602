import { createSelector } from 'reselect'

const getGisDataSources = state => state.gisDataSources.data

export const getSpecialSearchFields = createSelector(
  getGisDataSources,
  gisDataSources => {
    const gisDataSourcesJs = gisDataSources.toJS()
    let searchableFields = []
    for (const key in gisDataSourcesJs) {
      if (gisDataSourcesJs[key].searchableFields && gisDataSourcesJs[key].searchableFields.search) {
        let temp = { table: key, trigger: gisDataSourcesJs[key].searchableFields.search }
        searchableFields.push(temp)
      }
    }
    return searchableFields
  }
)
