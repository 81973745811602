import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ViewWrapper from '../components/ViewWrapper'
import LoginForm from '../containers/LoginForm'

const mapStateToProps = state => ({
  signedIn: !!state.auth.user,
})

type Props = {
  location: any // react-router location object
  signedIn: boolean
}

const LoginView = (props: any) => {
  const [timeoutOver, setTimeoutOver] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutOver(true)
    }, 800)
    return () => clearTimeout(timer)
  }, [props.signedIn])
  const shouldRender = props.signedIn || timeoutOver
  if (!shouldRender) {
    return null
  }
  return (
    <ViewWrapper header={props.signedIn ? 'Account' : 'Login'}>
      <LoginForm location={props.location} />
    </ViewWrapper>
  )
}

export default connect(mapStateToProps)(LoginView)
