import './Help.css'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaQuestionCircle } from 'react-icons/fa'
import React from 'react'

type Props = {
  text: string
}

const Help = (props: Props) => {
  const tooltip = <Tooltip id={props.text.replace(' ', '_')}>{props.text}</Tooltip>
  return (
    <OverlayTrigger overlay={tooltip} delay={{ show: 300, hide: 150 }}>
      <FaQuestionCircle className="Help" />
    </OverlayTrigger>
  )
}

export default Help
