import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase'
import { firebaseRoot } from '../firebase'
import { getFeatureSetGuidForDataSource } from '../utils'
import { client, UNDO_FEATURE_SET_REPLACE } from '../apollo'
import ViewWrapper from '../components/ViewWrapper'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import EditGisDataSourceForm from '../containers/EditGisDataSourceForm'
import { LoadingButton } from '../components/BootstrapReduxForm'
import { Modal, Button, FormControl, Alert } from 'react-bootstrap'
import { getInspectionConfigDataSources } from '../selectors'
const firebase = require('firebase')
require('firebase/functions')

const StringProtectedLoadingButton = ({ securityString, onClick, ...props }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const canConfirm = inputValue === securityString

  const closeModal = () => {
    setModalOpen(false)
    setInputValue('')
  }

  const onInputChange = e => {
    setInputValue(e.target.value)
  }
  const confirmOperation = () => {
    closeModal()
    onClick()
  }

  return (
    <>
      <LoadingButton {...props} onClick={() => setModalOpen(true)} />
      <Modal show={modalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          Confirm - {props.label} - {securityString}
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontWeight: 'bold' }}>This Action cannot be undone!</p>
          <p>Please type the layer name in order to confirm</p>
          <FormControl type="text" value={inputValue} onChange={onInputChange} />
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-around' }}>
            <Button onClick={closeModal}>Cancel</Button>
            <Button variant="primary" disabled={!canConfirm} onClick={confirmOperation}>
              Confirm
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

function mapStateToProps(state, ownProps) {
  const dataSources =
    state.firebase.data.gisDataSources && state.firebase.data.gisDataSources[ownProps.match.params.agencyName]
  const dataSourceConfigs =
    state.firebase.data.gisDataSourceConfigurations &&
    state.firebase.data.gisDataSourceConfigurations[ownProps.match.params.agencyName]
  return {
    user: state.auth.user,
    dataSource: dataSources && dataSources[ownProps.match.params.dataSourceName],
    dataSourceConfig: dataSourceConfigs && dataSourceConfigs[ownProps.match.params.dataSourceName],
    inspectionConfigDataSources: getInspectionConfigDataSources(state),
  }
}

type Props = {
  user: Record<string, any>
  dataSource: Record<string, any>
  // from react-router
  params: Record<string, any>
  dataSourceConfig: Record<string, any>
  inspectionConfigDataSources: any[]
} & RouteComponentProps<any>

const GisLayerView = ({
  user,
  dataSource,
  dataSourceConfig,
  // deleteGisDataPostgres,
  match,
  history,
  inspectionConfigDataSources,
}: Props) => {
  const { agencyName, dataSourceName } = match.params
  const isInspectionDataSource = inspectionConfigDataSources.includes(dataSourceName)

  const [deleting, setDeleting] = useState(false)
  const [undoInProgress, setUndoInProgress] = useState(false)

  const onSuccess = async () => {
    // Generate styleJson for agency
    await firebaseRoot.child(`/queues/generateStyleJson/tasks`).push({ agencyName })
    // Return to gis page
    history.push(`/gis?agencyName=${agencyName}`)
  }

  const onCancel = async () => {
    // Return to gis page
    history.push(`/gis?agencyName=${agencyName}`)
  }

  const deleteLayer = async () => {
    // eslint-disable-next-line no-restricted-globals
    const sureYouWantToDelete = confirm(
      'Are you sure you want to delete this? Its associated data will be permanently deleted and ' +
        'will no longer be available in your IncidentView maps.'
    )

    if (sureYouWantToDelete) {
      setDeleting(true)
      try {
        const featureSetGuid = await getFeatureSetGuidForDataSource(agencyName, dataSourceName)

        const deleteFeatureSet = firebase.functions().httpsCallable('deleteFeatureSet')
        await deleteFeatureSet({ featureSetGuid })

        await firebaseRoot.child(`/gisDataSourceConfigurations/${agencyName}/${dataSourceName}`).remove()

        // Re-generate style JSON
        await onSuccess()
        setDeleting(false)
        alert(`Successfully deleted ${dataSourceName} data source and its associated feature set.`)

        setDeleting(false)
      } catch (error) {
        setDeleting(false)
        console.error(error)
        alert(`Failed to delete ${dataSourceName} data source and its associated feature set. ` + error)
      }
    }
  }
  const undoFeatureSetReplace = async () => {
    // eslint-disable-next-line no-restricted-globals
    const sureYouWantToUndo = confirm('Are you sure? This cannot be undone!')
    if (sureYouWantToUndo) {
      setUndoInProgress(true)
      try {
        const featureSetGuid = await getFeatureSetGuidForDataSource(agencyName, dataSourceName)
        const res = await client.mutate({
          mutation: UNDO_FEATURE_SET_REPLACE,
          variables: {
            featureSetGuid,
          },
        })
        setUndoInProgress(false)
        console.log(res)
      } catch (e) {
        setUndoInProgress(false)
        alert('Failed to undo update' + e)
        console.error(e)
      }
    }
  }

  return (
    <ViewWrapper header={`Edit ${agencyName}'s ${dataSourceName} layer settings`}>
      {/* TODO: set up a form to edit individual values */}
      {/* {dataSource &&
        (user.isSuperAdmin || user.isAgencyAdmin) && (
          <EditDataSourceForm dataSource={dataSource} onSuccess={onSuccess} onCancel={history.goBack} />
        )} */}

      {user && user.isSuperAdmin && (
        <div>
          <EditGisDataSourceForm
            agencyName={agencyName}
            dataSourceName={dataSourceName}
            dataSource={dataSource}
            dataSourceConfig={dataSourceConfig}
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
          {isInspectionDataSource ? (
            <Alert variant="warning">
              This data source cannot be deleted as it is currently in use by one or more inspeciton configs
            </Alert>
          ) : null}
          <StringProtectedLoadingButton
            variant="danger"
            label="Delete"
            loadingLabel="Deleting"
            loading={deleting}
            onClick={deleteLayer}
            disabled={isInspectionDataSource}
            securityString={dataSourceName}
          />
          <StringProtectedLoadingButton
            variant="danger"
            label="Undo Feature Set Replace"
            loadingLabel="Undoing"
            loading={undoInProgress}
            style={{ marginLeft: '4px' }}
            disabled={true}
            onClick={undoFeatureSetReplace}
            securityString={dataSourceName}
          />
        </div>
      )}
    </ViewWrapper>
  )
}

export default compose(
  withRouter,
  firebaseConnect(props => {
    return [
      `/gisDataSources/${props.match.params.agencyName}/${props.match.params.dataSourceName}`,
      `/gisDataSourceConfigurations/${props.match.params.agencyName}/${props.match.params.dataSourceName}`,
    ]
  }),
  connect(mapStateToProps),
  firestoreConnect((props, firestore) => {
    const paths = [
      {
        collection: 'inspectionConfigs',
        where: [['agencyName', '==', props.match.params.agencyName]],
        type: 'once',
      },
    ]
    return paths
  })
)(GisLayerView)
