import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { useQuery } from 'react-apollo'
import { firestore } from '../firebase'
import { Button, Row, Col } from 'react-bootstrap'
import { GET_LAST_EDIT_OR_DELETE_DATE } from '../apollo'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { LastDataChange } from '../types/EditHistoryTypes'

type lastEditImportOrDeleteDateObj = { [key: string]: LastDataChange }

type Props = {
  agencyNameFilter: string
  firestore: any
  lastEditImportOrDeleteDate?: lastEditImportOrDeleteDateObj
  vectorLastExportedTime?: any
} & RouteComponentProps

type State = {
  vectorLastExportedTime?: any
}

const EditorExportDateEditDateWarningDisplay = (props: Props) => {
  const { agencyNameFilter } = props

  const [vectorLastExportedTime, setVectorLastExportedTime] = useState(null)

  const dataChangeQuery = useQuery(GET_LAST_EDIT_OR_DELETE_DATE(agencyNameFilter), {
    variables: {
      agencyNameFilter,
    },
  })

  useEffect(() => {
    const dateQuery = firestore
      .collection('functions')
      .doc('exportVectorTiles')
      .collection('tasks')
      .where('agencyName', '==', `${agencyNameFilter}`)
      .orderBy('_completeTime', 'desc')
      .limit(1)
    dateQuery.get().then(results => {
      if (results.empty) {
        setVectorLastExportedTime(null)
      } else {
        const result = results.docs[0]
        if (result.data()) {
          const timeAsSeconds = result.data()._completeTime
          const timeResult = timeAsSeconds.toDate()
          setVectorLastExportedTime(timeResult.toString())
        } else {
          console.info(
            `Firestore query for ${agencyNameFilter} results are not empty but do not contain a vectorTileLastExport`
          )
          setVectorLastExportedTime(null)
        }
      }
    })
  }, [agencyNameFilter])

  const displayDataChangeDate = () => {
    if (dataChangeQuery && dataChangeQuery.data) {
      let lastEditOrImportDate = 0
      let lastDeleteDate = 0
      if (
        dataChangeQuery.data.feature &&
        dataChangeQuery.data.feature[0] &&
        dataChangeQuery.data.feature[0].modified_date
      ) {
        lastEditOrImportDate = Date.parse(dataChangeQuery.data.feature[0].modified_date)
      }
      if (
        dataChangeQuery.data.feature_history &&
        dataChangeQuery.data.feature_history[0] &&
        dataChangeQuery.data.feature_history[0].deleted_date
      ) {
        lastDeleteDate = Date.parse(dataChangeQuery.data.feature_history[0].deleted_date)
      }

      // return latest value.
      if (lastEditOrImportDate > lastDeleteDate) {
        const dateAsObject = new Date(lastEditOrImportDate)
        return dateAsObject.toString()
      } else if (lastDeleteDate > lastEditOrImportDate) {
        const dateAsObject = new Date(lastDeleteDate)
        return dateAsObject.toString()
      }
    } else {
      return null
    }
  }

  const shouldDisplayExportVectorTilesButton = (lastEditTime: string) => {
    const vectorTime = Date.parse(vectorLastExportedTime)
    const editTime = Date.parse(lastEditTime)
    if (editTime > vectorTime) {
      return true
    } else {
      return false
    }
  }

  return (
    <div>
      <Row>
        <Col xs={20}>Last Export Date: {vectorLastExportedTime}</Col>
      </Row>
      <Row>
        <Col xs={20}>Last Data Change Date: {displayDataChangeDate()}</Col>
      </Row>
      {shouldDisplayExportVectorTilesButton(displayDataChangeDate()) ? (
        <Row>
          <Col xs={15}>
            <Button variant="info" onClick={() => window.open('gis/export', '_blank')}>
              Export Vector Tiles
            </Button>{' '}
          </Col>
        </Row>
      ) : null}
    </div>
  )
}

export default compose(withRouter)(EditorExportDateEditDateWarningDisplay)
