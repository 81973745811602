import { createSelector } from 'reselect'
import { getAgencyNameUserOrUrl } from './agenciesSelectors'
import { getAllNonAgiDeviceIds } from './devicesSelectors'

const getAvlLocationsNodeOrdered = state => state.firebase.ordered.avlLocationsDigest
export const getAvlLocationsArray = createSelector(
  getAvlLocationsNodeOrdered,
  getAgencyNameUserOrUrl,
  (avlLocationsOrdered, agencyName) => {
    if (!agencyName || !avlLocationsOrdered || !avlLocationsOrdered[agencyName]) return []
    return avlLocationsOrdered[agencyName].map(({ key, value }) => {
      return { avlColor: 'FFCC0000', hardwareUid: key, ...value, key }
    })
  }
)

export const getNonAgiAvlLocationsArray = createSelector(
  getAvlLocationsArray,
  getAllNonAgiDeviceIds,
  (avlLocations, deviceKeys) => {
    return avlLocations && avlLocations.filter(avl => deviceKeys.includes(avl.hardwareUid))
  }
)
