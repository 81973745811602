import { createSelector } from 'reselect'
import { getAgencyNameUserOrUrl, getAgencyConfigJs } from './agenciesSelectors'

export const getDeviceGroupsNode = state => state.firebase.data.deviceGroups

export const getDeviceGroupsDispatchOnlyNameArray = createSelector(
  getAgencyNameUserOrUrl,
  getDeviceGroupsNode,
  (agencyName, deviceGroupsNode) => {
    let deviceGroupNames = []
    if (deviceGroupsNode && deviceGroupsNode[agencyName]) {
      for (const [groupName, deviceGroup] of Object.entries(deviceGroupsNode[agencyName] as Record<string, any>)) {
        if (deviceGroup.dispatchGroup) {
          deviceGroupNames.push(groupName)
        }
      }
    }
    deviceGroupNames.sort()
    return deviceGroupNames
  }
)

export const getDeviceGroupsNotDispatchNameArray = createSelector(
  getAgencyNameUserOrUrl,
  getDeviceGroupsNode,
  (agencyName, deviceGroupsNode) => {
    let deviceGroupNames = []
    if (deviceGroupsNode && deviceGroupsNode[agencyName]) {
      for (const [groupName, deviceGroup] of Object.entries(deviceGroupsNode[agencyName] as Record<string, any>)) {
        if (!deviceGroup.dispatchGroup) {
          deviceGroupNames.push(groupName)
        }
      }
    }
    deviceGroupNames.sort()
    return deviceGroupNames
  }
)

export const getDeviceGroupsIdsObjectJs = createSelector(
  getAgencyNameUserOrUrl,
  getDeviceGroupsNode,
  (agencyName, deviceGroupsNode) => {
    if (deviceGroupsNode && deviceGroupsNode[agencyName]) {
      return Object.entries(deviceGroupsNode[agencyName] as Record<string, any>).reduce(
        (acc, [groupId, { deviceIds }]) => {
          if (deviceIds) {
            acc[groupId] = Object.keys(deviceIds) // deviceIds is an object with values of true
          } else {
            acc[groupId] = []
          }
          return acc
        },
        {}
      )
    } else {
      return {}
    }
  }
)

export const getDispatchGroups = createSelector(
  getAgencyNameUserOrUrl,
  getDeviceGroupsNode,
  (agencyName, deviceGroupsNode) => {
    if (!deviceGroupsNode || !deviceGroupsNode[agencyName]) {
      return {}
    }

    return Object.entries(deviceGroupsNode[agencyName] as Record<string, any>).reduce(
      (acc, [groupName, deviceGroup]) => {
        if (deviceGroup.dispatchGroup) {
          acc[groupName] = deviceGroup
        }
        return acc
      },
      {}
    )
  }
)

// map of avlDisplayGroup settings keyed by hardwareUids
export const getAvlGroupsByDevice = createSelector(
  getDeviceGroupsIdsObjectJs,
  getAgencyConfigJs,
  (deviceGroups, agencyConfig) => {
    if (!deviceGroups || !agencyConfig || !agencyConfig.avlDisplayGroups) {
      return {}
    }
    const groupSettings = agencyConfig.avlDisplayGroups
    return Object.keys(deviceGroups).reduce((acc, groupName) => {
      return Object.assign(
        acc,
        deviceGroups[groupName].reduce((accGrp, hardwareUid) => {
          accGrp[hardwareUid] = groupSettings[groupName]
          return accGrp
        }, {})
      )
    }, {})
  }
)
