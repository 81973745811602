import React from 'react'
import { Row, Col, Button, Card } from 'react-bootstrap'
import { FormFieldInput } from '../components/BootstrapReduxForm'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseRoot } from '../firebase'
import { firebaseConnect } from 'react-redux-firebase'
import { FaPlus, FaTimes, FaTrash } from 'react-icons/fa'
import { formValues, reduxForm, Field } from 'redux-form'
import Immutable from 'immutable'

import { saveAvlDisplayGroups, updateDispatchGroups } from '../actions/agencyConfigs'
import { saveDeviceGroup, deleteDeviceGroup, setIsDispatchGroup } from '../actions/deviceGroups'
// updateSpecialSearchData
import { updateSpecialSearchData } from '../actions/gisDataSources'
import { getAgenciesMap, getAgencyNameUserOrUrl, getSpecialSearchFields } from '../selectors'

const DEVICE_GROUP = 'deviceGroup'

const validateForm = data => {
  const errors = {}

  if (data['addTable'] && !data['addTrigger']) {
    errors['addTrigger'] = 'You must have a Prefix'
  } else if (!data['addTable'] && data['addTrigger']) {
    errors['addTable'] = 'You must have a Table name'
  }

  return errors
}

function isEqual(newSpecialSearchFields, oldSpecialSearchFields) {
  let equal = true
  if (newSpecialSearchFields.length && oldSpecialSearchFields.length) {
    for (const key in newSpecialSearchFields) {
      let newTrigger = newSpecialSearchFields[key]['trigger']
      let oldTrigger = null
      if (oldSpecialSearchFields && oldSpecialSearchFields[key] && oldSpecialSearchFields[key]['trigger']) {
        oldTrigger = oldSpecialSearchFields[key]['trigger']
      }
      if (newTrigger !== oldTrigger) {
        equal = false
      }
    }
  }
  return equal
}

function mapStateToProps(state, ownProps) {
  return {
    agencies: getAgenciesMap(state),
    agencyName: getAgencyNameUserOrUrl(state, ownProps),
    specialSearchFields: getSpecialSearchFields(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveDeviceGroup,
      deleteDeviceGroup,
      setIsDispatchGroup,
      saveAvlDisplayGroups,
      updateDispatchGroups,
      updateSpecialSearchData,
    },
    dispatch
  )
}

type Props = {
  change: Function
  handleSubmit: Function
  initialize: Function
  reset: any
  saveDeviceGroup: Function
  deleteDeviceGroup: Function
  setIsDispatchGroup: Function
  saveAvlDisplayGroups: Function
  updateDispatchGroups: Function
  agencies: Record<string, any>
  devices: Immutable.List<any>
  deviceGroupNames: any[]
  deviceGroupIds: Record<string, any>
  agencyName: string
  agencyConfig: Record<string, any>
  selectedDeviceGroup: string
  specialSearchFields: any[]
  updateSpecialSearchData: Function
  dirty: any
}

type State = {
  addNew: boolean
}

class EditSpecialSearchGroupForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      addNew: false,
    }
    if (props.specialSearchFields) {
      props.initialize(this.createInitialValues(props))
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      (nextProps.specialSearchFields.length && this.props.specialSearchFields.length === 0) ||
      !isEqual(nextProps.specialSearchFields, this.props.specialSearchFields)
    ) {
      this.props.initialize(this.createInitialValues(nextProps))
    }
  }

  createInitialValues = props => {
    let initialValues = {}
    let { specialSearchFields } = props
    for (const iterator in specialSearchFields) {
      let element = specialSearchFields[iterator]
      let keyTable = `${element['table']}-table`
      let keyTrigger = `${element['table']}-trigger`
      initialValues[keyTable] = element['table']
      initialValues[keyTrigger] = element['trigger']
    }

    return initialValues
  }

  deleteTable = async table => {
    if (table) {
      await firebaseRoot
        .child(`/gisDataSourceConfigurations/${this.props.agencyName}/${table}/searchableFields/`)
        .remove()
      await firebaseRoot.child('queues/generateStyleJson/tasks/').push({ agencyName: this.props.agencyName })
    }
  }

  submitEditAgencyForm = async data => {
    let sortedArray = []
    this.setState({
      addNew: false,
    })
    for (const key in data) {
      let temp = key.split('-')
      if (temp.length > 1) {
        if (!sortedArray[temp[0]]) {
          sortedArray[temp[0]] = {}
        }
        if (temp[1].includes('table')) {
          sortedArray[temp[0]]['table'] = data[key]
        } else {
          sortedArray[temp[0]]['trigger'] = data[key]
        }
      }
    }
    let outputArray = []
    for (const key in sortedArray) {
      let temp = { tables: key }
      let temp2 = sortedArray[key]['trigger']
      let field = sortedArray[key]['table']
      temp['trigger'] = temp2
      outputArray.push(temp)
      let url = `/gisDataSourceConfigurations/${this.props.agencyName}/${field}/searchableFields/`
      await firebaseRoot.child(url).update({ search: temp2 })
      if (key !== field) {
        await firebaseRoot
          .child(`/gisDataSourceConfigurations/${this.props.agencyName}/${key}/searchableFields/`)
          .remove()
      }
      console.log(url)
    }

    if (data.hasOwnProperty('addTable') && data.hasOwnProperty('addTrigger')) {
      let url = `/gisDataSourceConfigurations/${this.props.agencyName}/${data['addTable']}/searchableFields/`
      await firebaseRoot.child(url).update({ search: data['addTrigger'] })
    }

    let firebaseJSon = { agency: this.props.agencyName, updated: sortedArray }

    let specialSearchPromise = this.props.updateSpecialSearchData(firebaseJSon)

    await Promise.all([specialSearchPromise]).then(this.props.reset)

    await firebaseRoot.child('queues/generateStyleJson/tasks/').push({ agencyName: this.props.agencyName })
  }

  render() {
    const { handleSubmit, specialSearchFields } = this.props

    let specialSearchForms = specialSearchFields.map(specialSearchValue => {
      let keyTable = `${specialSearchValue['table']}-table`
      let keyTrigger = `${specialSearchValue['table']}-trigger`
      return (
        <Card.Body style={{ textAlign: 'left' }} key={keyTable}>
          <Col>
            <Field name={keyTable} id={keyTable} component={FormFieldInput} type="text" label="Table:" />
          </Col>
          <Col>
            <Field name={keyTrigger} id={keyTrigger} component={FormFieldInput} type="text" label="Prefix:" />
          </Col>
          <Button variant="danger" onClick={() => this.deleteTable(specialSearchValue.table)}>
            {' '}
            Delete <FaTrash />
          </Button>
          <hr />
        </Card.Body>
      )
    })

    const label = this.state.addNew ? 'Cancel' : 'Add'

    return (
      <form>
        {specialSearchForms}
        {this.state.addNew && (
          <Row style={{ textAlign: 'left' }}>
            <Col>
              <Field name={'addTable'} id={'addTable'} component={FormFieldInput} type="text" label="Table:" />
            </Col>
            <Col>
              <Field name={'addTrigger'} id={'addTrigger'} component={FormFieldInput} type="text" label="Prefix:" />
            </Col>
          </Row>
        )}
        <Button
          variant="primary"
          onClick={() => {
            this.setState({
              addNew: !this.state.addNew,
            })
            this.props.reset()
          }}
        >
          {label} {this.state.addNew ? <FaTimes /> : <FaPlus />}
        </Button>
        {this.props.dirty && (
          <Button variant="primary" onClick={handleSubmit(this.submitEditAgencyForm)}>
            Save
          </Button>
        )}
      </form>
    )
  }
}

export default compose(
  firebaseConnect(['/gisDataSourceConfigurations/']),
  reduxForm({
    // form name defined in parent component
    // form: 'EditSpecialSearchGroup',
    validate: validateForm,
  }),
  formValues({ selectedDeviceGroup: DEVICE_GROUP }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EditSpecialSearchGroupForm)
