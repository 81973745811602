import React from 'react'
import { Cell } from 'fixed-data-table-2'
import * as SortTypes from '../../constants/SortTypes'

const reverseSortDirection = sortDir => {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC
}

type Props = {
  columnKey: string
  sortDir: typeof SortTypes.ASC | typeof SortTypes.DESC | false | undefined
  onSortChange: Function
  children: any
}

const SortHeaderCell = props => {
  const { columnKey, sortDir, onSortChange, children, ...remainingProps } = props

  const headerClickHandler = e => {
    e.preventDefault()
    if (onSortChange) {
      onSortChange(columnKey, sortDir ? reverseSortDirection(props.sortDir) : SortTypes.DESC)
    }
  }

  let dirText = ''
  if (sortDir === SortTypes.DESC) {
    dirText = '↓'
  } else if (sortDir === SortTypes.ASC) {
    dirText = '↑'
  }

  return (
    <Cell {...remainingProps}>
      <a onClick={headerClickHandler}>
        {children} {dirText}
      </a>
    </Cell>
  )
}

export default SortHeaderCell
