import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { addAgency } from '../actions/agencies'
import { Alert, Col, Row, Button, ButtonToolbar } from 'react-bootstrap'
import { FormFieldInput, LoadingButton } from '../components/BootstrapReduxForm'
import { getAgenciesPromise } from '../firebase'
import { LinkContainer } from 'react-router-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'

export const AGENCY_NAME_FIELD = 'agencyName'
export const DISPLAY_NAME_FIELD = 'displayName'

const lower = value => value && value.toLowerCase()

function asyncValidateAddAgencyForm(data) {
  return getAgenciesPromise().then(agencies => {
    const errors = {}
    if (Object.keys(agencies).includes(data[AGENCY_NAME_FIELD])) {
      errors[AGENCY_NAME_FIELD] = 'An agency with this name already exists'
    }
    return Promise.resolve(errors)
  })
}

function validateAddAgencyForm(data) {
  const errors = {}
  if (!data[AGENCY_NAME_FIELD]) {
    errors[AGENCY_NAME_FIELD] = 'agencyName is required'
  } else if (!data[AGENCY_NAME_FIELD].match(/^[a-z0-9_]+$/i)) {
    errors[AGENCY_NAME_FIELD] = 'agencyName must not contain special characters other than underscore'
  } else if (data[AGENCY_NAME_FIELD].split('_').length !== 3) {
    errors[AGENCY_NAME_FIELD] = 'agencyName must be in the format <state>_<name>_<agencytype>, ie. or_bend_fd'
  } else if (data[AGENCY_NAME_FIELD].length > 50) {
    errors[AGENCY_NAME_FIELD] = 'agencyName must be 50 characters or less'
  }

  if (!data[DISPLAY_NAME_FIELD]) {
    errors[DISPLAY_NAME_FIELD] = 'displayName is required'
  } else if (data[DISPLAY_NAME_FIELD].length > 60) {
    errors[DISPLAY_NAME_FIELD] = 'displayName must be 60 characters or less'
  }
  return errors
}

function mapStateToProps() {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ addAgency }, dispatch)
}

const formParams = {
  form: 'addAgency',
  asyncBlurFields: [AGENCY_NAME_FIELD],
  asyncValidate: asyncValidateAddAgencyForm,
  validate: validateAddAgencyForm,
}

type Props = {
  addAgency: any
} & RouteComponentProps &
  InjectedFormProps

class AddAgencyForm extends React.Component<Props> {
  submitAddAgencyForm = data => {
    return this.props.addAgency(data[AGENCY_NAME_FIELD], data[DISPLAY_NAME_FIELD]).then(result => {
      if (result.error) {
        throw new SubmissionError({ _error: result.payload.message })
      }
      // clear form
      this.props.initialize({})
      // move out of addAgency mode
      this.props.history.push({ pathname: '/agencies', search: queryString.stringify({ addAgency: undefined }) })
    })
  }

  render() {
    const { handleSubmit, error, submitting, location } = this.props
    const urlQuery = queryString.parse(location.search)
    return (
      <form>
        <Col>
          <Row>
            <Field
              name={DISPLAY_NAME_FIELD}
              component={FormFieldInput}
              type="text"
              label="Display Name"
              placeholder="Example FD"
            />
          </Row>
          <Row>
            <Field
              name={AGENCY_NAME_FIELD}
              component={FormFieldInput}
              type="text"
              label="agencyName"
              placeholder="Ex: or_corvallis_fd"
              normalize={lower}
            />
          </Row>
          {error && (
            <Row>
              <Col sm={{ span: 6, offset: 3 }}>
                <Alert variant="danger">{error}</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={{ offset: 7 }}>
              <ButtonToolbar>
                <LoadingButton
                  variant="primary"
                  label="Submit"
                  loading={submitting}
                  loadingLabel="Submitting"
                  onClick={handleSubmit(this.submitAddAgencyForm)}
                />
                <LinkContainer
                  to={{ pathname: '/agencies', search: queryString.stringify({ ...urlQuery, addAgency: undefined }) }}
                  active={false}
                >
                  <Button>Cancel</Button>
                </LinkContainer>
              </ButtonToolbar>
            </Col>
          </Row>
        </Col>
      </form>
    )
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm(formParams)
)(AddAgencyForm)
