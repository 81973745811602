import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import FirebaseConnector from '../components/FirebaseConnector'
import ViewWrapper from '../components/ViewWrapper'
import * as devicesActions from '../actions/devices'
import { devicesRef } from '../firebase'
import { Button } from 'react-bootstrap'
import { FaTrash } from 'react-icons/fa'
import EditDeviceForm from '../containers/EditDeviceForm'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    devices: state.devices.data,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...devicesActions }, dispatch)
}

type Props = {
  user: Record<string, any>
  devices: Record<string, any>
  loadDevice: Function
  deleteDevice: Function
} & RouteComponentProps<any>

const DeviceView = (props: Props) => {
  const { user, devices, loadDevice, deleteDevice, match, location } = props
  const { agencyName, deviceId } = match.params
  let device
  let deviceRef
  if (agencyName && deviceId) {
    deviceRef = devicesRef.child(agencyName).child(deviceId)
    if (devices.size) {
      const agencyDevices = devices.get(agencyName)
      if (agencyDevices && agencyDevices.size) {
        device = agencyDevices.get(deviceId)
      }
    }
  }

  const onClick = () => {
    // TODO: use something nicer than a 'confirm', like a Bootstrap modal
    const sureYouWantToDelete = confirm('Are you sure you want to delete this?') // eslint-disable-line no-restricted-globals
    if (sureYouWantToDelete) {
      deleteDevice(agencyName, deviceId)
      returnToDevices()
    }
  }

  const returnToDevices = () => {
    const urlQuery = queryString.parse(location.search)
    if (location && urlQuery && urlQuery.agencyName !== undefined) {
      props.history.push(`/devices?agencyName=${urlQuery.agencyName}`)
    } else {
      props.history.push('/devices')
    }
  }

  return (
    <ViewWrapper header="Edit Device">
      {deviceRef && (
        <FirebaseConnector
          key={`devices`} // key lets react know to umount and mount component when changed
          firebaseRef={devicesRef.child(agencyName).child(deviceId)}
          onNodeChange={device_ => loadDevice(agencyName, deviceId, device_)}
        />
      )}

      {/* {device && Object.entries(device.toJS()).map(([key, val]) =>
          <p>{key}: {val}</p>
        )} */}

      {device && (user.isSuperAdmin || user.isAgencyAdmin) && (
        <EditDeviceForm device={device} onSuccess={returnToDevices} onCancel={props.history.goBack} />
      )}

      {user && user.isSuperAdmin && (
        <Button onClick={onClick} variant="danger">
          Delete <FaTrash />
        </Button>
      )}
    </ViewWrapper>
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeviceView)
