import React from 'react'
import FormikGroup from './FormikGroup'
import { FormControl } from 'react-bootstrap'
import { FieldProps } from 'formik'

type Props = {
  label: string
} & FieldProps

const FormikInput = (props: Props) => {
  const { field, form, ...otherProps } = props
  return (
    <FormikGroup {...props}>
      <FormControl type="text" {...field} {...otherProps} />
    </FormikGroup>
  )
}

export default FormikInput
