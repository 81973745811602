import * as types from '../constants/ActionTypes'
import { queuesRef } from '../firebase'

export function loadAllQueues(queuesNode) {
  return {
    type: types.LOAD_ALL_QUEUES,
    payload: {
      queuesNode,
    },
  }
}

export function pushTaskToQueue(queueName, taskPayload) {
  return {
    type: types.PUSH_TASK_TO_QUEUE,
    payload: queuesRef
      .child(queueName)
      .child('tasks')
      .push(taskPayload),
  }
}
