import React from 'react'
import { Button, Card } from 'react-bootstrap'
import ViewWrapper from '../components/ViewWrapper'
import ExportDataForm from '../containers/ExportDataForm'
import FirestoreFunctionViewWrapper, { TaskViewWrapper } from '../containers/FirestoreFunctionViewWrapper'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

const ExportDataView = ({ location, history }) => {
  const urlQuery = queryString.parse(location.search)
  return (
    <FirestoreFunctionViewWrapper
      firestoreFunctionName="exportVectorTiles"
      location={location}
      render={({ task, taskProgress }) => {
        if (!urlQuery.taskId) {
          return (
            <ViewWrapper header="Export vector tiles">
              <ExportDataForm onCancel={history.goBack} location={location} />
            </ViewWrapper>
          )
        }

        if (task) {
          if (task._error) {
            return (
              <TaskViewWrapper header="An error occurred while exporting data">
                <Card bg="danger">
                  <Card.Body>{task._error}</Card.Body>
                </Card>
                <Button variant="primary" onClick={() => history.push('/gis/export')}>
                  Back to export GIS data
                </Button>
              </TaskViewWrapper>
            )
          }
          if (task._status === 'COMPLETE') {
            return (
              <TaskViewWrapper header="Data export complete!">
                <p>{`Finished exporting vector tiles for ${task.agencyName} at ${task._completeTime}.`}</p>
                <Button variant="primary" onClick={() => history.push('/gis')}>
                  Back to GIS data
                </Button>
              </TaskViewWrapper>
            )
          }
          return <TaskViewWrapper header="Exporting data..." {...taskProgress} />
        } else {
          return <div />
        }
      }}
    />
  )
}

export default withRouter(ExportDataView)
