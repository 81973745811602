// This Input component mimics the functionality of React Bootstrap's own Input
// component which was deprecated in version 0.29.0. To be used as a drop-in replacement.
// Taken from here: https://gist.github.com/jchapple/fa054b9c9e372a458b31d94b317bfebe
import React from 'react'
import ReactDOM from 'react-dom'
import cx from 'classnames'

import { FormGroup, FormLabel, FormControl, FormText, InputGroup } from 'react-bootstrap'

type Props = {
  name?: string
  id?: string
  children: any
  help: any
  size?: string
  wrapperClassName?: string
  groupClassName?: string
  labelClassName?: string
  addonBefore: any
  addonAfter: any
  buttonBefore: any
  buttonAfter: any
  standalone?: boolean
  hasFeedback?: boolean
  validationState?: string
  label: any
  type?: string
  value?: any
  onChange: any
} & any

export default class Input extends React.Component<Props> {
  refFormControl: FormControl
  constructor(props, context) {
    super(props, context)

    this.refFormControl = null
  }

  // getDOMNode = () => ReactDOM.findDOMNode(this.refFormControl)

  getValue() {
    const inputNode: any = ReactDOM.findDOMNode(this.refs.formControl)

    if (this.props.type === 'select' && inputNode.multiple) {
      return this.getMultipleSelectValues(inputNode)
    }

    return inputNode.value
  }

  getMultipleSelectValues(selectNode) {
    const values = []
    const options = selectNode.options

    for (let i = 0; i < options.length; i++) {
      const opt = options[i]

      if (opt.selected) {
        values.push(opt.value || opt.text)
      }
    }

    return values
  }

  renderAddon(addon) {
    return (
      addon && (
        <InputGroup.Prepend>
          <InputGroup.Text>{addon}</InputGroup.Text>
        </InputGroup.Prepend>
      )
    )
  }

  renderButton(button) {
    return button && <InputGroup.Prepend>{button}</InputGroup.Prepend>
  }

  renderInputGroup({
    wrapperClassName,
    addonBefore,
    addonAfter,
    buttonBefore,
    buttonAfter,
    help,
    hasFeedback,
    children,
    value,
    ...props
  }: Props) {
    if (props.type === 'select' || props.type === 'textarea') {
      props.as = props.type
      delete props.type
    }

    const formControl = (
      <FormControl
        ref={c => {
          this.refFormControl = c
        }}
        value={value}
        {...props}
      >
        {children}
      </FormControl>
    )

    const getFormControlWrapped = (className?: string) => {
      return className || hasFeedback || help ? (
        <div style={{ display: 'flex', flex: 1 }} className={className}>
          {formControl}
          {hasFeedback && <FormControl.Feedback />}
          {help && <FormText>{help}</FormText>}
        </div>
      ) : (
        formControl
      )
    }

    if (!addonBefore && !addonAfter && !buttonBefore && !buttonAfter) {
      return getFormControlWrapped(wrapperClassName)
    }

    return (
      <InputGroup bsPrefix={cx('input-group', wrapperClassName)}>
        {this.renderAddon(addonBefore)}
        {this.renderButton(buttonBefore)}
        {getFormControlWrapped()}
        {this.renderButton(buttonAfter)}
        {this.renderAddon(addonAfter)}
      </InputGroup>
    )
  }

  render() {
    const { id, label, size, groupClassName, labelClassName, standalone, validationState, ...props } = this.props

    // FIXME: Get size and validationState working
    return (
      <FormGroup
        controlId={id}
        // size={size}
        bsPrefix={cx({ 'form-group': !standalone }, groupClassName)}
        //validationState={validationState}
      >
        {label && <FormLabel bsPrefix={cx('control-label', labelClassName)}>{label}</FormLabel>}
        {this.renderInputGroup(props)}
      </FormGroup>
    )
  }
}
