import React from 'react'
import { omit, throttle } from 'lodash'

function _isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

function _toArray(obj) {
  const arr = []
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (_isObject(obj[key])) {
        obj[key].key = key
      }
      arr.push(obj[key])
    }
  }
  return arr
}

type Props = {
  firebaseRef: any
  onNodeChange?: any
  onNewChildAdded?: Function
  onChildRemoved?: Function
  onUnmount?: Function
  shouldReturnKey?: boolean
  throttle?: number
  once?: boolean
  asArray?: boolean
}

class FirebaseConnector extends React.Component<Props> {
  props: Props
  obj: Object
  valueCount: number
  initialDataLoaded: boolean
  onNodeChange: any
  onChildRemoved: Function
  onNewChildAdded: Function
  onNodeFail: Function

  constructor(props: Props) {
    super(props)
    this.initialDataLoaded = false
    this.obj = {}
    this.valueCount = 0

    if (props.onNodeChange) {
      this.onNodeChange = throttle(
        this.partialOptions(props.onNodeChange, props),
        isNaN(props.throttle) ? 500 : props.throttle
      )
    }

    if (props.onChildRemoved) {
      this.onChildRemoved = snapshot => this.handleChildRemoved(snapshot)
    }

    if (props.onNewChildAdded) {
      this.onNewChildAdded = snapshot => this.handleNewChildAdded(snapshot)
    }
    // TODO: dispatch action signifying failure to connect
    this.onNodeFail = err => {
      console.warn(`FirebaseConnector failed at ${this.props.firebaseRef.toString()}: ${err}`) // eslint-disable-line no-console
      // throw err
    }
  }

  componentWillMount() {
    if (!this.props.onNewChildAdded && !this.props.onNodeChange) {
      throw new Error(
        'FirebaseConnector configuration error. One of either onNewChildAdded or onNodeChange must be defined.'
      )
    }

    if (this.props.onNodeChange) {
      this.props.firebaseRef.once(
        'value',
        snapshot => {
          if (this.props.once) {
            this.onNodeChange(snapshot)
          } else {
            const val = snapshot.val()
            // if val is an object, watch its children instead of the entire node to reduce data downloads
            if (val && typeof val === 'object') {
              this.obj = val
              this.props.firebaseRef.on('child_added', this.onStateChildChanged, this.onNodeFail)
              this.props.firebaseRef.on('child_removed', this.onStateChildRemoved, this.onNodeFail)
              this.props.firebaseRef.on('child_changed', this.onStateChildChanged, this.onNodeFail)
            } else {
              this.props.firebaseRef.on('value', this.onNodeChange, this.onNodeFail)
            }
          }
        },
        this.onNodeFail
      )
    }

    if (this.props.onNewChildAdded) {
      if (this.props.once) {
        this.props.firebaseRef.once('child_added', this.onNewChildAdded, this.onNodeFail)
      } else {
        this.props.firebaseRef.on('child_added', this.onNewChildAdded, this.onNodeFail)
      }
    }

    if (this.props.onChildRemoved) {
      if (this.props.once) {
        this.props.firebaseRef.once('child_removed', this.onChildRemoved, this.onNodeFail)
      } else {
        this.props.firebaseRef.on('child_removed', this.onChildRemoved, this.onNodeFail)
      }
    }
  }

  componentWillUnmount() {
    // cancel any throttled functions
    this.onNodeChange && this.onNodeChange.cancel()

    this.props.firebaseRef.off('value', this.onNodeChange)
    this.props.firebaseRef.off('child_added', this.onNewChildAdded)
    this.props.firebaseRef.off('child_added', this.onStateChildChanged)
    this.props.firebaseRef.off('child_removed', this.onChildRemoved)
    this.props.firebaseRef.off('child_removed', this.onStateChildRemoved)
    this.props.firebaseRef.off('child_changed', this.onStateChildChanged)
    this.props.onUnmount && this.props.onUnmount()
  }

  onStateChildChanged = (snapshot: any) => {
    const newObj = Object.assign(this.obj, { [snapshot.key]: snapshot.val() })
    const newValueCount = this.valueCount + 1
    this.obj = newObj
    this.valueCount = newValueCount
    // when first registered for 'child_changed' events, firebase immediately sends one event for each child
    // already there; this check prevents onNodeChange from firing multiple times on mount
    // shouldReturnKey adds one extra value to this.obj so we account for this
    if (
      newValueCount >= Object.keys(newObj).length ||
      (this.props.shouldReturnKey && newValueCount + 1 >= Object.keys(newObj).length)
    ) {
      this.onNodeChange(newObj)
    }
  }

  onStateChildRemoved = (snapshot: any) => {
    const newObj = omit(this.obj, [snapshot.key])
    this.obj = newObj
    this.onNodeChange(newObj)
  }

  handleChildRemoved(snapshot) {
    if (!this.initialDataLoaded) {
      return
    }

    if (!this.props.onChildRemoved) {
      return
    }

    const rVal = { child: snapshot && snapshot.val(), key: snapshot && snapshot.key }
    this.props.onChildRemoved(rVal)
  }

  handleNewChildAdded(snapshot) {
    if (!this.initialDataLoaded) {
      return
    }

    if (!this.props.onNewChildAdded) {
      return
    }

    const rVal = { child: snapshot && snapshot.val(), key: snapshot && snapshot.key }
    this.props.onNewChildAdded(rVal)
  }

  // Firebase returns snapshot objects which must call .val() to get their JS obj
  partialOptions(func, options) {
    return snapshotOrVal => {
      let val
      val = snapshotOrVal && snapshotOrVal.val ? snapshotOrVal.val() : snapshotOrVal
      if (options.asArray) {
        val = _toArray(val)
      } else {
        // Add key as attribute, just like we do with asArray
        if (options.shouldReturnKey) {
          if (!val) {
            val = {}
          }
          val.key = options.firebaseRef.key
        }
      }
      func(val)
      this.initialDataLoaded = true
    }
  }

  render() {
    return null
  }
}

export default FirebaseConnector
