import React from 'react'
import '../styles/core.css'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Navbar, Nav, NavItem, NavDropdown, Container } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { loadAuthorizedUser, logout } from '../actions/auth'
import FirebaseConnector from '../components/FirebaseConnector'
import { usersRef } from '../firebase'
import Routes from '../routes'
import { getAgencies, getAgencyName } from '../selectors'
import _ from 'lodash'
import { ROLE_INSPECTOR } from '../constants/UserRoles'

type NavDropdownOptionProps = {
  title: string
  to: string
  activePath: string
}
const NavDropdownOption = (props: NavDropdownOptionProps) => {
  const isActive = props.to === props.activePath
  return (
    <LinkContainer style={isActive ? { backgroundColor: '#007bff', color: '#fff' } : null} to={props.to}>
      <Nav.Link>{props.title}</Nav.Link>
    </LinkContainer>
  )
}

function mapStateToProps(state, ownProps) {
  return { user: state.auth.user, agencies: getAgencies(state), agencyName: getAgencyName(state) }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadAuthorizedUser, logout }, dispatch)
}

type Props = {
  children: JSX.Element
  user: Record<string, any>
  loadAuthorizedUser: Function
  // TODO: figure out correct type
  logout: (event: any) => void
  agencies: any
  agencyName: string
  location: any
}

const CoreLayout = (props: Props) => {
  const { user, agencies, agencyName, location } = props
  const hasInspect = _.get(agencies, [agencyName, 'hasInspect'])
  const showInspections = () => {
    if (!user) return false
    if (user.isAgi) return true
    if (!hasInspect) return false
    if (user.isAgencyAdmin || user.roles.includes(ROLE_INSPECTOR)) return true
    return false
  }
  const hasEditorView = false // TODO: decide what kind of users we should allow to view this page. (agi and anyone with editor permissions? )
  return (
    <div style={{ height: 'inherit' }}>
      <div style={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}>
        {user && (
          <FirebaseConnector
            key={`users/${user.uid}`}
            firebaseRef={usersRef.child(`${user.uid}`)}
            onNodeChange={props.loadAuthorizedUser}
          />
        )}
        <Navbar bg="light" fixed="top" expand="md" className="clearfix" collapseOnSelect>
          <Container>
            <Navbar.Brand as="div" className="my-auto align-text-bottom" href="/" style={{ textDecoration: 'none' }}>
              <Link to="/" className="clearfix" style={{ textDecoration: 'none' }}>
                <img
                  src={require('../images/IV_logo_icon_only.svg')}
                  alt="IV Logo"
                  className="d-inline-block align-top"
                />
                <span style={{ display: 'inline-block', paddingTop: '1rem' }}>
                  {process.env.REACT_APP_USE_DEV_DB === '1' ? 'DEV ' : ''}Admin
                </span>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <Nav>
                {/* user && user.isAgi &&
                <LinkContainer to="/command-center-map">
                  <NavItem>Map</NavItem>
                </LinkContainer>
              */}

                {user && (user.isAgencyAdmin || user.isAgi) && (
                  <LinkContainer to="/devices">
                    <Nav.Link>Devices</Nav.Link>
                  </LinkContainer>
                )}
                {user && (user.isAgencyAdmin || user.isAgi) && (
                  <LinkContainer to="/dispatches">
                    <Nav.Link>Dispatches</Nav.Link>
                  </LinkContainer>
                )}
                {showInspections() && (
                  <LinkContainer to="/inspections">
                    <Nav.Link>Inspections</Nav.Link>
                  </LinkContainer>
                )}
                {user && (user.isAgi || user.isSuperAdmin || hasEditorView) && (
                  <LinkContainer to="/editor">
                    <Nav.Link>Editor</Nav.Link>
                  </LinkContainer>
                )}
                {user && (user.isAgencyAdmin || user.isAgi) && (
                  <LinkContainer to="/config">
                    <Nav.Link>Configuration</Nav.Link>
                  </LinkContainer>
                )}
                {user && user.isAgi && (
                  <NavDropdown id="agitools" title="AGI Tools">
                    <NavDropdownOption to="/avl" title="Map" activePath={location.pathname} />
                    <NavDropdownOption to="/agencies" title="Agencies" activePath={location.pathname} />
                    {/* TODO: we want agencyAdmins to be able to see their users, but
                 we'll need to have a new 'usersByAgency' node since filtering
                 the existing 'users' node requires access to reading the entire
                 node (which non-superAdmins aren't allowed to do) */}
                    <NavDropdownOption to="/users" activePath={location.pathname} title="Users" />
                    <NavDropdownOption to="/gis" activePath={location.pathname} title="GIS Data" />
                    <NavDropdownOption to="/featuresets" activePath={location.pathname} title="Edit Feature Sets" />
                    <NavDropdownOption to="/inspectadmin" activePath={location.pathname} title="Inspect Admin" />
                    {user.isSuperAdmin && (
                      <NavDropdownOption to="/superadmin" activePath={location.pathname} title="SuperAdmin" />
                    )}
                    {user.isSuperAdmin && (
                      <NavDropdownOption
                        to="/dispatchagencies"
                        activePath={location.pathname}
                        title="Dispatch Agencies"
                      />
                    )}
                    {user.isSuperAdmin && (
                      <NavDropdownOption to="/dispatchpayload" activePath={location.pathname} title="Raw Dispatches" />
                    )}
                  </NavDropdown>
                )}
                {user && (
                  <LinkContainer to="/login" key="signedIn">
                    <Nav.Link>Account</Nav.Link>
                  </LinkContainer>
                )}
              </Nav>
              <Nav className="ml-auto">
                {user ? (
                  <Nav.Link onClick={props.logout} key="signOut">
                    Sign Out
                  </Nav.Link>
                ) : (
                  <LinkContainer to="/login">
                    <Nav.Link>Sign In</Nav.Link>
                  </LinkContainer>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div style={{ flexGrow: 2, position: 'relative' }}>{Routes}</div>
      </div>
    </div>
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firebaseConnect((props, firebase) => {
    if (props.agencyName) {
      return [`/agencies/${props.agencyName}`]
    }
  })
)(CoreLayout)
