import * as types from '../constants/ActionTypes'
import { devicesRef, deleteDevicePromise } from '../firebase'

export function loadDevice(agencyName, deviceId, device) {
  return {
    type: types.LOAD_DEVICE,
    payload: {
      agencyName,
      deviceId,
      device,
    },
  }
}

export function deleteDevice(agencyName, deviceId) {
  return {
    type: types.DELETE_DEVICE,
    payload: deleteDevicePromise(agencyName, deviceId),
  }
}

export function loadDevicesForAgency(agencyName, devices) {
  return {
    type: types.LOAD_DEVICES_FOR_AGENCY,
    payload: {
      agencyName,
      devices,
    },
  }
}

export function loadAllAgencyDevices(allAgencyDevicesNode) {
  return {
    type: types.LOAD_ALL_AGENCY_DEVICES,
    payload: {
      allAgencyDevicesNode,
    },
  }
}

export function clearAgencyDevices(agencyName) {
  return {
    type: types.CLEAR_AGENCY_DEVICES,
    payload: {
      agencyName,
    },
  }
}

export function sortDevices(attributeName, sortDirection) {
  return {
    type: types.SORT_DEVICES,
    payload: {
      attributeName,
      sortDirection,
    },
  }
}

export function updateDevice(agencyName, deviceId, updateObj) {
  return {
    type: types.UPDATE_DEVICE,
    payload: devicesRef
      .child(agencyName)
      .child(deviceId)
      .update(updateObj),
  }
}
