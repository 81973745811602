import history from '../history'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import axios from 'axios'

export default firebase

export let firebaseConfig
if (process.env.REACT_APP_USE_DEV_DB === '1') {
  firebaseConfig = {
    projectId: 'incidentview-dev',
    apiKey: 'AIzaSyBipubr1ZeqDlWO2jWoGefCX04ZArR6AlU',
    authDomain: 'incidentview-dev.firebaseapp.com',
    databaseURL: 'https://incidentview-dev.firebaseio.com',
    storageBucket: 'incidentview-dev.appspot.com',
  }
} else {
  firebaseConfig = {
    projectId: 'incidentview',
    apiKey: 'AIzaSyDFkklN5RV_rE9UJ5yRBaUm9JJ4lNrCPKM',
    authDomain: 'incidentview.firebaseapp.com',
    databaseURL: 'https://incidentview.firebaseio.com',
    storageBucket: 'incidentview.appspot.com',
  }
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

// initialize Firestore
export const firestore = firebase.firestore()

export const firebaseRoot = firebase.database().ref()

export const cadCentersRef = firebaseRoot.child('/cadCenters')
export const agencyAllowedSharingRef = firebaseRoot.child('/agencyAllowedSharing')
export const agencyConfigsRef = firebaseRoot.child('/agencyConfigs')
export const avlLocationsRef = firebaseRoot.child('/avlLocations')
export const deviceGroupsRef = firebaseRoot.child('/deviceGroups')
export const dispatchesRef = firebaseRoot.child('/dispatches')
export const gisDataSourcesRef = firebaseRoot.child('/gisDataSourceConfigurations')
export const queuesRef = firebaseRoot.child('queues')
export const dispatchToAgencyRef = queuesRef.child('dispatchToAgency/tasks')
export const prodToDevDataCopyQueueRef = firebaseRoot.child('prodToDevDataCopyQueue')
export const usersRef = firebaseRoot.child('/users')

export const addUser = firebase.functions().httpsCallable('addUser')
export const deleteUser = firebase.functions().httpsCallable('deleteUser')
export const addLayerToEditor = firebase.functions().httpsCallable('addLayerToEditor')
export const functions = {
  addUser,
  deleteUser,
  addLayerToEditor,
}

export const devicesRef = firebaseRoot.child('/devices')

export const deleteDevicePromise = (agencyName, deviceId) => {
  // clear out device from all possible locations in the database
  // TODO: when avlLocations are read per agency rather than from all shared agencies,
  // we will need to be sure to clear out the avlLocation from all shared agencies also
  return Promise.all([
    devicesRef
      .child(agencyName)
      .child(deviceId)
      .remove(),
    firebaseRoot
      .child('avlLocations')
      .child(agencyName)
      .child(deviceId)
      .remove(),
    firebaseRoot
      .child('responseStatus')
      .child(agencyName)
      .child(deviceId)
      .remove(),
    firebaseRoot
      .child('deviceGroups')
      .once('value')
      .then(snap => {
        let arr = []
        const object = snap.val()
        for (const aName in object) {
          for (const groupName in object[aName]) {
            const group = object[aName][groupName]
            if (group.deviceIds && group.deviceIds[deviceId]) {
              arr.push({ aName, groupName })
            }
          }
        }
        return arr
      })
      .then(arr =>
        Promise.all(
          arr.map(({ aName, groupName }) =>
            firebaseRoot
              .child('deviceGroups')
              .child(aName)
              .child(groupName)
              .child('deviceIds')
              .child(deviceId)
              .remove()
          )
        )
      ),
  ])
}

export const agenciesRef = firebaseRoot.child('/agencies')

export function getAgenciesPromise() {
  return new Promise((resolve, reject) => {
    agenciesRef.once(
      'value',
      snapshot => {
        resolve(snapshot.val())
      },
      err => {
        reject(err)
      }
    )
  })
}

export function addAgencyPromise(agencyName, displayName) {
  const agencyObj = { isActive: true, displayName }

  return Promise.all([
    // Save to both realtime db and firestore, since security rules for both rely on agencies
    firestore.doc(`agencies/${agencyName}`).set(agencyObj),
    agenciesRef.child(agencyName).set(agencyObj),
  ]).then(() => {
    return `${agencyName} successfully added to agencies node.`
  })
}

export async function loginPromise(email: string, password: string) {
  const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password)
  return userCredential.user
}

export function logout() {
  history.push('/login')
  return firebase.auth().signOut()
}

export function getUserToken() {
  let output = firebase.auth().currentUser.getIdToken(true)
  return output
}

export function changePasswordPromise(email: string, oldPassword: string, newPassword: string) {
  const user = firebase.auth().currentUser
  // TODO: docs say to use user.reauthenticate, but it's telling me it's undefined, so re-sign in
  // TODO: I think this issue might have something to do with our FB users migrating over from FB 2.0
  // return user.reauthenticate(email, oldPassword)
  return (
    firebase
      .auth()
      .signInWithEmailAndPassword(email, oldPassword)
      .then(() => user.updatePassword(newPassword))
      // set 'hasTemporaryPassword' to false so Admin site no longer forces user to change
      .then(() =>
        usersRef
          .child(user.uid)
          .child('hasTemporaryPassword')
          .set(false)
      )
      .then(() => 'Password changed successfully.')
  )
}

export function resetPasswordPromise(email: string) {
  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => 'Password reset email sent successfully.')
}

export async function checkContactEmail(user) {
  if (user.agencyName && user.roles && user.roles.agencyAdmin) {
    const contactEmail = await firebaseRoot
      .child('agencies')
      .child(user.agencyName)
      .child('contactEmail')
      .once('value')
      .then(snap => snap.val())

    if (!contactEmail) {
      history.push('/contact-email')
    }
  }
}
