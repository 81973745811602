import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import FirebaseConnector from '../components/FirebaseConnector'
import ViewWrapper from '../components/ViewWrapper'
import * as usersActions from '../actions/users'
import { usersRef, functions } from '../firebase'
import EditUserForm from '../containers/EditUserForm'
import { withRouter, RouteComponentProps } from 'react-router-dom'

function mapStateToProps(state) {
  return {
    users: state.users.data,
    isSuperAdmin: state.auth.user.roles.includes('agiSuperAdmin'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...usersActions }, dispatch)
}

type Props = {
  users: Record<string, any>
  loadUser: Function
} & RouteComponentProps<any>

const UserView = (props: Props) => {
  const { users, loadUser, match, history } = props
  const { userId } = match.params

  const userRef = usersRef.child(userId)
  let user
  if (users.size) {
    user = users.get(userId)
  }

  const onClickDelete = async () => {
    const sureYouWantToDelete = confirm('Are you sure you want to delete this user?') // eslint-disable-line no-restricted-globals
    if (sureYouWantToDelete) {
      await functions.deleteUser({ uid: userId })
      history.push('/users')
    }
  }

  return (
    <ViewWrapper header="User">
      {userRef ? (
        <FirebaseConnector key={`users`} firebaseRef={userRef} onNodeChange={user_ => loadUser(userId, user_)} />
      ) : null}

      {user ? (
        <EditUserForm
          key={user.uid}
          user={user}
          onSuccess={history.goBack}
          onCancel={history.goBack}
          onClickDelete={onClickDelete}
        />
      ) : null}
    </ViewWrapper>
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserView)
