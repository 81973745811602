import React, { useState, useEffect } from 'react'
import { bindActionCreators, compose } from 'redux'
import { Card, Button, Collapse } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { Table, Column } from 'react-virtualized'
import 'react-virtualized/styles.css'
import { withRouter } from 'react-router-dom'
import * as selectors from '../selectors'
import { saveReceivesSharedAvlFrom } from '../actions/agencyConfigs'

// agencyAllowedSharing is other agencies sharing with myAgency
// agencyAllowsSharingAvlWith is agencies which myAgency shares with (derived)

const ConfigAvlForm = props => {
  const { agencySharesAvlWith, agencyAllowedSharing, agencyConfig, agencyDisplayNames } = props
  const agencyAllowsSharingAvlWith = props.agencyAllowsSharingAvlWith
    ? Object.keys(props.agencyAllowsSharingAvlWith)
        .filter(agency => agency !== 'or_agi_fd')
        .map(agency => {
          return {
            agencyName: agency,
            displayName: agencyDisplayNames[agency],
            visibility: agencySharesAvlWith.includes(agency),
          }
        })
        .sort((a, b) => a.displayName && a.displayName.localeCompare(b.displayName, 'en', { sensitivity: 'base' }))
    : []

  const receivesSharedAvlFrom = agencyConfig.receivesSharedAvlFrom
    ? Object.keys(agencyConfig.receivesSharedAvlFrom)
    : []
  const [initialReceivesSharedAvlFrom, setInitialReceivesSharedAvlFrom] = useState(receivesSharedAvlFrom)
  const calcAgencyAvlVisibility = () => {
    return agencyAllowedSharing
      .map(agency => ({
        agencyName: agency,
        displayName: agencyDisplayNames[agency],
        visibility: receivesSharedAvlFrom.includes(agency),
      }))
      .sort((a, b) => a.displayName && a.displayName.localeCompare(b.displayName, 'en', { sensitivity: 'base' }))
  }

  const [agencyAvlVisibility, setAgencyAvlVisibility] = useState(calcAgencyAvlVisibility)
  useEffect(() => {
    setInitialReceivesSharedAvlFrom(receivesSharedAvlFrom)
    setAgencyAvlVisibility(calcAgencyAvlVisibility())
  }, [
    !!agencyConfig.receivesSharedAvlFrom,
    Object.keys(agencyDisplayNames).length,
    agencyAllowedSharing && agencyAllowedSharing.length,
  ])

  const visibleAgencies = agencyAvlVisibility.filter(agency => agency.visibility).map(agency => agency.agencyName)
  let allowSave = false
  if (receivesSharedAvlFrom.length !== visibleAgencies.length) {
    allowSave = true
  } else if (
    receivesSharedAvlFrom
      .slice()
      .sort()
      .toString() !==
    visibleAgencies
      .slice()
      .sort()
      .toString()
  ) {
    allowSave = true
  } else {
    allowSave = false
  }

  const onSave = e => {
    props.saveReceivesSharedAvlFrom(props.agencyName, visibleAgencies)
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', flexWrap: 'wrap' }}>
      <div style={{ minWidth: '392px', width: '45%' }}>
        <Card>
          <Card.Header>
            <Card.Title>Received AVL</Card.Title>
          </Card.Header>
          <Card.Body>
            {agencyAllowedSharing.length ? (
              <div>
                <div style={{ paddingBottom: '15px', marginBottom: '15px', borderBottom: '1px solid lightgray' }}>
                  All agencies sharing AVL data with your agency. <br />
                  Click on an agency to toggle the visibility of AVL data received from that agency. Click Save to save
                  your configuration.
                </div>
                <div>
                  <Table
                    style={{ margin: '3px' }}
                    headerHeight={24}
                    headerStyle={{ textAlign: 'left', overflow: 'visible' }}
                    height={(1 + agencyAvlVisibility.length) * 24 || 240}
                    width={360}
                    rowGetter={({ index }) => agencyAvlVisibility[index]}
                    rowCount={agencyAvlVisibility.length || 0}
                    rowHeight={24}
                    onRowClick={row => {
                      setAgencyAvlVisibility(currentVisibility => {
                        return currentVisibility.map(agency => {
                          if (agency.agencyName === row.rowData.agencyName) {
                            return { ...agency, visibility: !agency.visibility }
                          }
                          return agency
                        })
                      })
                    }}
                  >
                    <Column
                      dataKey="visibility"
                      width={30}
                      style={{ textAlign: 'left' }}
                      // label="visibility"
                      label={<FaEye />}
                      cellRenderer={({ cellData }) => {
                        if (cellData === true) {
                          return <FaEye />
                        } else {
                          return ''
                        }
                      }}
                    />
                    <Column dataKey="displayName" width={300} label="agency" style={{ textAlign: 'left' }} />
                  </Table>
                </div>

                <div style={{ marginTop: '15px' }}>
                  <Collapse in={allowSave}>
                    <div>
                      <Button variant="success" block onClick={onSave}>
                        Save
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </div>
            ) : (
              <i>Your agency is not currently receiving AVL from another agency.</i>
            )}
          </Card.Body>
        </Card>
      </div>
      <div style={{ minWidth: '392px', width: '45%' }}>
        <Card>
          <Card.Header>
            <Card.Title>Shared AVL</Card.Title>
          </Card.Header>
          <Card.Body>
            {agencyAllowsSharingAvlWith.length ? (
              <div>
                <div style={{ paddingBottom: '15px', marginBottom: '15px', borderBottom: '1px solid lightgray' }}>
                  All agencies with whom your agency shares AVL data. <br /> The visibility icon indicates that the
                  agency is displaying your agency's AVL data in their map.
                </div>
                <div>
                  <Table
                    headerHeight={24}
                    headerStyle={{ textAlign: 'left', overflow: 'visible' }}
                    height={(1 + agencyAllowsSharingAvlWith.length) * 24 || 240}
                    width={360}
                    rowGetter={({ index }) => agencyAllowsSharingAvlWith[index]}
                    rowCount={agencyAllowsSharingAvlWith.length || 0}
                    rowHeight={24}
                  >
                    <Column
                      dataKey="visibility"
                      width={30}
                      style={{ textAlign: 'left' }}
                      // label="visibility"
                      label={
                        <span>
                          <FaEye />
                        </span>
                      }
                      cellRenderer={({ cellData }) => {
                        if (cellData === true) {
                          return <FaEye />
                        } else {
                          return ''
                        }
                      }}
                    />
                    <Column dataKey="displayName" width={300} label="agency" style={{ textAlign: 'left' }} />
                  </Table>
                </div>
              </div>
            ) : (
              <div>
                <i>Your agency is not sharing AVL data.</i>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

const getDisplayNames = (state, ownProps) => {
  const agencyDisplayNames = state.firebase.data.agencies
  const displayNamesOnly = {}
  for (const agency in agencyDisplayNames) {
    displayNamesOnly[agency] = agencyDisplayNames[agency].displayName
  }
  return displayNamesOnly
}

const mapStateToProps = (state, ownProps) => ({
  agencyName: selectors.getAgencyNameUserOrUrl(state, ownProps),
  agencyAllowsSharingAvlWith: selectors.getAgencyAllowsSharingAvlWith(state, ownProps),
  agencySharesAvlWith: selectors.getAgencySharesAvlWith(state, ownProps),
  agencyDisplayNames: getDisplayNames(state, ownProps),
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveReceivesSharedAvlFrom,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firebaseConnect(props => {
    const paths = []
    const { agencyAllowedSharing, agencyAllowsSharingAvlWith, agencyName } = props
    if (agencyName) {
      paths.push(
        { path: `/agencySharesAvlWith/${agencyName}`, type: 'once' },
        { path: `/agencyAllowsSharingAvlWith/${agencyName}`, type: 'once' }
      )
    }

    if (agencyAllowedSharing) {
      agencyAllowedSharing.forEach(agency => {
        paths.push({ path: `/agencies/${agency}/displayName`, type: 'once' })
      })
    }
    if (agencyAllowsSharingAvlWith) {
      Object.keys(agencyAllowsSharingAvlWith).forEach(agency => {
        paths.push({ path: `/agencies/${agency}/displayName`, type: 'once' })
      })
    }
    return paths
  })
)(ConfigAvlForm)

// avlTransmitActivatedDefault, avlTransmitToggleEnabled, and avlRemoveFromMapAfterTime
