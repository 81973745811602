import React from 'react'
import { Cell } from 'fixed-data-table-2'

const HexColorDiv = ({ color }) => {
  const style = {
    backgroundColor: '#' + color,
    height: '20px',
  }
  return <div style={style} />
}

const getColorCellRender = val => {
  if (typeof val === 'string' || val instanceof String) {
    if (val.length === 8) {
      return <HexColorDiv color={val.slice(2)} />
    } else if (val.length === 6) {
      return <HexColorDiv color={val} />
    }
  }
  // if no valid hex color string input, just display whatever the value is, as a string
  return String(val)
}

const ColorCell = ({ rowIndex, data, columnKey, ...props }) => {
  const val = data.get(rowIndex)[columnKey]
  return <Cell {...props}>{getColorCellRender(val)}</Cell>
}

export default ColorCell
