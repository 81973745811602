import {
  LOAD_DEVICE,
  LOAD_DEVICES_FOR_AGENCY,
  LOAD_ALL_AGENCY_DEVICES,
  CLEAR_AGENCY_DEVICES,
  SORT_DEVICES,
} from '../constants/ActionTypes'
import Immutable from 'immutable'
import Device from '../records/device'
import { sortDataGridItems } from '../utils/sorting'

export const DevicesState = Immutable.Record({
  // eslint-disable-line new-cap
  data: Immutable.Map(),
  sorting: Immutable.Map(),
})

const initialState = new DevicesState()

const handlers = {
  [LOAD_DEVICE]: (state, action) => {
    const { agencyName, device, deviceId } = action.payload
    return state.updateIn(['data', agencyName, deviceId], () => new Device({ key: deviceId, agencyName, ...device }))
  },
  [LOAD_ALL_AGENCY_DEVICES]: (state, action) => {
    const allAgencyDevicesNode = action.payload.allAgencyDevicesNode
    let immutableDevicesNode = Immutable.Map()
    let agencyDevicesNode
    for (const [agencyName, agencyDevicesObj] of Object.entries(allAgencyDevicesNode)) {
      agencyDevicesNode = Immutable.fromJS(agencyDevicesObj, (key, val) => {
        // eslint-disable-line no-loop-func
        // if it has one of following columns, it is definitely a device
        if (val && (val.get('deviceType') || val.get('hardwareUid') || val.get('timeStamp'))) {
          // device objects need a key and agencyName
          const mapWithKeyAndAgency = val
            .toMap()
            .set('key', key)
            .set('agencyName', agencyName)
          return new Device(mapWithKeyAndAgency)
        }
        const isIndexed = Immutable.Iterable.isIndexed(val)
        return isIndexed ? val.toList() : val.toMap()
      })
      immutableDevicesNode = immutableDevicesNode.set(agencyName, agencyDevicesNode)
    }
    return state.set('data', immutableDevicesNode)
  },
  [LOAD_DEVICES_FOR_AGENCY]: (state, action) => {
    const agencyName = action.payload.agencyName
    let devices = Immutable.Map()
    if (action.payload.devices) {
      for (const [key, device] of Object.entries(action.payload.devices)) {
        devices = devices.set(key, new Device({ key: key, agencyName, ...device }))
      }
    }
    return state.update('data', map => map.set(agencyName, devices))
  },
  [CLEAR_AGENCY_DEVICES]: (state, action) => state.update('data', map => map.delete(action.payload.agencyName)),
  [SORT_DEVICES]: (state, action) => {
    const { attributeName, sortDirection } = action.payload
    const sortedDevices = sortDataGridItems(state.data, attributeName, sortDirection)
    return state
      .update('sorting', map => map.set('attributeName', attributeName))
      .update('sorting', map => map.set('sortDirection', sortDirection))
      .set('data', sortedDevices)
  },
}

export default function reducer(state = initialState, action) {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action)
  } else {
    return state
  }
}
