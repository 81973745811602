import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import ViewWrapper from '../components/ViewWrapper'
import SendNewDispatchForm from '../containers/SendNewDispatchForm'
import { withRouter, RouteComponentProps } from 'react-router-dom'

function mapStateToProps(state) {
  return {
    authUser: state.auth.user,
  }
}

type Props = {
  authUser: Record<string, any>
} & RouteComponentProps<any>

const DispatchView = (props: Props) => {
  const { match, history } = props
  const returnToDispatches = agencyName => history.push(`/dispatches/?agencyName=${agencyName}`)
  const { agencyName } = match.params
  return (
    <ViewWrapper sizable header={`Manually Send Dispatch to ${agencyName}`}>
      <SendNewDispatchForm agencyName={agencyName} onSuccess={returnToDispatches} onCancel={history.goBack} />
    </ViewWrapper>
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(DispatchView)
