import React from 'react'
import Input from '../Input'
import FormField from './FormField'
import { FormFieldProps } from './FormField'

type Props = {
  type: string
  disabled: Boolean
  placeholder?: string
  addonBefore?: any // React.Node once we upgrade React
}

const FormFieldInput = (props: FormFieldProps & Props) => (
  <FormField {...props}>
    <Input
      id={props.id}
      type={props.type}
      placeholder={props.placeholder}
      addonBefore={props.addonBefore}
      disabled={props.disabled}
      {...props.input}
    >
      {props.children}
    </Input>
  </FormField>
)

export default FormFieldInput
