import React from 'react'
import ViewWrapper from '../components/ViewWrapper'
import ResetPasswordForm from '../containers/ResetPasswordForm'

export default class ResetPasswordView extends React.Component {
  render() {
    return (
      <ViewWrapper header="Reset Password">
        <ResetPasswordForm />
      </ViewWrapper>
    )
  }
}
