import { createSelector } from 'reselect'

export const getUser = state => state.auth.user
export const getToken = state => state.auth.token

export const getUserRoleForInspectionsQuery = createSelector(
  getUser,
  user => {
    const rolesInOrderOfPreference = ['superAdmin', 'agi', 'agencyAdmin', 'inspector']
    let defaultRole = 'user'
    for (const role of rolesInOrderOfPreference) {
      if (user.roles.includes(role)) {
        defaultRole = role
        break
      }
    }
    return defaultRole
  }
)
