export * from './firestore'

export function createConstants(...constants) {
  return constants.reduce((acc, constant) => {
    acc[constant] = constant
    return acc
  }, {})
}

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type]

    return reducer ? reducer(state, action.payload) : state
  }
}

function _isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

export function objectToArray(obj) {
  const arr = []
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (_isObject(obj[key])) {
        if (obj[key].key) {
          console.warn('Object already has "key" property')
        } else {
          obj[key].key = key
        }
      }
      arr.push(obj[key])
    }
  }
  return arr
}

export const newRand = () =>
  Math.random()
    .toString(36)
    .substring(7)
