// agencies
export const ADD_AGENCY = 'ADD_AGENCY'
export const SAVE_AGENCY = 'SAVE_AGENCY'
export const LOAD_AGENCY_DISPLAY_NAME = 'LOAD_AGENCY_DISPLAY_NAME'
export const UPDATE_CONTACT_EMAIL = 'UPDATE_CONTACT_EMAIL'

// agencyAllowedSharing
export const LOAD_AGENCY_ALLOWED_SHARING = 'LOAD_AGENCY_ALLOWED_SHARING'
export const LOAD_ALL_AGENCY_ALLOWED_SHARING = 'LOAD_ALL_AGENCY_ALLOWED_SHARING'
export const SAVE_AGENCY_ALLOWED_SHARING = 'SAVE_AGENCY_ALLOWED_SHARING'

// agencyConfigs
export const LOAD_AGENCY_CONFIG = 'LOAD_AGENCY_CONFIG'
export const SAVE_AGENCY_CONFIG = 'SAVE_AGENCY_CONFIG'
export const SAVE_RECEIVES_SHARED_AVL_FROM = 'SAVE_RECEIVES_SHARED_AVL_FROM'
export const SAVE_AVL_DISPLAY_GROUPS = 'SAVE_AVL_DISPLAY_GROUPS'
export const HIDE_SHARED_AVL = 'HIDE_SHARED_AVL'
export const SHOW_SHARED_AVL = 'SHOW_SHARED_AVL'
export const SET_SHARED_AVL = 'SET_SHARED_AVL'
export const UPDATE_DISPATCH_GROUPS = 'UPDATE_DISPATCH_GROUPS'

// auth (not a firebase node)
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const LOAD = 'LOAD'
export const AUTHENTICATED = 'AUTHENTICATED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'
export const LOAD_AUTHORIZED_USER = 'LOAD_AUTHORIZED_USER'

// cad centers
export const LOAD_ALL_CAD_CENTERS = 'LOAD_ALL_CAD_CENTERS'

// deviceGroups
export const SAVE_DEVICE_GROUP = 'SAVE_DEVICE_GROUP'
export const SET_IS_DISPATCH_GROUP = 'SET_IS_DISPATCH_GROUP'
export const DELETE_DEVICE_GROUP = 'DELETE_DEVICE_GROUP'

// devices
export const LOAD_DEVICE = 'LOAD_DEVICE'
export const LOAD_DEVICES_FOR_AGENCY = 'LOAD_DEVICES_FOR_AGENCY'
export const LOAD_ALL_AGENCY_DEVICES = 'LOAD_ALL_AGENCY_DEVICES'
export const CLEAR_AGENCY_DEVICES = 'CLEAR_AGENCY_DEVICES'
export const SORT_DEVICES = 'SORT_DEVICES'
export const DELETE_DEVICE = 'DELETE_DEVICE'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'

// dispatches
export const LOAD_DISPATCHES_FOR_AGENCY = 'LOAD_DISPATCHES_FOR_AGENCY'
export const SORT_DISPATCHES = 'SORT_DISPATCHES'
export const ADD_DISPATCH = 'ADD_DISPATCH'
export const ADD_DISPATCH_PARSER = 'ADD_DISPATCH_PARSER'

// gisDataSources
export const LOAD_GIS_DATA_SOURCES = 'LOAD_GIS_DATA_SOURCES'
export const UPDATE_SPECIAL_SEARCH_DATA = 'UPDATE_SPECIAL_SEARCH_DATA'

// firebase
export const SET_SERVER_TIME_OFFSET = 'SET_SERVER_TIME_OFFSET'

// queues
export const LOAD_ALL_QUEUES = 'LOAD_ALL_QUEUES'
export const PUSH_TASK_TO_QUEUE = 'PUSH_TASK_TO_QUEUE'

// users
export const ADD_USER = 'ADD_USER'
export const CLEAR_ADD_USER_MESSAGE = 'CLEAR_ADD_USER_MESSAGE'
export const LOAD_ALL_USERS = 'LOAD_ALL_USERS'
export const LOAD_USER = 'LOAD_USER'
export const SAVE_USER = 'SAVE_USER'
