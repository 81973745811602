import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { reduxForm, formValues, Field, SubmissionError, InjectedFormProps } from 'redux-form'
import { addDispatch } from '../actions/dispatches'
import { pushTaskToQueue } from '../actions/queues'
import { Row, Col, Alert, Button, ButtonToolbar } from 'react-bootstrap'
import { firebaseConnect } from 'react-redux-firebase'
import {
  LoadingButton,
  FormFieldInput,
  FormFieldCheckbox,
  FormFieldReactSelect,
} from '../components/BootstrapReduxForm' // eslint-disable-line import/named
import { getRegion } from '../utils/getRegion'
import { validate } from 'postcode-validator'
import { getUser, getDeviceGroupsNode } from '../selectors'

const latLongTest = /^(-?\d{1,3}\.?\d*), *(-?\d{1,3}\.?\d*)$/
const dmsTest = /^(-?\d{1,3} \d{1,2} \d{1,2}.?\d*), *(-?\d{1,3} \d{1,2} \d{1,2}.?\d*)$/
const nsarcTest = /^(\d{1,3}°? \d{1,2}.?\d* [NS]), *(\d{1,3}°? \d{1,2}.?\d* [EW])$/
const usngTest = /^\d{1,3}[A-Z] ?[A-Z]{2} ?\d{1,5} ?\d{1,5}$/

const BUILDING_FIELD = 'building'
const CATEGORY_FIELD = 'category'
const CITY_FIELD = 'city'
const COUNTRY_FIELD = 'country'
const CROSS_STREET_FIELD = 'crossStreet'
const DEVICE_GROUPS = 'deviceGroups'
const DISPATCH_LAT_FIELD = 'dispatchLat'
const DISPATCH_LNG_FIELD = 'dispatchLng'
const INTERSECTION_FIELD = 'intersection'
const SHORT_DESC_FIELD = 'shortDesc'
const STATE_FIELD = 'state'
const STREET_FIELD = 'street'
const STREET_FIELD2 = 'street2'
const ZIP_CODE_FIELD = 'zipCode'

const CATEGORIES_ARRAY = ['UNKNOWN', 'FIRE', 'MEDICAL', 'TRANSFER', 'VEHICLE_ACCIDENT', 'HAZMAT']

function mapStateToProps(state, ownProps) {
  return {
    user: getUser(state),
    deviceGroupsNode: getDeviceGroupsNode(state),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addDispatch,
      pushTaskToQueue,
    },
    dispatch
  )
}

const DELIMITER = ','

function validateSendNewDispatchForm(data) {
  const errors = {}
  if (!data[SHORT_DESC_FIELD]) {
    errors[SHORT_DESC_FIELD] = 'Description field cannot be empty'
  }
  if (
    !validate(data[ZIP_CODE_FIELD], 'US') &&
    !validate(data[ZIP_CODE_FIELD], 'CA') &&
    !(data[ZIP_CODE_FIELD] === undefined || data[ZIP_CODE_FIELD] === null) &&
    data[ZIP_CODE_FIELD].length > 0
  ) {
    errors[ZIP_CODE_FIELD] = 'Must be valid US zip code or Canadian post code'
  }
  const location = String(data[DISPATCH_LAT_FIELD]) + ', ' + String(data[DISPATCH_LNG_FIELD])
  if (
    !latLongTest.test(location) &&
    !dmsTest.test(location) &&
    !nsarcTest.test(location) &&
    !usngTest.test(location) &&
    data[DISPATCH_LAT_FIELD] &&
    data[DISPATCH_LNG_FIELD]
  ) {
    errors[DISPATCH_LAT_FIELD] = 'Please enter valid latitude'
    errors[DISPATCH_LNG_FIELD] = 'Please enter valid longitude'
  }

  return errors
}

type Props = {
  onSuccess: Function
  // TODO: figure out correct type
  onCancel: (event: any) => void
  agencyName: string
  deviceGroupsNode: {
    [key: string]: {
      [key: string]: any
    }
  }
  error: string
  addDispatch: Function
  pushTaskToQueue: Function
  user: any
  // From formValues
  isIntersection: boolean
  country: string
} & InjectedFormProps

type State = {
  resourceGroup: string
}

class SendNewDispatchForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      resourceGroup: '',
    }
    this.props.initialize({
      [CATEGORY_FIELD]: this.getCategories()[0],
      [COUNTRY_FIELD]: 'US',
      [INTERSECTION_FIELD]: false,
      [STATE_FIELD]: '',
    })
  }

  getCategories() {
    return CATEGORIES_ARRAY
  }

  makeStreet(data) {
    let street = ''
    if (data[STREET_FIELD] && data[STREET_FIELD2]) {
      street = data[STREET_FIELD] + ' AND ' + data[STREET_FIELD2]
    } else if (data[STREET_FIELD]) {
      street = data[STREET_FIELD]
    }
    return street
  }

  makeRaw(data) {
    let raw = ''
    for (const i in data) {
      if (data[i]) {
        if (raw.length === 0) {
          raw += i + ': ' + JSON.stringify(data[i])
        } else {
          raw += ', ' + i + ': ' + JSON.stringify(data[i])
        }
      }
    }
    return raw
  }

  makeResourceGroupJson() {
    let groupSplit = this.state.resourceGroup.split(',')
    let output = {}
    groupSplit.forEach(element => {
      output[element] = true
    })
    this.setState({ resourceGroup: '' })
    return output
  }

  fireNewDispatch = data => {
    const agencyName = this.props.agencyName
    if (agencyName !== 'or_agi_fd' && agencyName !== this.props.user.agencyName) {
      const userIsSure = window.confirm(
        `You're not part of the ${agencyName} agency. Are you absolutely sure you want to send a message to it?`
      )
      if (!userIsSure) {
        return
      }
    }

    let deviceGroupJson
    if (this.state.resourceGroup) {
      deviceGroupJson = this.makeResourceGroupJson()
    }

    const d = new Date()
    const seconds = Math.round(d.getTime() / 1000)
    const messageObj = {
      building: data[BUILDING_FIELD] ? data[BUILDING_FIELD] : '',
      category: data[CATEGORY_FIELD] ? data[CATEGORY_FIELD] : '',
      city: data[CITY_FIELD] ? data[CITY_FIELD] : '',
      country: data[COUNTRY_FIELD] ? data[COUNTRY_FIELD] : '',
      crossStreet: data[CROSS_STREET_FIELD] ? data[CROSS_STREET_FIELD] : '',
      dispatchLat: data[DISPATCH_LAT_FIELD] ? data[DISPATCH_LAT_FIELD] : 0,
      dispatchLng: data[DISPATCH_LNG_FIELD] ? data[DISPATCH_LNG_FIELD] : 0,
      dispatchUnits: deviceGroupJson ? deviceGroupJson : null,
      firebaseTimeStamp: { '.sv': 'timestamp' },
      raw: this.makeRaw(data),
      intersection: data[INTERSECTION_FIELD] ? data[INTERSECTION_FIELD] : false,
      lat: data[DISPATCH_LAT_FIELD] ? data[DISPATCH_LAT_FIELD] : 0,
      lng: data[DISPATCH_LNG_FIELD] ? data[DISPATCH_LNG_FIELD] : 0,
      shortDesc: data[SHORT_DESC_FIELD] ? data[SHORT_DESC_FIELD] : '',
      state: data[STATE_FIELD] ? data[STATE_FIELD] : '',
      street: this.makeStreet(data),
      timeStamp: seconds,
      zipCode: data[ZIP_CODE_FIELD] ? data[ZIP_CODE_FIELD] : '',
    }
    const dispatchObj = {
      subject: data[SHORT_DESC_FIELD] ? data[SHORT_DESC_FIELD].substring(0, 199) : '',
      agencyName: agencyName,
      message: messageObj,
    }
    // return null
    return this.props.addDispatch(dispatchObj).then(result => {
      if (result.error) {
        throw new SubmissionError({ _error: result.payload.message })
      } else {
        this.props.onSuccess(agencyName)
      }
    })
  }

  onSelectGroup = (event, group) => {
    this.setState({ resourceGroup: group })
  }

  getDeviceGroupOptions(props: Props) {
    let deviceGroup = []
    const { deviceGroupsNode, agencyName } = props
    if (deviceGroupsNode && deviceGroupsNode[agencyName]) {
      Object.entries(deviceGroupsNode[agencyName]).forEach(([key, val]) => {
        if (val.dispatchGroup) {
          deviceGroup.push({ value: key, label: key })
        }
      })
    }
    return deviceGroup
  }

  render() {
    const { error, handleSubmit, country, submitting } = this.props
    let regionList
    if (country) {
      regionList = getRegion(country)
    } else {
      regionList = getRegion('US')
    }
    return (
      <form>
        <div>
          <Row>
            <Field name={CATEGORY_FIELD} component={FormFieldInput} type="select" label="Category" labelColSize={4}>
              {CATEGORIES_ARRAY.map(category => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </Field>
          </Row>
          <Row>
            <Field
              name={SHORT_DESC_FIELD}
              component={FormFieldInput}
              type="text"
              label="Description"
              help="shortDesc"
            />
          </Row>
          <Row>
            <Field
              name={DEVICE_GROUPS}
              component={FormFieldReactSelect}
              label="Resources"
              labelColSize={4}
              options={this.getDeviceGroupOptions(this.props)}
              placeholder="Select resource(s)"
              multi
              delimiter={DELIMITER}
              onChange={this.onSelectGroup}
            />
          </Row>
          <Row>
            <Field
              name={INTERSECTION_FIELD}
              component={FormFieldCheckbox}
              label="Intersection"
              labelColSize={4}
              help="Whether or not this dispatch should be treated as an intersection"
            />
          </Row>
          <Row>
            <Field name={STREET_FIELD} component={FormFieldInput} type="text" label="Street / Address" />
            {this.props.isIntersection && (
              <Field name={STREET_FIELD2} component={FormFieldInput} type="text" label="Street 2" />
            )}
          </Row>
          <Row>
            <Field
              name={CROSS_STREET_FIELD}
              component={FormFieldInput}
              type="text"
              label="Cross Street"
              help="crossStreet"
            />
          </Row>
          <Row>
            <Field name={BUILDING_FIELD} component={FormFieldInput} type="text" label="Building / Place Name" />
          </Row>
          <Row>
            <Field
              name={CITY_FIELD}
              component={FormFieldInput}
              type="text"
              label="City"
              help="The city this dispatch is in. Helpful to have when there are multiple identical address results in separate cities."
            />
          </Row>
          <Row>
            <Field
              name={STATE_FIELD}
              component={FormFieldInput}
              type="select"
              label="State / Province"
              labelColSize={4}
            >
              <option key="" value="" />
              {regionList.map(region => (
                <option key={region.abbreviation} value={region.abbreviation}>
                  {region.name}
                </option>
              ))}
            </Field>
          </Row>
          <Row>
            <Field name={ZIP_CODE_FIELD} component={FormFieldInput} type="text" label="Zip Code / Post Code" />
          </Row>
          <Row>
            <Field name={COUNTRY_FIELD} component={FormFieldInput} type="select" label="Country" labelColSize={4}>
              <option key="US" value="US">
                United States
              </option>
              <option key="CA" value="CA">
                Canada
              </option>
            </Field>
          </Row>
          <Row>
            <Col sm={6}>
              <Field
                name={DISPATCH_LAT_FIELD}
                component={FormFieldInput}
                type="text"
                label="Latitude"
                help="dispatchLat"
              />
            </Col>
            <Col sm={6}>
              <Field
                name={DISPATCH_LNG_FIELD}
                component={FormFieldInput}
                type="text"
                label="Longitude"
                help="dispatchLng"
              />
            </Col>
          </Row>
        </div>
        {error && (
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row className="form-group">
          <Col sm={{ span: 6, offset: 6 }} className="text-center">
            <ButtonToolbar>
              <LoadingButton
                variant="primary"
                size="lg"
                label="Send"
                loading={submitting}
                loadingLabel="Sending"
                onClick={handleSubmit(this.fireNewDispatch)}
                type="submit"
              />
              <Button onClick={this.props.onCancel} size="lg">
                Cancel
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </form>
    )
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'sendNewDispatch',
    validate: validateSendNewDispatchForm,
  }),
  formValues({ isIntersection: 'intersection', country: 'country' }),
  firebaseConnect(props => {
    const { agencyName } = props
    let paths = []
    if (agencyName) {
      paths.push(`deviceGroups/${agencyName}`)
    }
    return paths
  })
)(SendNewDispatchForm)
