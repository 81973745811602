import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import LoadDataForm from '../containers/LoadDataForm'
import FieldMapDataForm from '../containers/FieldMapDataForm'
import ValueMapDataForm from '../containers/ValueMapDataForm'
import FirestoreFunctionViewWrapper, { TaskViewWrapper } from '../containers/FirestoreFunctionViewWrapper'
import queryString from 'query-string'
import firebase from '../firebase'

type Props = {
  task: {
    _error?: string
    _status?: string
    fileName?: string
  }
  taskProgress?: {
    percent: number
    label: string
  }
  taskFirebasePath: string
} & RouteComponentProps

// delete gis data in firebase storage on gis data upload success
async function handleSuccess(taskId, name) {
  const storageRef = firebase.storage().ref()
  const deleteRef = storageRef.child(`loadGisDataToPostgres/${taskId}/${name}`)
  try {
    await deleteRef.delete()
  } catch (e) {
    console.log(e.message)
  }
}

const LoadDataView = ({ location, history }: Props) => (
  <FirestoreFunctionViewWrapper
    firestoreFunctionName="loadGisDataToPostgres"
    location={location}
    render={({ task, taskFirebasePath, taskProgress }) => {
      const urlQuery = queryString.parse(location.search)
      if (!urlQuery.taskId) {
        return (
          <TaskViewWrapper header="Load data">
            <Card bg="danger">
              <Card.Body>
                WARNING. Be cautious using this tool for now. Only use it to load data into new agencies (agencies that
                don't exist on the old Editor database). Once we're fully migrated to the new db you won't have to worry
                about this. If you want to test this tool (for development purposes), please set up your dev environment
                to point to incidentview-dev firebase.
              </Card.Body>
            </Card>
            <LoadDataForm />
          </TaskViewWrapper>
        )
      }

      if (task) {
        if (task._error) {
          return (
            <TaskViewWrapper header="An error occurred while loading data">
              <Card bg="danger">
                <Card.Body>{task._error}</Card.Body>
              </Card>
              <Button variant="primary" onClick={() => history.push('/gis/load')}>
                Back to load GIS data
              </Button>
            </TaskViewWrapper>
          )
        }
        if (task._status === 'AWAITING_FIELD_MAP') {
          return (
            <TaskViewWrapper header="Field map data" {...taskProgress}>
              <FieldMapDataForm task={task} taskFirebasePath={taskFirebasePath} />
            </TaskViewWrapper>
          )
        } else if (task._status === 'PROCESSING_FIELD_MAP') {
          return <TaskViewWrapper header="Field mapping data..." {...taskProgress} />
        } else if (task._status === 'AWAITING_VALUE_MAP') {
          return (
            <TaskViewWrapper header="Value map data">
              <ValueMapDataForm task={task} taskFirebasePath={taskFirebasePath} />
            </TaskViewWrapper>
          )
        } else if (task._status === 'PROCESSING_VALUE_MAP') {
          return <TaskViewWrapper header="Field and value mapping data..." {...taskProgress} />
        } else if (task._status === 'COMPLETE') {
          return (
            <TaskViewWrapper
              header="Data upload complete!"
              {...taskProgress}
              onLoad={handleSuccess(urlQuery.taskId, task.fileName)}
            >
              <div>Please export vector tiles after uploads are finished for {task.agencyName}</div>
              <Button variant="primary" onClick={() => history.push(`/gis?agencyName=${task.agencyName}`)}>
                Back to GIS data
              </Button>
            </TaskViewWrapper>
          )
        } else {
          return <TaskViewWrapper header="Processing uploaded data..." {...taskProgress} />
        }
      } else {
        // Haven't yet loaded the task from firebase
        return <div />
      }
    }}
  />
)

export default withRouter(LoadDataView)
