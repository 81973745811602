import React from 'react'
// TODO: use alert to handle errors
import { Alert, Row, Col } from 'react-bootstrap'
import { FieldProps } from 'formik'
import _ from 'lodash'

type Props = {
  label: string
  children: any
} & FieldProps

const FormikGroup = (props: Props) => {
  const { field, form } = props
  const fieldName = field.name
  const error = _.get(form.touched, fieldName) && form.errors[fieldName]
  return (
    <Col sm={12} style={{ paddingTop: '0.5em' }}>
      <Row>
        <Col sm={4} className="control-label">
          <label htmlFor={fieldName}>{props.label}</label>
        </Col>
        <Col sm={7}>{props.children}</Col>
      </Row>
      <Col sm={{ span: 7, offset: 4 }}>{error && <Alert variant="danger">{error}</Alert>}</Col>
    </Col>
  )
}

export default FormikGroup
