import React from 'react'
import FormField from './FormField'
import { WrappedFieldProps } from 'redux-form'

type Props = {
  label: string
  value: string
} & WrappedFieldProps &
  any

export default class StaticField extends React.Component<Props> {
  shouldComponentUpdate(nextProps) {
    return this.props.label !== nextProps.label || this.props.value !== nextProps.value
  }

  render() {
    const { label, value } = this.props
    return (
      <FormField inputClass="form-control-static" label={label} {...this.props}>
        {value}
      </FormField>
    )
  }
}
